import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  hideUserProfileUI,
  hideUserAccount,
  resetPrivateBox,
  initPrivateBox,
  hideAccountUI,
} from './../../redux/actions/appActions';
import C from './../../util/Constants';
import axios from 'axios';

const AccountContainer = ({ dispatch, id }) => {
  const [account, setAccount] = useState({
    load: 2 /* -1 for error, 0 for loading, 1 for sccess, 2 for not loading */,
    update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
    data: {
      fullname: '',
      picture: '',
    },
    userdata: '',
    privateChatSend: 2,
    privateChatDelete: 2,
    privateChatUnBlock: 2,
  });

  useEffect(() => {
    setAccount({ ...account, load: 0 });

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
          '/a/user/account?id=' +
          id +
          '&email=' +
          spSession.email +
          '&token=' +
          spSession.token
      )
      .then((response) => {
        var res = response.data.profile;
        var userdata = response.data.userdata;
        setAccount({ ...account, load: 1, data: res, userdata: userdata });
      })
      .catch((error) => {
        if (error.status === 403) {
          return;
        }
        setAccount({ load: -1 });
      });
  }, []);

  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));

      return spSession;
    } catch (e) {
      return false;
    }
  };

  const changeColor = (e) => {
    console.log(e);
    console.log(e.target);
    console.log(e.target.value);
    setAccount({
      ...account,
      userdata: { ...account.userdata, color: e.target.value },
    });

    var color = e.target.value;

    var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
    if (spSession === null) return;

    setAccount({ ...account, update: 0 });

    axios
      .post(C.API_EP + '/a/user/account/color', {
        email: spSession.email,
        token: spSession.token,
        color: color,
      })
      .then((response) => {
        setAccount({
          ...account,
          userdata: { ...account.userdata, color: color },
          update: 1,
        });
      })
      .catch((error) => {
        setAccount({ ...account, update: -1 });
      });
  };

  const changeTColor = (e) => {
    console.log(e);
    console.log(e.target);
    console.log(e.target.value);
    setAccount({
      ...account,
      userdata: { ...account.userdata, tcolor: e.target.value },
    });

    var tcolor = e.target.value;

    var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
    if (spSession === null) return;

    setAccount({ ...account, update: 0 });

    axios
      .post(C.API_EP + '/a/user/account/tcolor', {
        email: spSession.email,
        token: spSession.token,
        tcolor: tcolor,
      })
      .then((response) => {
        setAccount({
          ...account,
          userdata: { ...account.userdata, tcolor: tcolor },
          update: 1,
        });
      })
      .catch((error) => {
        setAccount({ ...account, update: -1 });
      });
  };

  const changeSounds = (e) => {
    var sounds = e.target.value;

    var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
    if (spSession === null) return;

    setAccount({ ...account, update: 0 });

    axios
      .post(C.API_EP + '/a/user/account/sounds', {
        email: spSession.email,
        token: spSession.token,
        sounds: sounds,
      })
      .then((response) => {
        setAccount({
          ...account,
          userdata: { ...account.userdata, sounds: sounds },
          update: 1,
        });
      })
      .catch((error) => {
        setAccount({ ...account, update: -1 });
      });
  };

  const changePrivateOptions = (e) => {
    var privateOptions = e.target.value;

    var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
    if (spSession === null) return;

    setAccount({ ...account, update: 0 });

    axios
      .post(C.API_EP + '/a/user/account/privateOptions', {
        email: spSession.email,
        token: spSession.token,
        options: privateOptions,
      })
      .then((response) => {
        setAccount({
          ...account,
          userdata: { ...account.userdata, privateOptions: e.target.value },
          update: 1,
        });
      })
      .catch((error) => {
        setAccount({ ...account, update: -1 });
      });
  };

  const closeUserAccountUI = () => {
    dispatch(hideAccountUI());
  };

  return (
    <div className='sp-profile-wrapper'>
      <div className='sp-profile-inner'>
        <div className='card'>
          <div className='card-header'>
            User Account
            <i
              className='fas fa-times sp-small-icon'
              onClick={closeUserAccountUI}
            ></i>
          </div>
          <div className='card-body' style={{ padding: '10px' }}>
            {typeof account !== 'undefined' && account.load === 0 && (
              <div class='progress'>
                <div
                  class='progress-bar progress-bar-striped progress-bar-animated'
                  style={{ width: '100%' }}
                ></div>
              </div>
            )}

            {typeof account !== 'undefined' && account.load === -1 && (
              <div class='alert alert-danger'>Account Loading Error.</div>
            )}

            {typeof account !== 'undefined' && account.load === 1 && (
              <div className='row'>
                <div className='col-md-12'>
                  <b>Text Color</b>
                  <input
                    type='color'
                    className='form-control'
                    value={account.userdata.tcolor}
                    onChange={(e) => {
                      changeTColor(e);
                    }}
                  />
                </div>
                <div className='col-md-12'>
                  <b>Background Color</b>
                  <input
                    type='color'
                    className='form-control'
                    value={account.userdata.color}
                    onChange={(e) => {
                      changeColor(e);
                    }}
                  />
                </div>
                <div className='col-md-12'>
                  <b>Sounds</b>
                  <select
                    className='form-control'
                    value={account.userdata.sounds}
                    onChange={(e) => {
                      changeSounds(e);
                    }}
                  >
                    <option value='1'>All Sounds</option>
                    <option value='0'>No Sounds</option>
                    <option value='2'>PM Sounds</option>
                  </select>
                </div>
                <div className='col-md-12'>
                  <b>Who can PM you?</b>
                  <select
                    className='form-control'
                    value={account.userdata.privateOptions}
                    onChange={(e) => {
                      changePrivateOptions(e);
                    }}
                  >
                    <option value='0'>Everyone</option>
                    <option value='2'>No one</option>
                    <option value='1'>Users in the same server</option>
                  </select>
                </div>
              </div>
            )}

            <div style={{ padding: '10px 5px', paddingTop: '20px' }}>
              <input
                type='checkbox'
                onChange={() => {
                  if (localStorage.getItem('old_layout') === null) {
                    localStorage.setItem('old_layout', true);
                  } else {
                    localStorage.removeItem('old_layout');
                  }

                  setTimeout(function () {
                    window.location.reload();
                  }, 10);
                }}
                checked={
                  localStorage.getItem('old_layout') !== null ? true : false
                }
              />{' '}
              New Layout
            </div>
          </div>
          <div className='card-footer' style={{ textAlign: 'center' }}>
            {typeof account !== 'undefined' && account.update === 0 && (
              <div class='progress' style={{ height: '9px', margin: '15px' }}>
                <div
                  class='progress-bar progress-bar-striped progress-bar-animated'
                  style={{ width: '100%', height: '9px' }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    parentData: state.appState.chatbox.userProfile,
  };
};

const UserAccount = connect(mapStateToProps)(AccountContainer);

export default UserAccount;
