import Axios from 'axios';
import React, { useState } from 'react';
import C from './../../../util/Constants';


function RoomCardAction({ data, setData }) {

    const [updatedAllowedGuests, setUpdatedAllowedGuests] = useState(data.room.allowGuests);
    const [updatedAllowedGuestsError, setUpdatedAllowedGuestsError] = useState("");
    const [updatedAllowedGuestsSuccess, setUpdatedAllowedGuestsSuccess] = useState("");

    const updateAllowedGuests = () => {
        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .post(C.API_EP + "/api/admin/room/updateAllowGuests", {
                email: spSession.email,
                token: spSession.token,
                rid: data.room._id,
                allowGuests: updatedAllowedGuests,
            })
            .then((response) => {
                if (response.data.status) {
                    // setData({ ...data, user: { ...data.user, verified: updatedVerified } })
                    // updateRoom(room._id, "isFeatured", updatedFeatured == 0 ? false : true);
                    setUpdatedAllowedGuestsSuccess("Operation successful !");
                    setData({ ...data, room: { ...data.room, allowGuests: updatedAllowedGuests } });
                }
                else {
                    setUpdatedAllowedGuestsError(response.data.message)
                }
            })
            .catch((error) => {

            });
    }

    return (
        <div>
            <div
                className="form-group user-option-area"
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div style={{
                    display: 'flex'
                }}>
                    <label>Allow Guests </label>
                    <select
                        onChange={(e) => {
                            // setUpdatedVerified(e.target.value)
                            // setUpdatedFeatured(e.target.value);
                            if (e.target.value === "true") {
                                setUpdatedAllowedGuests(true);
                            }
                            if (e.target.value === "false") {
                                setUpdatedAllowedGuests(false);
                            }
                        }}
                        className="form-control"
                    >
                        <option selected={data.room.allowGuests} value="true">Yes</option>
                        <option selected={!data.room.allowGuests} value="false">No</option>
                    </select>
                </div>


                {updatedAllowedGuestsError !== "" &&
                    <div
                        style={{
                            background: '#d55a5a',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            justifyContent: 'space-between',
                            padding: '20px',
                            margin: '10px',
                            borderRadius: '3px'
                        }}
                    >
                        <span
                            style={{ cursor: 'pointer' }}
                            className="fa fa-times"
                            onClick={() => {
                                setUpdatedAllowedGuestsError("")
                            }}
                        ></span>
                        <p
                            style={{
                                margin: 0,
                                padding: 0
                            }}
                        >{updatedAllowedGuestsError}</p>
                    </div>
                }

                {updatedAllowedGuestsSuccess !== "" &&
                    <div
                        style={{
                            background: '#4caf50',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            justifyContent: 'space-between',
                            padding: '20px',
                            margin: '10px',
                            borderRadius: '3px'
                        }}
                    >
                        <span
                            style={{ cursor: 'pointer' }}
                            className="fa fa-times"
                            onClick={() => {
                                setUpdatedAllowedGuestsSuccess("")
                            }}
                        ></span>
                        <p
                            style={{
                                margin: 0,
                                padding: 0
                            }}
                        >{updatedAllowedGuestsSuccess}</p>
                    </div>
                }


                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right'
                    }}
                >
                    <button
                        disabled={data.room.allowGuests == updatedAllowedGuests}
                        onClick={() => {
                            updateAllowedGuests()
                        }}
                        className="btn btn-primary"
                    >Update</button>
                </div>
            </div>

        </div>
    )
}

export default RoomCardAction