import React, { useEffect, useState, useRef } from 'react';
import C from './../../util/Constants';
import axios from 'axios';
import { Fragment } from 'react';

const MediaPlayerPortable = ({ media, closeHandler }) => {
    const InputFile = useRef(null);
    const [loading, setLoading] = useState(true);

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100%',
                background: '#fff',
                zIndex: 999,
                overflow: 'auto'
            }}>

            <div
                style={{
                    height: '8%',
                    width: '100%'
                }}>
                <h3
                    style={{
                        margin: '5px'
                    }}>Media Viewer</h3>
                <span className="fa fa-times"
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        top: '10px'
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        closeHandler(false);
                    }}></span>
            </div>

            <hr style={{ margin: 0 }} />

            <div
                style={{
                    height: '84%',
                    width: '100%'
                }}>
                {typeof media !== 'undefined' && media.type === 3 && <div
                    style={{
                        height: '100%',
                        width: '100%'
                    }}>
                    <iframe src={media.extra.data}
                        style={{
                            width: '100%',
                            height: '100%',
                            maxWidth: '100%',
                            maxHeight: '100%'
                        }}
                        title="External Media"
                    >
                    </iframe></div>}

                {typeof media !== 'undefined' && media.type === 1 &&
                    <Fragment>
                        {loading &&
                            <div style={{ background: '#fff', position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <i className="fas fa-sync fa-spin"></i>
                            </div>
                        }
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: '#000000',
                                padding: '0px'
                            }}>
                            <img src={media.extra.data}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%'
                                }}
                                alt="img upload"
                                onLoad={(e) => {
                                    console.log(e);
                                    setLoading(false);
                                }}
                            />
                        </div>
                    </Fragment>

                }

                {typeof media !== 'undefined' && media.type === 2 &&
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#000000',
                            padding: '0px'
                        }}>
                        <video src={media.extra.data}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%'
                            }} controls autoPlay />
                    </div>}

            </div>

            <hr style={{ margin: 0 }} />

            <div style={{ display: 'none', height: '8%', width: '100%' }}>
                {/* <div style={{ textAlign: 'center' }}>by
                    <a href="#" style={{}}>@{
                        typeof media.extra.uploader !== 'undefined'
                            ? media.extra.uploader
                            : 'Anonymous'}
                    </a>
                </div> */}
            </div>

        </div>
    );
};



export default MediaPlayerPortable;
