import React from 'react'
import { Fragment } from 'react'

function ThreadVideoLight({ src, vidstyle }) {
    const [loading, setLoading] = React.useState(true)
    return (
        <Fragment
        >
            <span style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && <i className="fas fa-sync fa-spin"></i>}
            </span>

            <span
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#eeeeee5e'
                }}>
                {!loading && <i className="fa fa-play" style={{ fontSize: '75px', color: '#777' }}></i>}
            </span>

            <video

                src={src}
                style={vidstyle}
                className="thread-gallery-light-vid"
                onLoadedData={(e) => {
                    console.log(e);
                    setLoading(false);
                }}
                onProgress={(e) => {
                    //console.log(e);
                }}
            />
        </Fragment>
    )
}

export default ThreadVideoLight
