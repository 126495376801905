import React, { useState, useEffect, Fragment } from 'react'
import AudioBroadcastPlayer from './AudioBroadcastPlayer';
import { consumedAudioStreamChunk, dispatchNotification, downgradeFromSpeaker, joinRoomBroadcast, leaveRoomBroadcast, peekRoomBroadcast, sendAudioSlice, uninitAudioStreamBuffer, upgradeToSpeaker } from '../../redux/actions/appActions';
import { connect } from 'react-redux';
import C from './../../util/Constants';

var mediaRecorder;
var mediaStream;
var mediaSources = {};
var sourceBuffers = {};
var localQueue = {};

const AudioBroadcastComponent = ({ dispatch, chatboxState }) => {

    const [playing, setPlaying] = useState(false);

    const [joined, setJoined] = useState(false);
    const [broadcasting, setBroadcasting] = useState(false);

    const [audioStream, setAudioStream] = React.useState([]);

    const sourceOpen = (e, username) => {
        console.log("Zest: 92", e)
        console.log("Zest: 92", "Source open for " + username);
        var mime = 'audio/webm; codecs=opus';
        var mediaSource = e.target;
        //alert(mediaSource.sourceBuffers.length);
        if (mediaSource.sourceBuffers.length > 0) {
            return;
        }
        console.log(mediaSource);
        sourceBuffers[username] = mediaSource.addSourceBuffer(mime);
        console.log("SBL: ", mediaSources[username]);
        consumeNextChunk(username, sourceBuffers[username]);
        sourceBuffers[username].addEventListener('updateend', function () {
            consumeNextChunk(username, sourceBuffers[username]);
        })
    }

    const consumeNextChunk = (username, sourceBuffer) => {
        if (!localQueue[username])
            return;
        var queue = localQueue[username];
        queue.index++;
        var packet = queue.data[queue.index];
        console.log({ localQueue });
        console.log("Zest: 92 | packet", packet)
        if (packet) {
            console.log("Adding to source buffer ...")
            console.log({ sourceBuffer })
            console.log(sourceBuffer.updating)
            if (!sourceBuffer.updating)
                sourceBuffer.appendBuffer(packet);
        }
        else {
            queue.index--;
        }
    }

    useEffect(() => {
        setTimeout(() => {
            var audioData = chatboxState.stream.audio;
            console.log({ audioData })
            audioData.map(e => {
                var chunks = e.data;
                chunks.map((chunk, j) => {
                    if (!chunk[0]) {
                        console.log("Pakcet: ", chunk[1]);
                        localQueue[e.username].data.push(chunk[1]);

                        if (sourceBuffers[e.username] && sourceBuffers[e.username].updating === false) {
                            consumeNextChunk(e.username, sourceBuffers[e.username]);
                        }

                        dispatch(consumedAudioStreamChunk(e.username, j));
                    }
                })
            })

        }, 500);
    }, [chatboxState.stream.audio])

    useEffect(() => {
        var audioSpeakers = chatboxState.stream.audioSpeakers;
        console.log("UPDATE SPEAKERS: ", audioSpeakers.length);
        //alert(audioSpeakers.length + " : " + audioStream.length)
        console.log(audioStream.length);
        console.log(audioSpeakers.length);
        if (audioSpeakers.length < audioStream.length) {
            //alert(audioSpeakers.length + " : " + audioStream.length)
            var audioStreamLength = audioStream.length;
            for (var i = 0; i < audioStreamLength; i++) {
                var currentUsername = audioStream[i].username;
                // alert(audioSpeakers.some(e => e.username === currentUsername));
                if (!audioSpeakers.some(e => e.username === currentUsername)) {
                    //alert("Removing: " + currentUsername);
                    dispatch(uninitAudioStreamBuffer(currentUsername))
                    setAudioStream(audioStream.filter(e => e.username !== currentUsername));
                    setTimeout(() => {
                        localQueue[currentUsername] = undefined;
                        mediaSources[currentUsername] = undefined;
                    }, 1000);
                }
            }

            // audioSpeakers.map(({ username }) => {
            //     alert(audioStream.some(e => e.username === username));
            //     alert(JSON.stringify(audioStream));
            //     if (!audioStream.some(e => e.username === username)) {
            //         alert(JSON.stringify(audioStream.filter(e => e.username === username)));
            //         setAudioStream([...audioStream.filter(e => e.username === username)]);
            //         localQueue[username] = undefined;

            //         mediaSources[username] = undefined;
            //     }
            // })

        }
        else {
            audioSpeakers.map(({ username }) => {
                var alreadyInit = audioStream.some((e => e.username === username));
                console.log({ username })
                if (!alreadyInit) {
                    //setAudioStream([...audioStream, {  srcUrl: '' }]);

                    localQueue[username] = { index: -1, data: [] };

                    mediaSources[username] = new MediaSource();

                    setAudioStream([...audioStream, { username: username, srcUrl: URL.createObjectURL(mediaSources[username]) }]);

                    console.log("Zest: 92", "Setting audio speaker related data for " + username)
                    mediaSources[username].addEventListener('sourceopen', (e) => {
                        sourceOpen(e, username);
                    });
                }
            })
        }
    }, [chatboxState.stream.audioSpeakers])

    useEffect(() => {
        if (chatboxState.stream.speaking) {
            navigator
                .mediaDevices
                .getUserMedia({ audio: true, video: false })
                .then(handleSuccess)
                .catch(handleFailure);
        }
    }, [chatboxState.stream.speaking])

    const joinBroadcast = () => {

        dispatch(joinRoomBroadcast(chatboxState.roomId));
        return;
        navigator
            .mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then(handleSuccess);
    }

    const startAudioBroadcast = () => {
        dispatch(upgradeToSpeaker(chatboxState.roomId))
    }



    const handleFailure = e => {
        // if(e.name === "NotFoundError")
        alert("Error: " + e.message);
        console.log({ e })
        console.log(e.code)
    }

    const handleSuccess = (stream) => {

        console.log(stream)

        mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = function (e) {
            const d = e.data;
            dispatch(sendAudioSlice(d, chatboxState.roomId));
            // console.log(d);
            // const audioURL = window.URL.createObjectURL(d);
            // //setAudioSrc(audioURL);
            // const audio = new Audio(audioURL);
            // audio.play();
        }

        console.log(mediaRecorder)
        mediaRecorder.start(1000);

        mediaRecorder.onstart = function (e) {
            setPlaying(true);
        }

        mediaRecorder.onresume = function (e) {
            setPlaying(true);
        }

        mediaRecorder.onpause = function (e) {
            setPlaying(false)
        }

        mediaRecorder.onstop = function (e) {
            setPlaying(false);
        }
    }

    const pauseAudioBroadcast = () => {
        mediaRecorder.pause();
        //mediaRecorder.stream.getTracks()[0].stop();
        console.log(mediaRecorder)
    }

    const resumeAudioBroadcast = () => {
        mediaRecorder.resume();
    }

    const stopAudioBroadcast = () => {
        dispatch(downgradeFromSpeaker(chatboxState.roomId));
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks()[0].stop();
    }

    useEffect(() => {
        dispatch(peekRoomBroadcast(chatboxState.roomId));
        return () => {
            dispatch(leaveRoomBroadcast(chatboxState.roomId, true))
        }
    }, [])

    return (


        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'space-between' }}>

            {/* <audio controls>
                <source src={audioSrc} type="audio/webm" />
            </audio> */}

            {chatboxState.stream.disconnected &&

                <div
                    style={{
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <span
                        style={{ fontWeight: 'bold' }}
                    >
                        Stream has been disconnected.
                    </span>
                    <button onClick={() => {
                        dispatch(peekRoomBroadcast(chatboxState.roomId));
                    }} className="btn btn-success">Join Again</button>
                </div>

            }

            {chatboxState.stream.init &&
                (!chatboxState.stream.joining) &&
                (!chatboxState.stream.joined) &&
                < div style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{ textAlign: 'center' }}>
                        {chatboxState.stream.count === 0 &&
                            <p>No one in the broadcast.</p>
                        }
                        {chatboxState.stream.count === 1 &&
                            <p><b>{chatboxState.stream.count}</b> person in the audio broadcast</p>
                        }
                        {chatboxState.stream.count > 1 &&
                            <p><b>{chatboxState.stream.count}</b> people in the audio broadcast</p>
                        }


                        <hr />
                        <button
                            onClick={joinBroadcast}
                            className="btn btn-success">
                            Join Broadcast
                        </button>
                    </div>
                </div>
            }

            {
                chatboxState.stream.joined && <div className="broadcast-area"
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'

                    }}
                >

                    {
                        audioStream.map(e => {
                            return (
                                <div style={{ display: '' }}>
                                    <b>{e.username}</b>
                                    <audio autoPlay controls style={{ display: '' }}>
                                        <source src={e.srcUrl} type="audio/webm" />
                                    </audio>
                                </div>)
                        })
                    }


                    <div>

                        <div style={{ margin: '2.5%', padding: '2.5%' }}>
                            {
                                chatboxState.stream.audioSpeakers.length === 0 &&
                                <h5>No speakers active right now.</h5>
                            }
                            {chatboxState.stream.audioSpeakers.length !== 0 &&
                                <h4>Speakers</h4>
                            }
                            <div
                                style={{
                                    display: 'flex',
                                    overflowY: 'auto'
                                }}
                            >
                                {
                                    chatboxState.stream.meSpeaker.map(e => {
                                        return <span style={{
                                            border: '0 solid black',
                                            padding: '5px',
                                            display: 'inline-block',
                                            textAlign: 'center',
                                            position: 'relative'
                                        }}>
                                            <img
                                                src={C.ASSET_EP + '/avatars/' + e.avatar}
                                                style={{ width: '35px', height: '35px', borderRadius: '20px' }} />
                                            <i style={{
                                                position: 'absolute',
                                                color: 'green',
                                                boxShadow: '0px 0px 23px 19px #eee',
                                                borderRadius: '10px',
                                                right: '3px'
                                            }} className="fa fa-volume"></i>
                                            <span style={{ display: 'block' }}>
                                                {e.username}
                                            </span>
                                        </span>
                                    })
                                }
                                {
                                    chatboxState.stream.audioSpeakers.map(e => {
                                        return <span style={{
                                            border: '0 solid black',
                                            padding: '5px',
                                            display: 'inline-block',
                                            textAlign: 'center',
                                            position: 'relative'
                                        }}>
                                            <img
                                                src={C.ASSET_EP + '/avatars/' + e.avatar}
                                                style={{ width: '35px', height: '35px', borderRadius: '20px' }} />
                                            <i style={{
                                                position: 'absolute',
                                                color: 'green',
                                                boxShadow: '0px 0px 23px 19px #eee',
                                                borderRadius: '10px',
                                                right: '3px'
                                            }} className="fa fa-volume"></i>
                                            <span style={{ display: 'block' }}>
                                                {e.username}
                                            </span>
                                        </span>
                                    })
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="audio-stream-listeners" style={{ padding: '2.5%', margin: '2.5%' }}>
                            {
                                chatboxState.stream.audioListeners.length === 0 &&
                                <h5>No listerners here.</h5>
                            }
                            {chatboxState.stream.audioListeners.length !== 0 &&
                                <h4>Listeners</h4>
                            }
                            {chatboxState.stream.audioListeners.map(e => {

                                return <span style={{
                                    border: '0 solid black',
                                    padding: '5px',
                                    display: 'inline-block',
                                    textAlign: 'center',
                                    position: 'relative'
                                }}>
                                    <img
                                        src={C.ASSET_EP + '/avatars/' +
                                            e.avatar.split(
                                                '.'
                                            )[0] +
                                            '_sm.' +
                                            e.avatar.split('.')[1]
                                        }
                                        style={{ width: '35px', height: '35px', borderRadius: '20px' }} />

                                    <span style={{ display: 'block' }}>
                                        {e.username}
                                    </span>
                                </span>

                            })}
                        </div>
                    </div>

                    <div className="broadcast-controls"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            boxShadow: '0px 0px 23px 20px #eee'
                        }}>
                        {joined ? <AudioBroadcastPlayer /> :
                            <div style={{ display: 'none' }}>

                                <audio id="audio-player" />

                            </div>
                        }

                        {chatboxState.stream.speaking && playing && <i className="fa fa-microphone-slash"
                            onClick={pauseAudioBroadcast}
                        ></i>}

                        {chatboxState.stream.speaking && !playing && <i className="fa fa-microphone"
                            onClick={resumeAudioBroadcast}
                        ></i>}

                        {joined && broadcasting &&
                            <i
                                className="fa fa-microphone-slash"
                                onClick={pauseAudioBroadcast}
                            ></i>}


                        <div className="broadcast-control" style={{ pointerEvents: chatboxState.stream.permission ? 'auto' : 'none' }}>
                            {!chatboxState.stream.speaking
                                &&
                                <i
                                    className={chatboxState.stream.permission ? "fa fa-microphone" : "fa fa-microphone-slash"}
                                    style={{
                                        color: chatboxState.stream.permission
                                            ? 'white'
                                            : 'gray',
                                        background: chatboxState.stream.permission
                                            ? 'green'
                                            : 'none',
                                        padding: '10px',
                                        borderRadius: '50%'
                                    }}
                                    onClick={startAudioBroadcast}
                                ></i>
                            }
                            {chatboxState.stream.speaking
                                && <i className="fa fa-microphone-slash"
                                    onClick={stopAudioBroadcast}
                                    style={{
                                        color: 'white',

                                        background: 'red',
                                        padding: '10px',
                                        borderRadius: '50%'
                                    }}
                                ></i>}
                        </div>
                        <div>
                            <i
                                className="fa fa-phone broadcast-control disconnect-control"
                                style={{ transform: 'rotate(220deg)', cursor: 'pointer' }}
                                onClick={() => {
                                    dispatch(leaveRoomBroadcast(chatboxState.roomId));
                                }}
                            ></i>
                        </div>
                    </div>


                </div>

            }



        </div >

    )
}

const mapStateToProps = (state) => {
    return {
        chatboxState: state.appState.chatbox,
    };
};

const AudioBroadcast = connect(mapStateToProps)(AudioBroadcastComponent);

export default AudioBroadcast
