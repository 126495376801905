import React from 'react'
import C from './../../util/Constants';
import ThreadImageLight from './ThreadImageLight';
import ThreadVideoLight from './ThreadVideoLight';

function ThreadGalleryLight({ gallery, setMedia, setShowMediaPlayer }) {


    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: '10px'
            }}

        >
            {
                gallery.map(
                    (e, i) => {
                        return (<div
                            onClick={(e) => {
                                console.log("Got click!");
                                e.stopPropagation();
                                var ge = gallery[i];

                                if (ge.type === "image") {
                                    setMedia({
                                        type: 1,
                                        extra: {
                                            data: C.API_EP + '/uploads/gallery/' + ge.name,
                                            uploader: 'none'
                                        }
                                    })
                                    setShowMediaPlayer(true);
                                    console.log(setShowMediaPlayer);
                                }

                                if (ge.type === "video") {
                                    setMedia({
                                        type: 2,
                                        extra: {
                                            data: C.API_EP + '/uploads/gallery/' + ge.name,
                                            uploader: 'none'
                                        }
                                    })
                                    setShowMediaPlayer(true);
                                }
                            }}
                            key={i}
                            style={{
                                position: 'relative',
                                borderRadius: '8px',
                                padding: '2px',
                                height: gallery.length > 2 ? '20vh' : gallery.length === 1 ? '40vh' : '30vh',
                                width:
                                    gallery.length === 4
                                        ? '50%'
                                        : gallery.length === 3
                                            ? i === 2
                                                ? '100%'
                                                : '50%'
                                            : gallery.length === 2 ? '50%' : '100%'
                            }}>
                            {e.type === 'image' ?
                                <ThreadImageLight imgstyle={{
                                    width: '100%',
                                    display: 'block',
                                    border: '1px solid #eee',
                                    height: '100%',
                                    maxHeight: gallery.length > 2 ? '20vh' : gallery.length === 1 ? '40vh' : '30vh',
                                    overflow: 'hidden',
                                    objectFit: 'cover',
                                    borderRadius: '8px',
                                    border: '3px solid #eee'
                                }}
                                    src={C.API_EP + '/uploads/gallery/' + e.name}
                                />
                                :
                                <ThreadVideoLight vidstyle={{
                                    width: '100%',
                                    display: 'block',
                                    border: '1px solid #eee',
                                    height: '100%',
                                    maxHeight: gallery.length > 2 ? '20vh' : gallery.length === 1 ? '40vh' : '30vh',
                                    overflow: 'hidden',
                                    objectFit: 'contain',
                                    borderRadius: '8px'
                                }} src={C.API_EP + '/uploads/gallery/' + e.name} />
                                // <video
                                //     style={{

                                //     }}  alt="user upload" key={i} />
                            }

                        </div>)
                    })
            }


        </div>
    )
}

export default ThreadGalleryLight
