import React, { Component, Fragment } from 'react';
import axios from 'axios';
import C from './../util/Constants';
import { Redirect, Link } from 'react-router-dom';

class Register extends Component {
  state = {
    username: '',
    email: '',
    password: '',
    startedTypingUsername: false,
    startedTypingPassword: false,
    startedTypingEmail: false,
    sending: false,
    notification: { status: '', heading: '', msg: '', show: false },
    redirect: false,
    redirectTo: '',
    init: false,
    agree: false,
  };

  fadeAwayNotification = () => {
    var _this = this;
    setTimeout(function () {
      _this.setState({ notification: { show: false } });
    }, 2500);
  };

  submitRegister = (token) => {
    if (
      this.state.username.trim() === '' ||
      this.state.email.trim() === '' ||
      this.state.password.trim() === ''
    ) {
      this.setState(
        {
          notification: {
            status: 'danger',
            heading: 'Error',
            msg: 'Fill all fields to register.',
            show: true,
          },
        },
        this.fadeAwayNotification
      );
      return;
    }

    this.setState({ sending: true });

    axios
      .post(C.API_EP + '/user', {
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        captcha: token,
      })
      .then((response) => {
        this.setState({ sending: false });
        this.setState(
          {
            notification: {
              status: 'success',
              heading: 'Success',
              msg: 'Registered successfully! Redirecting ...',
              show: true,
            },
          },
          this.fadeAwayNotification
        );
        var _this = this;
        setTimeout(function () {
          // _this.setState({ redirectTo: '/login' });
          // _this.setState({ redirect: true });
          window.location = '/login';
        }, 2500);
        console.log(response);
      })
      .catch((err) => {
        window.grecaptcha.reset();
        this.setState({ sending: false });
        if (typeof err.response != 'undefined') {
          if (typeof err.response.data.msg != 'undefined') {
            this.setState(
              {
                notification: {
                  status: 'danger',
                  heading: 'Error',
                  msg: err.response.data.msg,
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          } else {
            if (err.response.data.errors) {
              var _errString =
                err.response.data.errors[0].param +
                ' : ' +
                err.response.data.errors[0].msg;
              // for (
              //   var _index = 0;
              //   _index < err.response.data.errors.length;
              //   _index++
              // ) {
              //   _errString +=
              //     err.response.data.errors[_index].param +
              //     ' : ' +
              //     err.response.data.errors[_index].msg +
              //     ' \n ';
              // }

              this.setState(
                {
                  notification: {
                    status: 'danger',
                    heading: 'Error',
                    msg: _errString,
                    show: true,
                  },
                },
                this.fadeAwayNotification
              );
              return;
            }
            this.setState(
              {
                notification: {
                  status: 'danger',
                  heading: 'Error',
                  msg: 'Input validation error.',
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          }
        }
        this.setState(
          {
            notification: {
              status: 'danger',
              heading: 'Error',
              msg: 'Error occured on server.',
              show: true,
            },
          },
          this.fadeAwayNotification
        );
        console.log(err.response);
      });

    return false;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  initChecks() {
    if (localStorage.getItem('sp_session') != null) {
      var _this = this;
      setTimeout(function () {
        _this.setState({ redirectTo: '/home' });
        _this.setState({ redirect: true });
      }, 800);
      return false;
    } else {
      this.setState({ init: true });
      return true;
    }
  }

  onCaptcheCompleted = (e) => {
    console.log(e);
    if (e.trim() !== '') {
      this.submitRegister(e);
    } else {
      this.setState(
        {
          notification: {
            status: 'danger',
            heading: 'Error',
            msg: 'Captcha failed!.',
            show: true,
          },
        },
        this.fadeAwayNotification
      );
    }
  };

  onCaptcheError = (e) => {
    console.log(e);
    this.setState({
      notification: {
        status: 'danger',
        heading: 'Error',
        msg: 'Captcha failed!.',
        show: true,
      },
    });

    window.grecaptcha.reset();
  };

  componentDidMount() {
    if (this.initChecks()) {
      var script = document.getElementById('captcha-script');
      if (script === null) {
        const captcha_script = document.createElement('script');
        captcha_script.setAttribute('id', 'captcha-script');
        captcha_script.src = 'https://www.google.com/recaptcha/api.js';
        captcha_script.async = true;
        captcha_script.defer = true;
        document.body.appendChild(captcha_script);
        window.addEventListener('load', this.onLoad);
        setTimeout(function () {
          window.addEventListener('load', this.onLoad);
        }, 200)

        return;
      }

      var _this = this;
      setTimeout(function () {
        _this.onLoad();
      }, 300);
    }
  }

  componentWillUnmount() {
    // var element = document.getElementById('register-captcha-script');
    // element.parentNode.removeChild(element);
    // window.grecaptcha.reset('recaptcha');
  }

  onLoad = () => {
    if (window.grecaptcha) {
      window.grecaptcha.render('recaptcha', {
        sitekey: '6LewRfMUAAAAAPkmGc_VaL8h0l_eRsia3GMU3V-b',
        size: 'invisible',
        callback: this.onCaptcheCompleted,
        'error-callback': this.onCaptchaError,
      });
    }
  };

  render() {
    return (
      <Fragment
        className='container'
        style={{
          background:
            'url(https://c8.alamy.com/comp/TY830X/group-of-people-chatting-with-speech-bubbles-TY830X.jpg)',
        }}
      >
        {this.state.sending && (
          <span
            class='spinner-grow text-muted'
            style={{
              position: 'fixed',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
              width: '100px',
              height: '100px',
              zIndex: 99,
            }}
          ></span>
        )}
        {this.state.notification.show && (
          <div
            class={'alert alert-' + this.state.notification.status}
            style={{
              position: 'fixed',
              left: '5%',
              bottom: '5%',
              zIndex: 99,
              boxShadow: '0px 0px 10px -2px',
            }}
          >
            <strong>{this.state.notification.heading}!</strong>{' '}
            {this.state.notification.msg}
          </div>
        )}

        {this.state.redirect && <Redirect to={this.state.redirectTo} />}
        {this.state.init && (
          <div className='sc-register-container'>
            <div className='sc-register-inner card mx-sm-auto my-sm-5 mx-2 my-3 col-md-3'>
              <div class='card-header' style={{ textAlign: 'center' }}>
                <img
                  src='/resources/images/logo_t.svg'
                  alt='sparkle logo'
                  style={{ maxWidth: '125px' }}
                />
              </div>
              <div className='card-body'>
                <div
                  className="sc-app-card-headline"
                  style={{
                    fontSize: '25px',
                    textAlign: 'center',
                    fontFamily: 'poppins',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >
                  Register with Sparklechat
                </div>
                <hr />
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div class='form-group'>
                    <label for='username'>Username</label>
                    <input
                      type='text'
                      placeholder='chatter1122'
                      className={
                        'form-control ' +
                        ((!/^[a-z]+[a-z0-9]*$/.test(this.state.username) ||
                          this.state.username.length < 6) &&
                          this.state.startedTypingUsername
                          ? 'is-invalid'
                          : '')
                      }
                      name='username'
                      onChange={this.handleChange}
                      maxLength="15"
                      disabled={this.state.sending}
                      onKeyUp={(e) => {
                        if (e.target.value.trim() !== '')
                          this.setState({ startedTypingUsername: true });
                        else this.setState({ startedTypingUsername: false });
                      }}
                    />
                    <div class='invalid-feedback'>
                      Only lower case alphabets and digits can be use used, must
                      at least be six characters.
                    </div>
                  </div>

                  <div class='form-group'>
                    <label for='Email'>Email</label>
                    <input
                      type='email'
                      class={
                        'form-control ' +
                        (!/^[a-zA-Z0-9-_.]+@[a-z0-9A-Z-_.]+.[a-zA-Z0-9]{2,4}$/.test(
                          this.state.email
                        ) && this.state.startedTypingEmail
                          ? 'is-invalid'
                          : '')
                      }
                      placeholder='chatter@mail.com'
                      name='email'
                      onChange={this.handleChange}
                      disabled={this.state.sending}
                      onKeyUp={(e) => {
                        if (e.target.value.trim() !== '')
                          this.setState({ startedTypingEmail: true });
                        else this.setState({ startedTypingEmail: false });
                      }}
                    />
                  </div>
                  <div class='form-group'>
                    <label for='password'>Password</label>
                    <input
                      type='password'
                      placeholder='Password'
                      className={
                        'form-control ' +
                        (this.state.password.length < 8 &&
                          this.state.startedTypingPassword
                          ? 'is-invalid'
                          : '')
                      }
                      name='password'
                      onChange={this.handleChange}
                      disabled={this.state.sending}
                      onKeyUp={(e) => {
                        if (e.target.value.trim() !== '')
                          this.setState({ startedTypingPassword: true });
                        else this.setState({ startedTypingPassword: false });
                      }}
                    />
                    <div class='invalid-feedback'>
                      at least eight characters
                    </div>
                  </div>

                  <hr />

                  <div class='form-check-inline'>
                    <label class='form-check-label'>
                      <input
                        type='checkbox'
                        class='form-check-input'
                        value={this.agree}
                        onClick={() => {
                          if (this.agree) {
                            this.setState({ agree: false });
                          } else {
                            this.setState({ agree: true });
                          }
                        }}
                      />
                      I agree to the <a href='/terms'>terms</a> and{' '}
                      <a href='/privacy'>privacy policy</a>
                    </label>
                  </div>
                  <hr />
                  <button
                    type='submit'
                    class='btn btn-success btn-block sc-app-button'
                    disabled={
                      this.state.sending ||
                      this.state.username.trim() === '' ||
                      this.state.email.trim() === '' ||
                      this.state.password.trim() === '' ||
                      !this.state.agree
                    }
                    onClick={() => {
                      this.setState({ sending: true });
                      console.log(this.myRef);
                      this.myRef.current.click();
                    }}
                  >
                    Register
                  </button>
                  <button
                    id='recaptcha'
                    style={{ display: 'none' }}
                    ref={this.myRef}
                  ></button>
                </form>
              </div>
              <span
                style={{
                  position: 'absolute',
                  bottom: '-30px',
                  right: '10px',
                  textDecoration: 'underline',
                  fontWeight: 'italic',
                }}
              >
                <Link to='/login'>have an account?</Link>
              </span>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Register;
