import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import C from './../../util/Constants';

const Members = (props) => {
  const [roomDetails, setRoomDetails] = useState([]);
  const [load, setLoad] = useState(2);
  const [more, setMore] = useState(false);
  const [page, setPage] = useState(1);

  const closeMembers = () => {
    props.closeHandler();
  };

  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
      return spSession;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    setLoad(0);
    var spSession = checkForSession();
    if (spSession === false) {
      setLoad(-1);
      return;
    }

    Axios.get(
      C.API_EP +
        '/a/r/members?email=' +
        spSession.email +
        '&token=' +
        spSession.token +
        '&roomId=' +
        props.id +
        '&page=' +
        page
    )
      .then((response) => {
        setLoad(1);
        console.log(response.data.data);
        setRoomDetails(response.data.data);
        setMore(response.data.more);
        if (response.data.more) setPage(page + 1);
      })
      .catch((e) => {
        setLoad(-1);
        console.log(e);
      });
  }, []);

  const loadMore = () => {
    setLoad(0);
    var spSession = checkForSession();
    if (spSession === false) {
      setLoad(-1);
      return;
    }

    Axios.get(
      C.API_EP +
        '/a/r/members?email=' +
        spSession.email +
        '&token=' +
        spSession.token +
        '&roomId=' +
        props.id +
        '&page=' +
        page
    )
      .then((response) => {
        setLoad(1);
        console.log([...roomDetails, ...response.data.data]);
        setRoomDetails([...roomDetails, ...response.data.data]);
        setMore(response.data.more);
        if (response.data.more) setPage(page + 1);
      })
      .catch((e) => {
        setLoad(-1);
        console.log(e);
      });
  };

  return (
    <div className='sp-joinroom-wrapper'>
      <div className='sp-joinroom-inner'>
        <div className='card'>
          <div className='card-header'>
            Room Members
            <i
              className='fas fa-times sp-small-icon'
              onClick={closeMembers}
            ></i>
          </div>
          <div className='card-body' style={{ padding: '15px' }}>
            <ul
              className='list-group list-group-flush'
              style={{ maxHeight: '50vh', overflow: 'auto' }}
            >
              {typeof roomDetails !== 'undefined' &&
                roomDetails.length !== 0 &&
                roomDetails.map((m) => {
                  return (
                    <li
                      className='list-group-item'
                      style={{
                        display: 'flex',
                      }}
                    >
                      <img
                        src={
                          typeof m.user.profile !== 'undefined' &&
                          C.API_EP +
                            '/avatars/' +
                            m.user.profile.picture.split('.')[0] +
                            '_sm.' +
                            m.user.profile.picture.split('.')[1]
                        }
                        alt='user icon'
                        style={{ height: '25px', width: '25px' }}
                        className='rounded-circle sp-message-usericon'
                      />
                      <b> {'@' + m.user.username}</b>
                    </li>
                  );
                })}
            </ul>
            {more && (
              <div style={{ textAlign: 'center' }}>
                <a href='#' onClick={loadMore}>
                  Load More
                </a>
              </div>
            )}
          </div>
          <div className='card-footer'></div>
        </div>
      </div>
    </div>
  );
};

export default Members;
