import * as actions from '../constants/action-types';
import { roomMessage } from '../actions/appActions';

const changeDetector = (store) => (next) => (action) => {
  next(action);
  if (action.type === actions.INCOMING_MESSAGE_SUCCESS) {
    console.log('Middleware triggered:');
    //var roomMessages = store.getState().appState.chatbox.roomMessages;
    store
      .getState()
      .appState.chatbox.roomMessages.sort(
        store.getState().appState.chatbox.sorter
      );
  }

  if (
    action.type === actions.NEW_PRIVATE_MESSAGE ||
    action.type === actions.NEW_PRIVATE_MESSAGE_NOTIF ||
    action.type === actions.PRIVATE_REQUEST_RECEIVED
  ) {
    store.getState().appState.Private.list.data = store
      .getState()
      .appState.Private.list.data.sort(function (a, b) {
        var dateA =
          a.lastMessage === null
            ? new Date(a.data)
            : new Date(a.lastMessage.date);

        var dateB =
          b.lastMessage === null
            ? new Date(b.data)
            : new Date(b.lastMessage.date);
        return dateB - dateA;
      });
  }
};

export default changeDetector;
