import React, { Component, Fragment } from "react";
import axios from "axios";
import C from "./../util/Constants";
import { Redirect, Link } from "react-router-dom";

class Login extends Component {
  state = {
    email: "",
    password: "",
    remember: false,
    sending: false,
    notification: { status: "", heading: "", msg: "", show: false },
    redirect: false,
    loginDisabled: false,
    redirectTo: "",
    init: false,
    verification: {
      show: true,
      resendToken: "",
      resending: false,
    },
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  fadeAwayNotification = () => {
    var _this = this;
    setTimeout(function () {
      _this.setState({ notification: { show: false } });
    }, 4500);
  };

  submitLogin = (token) => {
    if (this.state.email.trim() === "" || this.state.password.trim() === "") {
      this.setState(
        {
          notification: {
            status: "danger",
            heading: "Error",
            msg: "Fill all fields to login.",
            show: true,
          },
        },
        this.fadeAwayNotification
      );
      return;
    }

    this.setState({ sending: true });

    axios
      .post(C.API_EP + "/admin/login", {
        email: this.state.email,
        password: this.state.password,
        captcha: token,
      })
      .then((response) => {
        this.setState({ sending: false });
        this.setState(
          {
            notification: {
              status: "success",
              heading: "Success",
              msg: "Login successful! Redirecting ...",
              show: true,
            },
          },
          this.fadeAwayNotification
        );
        var data = response.data.data;
        localStorage.setItem("sp_session", JSON.stringify(data));
        localStorage.setItem("sp_admin_id", response.data.adminId);
        var _this = this;
        setTimeout(function () {
          _this.setState({ redirectTo: "/home" });
          _this.setState({ redirect: true });
        }, 2500);
        console.log(response);
      })
      .catch((err) => {
        window.grecaptcha.reset();
        this.setState({ sending: false });

        if (typeof err.response != "undefined") {
          console.log(err.response);
          if (
            typeof err.response.data != "undefined" &&
            err.response.data.data === 300
          ) {
            this.setState({ loginDisabled: true });
            this.setState({
              verification: {
                ...this.state.verification,
                resendToken: err.response.data.resendToken,
              },
            });
            this.loadResendCaptcha();
            return;
          }

          if (typeof err.response.data.msg != "undefined") {
            this.setState(
              {
                notification: {
                  status: "danger",
                  heading: "Error",
                  msg: err.response.data.msg,
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          } else {
            this.setState(
              {
                notification: {
                  status: "danger",
                  heading: "Error",
                  msg: "Error in the input email or password.",
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          }
        }

        this.setState(
          {
            notification: {
              status: "danger",
              heading: "Network Error!",
              msg:
                "Either you are not connected to internet or server temporarily down.",
              show: true,
            },
          },
          this.fadeAwayNotification
        );
      });

    return false;
  };

  resendEmail = (token) => {
    if (
      this.state.email.trim() === "" ||
      this.state.verification.resendToken.trim() === ""
    ) {
      this.setState(
        {
          notification: {
            status: "danger",
            heading: "Error",
            msg: "Invalid parameters to resend.",
            show: true,
          },
        },
        this.fadeAwayNotification
      );
      return;
    }

    this.setState({
      verification: { ...this.state.verification, resending: true },
    });

    axios
      .post(C.API_EP + "/user/resend_email", {
        email: this.state.email,
        resendToken: this.state.verification.resendToken,
        captcha: token,
      })
      .then((response) => {
        this.setState({
          verification: { ...this.state.verification, resending: false },
        });
        this.setState(
          {
            notification: {
              status: "success",
              heading: "Success",
              msg: "Email re-sent. Check your inbox for more details.",
              show: true,
            },
          },
          this.fadeAwayNotification
        );
        this.setState({ loginDisabled: false });
        window.grecaptcha.reset();
      })
      .catch((err) => {
        window.grecaptcha.reset();

        this.setState({
          verification: { ...this.state.verification, resending: false },
        });

        if (typeof err.response != "undefined") {
          console.log(err.response);

          if (typeof err.response.data.msg != "undefined") {
            this.setState(
              {
                notification: {
                  status: "danger",
                  heading: "Error",
                  msg: err.response.data.msg,
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          } else {
            this.setState(
              {
                notification: {
                  status: "danger",
                  heading: "Error",
                  msg: "Input validation error.",
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          }
        }
        this.setState(
          {
            notification: {
              status: "danger",
              heading: "Error",
              msg: "Error occured on server.",
              show: true,
            },
          },
          this.fadeAwayNotification
        );
        console.log(err.response);
      });

    return false;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "remember") {
      if (event.target.checked)
        this.setState({
          [name]: true,
        });
      else
        this.setState({
          [name]: false,
        });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  initChecks() {
    if (localStorage.getItem("sp_session") != null) {
      var _this = this;
      setTimeout(function () {
        _this.setState({ redirectTo: "/home" });
        _this.setState({ redirect: true });
      }, 700);
      return false;
    } else {
      this.setState({ init: true });
      return true;
    }
  }

  onCaptcheCompleted = (e) => {
    if (e.trim() !== "") {
      this.submitLogin(e);
    } else {
      this.setState(
        {
          notification: {
            status: "danger",
            heading: "Error",
            msg: "Captcha failed!.",
            show: true,
          },
        },
        this.fadeAwayNotification
      );
    }
  };

  onCaptcheError = (e) => {
    console.log(e);
    this.setState({
      notification: {
        status: "danger",
        heading: "Error",
        msg: "Captcha failed!.",
        show: true,
      },
    });

    window.grecaptcha.reset();
  };

  componentDidMount() {
    console.log("Login Mounted!")
    if (this.initChecks()) {
      var script = document.getElementById("captcha-script");
      console.log(script)
      if (script === null) {
        const captcha_script = document.createElement("script");
        captcha_script.setAttribute("id", "captcha-script");
        captcha_script.src = "https://www.google.com/recaptcha/api.js";
        captcha_script.async = true;
        captcha_script.defer = true;
        document.body.appendChild(captcha_script);
        window.addEventListener("load", this.onLoad);
        // setTimeout(function () {
        // }, 200);
        return;
      }

      var _this = this;
      setTimeout(function () {
        _this.onLoad();
      }, 300);
    }
  }

  componentWillUnmount() {
    // var element = document.getElementById('login-captcha-script');
    // element.parentNode.removeChild(element);
    // window.grecaptcha.reset('recaptcha');
    window.removeEventListener('load', this.onLoad)
  }

  onLoad = () => {
    console.log("OnLoad called!");
    console.log(typeof window.grecaptcha);
    if (
      typeof window.grecaptcha !== "undefined" &&
      typeof window.grecaptcha.render === "function"
    ) {
      if (window.grecaptcha) {
        window.grecaptcha.render("recaptcha", {
          sitekey: "6LewRfMUAAAAAPkmGc_VaL8h0l_eRsia3GMU3V-b",
          size: "invisible",
          callback: this.onCaptcheCompleted,
          "error-callback": this.onCaptcheError,
        });
      }
    }
  };

  loadResendCaptcha = () => {
    if (window.grecaptcha) {
      window.grecaptcha.render("resend", {
        sitekey: "6LewRfMUAAAAAPkmGc_VaL8h0l_eRsia3GMU3V-b",
        size: "invisible",
        callback: this.resendEmail,
        "error-callback": this.onCaptcheError,
      });
    }
  };

  render() {
    return (
      <Fragment>
        {this.state.sending && (
          <span
            className="spinner-grow text-muted"
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: "auto",
              width: "100px",
              height: "100px",
              zIndex: 99,
            }}
          ></span>
        )}
        {this.state.notification.show && (
          <div
            className={"alert alert-" + this.state.notification.status}
            style={{
              position: "fixed",
              left: "5%",
              bottom: "5%",
              zIndex: 99,
              boxShadow: "0px 0px 10px -2px",
            }}
          >
            <strong>{this.state.notification.heading}!</strong>{" "}
            {this.state.notification.msg}
          </div>
        )}

        {this.state.redirect && <Redirect to={this.state.redirectTo} />}

        {this.state.init && (
          <div className="sc-login-container">
            <div className="sc-login-inner card mx-sm-auto my-sm-5 mx-2 my-3 col-md-4">
              <div
                className="btn-group"
                role="group"
                aria-label="Login Button Group"
              >
                <button type="button" className="btn btn-secondary active">
                  Super-Admin Panel
                </button>
              </div>
              <div
                className="card-header"
                style={{
                  textAlign: "center",

                  backgroundColor: "#",
                }}
              >
                <img
                  src="/resources/images/logo_t.svg"
                  alt="sparkle logo"
                  style={{ maxWidth: "125px" }}
                />
              </div>
              <div className="card-body">
                <div
                  className="sc-app-card-headline"
                  style={{
                    fontSize: "25px",
                    color: "#1a73e8 ",
                    textAlign: "center",
                    fontFamily: "poppins",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  Login as SA
                </div>
                <hr />
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  style={{
                    display:
                      typeof this.state.loginDisabled !== "undefined" &&
                      (this.state.loginDisabled ? "none" : "initial"),
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="Email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={this.handleChange}
                      disabled={this.state.sending}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      onChange={this.handleChange}
                      disabled={this.state.sending}
                    />
                  </div>

                  <hr />
                  <button
                    className="btn btn-success btn-block sc-app-button"
                    type="submit"
                    disabled={
                      this.state.sending ||
                      this.state.email.trim() === "" ||
                      this.state.password.trim() === ""
                    }
                    onClick={() => {
                      this.setState({ sending: true });
                      this.myRef.current.click();
                    }}
                  >
                    Login
                  </button>
                </form>
                {this.state.loginDisabled && (
                  <div
                    className="alert alert-danger"
                    style={{
                      backgroundColor: "#f7f7f7",
                      color: "#c35963",
                      marginTop: "10px",
                    }}
                  >
                    <p>
                      <b>Action Required!</b> Verification email has been sent
                      to you. Please check your inbox for more details. If you
                      haven't the received the email, click resend.
                    </p>
                    <button
                      id="resend"
                      className="btn btn-primary btn-sm"
                      disabled={this.state.verification.resending}
                    >
                      {this.state.verification.resending && (
                        <div className="spinner-grow spinner-grow-sm"></div>
                      )}
                      Resend
                    </button>
                  </div>
                )}
                <button
                  id="recaptcha"
                  style={{ display: "none" }}
                  ref={this.myRef}
                ></button>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Login;
