import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import C from './../../util/Constants';

class Verify extends Component {
  state = {
    email: '',
    token: '',
    sending: true,
    notification: { status: '', heading: '', msg: '', show: false },
    redirect: false,
    loginDisabled: true,
    redirectTo: '',
    init: true,
    verification: {
      show: true,
      status: false,
    },
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  fadeAwayNotification = () => {
    var _this = this;
    setTimeout(function () {
      _this.setState({ notification: { show: false } });
    }, 3500);
  };

  submitVerify = (token) => {
    console.log(token);
    if (this.state.email.trim() === '' || this.state.token.trim() === '') {
      this.setState(
        {
          notification: {
            status: 'danger',
            heading: 'Error',
            msg: 'Not enought data to verify.',
            show: true,
          },
        },
        this.fadeAwayNotification
      );
      return;
    }

    this.setState({ sending: true });

    axios
      .post(C.API_EP + '/user/verify', {
        email: this.state.email,
        token: this.state.token,
        captcha: token,
      })
      .then((response) => {
        this.setState({ sending: false });
        this.setState(
          {
            notification: {
              status: 'success',
              heading: 'Success',
              msg: 'Account verification successful! Redirecting ...',
              show: true,
            },
          },
          this.fadeAwayNotification
        );

        var _this = this;
        setTimeout(function () {
          _this.setState({ redirectTo: '/login' });
          _this.setState({ redirect: true });
        }, 2500);
        console.log(response);
      })
      .catch((err) => {
        window.grecaptcha.reset();
        this.setState({
          sending: false,
          verification: { ...this.state.verification, status: true },
        });

        if (typeof err.response != 'undefined') {
          if (typeof err.response.data.msg != 'undefined') {
            this.setState(
              {
                notification: {
                  status: 'danger',
                  heading: 'Error',
                  msg: err.response.data.msg,
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          } else {
            this.setState(
              {
                notification: {
                  status: 'danger',
                  heading: 'Error',
                  msg: 'Input validation error.',
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          }
        }
        this.setState(
          {
            notification: {
              status: 'danger',
              heading: 'Error',
              msg: 'Error occured on server.',
              show: true,
            },
          },
          this.fadeAwayNotification
        );
        console.log(err.response);
      });

    return false;
  };

  onCaptcheCompleted = (e) => {
    console.log(e);
    if (e.trim() !== '') {
      this.submitVerify(e);
    } else {
      this.setState(
        {
          notification: {
            status: 'danger',
            heading: 'Error',
            msg: 'Captcha failed!.',
            show: true,
          },
        },
        this.fadeAwayNotification
      );
    }
  };
  componentDidMount() {
    var params = this.props.match.params;
    console.log(params);
    this.setState({ email: params.email, token: params.token });
    var script = document.getElementById('captcha-script');
    if (script === null) {
      const captcha_script = document.createElement('script');
      captcha_script.setAttribute('id', 'captcha-script');
      captcha_script.src = 'https://www.google.com/recaptcha/api.js';
      captcha_script.async = true;
      captcha_script.defer = true;
      document.body.appendChild(captcha_script);
      window.addEventListener('load', this.onLoad);
      return;
    }

    var _this = this;
    setTimeout(function () {
      _this.onLoad();
    }, 300);
  }

  onLoad = () => {
    console.log(typeof window.grecaptcha);
    if (
      typeof window.grecaptcha !== 'undefined' &&
      typeof window.grecaptcha.render === 'function'
    ) {
      if (window.grecaptcha) {
        window.grecaptcha.render('recaptcha', {
          sitekey: '6LewRfMUAAAAAPkmGc_VaL8h0l_eRsia3GMU3V-b',
          size: 'invisible',
          callback: this.onCaptcheCompleted,
        });
        window.grecaptcha.execute();
      }
    }
  };

  render() {
    return (
      <Fragment>
        {this.state.notification.show && (
          <div
            class={'alert alert-' + this.state.notification.status}
            style={{
              position: 'fixed',
              left: '5%',
              bottom: '5%',
              zIndex: 99,
              boxShadow: '0px 0px 10px -2px',
            }}
          >
            <strong>{this.state.notification.heading}!</strong>{' '}
            {this.state.notification.msg}
          </div>
        )}

        {this.state.redirect && <Redirect to={this.state.redirectTo} />}

        {this.state.init && (
          <div className='sc-login-container'>
            <div className='sc-login-inner card mx-sm-auto my-sm-5 mx-2 my-3 col-md-4'>
              <div
                class='card-header'
                style={{
                  textAlign: 'center',

                  backgroundColor: '#',
                }}
              >
                <img
                  src='/resources/images/sparkle_logo.png'
                  alt='sparkle logo'
                  style={{ maxWidth: '125px' }}
                />
              </div>
              <div className='card-body'>
                <div
                  style={{
                    fontSize: '25px',
                    color: '#1498d5',
                    textAlign: 'center',
                    background: 'rgba(0,123,255,.25)',
                    fontFamily: 'poppins',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >
                  Verifying Your Account
                </div>
                <hr />
                <button
                  id='recaptcha'
                  style={{ display: 'none' }}
                  ref={this.myRef}
                ></button>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '60%',
                  }}
                >
                  {this.state.sending && (
                    <div
                      class='progress'
                      style={{ height: '10px', width: '100%' }}
                    >
                      <div
                        class='progress-bar progress-bar-striped progress-bar-animated'
                        style={{ width: '100%', height: '10px' }}
                      ></div>
                    </div>
                  )}

                  {this.state.verification.status && (
                    <div className='alert alert-danger'>
                      Verification failed!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Verify;
