import React, { Fragment } from 'react'
import AudioBroadcast from './AudioBroadcast'

function AudioBroadcastWrapper() {

    const [isChrome, setIsChrome] = React.useState(false);

    React.useEffect(() => {
        var detectChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        if (detectChrome) {
            //alert(isChrome);
            setIsChrome(true);
        }

    }, []);

    return (
        <Fragment>
            {
                !isChrome ?

                    <div>
                        <h3>Broadcast is best supported in Google Chrome. Using any other browser might cause unexpected result.</h3>
                        <button className="btn btn-danger" onClick={() => { setIsChrome(true) }}>I don't give a fuck!</button>
                    </div>

                    :
                    <AudioBroadcast />
            }
        </Fragment>
    )
}

export default AudioBroadcastWrapper
