import React, { useState } from 'react';
import { sendCreateRoom } from './../../redux/actions/appActions';
const CreateRoom = (props) => {
  const [roomToCreate, setRoomToCreate] = useState('');
  const [roomType, setRoomType] = useState(1);
  const [roomDesc, setRoomDesc] = useState('');

  const createRoom = () => {
    console.log('Creating Room: ' + roomToCreate);
    if (roomToCreate.trim() === '') {
      console.log('Please provide a valid title for the room.');
      return;
    }
    // console.log(roomToCreate);
    // console.log(roomType);
    // console.log(roomDesc);
    // return;
    console.log(props);
    console.log(props.createRoomHandler);
    props.createRoomHandler(roomToCreate, roomType, roomDesc);
    closeCreateRoom();
  };

  const closeCreateRoom = () => {
    props.closeCreateRoom();
  };

  return (
    <div className='sp-createroom-wrapper'>
      <div className='sp-createroom-inner'>
        <div className='card'>
          <div className='card-header'>
            Create Room
            <i
              className='fas fa-times sp-small-icon'
              onClick={closeCreateRoom}
            ></i>
          </div>
          <div className='card-body'>
            <b>Title:</b>
            <input
              type='text'
              className='form-control'
              maxLength='25'
              onChange={(e) => {
                setRoomToCreate(e.target.value);
              }}
            />
            <b>Type:</b>
            <select
              className='form-control'
              onChange={(e) => {
                setRoomType(e.target.value);
              }}
              value={roomType}
            >
              <option value='1'>Normal</option>
              <option value='2'>Adult</option>
            </select>
            <b>Description:</b>
            <textarea
              className='form-control'
              onChange={(e) => setRoomDesc(e.target.value)}
              maxLength='250'
            ></textarea>
          </div>
          <div className='card-footer'>
            <button className='btn btn-success' onClick={createRoom}>
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRoom;
