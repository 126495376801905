import React, { Component, Fragment } from "react";
import axios from "axios";
import C from "./../util/Constants";
import { Redirect, Link } from "react-router-dom";

class GuestLogin extends Component {
  state = {
    username: "",
    sending: false,
    notification: { status: "", heading: "", msg: "", show: false },
    redirect: false,
    loginDisabled: false,
    redirectTo: "",
    init: false,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  fadeAwayNotification = () => {
    var _this = this;
    setTimeout(function () {
      _this.setState({ notification: { show: false } });
    }, 3500);
  };

  submitGuestLogin = (token) => {
    if (this.state.username.trim() === "") {
      this.setState(
        {
          notification: {
            status: "danger",
            heading: "Error",
            msg: "Fill all fields to login.",
            show: true,
          },
        },
        this.fadeAwayNotification
      );
      return;
    }

    this.setState({ sending: true });

    axios
      .post(C.API_EP + "/g/createGuestAccount", {
        username: this.state.username,
        captcha: token,
      })
      .then((response) => {
        this.setState({ sending: false });
        this.setState(
          {
            notification: {
              status: "success",
              heading: "Success",
              msg: "Login successful! Redirecting ...",
              show: true,
            },
          },
          this.fadeAwayNotification
        );
        var data = response.data.data;
        localStorage.setItem("sp_session", JSON.stringify(data));
        localStorage.setItem("sp_guest", 1);
        var _this = this;
        setTimeout(function () {
          _this.setState({ redirectTo: "/ghome" });
          _this.setState({ redirect: true });
        }, 2500);
        console.log(response);
      })
      .catch((err) => {
        window.grecaptcha.reset();
        this.setState({ sending: false });

        if (typeof err.response != "undefined") {
          console.log(err.response);
          if (typeof err.response.data.msg != "undefined") {
            this.setState(
              {
                notification: {
                  status: "danger",
                  heading: "Error",
                  msg: err.response.data.msg,
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          } else {
            this.setState(
              {
                notification: {
                  status: "danger",
                  heading: "Error",
                  msg: "Error with the input data.",
                  show: true,
                },
              },
              this.fadeAwayNotification
            );
            return;
          }
        }
        this.setState(
          {
            notification: {
              status: "danger",
              heading: "Error",
              msg: "Error occured on server.",
              show: true,
            },
          },
          this.fadeAwayNotification
        );
        console.log(err.response);
      });

    return false;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  initChecks() {
    if (localStorage.getItem("sp_session") != null) {
      var _this = this;
      setTimeout(function () {
        _this.setState({ redirectTo: "/ghome" });
        _this.setState({ redirect: true });
      }, 1000);
      return false;
    } else {
      this.setState({ init: true });
      return true;
    }
  }

  onCaptcheCompleted = (e) => {
    console.log(e);
    if (e.trim() !== "") {
      this.submitGuestLogin(e);
    } else {
      this.setState(
        {
          notification: {
            status: "danger",
            heading: "Error",
            msg: "Captcha failed!.",
            show: true,
          },
        },
        this.fadeAwayNotification
      );
    }
  };

  onCaptcheError = (e) => {
    console.log(e);
    this.setState({
      notification: {
        status: "danger",
        heading: "Error",
        msg: "Captcha failed!.",
        show: true,
      },
    });

    window.grecaptcha.reset();
  };

  componentDidMount() {
    if (this.initChecks()) {
      var script = document.getElementById("captcha-script");
      var that = this;
      if (script === null) {
        console.log("Component mount!");
        const captcha_script = document.createElement("script");
        captcha_script.setAttribute("id", "captcha-script");
        captcha_script.src = "https://www.google.com/recaptcha/api.js";
        captcha_script.async = true;
        captcha_script.defer = true;
        document.body.appendChild(captcha_script);
        // window.addEventListener("load", this.onLoad);
        setTimeout(function () {
          that.onLoad();
        }, 200);
        return;
      }

      var _this = this;
      setTimeout(function () {
        _this.onLoad();
      }, 300);
    }
  }

  componentWillUnmount() {
    // var element = document.getElementById('login-captcha-script');
    // element.parentNode.removeChild(element);
    // window.grecaptcha.reset('recaptcha');
  }

  onLoad = () => {
    console.log(typeof window.grecaptcha);
    if (
      typeof window.grecaptcha !== "undefined" &&
      typeof window.grecaptcha.render === "function"
    ) {
      if (window.grecaptcha) {
        window.grecaptcha.render("recaptcha", {
          sitekey: "6LewRfMUAAAAAPkmGc_VaL8h0l_eRsia3GMU3V-b",
          size: "invisible",
          callback: this.onCaptcheCompleted,
          "error-callback": this.onCaptcheError,
        });
      }
    }
  };

  loadResendCaptcha = () => {
    if (window.grecaptcha) {
      window.grecaptcha.render("resend", {
        sitekey: "6LewRfMUAAAAAPkmGc_VaL8h0l_eRsia3GMU3V-b",
        size: "invisible",
        callback: this.resendEmail,
        "error-callback": this.onCaptcheError,
      });
    }
  };

  render() {
    return (
      <Fragment className="container">
        {this.state.sending && (
          <span
            class="spinner-grow text-muted"
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: "auto",
              width: "100px",
              height: "100px",
              zIndex: 99,
            }}
          ></span>
        )}
        {this.state.notification.show && (
          <div
            class={"alert alert-" + this.state.notification.status}
            style={{
              position: "fixed",
              left: "5%",
              bottom: "5%",
              zIndex: 99,
              boxShadow: "0px 0px 10px -2px",
            }}
          >
            <strong>{this.state.notification.heading}!</strong>{" "}
            {this.state.notification.msg}
          </div>
        )}

        {this.state.redirect && <Redirect to={this.state.redirectTo} />}

        {this.state.init && (
          <div className="sc-login-container">
            <div className="sc-login-inner card mx-sm-auto my-sm-5 mx-2 my-3 col-md-4">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-secondary">
                  <Link style={{ color: "white" }} to="/login">
                    Login As User
                  </Link>
                </button>
                <button type="button" class="btn btn-secondary active">
                  Login As Guest
                </button>
              </div>

              <div
                class="card-header"
                style={{
                  textAlign: "center",

                  backgroundColor: "#",
                }}
              >
                <img
                  src="/resources/images/logo_t.svg"
                  alt="sparkle logo"
                  style={{ maxWidth: "125px" }}
                />
              </div>
              <div className="card-body">
                <div
                  className="sc-app-card-headline"
                  style={{
                    fontSize: "25px",
                    textAlign: "center",
                    fontFamily: "poppins",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  Login As Guest
                </div>
                <hr />
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  style={{
                    display:
                      typeof this.state.loginDisabled !== "undefined" &&
                      (this.state.loginDisabled ? "none" : "initial"),
                  }}
                >
                  <div class="form-group">
                    <label for="Username">Username</label>
                    <input
                      type="text"
                      class="form-control"
                      name="username"
                      onChange={this.handleChange}
                      disabled={this.state.sending}
                      placeholder="Username [lowercase alphabets]"
                      maxLength="10"
                    />
                  </div>
                  <hr />
                  <button
                    class="btn btn-success btn-block sc-app-button"
                    type="submit"
                    disabled={
                      this.state.sending || this.state.username.trim() === ""
                    }
                    onClick={() => {
                      this.setState({ sending: true });
                      console.log(this.myRef);
                      this.myRef.current.click();
                    }}
                  >
                    Guest Login
                  </button>
                </form>
                {this.state.loginDisabled && (
                  <div
                    className="alert alert-danger"
                    style={{
                      backgroundColor: "#f7f7f7",
                      color: "#c35963",
                      marginTop: "10px",
                    }}
                  >
                    <p>
                      <b>Action Required!</b> Verification email has been sent
                      to you. Please check your inbox for more details. If you
                      haven't the received the email, click resend.
                    </p>
                    <button
                      id="resend"
                      className="btn btn-primary btn-sm"
                      disabled={this.state.verification.resending}
                    >
                      {this.state.verification.resending && (
                        <div className="spinner-grow spinner-grow-sm"></div>
                      )}
                      Resend
                    </button>
                  </div>
                )}
                <button
                  id="recaptcha"
                  style={{ display: "none" }}
                  ref={this.myRef}
                ></button>
              </div>
              <span
                style={{
                  position: "absolute",
                  bottom: "-30px",
                  right: "10px",
                  textDecoration: "underline",
                  fontWeight: "italic",
                }}
              >
                <Link to="/register">create new account?</Link>
              </span>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default GuestLogin;
