import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import C from './../../../util/Constants';

function UserBan({ data, setData, updateUser }) {

    const [updatedGlobalBan, setUpdatedGlobalBan] = useState(0);
    const [updatedGlobalBanError, setUpdatedGlobalBanError] = useState("");

    const updateGlobalBan = () => {

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .post(C.API_EP + "/api/admin/user/updateGlobalBan", {
                email: spSession.email,
                token: spSession.token,
                uid: data.user._id,
                status: updatedGlobalBan,
            })
            .then((response) => {
                if (response.data.status) {
                    setData({ ...data, user: { ...data.user, globalBan: parseInt(updateGlobalBan) } })
                    updateUser(data.user._id, "globalBan", parseInt(updateGlobalBan))
                }
                else {
                    setUpdatedGlobalBanError(response.data.message)
                }
            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        setUpdatedGlobalBan(data.user.globalBan)
    }, [])

    return (
        <div>
            <div
                className="form-group user-option-area"
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div style={{
                    display: 'flex'
                }}>
                    <label>Ban All Rooms </label>
                    <select
                        // disabled={!verifiedEnabled}
                        onChange={(e) => {
                            setUpdatedGlobalBan(e.target.value)
                        }}
                        className="form-control"
                    >
                        <option selected={data.user.globalBan === 1} value="1">Banned</option>
                        <option selected={data.user.globalBan === 0} value="0">Not Banned</option>
                    </select>
                </div>
                {updatedGlobalBanError !== "" &&
                    <div
                        style={{
                            background: '#d55a5a',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            justifyContent: 'space-between',
                            padding: '20px',
                            margin: '10px',
                            borderRadius: '3px'
                        }}
                    >
                        <span
                            style={{ cursor: 'pointer' }}
                            className="fa fa-times"
                            onClick={() => {
                                setUpdatedGlobalBanError("")
                            }}
                        ></span>
                        <p
                            style={{
                                margin: 0,
                                padding: 0
                            }}
                        >{updatedGlobalBanError}</p>
                    </div>
                }
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right'
                    }}
                >
                    <button
                        disabled={
                            updatedGlobalBan == data.user.globalBan
                        }
                        onClick={() => {
                            updateGlobalBan()
                        }}
                        className="btn btn-primary"
                    >Update</button>
                </div>
            </div>

        </div>
    )
}

export default UserBan