import React from 'react';
import { useEffect } from 'react';

const JoinRoom = (props) => {
  const closeCreateRoom = () => {
    props.closeCreateRoom();
  };

  useEffect(() => {
    if (props.layout.device === 2)
      adjustHeight();
    console.log(props.layout);
    //window.addEventListener('resize', adjustHeight);
  }, [])

  const adjustHeight = () => {
    var winHeight = window.innerHeight;
    var spChatbox = document.getElementsByClassName('sp-joinroom-inner')[0];
    if (typeof spChatbox === 'undefined') {
      return;
    }
    var spChatboxCard = spChatbox.getElementsByClassName('card')[0];

    spChatbox.style.height = winHeight + 'px';
    spChatboxCard.style.height = winHeight + 'px';
    var spChatBoxCardHeader = spChatboxCard.getElementsByClassName(
      'card-header'
    )[0];
    var spChatBoxCardBody = spChatboxCard.getElementsByClassName(
      'card-body'
    )[0];
    //var spChatBoxCardBodyList = spChatBoxCardBody.getElementsByTagName('ul')[0];
    var spChatBoxCardFooter = spChatboxCard.getElementsByClassName(
      'card-footer'
    )[0];
    var heightToBeSet =
      winHeight -
      (spChatBoxCardHeader.offsetHeight + spChatBoxCardFooter.offsetHeight);
    console.log(heightToBeSet);
    spChatBoxCardBody.style.height = heightToBeSet + 'px';
    //spChatBoxCardBodyList.style.maxHeight = heightToBeSet + 'px';
    //- (heightToBeSet * 8) / 100
  };

  return (
    <div className='sp-joinroom-wrapper'>
      <div className='sp-joinroom-inner'>
        <div className='card'>
          <div className='card-header'>
            Join Room
            <i
              className='fas fa-times sp-small-icon'
              onClick={closeCreateRoom}
            ></i>
          </div>
          <div className='card-body'>
            <b>Title:</b> {props.roomDetails.title}

            {props.roomDetails.isFeatured &&
              <span class='badge badge-success'>Featured</span>
            }

            {!props.roomDetails.isFeatured &&
              <span class='badge badge-info'>Non-Featured</span>
            }

            <hr />
            <b>Type:</b> {props.roomDetails.type}
            <hr />
            <b>Rules: </b> <br />
            <textarea disabled value={props.roomDetails.rules} style={{ width: '100%' }} rows="7"></textarea>
          </div>
          <div className='card-footer'>
            <button className='btn btn-success' onClick={() => { props.joinRoom(props.roomId) }}>
              Join
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinRoom;
