import Axios from 'axios';
import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Loader } from './Loader';
import DataTable from 'react-data-table-component';

import C from './../../util/Constants';
import { UserList } from './UserList';

const columns = [

    {
        name: 'Username',
        selector: row => row.username,
    },
    {
        name: 'Email',
        selector: row => row.email,
        wrap: true
    },

    {
        name: 'IP',
        selector: row => row.ip,
    },

    {
        name: 'Rank',
        selector: row => row.globalRank,
    },

    {
        name: 'A/C Status',
        selector: row => row.accountStatus,
    },

    {
        name: 'Verified',
        selector: row => row.verified,
    },

    {
        name: 'Date Joined',
        selector: row => row.date,
    },


];

export const SAUsers = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [rankEnabled, setRankEnabled] = useState(false);
    const [verifiedEnabled, setVerifiedEnabled] = useState(false);
    const [sortEnabled, setSortEnabled] = useState(false);
    const [accountStatusEnabled, setAccountStatusEnabled] = useState(false);
    const [dateEnabled, setDateEnabled] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [usernameFilter, setUsernameFilter] = useState("");
    const [emailFilter, setEmailFilter] = useState("");
    const [ipFilter, setIpFilter] = useState("");
    const [globalRankFilter, setGlobalRankFilter] = useState(0);
    const [verfiedFilter, setVerfiedFilter] = useState(0);
    const [accountStatusFilter, setAccountStatusFilter] = useState(0);
    const [dateFilter, setDateFilter] = useState(1);
    const [showFilters, setShowFilters] = useState(false);
    const [globalRankFilterType, setGlobalRankFilterType] = useState(0);
    const [sortFilter, setSortFilter] = useState(0);


    useEffect(() => {


        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .get(
                C.API_EP +
                '/api/admin/users?email=' +
                spSession.email +
                '&token=' +
                spSession.token + "&page=" + page
            )
            .then((response) => {
                setLoading(false);
                if (response.data.users) {
                    setData(response.data.users)
                    setTotal(response.data.total);
                    setPage(0)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log("Error loading status: ", error);
                if (typeof error.response == 'undefined') {
                    console.log('network error.');
                    return;
                }
            });


        return () => {
        }
    }, [])

    const filterAndRefresh = () => {

        setUpdating(true);

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        var query = C.API_EP +
            '/api/admin/users?email=' +
            spSession.email +
            '&token=' +
            spSession.token;

        if (usernameFilter !== "") {
            query += "&usernameFilter=" + usernameFilter;
        }


        if (emailFilter !== "") {
            query += "&emailFilter=" + emailFilter;
        }


        if (ipFilter !== "") {
            query += "&ipFilter=" + ipFilter;
        }

        if (rankEnabled) {
            query += "&globalRankFilter=" + globalRankFilter + '&globalRankFilterType=' + globalRankFilterType;
        }

        if (verifiedEnabled) {
            query += "&verifiedFilter=" + verfiedFilter;
        }

        if (accountStatusEnabled) {
            query += "&accountStatusFilter=" + accountStatusFilter;
        }

        if (dateEnabled) {
            query += "&dateFilter=" + dateFilter;
        }

        if (sortEnabled) {
            query += '&sortFilter=' + sortFilter
        }

        Axios
            .get(
                query
            )
            .then((response) => {
                setUpdating(false);
                if (response.data.users) {
                    setData(response.data.users)
                    setTotal(response.data.total);
                    setPage(0)
                }
            })
            .catch((error) => {
                setUpdating(false)
                console.log("Error loading status: ", error);
                if (typeof error.response == 'undefined') {
                    console.log('network error.');
                    return;
                }
            });
    }

    const nextAndFilter = () => {

        setUpdating(true);

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }


        var query = C.API_EP +
            '/api/admin/users?email=' +
            spSession.email +
            '&token=' +
            spSession.token + '&page=' + (page - (-1));

        setPage((page + 1))

        if (usernameFilter !== "") {
            query += "&usernameFilter=" + usernameFilter;
        }


        if (emailFilter !== "") {
            query += "&emailFilter=" + emailFilter;
        }


        if (ipFilter !== "") {
            query += "&ipFilter=" + ipFilter;
        }

        if (rankEnabled) {
            query += "&globalRankFilter=" + globalRankFilter;
        }

        if (verifiedEnabled) {
            query += "&verifiedFilter=" + verfiedFilter;
        }

        if (accountStatusEnabled) {
            query += "&accountStatusFilter=" + accountStatusFilter;
        }

        if (dateEnabled) {
            query += "&dateFilter=" + dateFilter;
        }

        Axios
            .get(
                query
            )
            .then((response) => {
                setUpdating(false);
                if (response.data.users) {
                    setData(response.data.users)
                    setTotal(response.data.total);
                }
            })
            .catch((error) => {
                setUpdating(false)
                console.log("Error loading status: ", error);
                if (typeof error.response == 'undefined') {
                    console.log('network error.');
                    return;
                }
            });
    }

    const lastAndFilter = () => {

        if (page === 0)
            return;

        setUpdating(true);

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }


        var query = C.API_EP +
            '/api/admin/users?email=' +
            spSession.email +
            '&token=' +
            spSession.token + '&page=' + (page - (1));

        setPage((page - 1))

        if (usernameFilter !== "") {
            query += "&usernameFilter=" + usernameFilter;
        }


        if (emailFilter !== "") {
            query += "&emailFilter=" + emailFilter;
        }


        if (ipFilter !== "") {
            query += "&ipFilter=" + ipFilter;
        }

        if (rankEnabled) {
            query += "&globalRankFilter=" + globalRankFilter;
        }

        if (verifiedEnabled) {
            query += "&verifiedFilter=" + verfiedFilter;
        }

        if (accountStatusEnabled) {
            query += "&accountStatusFilter=" + accountStatusFilter;
        }

        if (dateEnabled) {
            query += "&dateFilter=" + dateFilter;
        }

        Axios
            .get(
                query
            )
            .then((response) => {
                setUpdating(false);
                if (response.data.users) {
                    setData(response.data.users)
                    setTotal(response.data.total);
                }
            })
            .catch((error) => {
                setUpdating(false)
                console.log("Error loading status: ", error);
                if (typeof error.response == 'undefined') {
                    console.log('network error.');
                    return;
                }
            });
    }

    const processData = (users) => {
        var retUsers = [];
        users.map(e => {
            if (e.accountStatus === 1) {
                e.accountStatus = "Enabled"
            }
            else {
                e.accountStatus = "Disabled"
            }

            if (e.verified === 1) {
                e.verified = "Yes"
            }
            else {
                e.verified = "No"
            }

            e.date = new Date(e.date);

            retUsers.push(e);

        })

        return retUsers;
    }

    const updateUser = (uid, theKey, theValue) => {
        // alert("Updating user with " + uid);
        // console.log({ data })
        setData(
            data.map((e) => {
                if (e._id === uid) {
                    //alert("And theKey=" + theKey + " and theValue=" + theValue)
                    var updatedE = e;
                    e[theKey] = theValue;
                    console.log(updatedE)
                    return updatedE;
                }
                else {
                    return e;
                }
            })
        );
    }

    const deleteUser = (uid) => {
        // alert("Updating user with " + uid);
        // console.log({ data })
        setData(
            data.filter((e) => {
                if (e._id === uid) {
                    return false
                }
                else {
                    return true;
                }
            })
        );
    }

    return (
        <div className="sp-content-area">
            {loading &&
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Loader />
                    <span style={{ marginTop: '35px', color: '#1a73e8', fontWeight: 'bold' }}>Loading Users ...</span>
                </div>
            }
            {!loading &&
                <div>
                    <div
                        style={{
                            textAlign: 'left',
                            padding: '10px',
                            paddingLeft: '30px',
                            color: 'white',
                            background: '#1a73e8'
                        }}
                    >
                        <h3>Users</h3>
                    </div>

                    <div style={{ margin: '40px' }}>
                        <div style={{ textAlign: 'left' }}>
                            <h4>Filters {showFilters ? <span onClick={() => { setShowFilters(false) }} className="fa fa-angle-up"></span> : <span onClick={() => { setShowFilters(true) }} className="fa fa-angle-down"></span>}</h4>
                        </div>
                        <hr />

                        <div style={{ display: showFilters ? '' : 'none' }}>
                            <div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="usernameFilter"
                                        placeholder={"Username Filter"}
                                        onChange={(e) => {
                                            setUsernameFilter(e.target.value)
                                        }}
                                        disabled={false}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="emailFilter"
                                        placeholder={"Email Filter"}
                                        onChange={(e) => {
                                            setEmailFilter(e.target.value)
                                        }}
                                        disabled={false}
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="ipFilter"
                                        placeholder={"IP Address Filter"}
                                        onChange={(e) => {
                                            setIpFilter(e.target.value)
                                        }}
                                        disabled={false}
                                    />
                                </div>

                                <div className="form-group" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <span className="filterbox">
                                        <input type="checkbox" onClick={() => {
                                            setRankEnabled(!rankEnabled);
                                        }} />
                                        <label>Rank</label>
                                        <select
                                            disabled={!rankEnabled}
                                            onChange={(e) => {
                                                setGlobalRankFilterType(e.target.value)
                                            }}
                                        >
                                            <option value="0">=</option>
                                            <option value="1">&gt;</option>
                                            <option value="-1">&lt;</option>
                                        </select>
                                        <select
                                            disabled={!rankEnabled}
                                            onChange={(e) => {
                                                setGlobalRankFilter(e.target.value)
                                            }}
                                        >
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </span>

                                    <span className="filterbox">
                                        <input type="checkbox" onClick={() => {
                                            setVerifiedEnabled(!verifiedEnabled);
                                        }} />
                                        <label>Verified</label>
                                        <select
                                            disabled={!verifiedEnabled}
                                            onChange={(e) => {
                                                setVerfiedFilter(e.target.value)
                                            }}

                                        >
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                    </span>
                                </div>

                                <div className="form-group" style={{ display: 'flex' }}>
                                    <span className="filterbox">
                                        <input type="checkbox" onClick={() => {
                                            setAccountStatusEnabled(!accountStatusEnabled);
                                        }} />
                                        <label>Account Status</label>
                                        <select
                                            disabled={!accountStatusEnabled}
                                            onChange={(e) => {
                                                setAccountStatusFilter(e.target.value)
                                            }}

                                        >
                                            <option value="1">Enabled</option>
                                            <option value="0">Disabled</option>
                                        </select>
                                    </span>

                                    <span className="filterbox">
                                        <input type="checkbox" onClick={() => {
                                            setDateEnabled(!dateEnabled);
                                        }} />
                                        <label>Date</label>
                                        <select
                                            disabled={!dateEnabled}
                                            onChange={(e) => {
                                                setDateFilter(e.target.value)
                                            }}
                                        >
                                            <option value="1">Last Day</option>
                                            <option value="3">Three Days</option>
                                            <option value="7">Week</option>
                                            <option value="30">Last Month</option>
                                            <option value="90">Three Months</option>
                                        </select>
                                    </span>
                                </div>

                                <div className="form-group" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <span className="filterbox">
                                        <input type="checkbox" onClick={() => {
                                            setSortEnabled(!sortEnabled);
                                        }} />
                                        <label>Sort By</label>
                                        <select
                                            disabled={!sortEnabled}
                                            onChange={(e) => {
                                                setSortFilter(e.target.value)
                                            }}
                                        >
                                            <option value="0">Rank - ASC</option>
                                            <option value="1">Rank - DESC</option>
                                            <option value="2">Date - ASC</option>
                                            <option value="3">Date - DESC</option>
                                        </select>
                                    </span>


                                </div>

                            </div>

                            <div>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        console.log(usernameFilter)
                                        console.log(emailFilter)
                                        console.log(ipFilter)
                                        console.log(globalRankFilter)
                                        console.log(verfiedFilter)
                                        console.log(accountStatusFilter)
                                        console.log(dateFilter)

                                        filterAndRefresh()

                                    }}>Filter</button>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className="btn btn-danger"
                                onClick={() => {

                                    lastAndFilter()

                                }}
                                disabled={page === 0}
                            >Last Page</button>
                            <p>
                                {25 * page + "-" + (25 * page + data.length) + '/' + total}
                            </p>
                            <button
                                className="btn btn-success"
                                onClick={() => {

                                    nextAndFilter()

                                }}
                                disabled={data.length === 0}
                            >Next Page</button>
                        </div>
                    </div>



                    {
                        false &&
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={updating}
                            responsive
                            highlightOnHover
                            pointerOnHover
                            dense
                            noContextMenu
                        />
                    }

                    <UserList columns={columns} deleteUser={deleteUser} updateUser={updateUser} data={data} loading={loading || updating} />

                </div>
            }
        </div>
    )
}
