import Axios from 'axios';
import React from 'react';
import C from './../../util/Constants';
import langauges from '../../languages';



export default function ThreadLikes({ threadId, roomId, closeHandler }) {

    var langauge = 'english';

    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState({ status: 0, msg: '' });
    const [data, setData] = React.useState([]);


    React.useEffect(() => {


        console.log(roomId);
        console.log(threadId);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }


        langauge = localStorage.getItem(C.LANGUAGE);

        if (langauge === null) {
            langauge = 'english'
        }


        var spSession = checkForSession();
        if (spSession === false) {
            return;
        }

        Axios
            .get(
                C.API_EP +
                '/a/r/thread/likes?roomId=' +
                roomId +
                '&email=' +
                spSession.email +
                '&token=' +
                spSession.token +
                '&threadId=' + threadId
            )
            .then((res) => {
                setLoading(false)
                setLoad({ status: 1, msg: 'Threads load success!' })
                setData(res.data.data);
                console.log(res.data.data);
            })
            .catch(error => {
                setLoading(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        setLoad({ status: -1, msg: error.response.data.msg })
                    }
                } else if (error.request) {
                    setLoad({ status: -1, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    setLoad({ status: -1, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }, [threadId])

    const checkForSession = () => {
        if (localStorage.getItem(C.SP_SESSION) == null) {
            return false;
        }

        try {
            var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
            return spSession;
        } catch (e) {
            return false;
        }
    };

    return (
        <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
            <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                    <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3 style={{ fontSize: '1.3rem' }}>{langauges[langauge].liked_by}</h3>
                            <i className="fa fa-times" onClick={() => {
                                closeHandler(false)
                            }}></i>
                        </div>
                        <hr />
                        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                            {data.map(like => (
                                <div key={like._id} style={{ padding: '10px', margin: '15px' }}>
                                    <div style={{ display: 'flex', }}>
                                        <img src={typeof like.profile !==
                                            'undefined' &&
                                            C.ASSET_EP +
                                            '/avatars/' +
                                            like.profile.picture.split(
                                                '.'
                                            )[0] +
                                            '_sm.' +
                                            like.profile.picture.split('.')[1]}
                                            alt="User Avatar"
                                            style={{ width: '50px', height: '50px', borderRadius: '25px' }}
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                            <span style={{ fontWeight: 'bold' }}>{like.profile.fullname}</span>
                                            <span style={{ color: '#777' }}>@{like.username}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}
