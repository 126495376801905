import Axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import C from './../../util/Constants';

export const EditSetting = ({ editKVP, editor, closeHandler, updateSettings }) => {

    const mainElement = useRef(null);
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [updating, setUpdating] = useState(false);
    const [result, setResult] = useState({ status: '', message: '' });

    useEffect(() => {
        var me = mainElement.current;
        var width = me.offsetWidth;
        var left = editor.x - width;
        me.style.left = left + 'px';
        me.style.top = editor.y + 'px';
        setKey(editKVP.key);
        setValue(editKVP.value);

    }, [])



    const updateSetting = () => {
        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        setUpdating(true)

        Axios
            .post(C.API_EP + "/api/admin/settings/update", {
                email: spSession.email,
                token: spSession.token,
                sid: editKVP._id,
                key: key,
                value: value
            })
            .then((response) => {
                if (response.data.status) {
                    setTimeout(() => {
                        setUpdating(false)
                        setResult({ status: 'success', message: response.data.message })
                        updateSettings(editKVP._id, "key", key);
                        updateSettings(editKVP._id, "value", value);

                    }, 300)
                }
                else {
                    setTimeout(() => {
                        setUpdating(false)
                        setResult({ status: 'danger', message: response.data.message })
                    }, 300)
                }
            })
            .catch((error) => {
                setTimeout(() => {
                    setUpdating(false)
                    setResult({ status: 'danger', message: 'An error occured!' })
                }, 300)
            });
    }

    return (
        <div
            class="animate sildeInLeft"
            ref={mainElement}
            style={{
                background: 'white',
                border: '1px solid #ddd',
                padding: '20px',
                borderRadius: '9px',
                boxShadow: '0px 0px 91px -25px #000',
                position: 'fixed',
            }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Edit Setting</span>
                <button className='btn btn-danger' onClick={closeHandler}>Close</button>
            </div>
            <hr />
            <div>
                <input className='form-control  sc-input-text' type='text' placeholder='Key' value={key}
                    onChange={(e) => {
                        setKey(e.target.value)
                    }}
                />
                <br />
                <input className='form-control' type='text' placeholder='Value' value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                />
                <hr />
                {
                    result.status !== '' &&
                    <div
                        style={{
                            background: result.status === 'danger' ? '#dc3545' : '#28a745',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            justifyContent: 'space-between',
                            padding: '20px',
                            margin: '10px',
                            borderRadius: '3px'
                        }}
                    >
                        <span
                            style={{ cursor: 'pointer', marginLeft: '20px', marginTop: '5px' }}
                            className="fa fa-times"
                            onClick={() => {
                                // setUserDeleteError("")
                                //setTransferOwnershipSuccess("");
                                setResult({ status: '', message: '' })
                            }}
                        ></span>
                        <p
                            style={{
                                margin: 0,
                                padding: 0
                            }}
                        >{result.message}</p>
                    </div>
                }
                <button className='btn btn-primary'
                    disabled={key === editKVP.key && value === editKVP.value}
                    onClick={updateSetting}
                >
                    Update
                    {updating && <span style={{ paddingLeft: '10px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                </button>
            </div>
        </div>
    );
};
