import React from 'react';

export default class GoogleAds extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <ins
        className='adsbygoogle'
        style={{ display: 'block' }}
        data-ad-format='fluid'
        data-ad-layout-key='-f9+5v+4m-d8+7b'
        data-ad-client='ca-pub-7359034842870226'
        data-ad-slot='4482200832'
      ></ins>
    );
  }
}
