import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import C from './../../util/Constants';
import EditRole from './EditRole';
import DeleteRole from './DeleteRole';
import AddRole from './AddRole';

const RoomSettings = (props) => {
  const [roomDetails, setRoomDetails] = useState({});
  const [banned, setBanned] = useState({
    banInput: '',
    data: [],
    load: 2,
    up: 2,
    down: 2,
  });
  const [load, setLoad] = useState(2);
  const [loadedRole, setLoadedRole] = useState(0);
  const [update, setUpdate] = useState(2);
  const [loadedUi, setLoadedUi] = useState(1);
  const [roles, setRoles] = useState([]);
  const [defRole, setDefRole] = useState('');
  const [newDefRole, setNewDefRole] = useState('');
  const [roleUpdate, setRoleUpdate] = useState(false);
  const [showEditRole, setShowEditRole] = useState(false);
  const [loadedView, setLoadedView] = useState("RolesList");
  const closeRoomSettings = () => {
    props.closeHandler();
  };

  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
      return spSession;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    setLoad(0);
    var spSession = checkForSession();
    if (spSession === false) {
      setLoad(-1);
      return;
    }

    Axios.get(
      C.API_EP +
      '/a/rooms/settings?email=' +
      spSession.email +
      '&token=' +
      spSession.token +
      '&roomId=' +
      props.id
    )
      .then((response) => {
        setLoad(1);
        setRoomDetails(response.data.roomDetails);
        setBanned({
          ...banned,
          data: response.data.banned,
        });
        console.log(banned);
      })
      .catch((e) => {
        setLoad(-1);
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (loadedUi === 3) {
      loadRoles();
    }
  }, [loadedUi]);

  const updateRoomSettings = () => {
    setUpdate(0);

    var spSession = checkForSession();
    if (spSession === false) {
      setUpdate(-1);
      return;
    }

    Axios.post(C.API_EP + '/a/rooms/update', {
      email: spSession.email,
      token: spSession.token,
      roomId: props.id,
      title: roomDetails.title,
      desc: roomDetails.desc,
      topic: roomDetails.topic,
      rules: roomDetails.rules,
      slug: roomDetails.slug,
      mountedView: roomDetails.mountedView
    })
      .then((response) => {
        setUpdate(1);
        console.log(response);
      })
      .catch((e) => {
        setUpdate(-1);
        console.log(e);
      });
  };

  const _setLoadedView = (lv) => {
    setLoadedView(lv);
  }

  const banUser = () => {
    setBanned({ ...banned, update: 0 });

    var spSession = checkForSession();
    if (spSession === false) {
      setLoad(-1);
      return;
    }

    Axios.post(C.API_EP + '/a/rooms/banUser', {
      email: spSession.email,
      token: spSession.token,
      roomId: props.id,
      username: banned.banInput,
    })
      .then((response) => {
        setBanned({
          ...banned,
          update: 1,
          data: [...banned.data, response.data.bannedUser],
          banInput: '',
        });
      })
      .catch((e) => {
        setBanned({ ...banned, update: -1, up: -1 });
        console.log(e);
      });
  };

  const unbanUser = (username) => {
    setBanned({ ...banned, update: 0 });

    var spSession = checkForSession();
    if (spSession === false) {
      setLoad(-1);
      return;
    }

    Axios.post(C.API_EP + '/a/rooms/unbanUser', {
      email: spSession.email,
      token: spSession.token,
      roomId: props.id,
      username: username,
    })
      .then((response) => {
        setBanned({
          ...banned,
          update: 1,
          data: banned.data.filter((e) => e.username !== username),
        });
      })
      .catch((e) => {
        setBanned({ ...banned, update: -1 });
        console.log(e);
      });
  };

  const loadRoles = () => {
    var spSession = checkForSession();
    if (spSession === false) {
      setLoad(-1);
      return;
    }

    Axios.get(
      C.API_EP +
      '/a/r/roles?email=' +
      spSession.email +
      '&token=' +
      spSession.token +
      '&roomId=' +
      props.id
    )
      .then((response) => {
        setLoad(1);
        setRoles(response.data.data);
        setDefRole(response.data.default);
      })
      .catch((e) => {
        setLoad(-1);
        console.log(e);
      });
  };

  const updateDefaultRole = (newrole) => {

    setUpdate(0);

    var spSession = checkForSession();
    if (spSession === false) {
      setLoad(-1);
      return;
    }

    Axios.post(C.API_EP + '/a/r/updateDefaultRole', {
      email: spSession.email,
      token: spSession.token,
      roomId: props.id,
      newrole: newrole,
    })
      .then((response) => {
        setUpdate(1);
        setRoleUpdate(false);
        console.log(response)
      })
      .catch((e) => {
        setUpdate(-1);
        console.log(e);
      });
  };

  return (
    <div className='sp-joinroom-wrapper'>
      <div className='sp-joinroom-inner'>
        <div className='card'>
          <div className='card-header'>
            Room Settings
            <i
              className='fas fa-times sp-small-icon'
              onClick={closeRoomSettings}
            ></i>
          </div>
          <div className='card-body room-settings' style={{ padding: '15px' }}>
            <div>
              {update === 1 && (
                <div className='alert alert-success'>Update complete.</div>
              )}

              {update === -1 && (
                <div className='alert alert-danger'>Update Failed.</div>
              )}

              {update === 0 && (
                <div class='progress'>
                  <div
                    class='progress-bar progress-bar-striped progress-bar-animated'
                    style={{ width: '100%' }}
                  ></div>
                </div>
              )}

            </div>
            <div class='btn-group' style={{ marginBottom: '10px' }}>
              <button
                type='button'
                class='btn btn-primary'
                onClick={() => {
                  setLoadedUi(1);
                }}
              >
                Basic
              </button>
              <button
                type='button'
                class='btn btn-primary'
                onClick={() => {
                  setLoadedUi(2);
                }}
              >
                Banned
              </button>
              <button
                type='button'
                class='btn btn-primary'
                onClick={() => {
                  setLoadedUi(3);
                }}
              >
                Roles
              </button>
            </div>
            {loadedUi === 1 && (
              <div>
                <b>Title:</b>
                <input
                  type='text'
                  value={roomDetails.title}
                  className='form-control'
                  maxLength='25'
                  onChange={(e) => {
                    setRoomDetails({ ...roomDetails, title: e.target.value });
                  }}
                />

                <b>Default View:</b>
                <select
                  value={roomDetails.mountedView}
                  className='form-control'
                  onChange={(e) => {
                    setRoomDetails({ ...roomDetails, mountedView: e.target.value });
                  }}
                >
                  <option value={0} selected={roomDetails.mountedView === 0}>Messages</option>
                  <option value={1} selected={roomDetails.mountedView === 1}>Threads</option>
                </select>
                {/* <input
                  type='text'
                  value={roomDetails.mountedView}
                  className='form-control'
                  maxLength='25'
                  onChange={(e) => {
                    setRoomDetails({ ...roomDetails, mountedView: e.target.value });
                  }}
                /> */}

                <b>Slug:</b>
                <input
                  type='text'
                  value={roomDetails.slug}
                  className='form-control'
                  maxLength='25'
                  onChange={(e) => {
                    if (e.target.value.match(/^[a-zA-Z]{1}[a-zA-Z0-9]{1,25}$/))
                      setRoomDetails({ ...roomDetails, slug: e.target.value });
                  }}
                />

                <b>Desc: </b>
                <span>
                  <textarea
                    maxLength='250'
                    value={roomDetails.desc}
                    className='form-control'
                    onChange={(e) => {
                      setRoomDetails({ ...roomDetails, desc: e.target.value });
                    }}
                  ></textarea>
                </span>

                <b>Topic: </b>
                <span>
                  <textarea
                    maxLength='150'
                    value={roomDetails.topic}
                    className='form-control'
                    onChange={(e) => {
                      setRoomDetails({ ...roomDetails, topic: e.target.value });
                    }}
                  ></textarea>
                </span>


                <b>Rules: </b>
                <span>
                  <textarea
                    maxLength='800'
                    value={roomDetails.rules}
                    className='form-control'
                    onChange={(e) => {
                      setRoomDetails({ ...roomDetails, rules: e.target.value });
                    }}
                  ></textarea>
                </span>

                <hr />
              </div>
            )}

            {loadedUi === 2 && (
              <div>
                {false && (
                  <span>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Username'
                      value={banned.banInput}
                      onChange={(e) => {
                        setBanned({ ...banned, banInput: e.target.value });
                      }}
                    />
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={banUser}
                      style={{ margin: '5px' }}
                      disabled={
                        typeof banned.banInput === 'undefined' ||
                        banned.banInput.length < 1
                      }
                    >
                      Ban
                    </button>
                  </span>
                )}
                {typeof banned.up !== 'undefined' && banned.up === -1 && (
                  <div className='alert alert-danger'>
                    Error occured while banning.
                  </div>
                )}
                <hr />

                {typeof banned.data !== 'undefined' &&
                  banned.data.length === 0 && <h5>No banned users</h5>}
                <ul
                  style={{ maxHeight: '40vh', overflow: 'auto' }}
                  className='list-group'
                >
                  {typeof banned.data !== 'undefined' &&
                    banned.data.map((e) => (
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        {e.username}
                        <span
                          class='badge badge-primary badge-pill'
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            //unbanUser(e.username);
                          }}
                        >
                          {/* <i className='fas fa-times'></i> */}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            )}

            {loadedUi === 3 && (
              <div>

                <div className='form-group'>
                </div>
                <div id='' class='roles-list'>
                  <div
                    class=''
                    style={{
                      backgroundColor: '#252525c9',
                      color: 'white',
                      padding: '40px',
                    }}
                  >
                    <hr />
                    {roles.length > 0 &&
                      <div
                        style={{ display: 'none' }}
                      >
                        <h5>Title: {roles[loadedRole].title}</h5>
                        <b>Rank: </b> {roles[loadedRole].rank}
                        <hr />
                        <div className='sc-roles-item'>
                          <b>Read Message</b>
                          {roles[loadedRole].permissions.Read ? (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              checked
                              disabled={true}
                            />
                          ) : (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              disabled={true}
                            />
                          )}
                        </div>
                        <div className='sc-roles-item'>
                          <b>Write Message</b>
                          {roles[loadedRole].permissions.Write ? (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              checked
                              disabled={true}
                            />
                          ) : (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              disabled={true}
                            />
                          )}
                        </div>
                        <div className='sc-roles-item'>
                          <b>Upload Media</b>
                          {roles[loadedRole].permissions.Upload ? (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              checked
                              disabled={true}
                            />
                          ) : (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              disabled={true}
                            />
                          )}
                        </div>
                        <div className='sc-roles-item'>
                          <b>Broadcast</b>
                          {roles[loadedRole].permissions.Broadcast ? (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              checked
                              disabled={true}
                            />
                          ) : (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              disabled={true}
                            />
                          )}
                        </div>
                        <div className='sc-roles-item'>
                          <b>Mention</b>
                          {roles[loadedRole].permissions.Mention ? (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              checked
                              disabled={true}
                            />
                          ) : (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              disabled={true}
                            />
                          )}
                        </div>
                        <div className='sc-roles-item'>
                          <b>Can Ban</b>
                          {roles[loadedRole].permissions.Ban ? (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              checked
                              disabled={true}
                            />
                          ) : (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              disabled={true}
                            />
                          )}
                        </div>
                        <div className='sc-roles-item'>
                          <b style={{ color: '#ec7979' }}>Can IpBan</b>
                          {roles[loadedRole].permissions.IpBan ? (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              disabled={true}
                              checked
                            />
                          ) : (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              disabled={true}
                            />
                          )}
                        </div>
                        <div className='sc-roles-item'>
                          <b style={{ color: '#ec7979' }}>Extend</b>
                          {roles[loadedRole].permissions.Extend ? (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              checked
                              disabled={true}
                            />
                          ) : (
                            <input
                              type='checkbox'
                              className='checkbox-inline'
                              disabled={true}
                            />
                          )}
                        </div>
                      </div>
                    }


                    {loadedView === "RolesList" &&
                      <div>
                        <h5>Default Role</h5>
                        <select style={{ margin: '10px 0' }} className="form-control" onChange={(e) => { if (e.target.value !== defRole) { setRoleUpdate(true); setNewDefRole(e.target.value); } else setRoleUpdate(false); }} >
                          {
                            roles.map((role, i) => {
                              return (
                                <option selected={defRole === role._id ? true : false} value={role._id} key={i} >{role.title}</option>
                              );
                            })

                          }
                        </select>
                        <label htmlFor="defaultrole">
                          {roleUpdate && <button className='btn btn-success btn-sm' onClick={() => { updateDefaultRole(newDefRole); }}>Update</button>}
                        </label>
                        <hr />
                        <h4>Roles List</h4>
                        <hr />
                        <div
                          className="edit-role"
                          key={0}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            border: '2px solid #28a745',
                            justifyContent: 'center',
                            padding: '10px',
                            margin: '10px',
                            borderRadius: '5px',
                            color: '#28a745',
                            cursor: 'pointer'
                          }}
                        >
                          <div style={{}} onClick={() => {
                            // setLoadedRole(i);
                            setLoadedView("AddRole");

                          }}>
                            <i className="fa fa-plus-circle " ></i>&nbsp;New Role
                          </div>
                        </div>

                        {
                          roles.map((role, i) => {
                            return (
                              <div
                                class={
                                  i === 0 ? '' : ''
                                }
                                key={role._id}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  border: '1px solid',
                                  justifyContent: 'space-between',
                                  padding: '10px',
                                  margin: '10px',
                                  borderRadius: '5px'
                                }}
                              >
                                <div style={{}}>
                                  <span style={{ paddingRight: '10px', marginRight: '10px' }}>{role.rank}</span>
                                  <span style={{ fontWeight: 'bold' }} >{role.title}</span>
                                </div>
                                <div style={{ cursor: 'pointer' }} >
                                  {defRole !== role._id &&
                                    <i className="fa fa-trash edit-role" onClick={() => {
                                      setLoadedRole(i);
                                      setLoadedView("DeleteRole");
                                    }}></i>}
                                  <i style={{ marginLeft: '10px' }} className="fa fa-pencil edit-role" onClick={() => {
                                    setLoadedRole(i);
                                    setLoadedView("EditRole");

                                  }}></i>
                                </div>
                              </div>
                            );

                          })
                        }
                      </div>
                    }

                    {loadedView === "EditRole" && <EditRole roomId={props.id} setRoles={setRoles} roles={roles} loadedView={_setLoadedView} role={roles[loadedRole]} />
                    }

                    {loadedView === "AddRole" && <AddRole roomId={props.id} setRoles={setRoles} roles={roles} loadedView={_setLoadedView} />
                    }

                    {loadedView === "DeleteRole" && <DeleteRole roomId={props.id} setRoles={setRoles} setLoadedRole={setLoadedRole} roles={roles} role={roles[loadedRole]} loadedView={_setLoadedView} />}


                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='card-footer'>
            {loadedUi === 1 && (
              <button
                className='btn btn-success btn-sm'
                onClick={updateRoomSettings}
              >
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomSettings;
