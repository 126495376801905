import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import C from './../../util/Constants';

export const UserStats = ({ stats }) => {

    useEffect(() => {
        console.log(stats)
    }, []);

    return (
        <div>
            <div
                style={{
                    height: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',

                        justifyContent: 'space-evenly'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            margin: '40px 0'
                        }}
                    >
                        <div
                            style={{
                                background: 'white',
                                borderRadius: '10px',
                                padding: '20px',
                                color: 'white',
                                background: '#1a73e8'
                            }}
                        >
                            <p>Rooms Joined</p>
                            <span
                                style={{
                                    fontSize: '45px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >{stats.roomsJoined}</span>
                        </div>
                        <div
                            style={{
                                background: 'white',
                                borderRadius: '10px',
                                padding: '20px',
                                color: 'white',
                                background: '#1a73e8'

                            }}
                        >
                            <p>Rooms Created</p>
                            <span
                                style={{
                                    fontSize: '45px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >{stats.roomsCreated}</span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            margin: '40px 0'
                        }}
                    >
                        <div
                            style={{
                                background: 'white',
                                borderRadius: '10px',
                                padding: '20px',
                                color: 'white',
                                background: '#1a73e8'
                            }}
                        >
                            <p>Private Threads</p>
                            <span
                                style={{
                                    fontSize: '45px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >{stats.privateThreads}</span>
                        </div>
                        <div
                            style={{
                                background: 'white',
                                borderRadius: '10px',
                                padding: '20px',
                                color: 'white',
                                background: '#1a73e8'

                            }}
                        >
                            <p>Private Messages</p>
                            <span
                                style={{
                                    fontSize: '45px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >{stats.privateMessages}</span>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            margin: '40px 0'
                        }}
                    >
                        <div
                            style={{
                                background: 'white',
                                borderRadius: '10px',
                                padding: '20px',
                                color: 'white',
                                background: '#1a73e8'
                            }}
                        >
                            <p>Total Logins</p>
                            <span
                                style={{
                                    fontSize: '45px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >{stats.logins}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
