import Axios from 'axios';
import React from 'react'
import { Fragment } from 'react';
import { connect } from 'react-redux';

import langauges from '../../languages';
import { changePinnedThread, loadMoreRoomThreadsSuccess, loadRoomThreadsSuccess, removePinnedThread, showUserProfileUI } from '../../redux/actions/appActions';
import ThreadView from '../util/ThreadView';
import C from './../../util/Constants';
import CreateThread from './CreateThread';
import ThreadItem from './ThreadItem';
import MediaPlayerPortable from '../util/MediaPlayerPortable';
import ReportThread from '../util/ReportThread';
import TagSearch from '../util/TagSearch';

function _ThreadsList({ dispatch, roomId, permissions, threads, iOwn }) {

    var langauge = 'english';

    const [showThreadPin, setShowThreadPin] = React.useState(false);
    const [pining, setPining] = React.useState(false);
    const [threadPinId, setThreadPinId] = React.useState(0);
    const [pinError, setPinError] = React.useState({ status: false, msg: '' })

    const [showThreadArchive, setShowThreadArchive] = React.useState(false);
    const [archiving, setArchiving] = React.useState(false);
    const [threadArchiveId, setThreadArchiveId] = React.useState(0);
    const [archiveError, setArchiveError] = React.useState({ status: false, msg: '' })

    const [showThreadUnpin, setShowThreadUnpin] = React.useState(false);
    const [unpining, setUnpining] = React.useState(false);
    const [threadUnpinId, setThreadUnpinId] = React.useState(0);
    const [unpinError, setUnpinError] = React.useState({ status: false, msg: '' });

    const [showThreadReport, setShowThreadReport] = React.useState(false);
    const [threadReportId, setThreadReportId] = React.useState(0);
    const [reportData, setReportData] = React.useState({ reason: 1, details: '' })
    const [reporting, setReporting] = React.useState(false)
    const [reportError, setReportError] = React.useState({ status: false, msg: '' });
    const [reported, setReported] = React.useState(false)

    const [showCreateThread, setShowCreateThread] = React.useState(false);

    const [showThreadView, setShowThreadView] = React.useState(false);

    const [showTagSearch, setShowTagSearch] = React.useState(false);
    const [tagName, setTagName] = React.useState("");

    const [threadLoaded, setThreadLoaded] = React.useState({});

    const [showThreadDelete, setShowThreadDelete] = React.useState(false);


    const [showThreadEmbed, setShowThreadEmbed] = React.useState(false);

    const [loading, setLoading] = React.useState(true);
    const [loadingMore, setLoadingMore] = React.useState(false);

    const [threadDeleteId, setThreadDeleteId] = React.useState(0);
    const [deleting, setDeleting] = React.useState(false);
    const [deleteError, setDeleteError] = React.useState({ status: false, msg: '' })

    const [load, setLoad] = React.useState({ status: 0, msg: '' })
    const [pinned, setPinned] = React.useState(null)
    const [threadEmbedId, setThreadEmbedId] = React.useState(0)


    const _setShowThreadArchive = (tid) => {
        setShowThreadArchive(true);
        setThreadArchiveId(tid);
    }

    const _setShowTagSearch = (tag) => {
        setTagName(tag);
        setShowTagSearch(true);
    }

    const _setShowThreadReport = (tid) => {
        setShowThreadReport(true);
        setThreadReportId(tid);
    }

    const _setShowThreadDelete = (tid) => {
        setShowThreadDelete(true);
        setThreadDeleteId(tid);
    }

    const _setShowThreadEmbed = (thread) => {
        console.log(thread);
        setThreadEmbedId(thread);
        setShowThreadEmbed(true)
    }

    const _setShowThreadPin = (tid) => {
        setShowThreadPin(true);
        setThreadPinId(tid);
    }

    const _setArchiveError = (dE, delay) => {
        setArchiveError(dE);
        setTimeout(() => {
            setArchiveError({ status: false, msg: '' })
        }, delay);
    }

    const _setPinError = (dE, delay) => {
        setPinError(dE);
        setTimeout(() => {
            setPinError({ status: false, msg: '' })
        }, delay);
    }

    const _setReportError = (dE, delay) => {
        setReportError(dE);
        setTimeout(() => {
            setReportError({ status: false, msg: '' })
        }, delay);
    }

    const _setDeleteError = (dE, delay) => {
        setDeleteError(dE);
        setTimeout(() => {
            setDeleteError({ status: false, msg: '' })
        }, delay);
    }

    const reportThread = (tid) => {
        setReporting(true);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }

        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios
            .post(C.API_EP + '/a/thread/report', {
                email: spSession.email,
                token: spSession.token,
                reason: reportData.reason,
                details: reportData.details,
                id: tid,
            })
            .then((res) => {
                setReporting(false)
                // setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                console.log(data);
                setReported(true);
                setTimeout(function () {
                    setShowThreadReport(false)
                }, 1500)

                //setShowThreadDelete(false);
                //setShowThreadPin(false);
                //dispatch(changePinnedThread(tid));
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {

                console.log("Error");

                setReporting(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        _setReportError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.status === 404) {
                        _setReportError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.state === 500) {
                        _setReportError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                } else if (error.request) {
                    _setReportError({ status: true, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    _setReportError({ status: true, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }


    React.useEffect(() => {
        console.log(threads);
        console.log(dispatch)
    }, [])

    const deleteThread = (tid) => {
        setDeleting(true);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }

        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios
            .post(C.API_EP + '/a/r/thread/delete', {
                email: spSession.email,
                token: spSession.token,
                threadId: tid,
                roomId: roomId
            })
            .then((res) => {
                setDeleting(false)
                // setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                console.log(data);
                setShowThreadDelete(false);
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {
                setDeleting(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        _setDeleteError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.state === 500) {
                        _setDeleteError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                } else if (error.request) {
                    _setDeleteError({ status: true, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    _setDeleteError({ status: true, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }

    const archiveThread = (tid) => {
        setArchiving(true);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }

        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios
            .post(C.API_EP + '/a/r/thread/archive', {
                email: spSession.email,
                token: spSession.token,
                threadId: tid,
                roomId: roomId
            })
            .then((res) => {
                setArchiving(false)
                // setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                console.log(data);
                setShowThreadArchive(false);
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {
                setArchiving(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        _setArchiveError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.state === 500) {
                        _setArchiveError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                } else if (error.request) {
                    _setArchiveError({ status: true, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    _setArchiveError({ status: true, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }

    const pinThread = (tid, thread) => {
        setPining(true);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }

        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios
            .post(C.API_EP + '/a/r/c/thread/pin', {
                email: spSession.email,
                token: spSession.token,
                threadId: tid,
                roomId: roomId
            })
            .then((res) => {
                setPining(false)
                // setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                console.log(data);
                //setShowThreadDelete(false);
                setShowThreadPin(false);
                dispatch(changePinnedThread(tid, thread));
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {

                console.log("Error");
                console.log(error);

                setPining(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        _setPinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.status === 404) {
                        _setPinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.state === 500) {
                        _setPinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                } else if (error.request) {
                    _setPinError({ status: true, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    _setPinError({ status: true, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }

    const _setUnpinError = (dE, delay) => {
        setUnpinError(dE);
        setTimeout(() => {
            setUnpinError({ status: false, msg: '' })
        }, delay);
    }

    const _setShowThreadUnpin = (tid) => {
        setShowThreadUnpin(true);
        setThreadUnpinId(tid);
    }

    const unpinThread = (tid, thread) => {
        setUnpining(true);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }

        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios
            .post(C.API_EP + '/a/r/c/thread/unpin', {
                email: spSession.email,
                token: spSession.token,
                threadId: tid,
                roomId: roomId
            })
            .then((res) => {
                setUnpining(false)
                // setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                console.log(data);
                //setShowThreadDelete(false);
                setShowThreadUnpin(false);

                //dispatch(changePinnedThread(tid));
                dispatch(removePinnedThread(tid, thread))
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {

                console.log("Error");
                console.log(error);

                setUnpining(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        _setUnpinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.status === 404) {
                        _setUnpinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.state === 500) {
                        _setUnpinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                } else if (error.request) {
                    _setUnpinError({ status: true, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    _setUnpinError({ status: true, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }

    const adjustHeight = () => {
        var winHeight = window.innerHeight;
        var spChatbox = document.getElementsByClassName('sp-chatbox')[0];
        if (typeof spChatbox === 'undefined') {
            return;
        }
        var spChatboxCard = spChatbox.getElementsByClassName('card')[0];

        spChatbox.style.height = winHeight + 'px';
        spChatboxCard.style.height = winHeight + 'px';
        var spChatBoxCardHeader = spChatboxCard.getElementsByClassName(
            'card-header'
        )[0];
        var spChatBoxCardBody = spChatboxCard.getElementsByClassName(
            'sp-threads-body'
        )[0];

        if (typeof spChatBoxCardBody === "undefined") {
            return;
        }

        var spChatBoxCardBodyList = spChatBoxCardBody.getElementsByTagName('div')[0];

        var heightToBeSet =
            winHeight -
            (spChatBoxCardHeader.offsetHeight + 1);
        console.log(heightToBeSet);
        spChatBoxCardBody.style.height = heightToBeSet + 'px';
        spChatBoxCardBodyList.style.maxHeight = heightToBeSet + 'px';
        spChatBoxCardBodyList.style.overflow = 'auto';
        //- (heightToBeSet * 8) / 100
    };

    React.useEffect(() => {
        console.log(threads);
    }, [threads]);

    React.useEffect(() => {


        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }


        langauge = localStorage.getItem(C.LANGUAGE);

        if (langauge === null) {
            langauge = 'english'
        }


        var spSession = checkForSession();
        if (spSession === false) {
            return;
        }

        Axios
            .get(
                C.API_EP +
                '/a/r/getTopThreads?roomId=' +
                roomId +
                '&email=' +
                spSession.email +
                '&token=' +
                spSession.token
            )
            .then((res) => {
                setLoading(false)
                setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                dispatch(loadRoomThreadsSuccess(data, res.data.canLoadMore, res.data.pinnedThreads));
                if (res.data.pinned !== 0)
                    setPinned(res.data.pinned);
                setTimeout(function () {
                    adjustHeight()
                }, 100)
            })
            .catch(error => {
                setLoading(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        setLoad({ status: -1, msg: error.response.data.msg })
                    }
                } else if (error.request) {
                    setLoad({ status: -1, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    setLoad({ status: -1, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }, [roomId])


    const loadMoreThreads = (threadId) => {

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }


        langauge = localStorage.getItem(C.LANGUAGE);

        if (langauge === null) {
            langauge = 'english'
        }


        var spSession = checkForSession();
        if (spSession === false) {
            return;
        }

        setLoadingMore(true);

        Axios
            .get(
                C.API_EP +
                '/a/r/getMoreThreads?roomId=' +
                roomId +
                '&email=' +
                spSession.email +
                '&token=' +
                spSession.token +
                '&threadId=' + threads.data[threads.data.length - 1]._id
            )
            .then((res) => {
                setLoadingMore(false);
                //setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;

                if (data.length !== 0)
                    dispatch(loadMoreRoomThreadsSuccess(data, res.data.canLoadMore));

                // if (res.data.pinned !== 0)
                //     setPinned(res.data.pinned);
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {

                console.log(error);

                setLoadingMore(false);
                if (error.response) {
                    if (error.response.status === 400) {
                        setLoad({ status: -1, msg: error.response.data.msg })
                    }
                } else if (error.request) {
                    setLoad({ status: -1, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    setLoad({ status: -1, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }


    const checkForSession = () => {
        if (localStorage.getItem(C.SP_SESSION) == null) {
            return false;
        }

        try {
            var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
            return spSession;
        } catch (e) {
            return false;
        }
    };

    const showUserProfile = (id) => {
        dispatch(showUserProfileUI(id));
    };

    const msgListScroll = (e) => {
        //scroll Height
        var sH = e.target.scrollHeight;

        //scroll top
        var sT = e.target.scrollTop;

        //calc position
        var total =
            (e.target.scrollHeight - e.target.offsetHeight) / e.target.offsetHeight;
        var sh = e.target.scrollTop / e.target.offsetHeight;
        var pos = (sh / total) * 100;
        var scrollPosition = pos;
        console.log(scrollPosition);

        if (scrollPosition === 100) {
            console.log(threads.canLoadMore);
            if (
                !loadingMore &&
                typeof threads.canLoadMore !== 'undefined' &&
                threads.canLoadMore &&
                threads.data.length !== 0
            ) {
                console.log("Sending load more ...");
                loadMoreThreads();
            }
            else {
                console.log(loadingMore);
                console.log(threads.canLoadMore);
                console.log(threads.data.length)
            }

            // dispatch(
            //   loadMoreMessages(
            //     chatboxState.roomId,
            //     chatboxState.roomMessages[0]._id,
            //     e.target
            //   )
            // );
        }
    };

    return (
        <div className="sp-threads-body">
            {
                loading && <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="lds-ripple"><div></div><div></div></div>
                </div>

            }

            {load.status === -1 && <div style={{ textAlign: 'center' }}><p>{load.msg}</p></div>}

            <div
                onScroll={(e) => {
                    msgListScroll(e);
                }}
            >
                <div style={{ marginLeft: '5px', borderLeft: '2px solid green' }}>
                    {
                        !loading && threads.pinnedThreads.length === 1 &&
                        <span
                            style={{
                                color: '#777',
                                fontSize: 'small',
                                marginLeft: '20px',
                                background: '#f5f5f5',
                                padding: '3px',
                                borderRadius: '4px',
                                fontWeight: 'bold'
                            }}
                        >
                            <i className="fas fa-thumbtack" style={{ verticalAlign: 'middle' }}></i>
                            &nbsp;Pinned Thread
                        </span>
                    }
                    {
                        !loading && threads.pinnedThreads.length > 1 &&
                        <span
                            style={{
                                color: '#777',
                                fontSize: 'small',
                                marginLeft: '20px',
                                background: '#f5f5f5',
                                padding: '3px',
                                borderRadius: '4px',
                                fontWeight: 'bold'
                            }}
                        >
                            <i className="fas fa-thumbtack" style={{ verticalAlign: 'middle' }}></i>
                            &nbsp;Pinned Threads
                        </span>
                    }

                    {
                        load.status === 1 &&
                        threads.pinnedThreads.map((thread, index) => {
                            return (
                                <Fragment>
                                    <ThreadItem clickHandler={(event) => {
                                        console.log("Expanding thread ...");
                                        setThreadLoaded(thread);
                                        setShowThreadView(true);
                                    }}
                                        permissions={permissions}
                                        deleteHandler={_setShowThreadDelete}
                                        key={thread._id}
                                        thread={thread}
                                        roomId={roomId}
                                        iOwn={iOwn}
                                        unPinHandler={_setShowThreadUnpin}
                                        profileHandler={showUserProfile}
                                        embedHandler={_setShowThreadEmbed}
                                        reportHandler={_setShowThreadReport}
                                        pinHandler={_setShowThreadPin}
                                        pinned={true}
                                        archiveHandler={_setShowThreadArchive}
                                        tagHandler={_setShowTagSearch}
                                    />
                                    {/* <p>{index} : {threads.data.length}</p> */}
                                    {index !== threads.data.length - 1 && <hr style={{ margin: 0 }} />}
                                </Fragment>
                            );
                        })
                    }
                </div>
                {
                    load.status === 1 &&
                    threads.data.map((thread, index) => {
                        return (
                            <Fragment>
                                <ThreadItem clickHandler={(event) => {
                                    console.log("Expanding thread ...");
                                    setThreadLoaded(thread);
                                    setShowThreadView(true);
                                }}
                                    permissions={permissions}
                                    deleteHandler={_setShowThreadDelete}
                                    key={thread._id}
                                    thread={thread}
                                    roomId={roomId}
                                    pinHandler={_setShowThreadPin}
                                    iOwn={iOwn}
                                    profileHandler={showUserProfile}
                                    embedHandler={_setShowThreadEmbed}
                                    reportHandler={_setShowThreadReport}
                                    pinned={false}
                                    archiveHandler={_setShowThreadArchive}
                                    tagHandler={_setShowTagSearch}
                                />
                                {/* <p>{index} : {threads.data.length}</p> */}
                                {index !== threads.data.length - 1 && <hr style={{ margin: 0 }} />}
                            </Fragment>
                        );
                    })
                }
                <br />
                {
                    loadingMore && <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="lds-ripple"><div></div><div></div></div>
                    </div>
                }
            </div>

            {
                load.status === 1 && threads.data.length === 0 &&
                <div style={{ textAlign: 'center' }}>
                    <p>No threads here.</p>
                </div>
            }

            {permissions.WriteThread &&
                <button style={{ boxShadow: '6px 9px 18px 1px black', position: 'absolute', bottom: '15px', right: '30px', borderRadius: '30px' }} className="btn btn-success" onClick={() => { setShowCreateThread(!showCreateThread) }} ><i className="fa fa-plus"></i></button>
            }
            {showCreateThread && <CreateThread show={setShowCreateThread} roomId={roomId} />}

            {showThreadView && <ThreadView closeHandle={setShowThreadView} thread={threadLoaded} permissions={permissions} roomId={roomId} replyIndex={0} parent={null} profileHandler={showUserProfile} />}

            {
                showTagSearch
                &&
                <TagSearch
                    closeHandler={setShowTagSearch}
                    permissions={permissions}
                    tag={tagName}
                    profileHandler={showUserProfile}
                />
            }


            {showThreadDelete &&
                <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                    <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                            <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                                <h4>{langauges[langauge].delete_thread_heading}</h4>
                                <p style={{ fontSize: 'small' }}>{langauges[langauge].delete_thread_body} </p>
                                {deleteError.status && <p style={{ color: 'red' }}>{deleteError.msg}</p>}
                                <button disabled={deleting} onClick={() => { setShowThreadDelete(false) }} className="btn btn-default ">Back</button>
                                <button className="btn btn-danger" disabled={deleting} onClick={() => {
                                    deleteThread(threadDeleteId);
                                }}>
                                    {deleting && <span style={{ paddingRight: '5px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                    {langauges[langauge].delete}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showThreadUnpin &&
                <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                    <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                                <h4>{langauges[langauge].unpin_thread_heading}</h4>
                                <p style={{ fontSize: 'small' }}>{langauges[langauge].unpin_thread_body} </p>
                                {unpinError.status && <p style={{ color: 'red' }}>{unpinError.msg}</p>}
                                <button className="btn btn-danger" disabled={unpining} onClick={() => {
                                    // deleteThread(threadDeleteId);
                                    unpinThread(threadUnpinId._id, threadUnpinId);
                                }}>
                                    {unpining && <span style={{ paddingRight: '5px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                    {langauges[langauge].unpin}
                                </button>
                                <button disabled={unpining} onClick={() => { setShowThreadUnpin(false) }} className="btn btn-default ">{langauges[langauge].back}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showThreadPin &&
                <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                    <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                            <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                                <h4>{langauges[langauge].pin_thread_heading}</h4>
                                <p style={{ fontSize: 'small' }}>{langauges[langauge].pin_thread_body} </p>
                                {pinError.status && <p style={{ color: 'red' }}>{pinError.msg}</p>}
                                <button className="btn btn-success" disabled={pining} onClick={() => {
                                    // deleteThread(threadDeleteId);
                                    pinThread(threadPinId._id, threadPinId);
                                }}>
                                    {pining && <span style={{ paddingRight: '5px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                    {langauges[langauge].pin}
                                </button>
                                <button disabled={pining} onClick={() => { setShowThreadPin(false) }} className="btn btn-default ">{langauges[langauge].back}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }


            {showThreadEmbed &&
                <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                    <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                            <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                                <h4>{langauges[langauge].embed_thread_heading}</h4>
                                <div
                                    style={{
                                        pointerEvents: 'none',
                                        border: '1px solid #eee',
                                        borderRadius: '6px',
                                        maxHeight: '35vh',
                                        margin: '20px 0',
                                        overflow: 'hidden'
                                    }}
                                ><ThreadItem
                                        clickHandler={(event) => {
                                            // console.log("Expanding thread ...");
                                            // setThreadLoaded(e);
                                            // setShowThreadView(true);
                                        }}
                                        permissions={permissions}
                                        // deleteHandler={_setShowThreadDelete}
                                        thread={threadEmbedId}
                                        roomId={roomId} /></div>

                                <p style={{ fontSize: 'small', margin: '20px 0' }}>{langauges[langauge].embed_thread_body} </p>

                                <span style={{
                                    border: '1px solid #eee',
                                    borderRadius: '6px',
                                    fontWeight: 'bold',
                                    display: 'block',
                                    padding: '5px'
                                }}>
                                    <a href={C.URL_EP + '/thread/public/' + threadEmbedId._id} target="_blank">{C.URL_EP + '/thread/public/' + threadEmbedId._id}</a>
                                </span>

                                {/* {pinError.status && <p style={{ color: 'red' }}>{pinError.msg}</p>} */}
                                {/* <button className="btn btn-success" disabled={pining} onClick={() => {
                                    // deleteThread(threadDeleteId);
                                    pinThread(threadPinId);
                                }}>
                                    {pining && <span style={{ paddingRight: '5px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                    {langauges[langauge].pin}
                                </button> */}
                                <hr />
                                <button onClick={() => { setShowThreadEmbed(false) }} className="btn btn-default ">{langauges[langauge].back}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showThreadReport && <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                            <h4>{langauges[langauge].report_thread}</h4>

                            <hr />
                            <select className="form-control"
                                onChange={(e) => {
                                    // alert(e.target.value);
                                    setReportData({ ...reportData, reason: e.target.value })
                                }}
                                disabled={reported || reporting}
                            >
                                <option value="1">{langauges[langauge].report_sexual}</option>
                                <option value="2">{langauges[langauge].report_spam}</option>
                                <option value="3">{langauges[langauge].report_harassment}</option>
                                <option value="4">{langauges[langauge].report_violent}</option>
                                <option value="5">{langauges[langauge].report_sensitive}</option>
                                <option value="6">{langauges[langauge].report_hate}</option>
                                <option value="7">{langauges[langauge].report_other}</option>
                            </select>
                            <br />
                            <textarea
                                rows={6}
                                maxLength={500}
                                className="form-control"
                                placeholder={langauges[langauge].report_thread_more_details}
                                onChange={(e) => {
                                    setReportData({ ...reportData, details: e.target.value })
                                }}
                                value={reportData.details}
                                disabled={reported || reporting}
                            >
                            </textarea>
                            <hr />
                            {reportError.status && <Fragment><p style={{ color: 'red' }}>{reportError.msg}</p> <hr /></Fragment>}

                            <button
                                disabled={reported || reporting}
                                className="btn btn-primary"
                                onClick={() => {
                                    reportThread(threadReportId);
                                }}>
                                {langauges[langauge].report}
                                {reporting && <span style={{ paddingRight: '5px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                {reported &&
                                    <span style={{ paddingLeft: '10px' }}>
                                        <i style={{ fontSize: '19px', color: 'white' }} className="fas fa-check"></i>
                                    </span>
                                }
                            </button>
                            <button className="btn btn-default" onClick={() => { setShowThreadReport(false) }} >{langauges[langauge].back}</button>
                        </div>
                    </div>
                </div>
            </div>
            }

            {showThreadArchive &&
                <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                    <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <div
                                style={{
                                    background: '#fff',
                                    padding: '15px 30px',
                                    borderRadius: '9px',
                                    maxWidth: '350px'
                                }}
                            >
                                <h4>{langauges[langauge].archive_thread_heading}</h4>
                                <p style={{ fontSize: 'small' }}>{langauges[langauge].archive_thread_body} </p>
                                {archiveError.status && <p style={{ color: 'red' }}>{archiveError.msg}</p>}
                                <button className="btn btn-success" disabled={archiving} onClick={() => {
                                    // deleteThread(threadDeleteId);
                                    archiveThread(threadArchiveId)
                                    //pinThread(threadPinId._id, threadPinId);

                                }}>
                                    {archiving &&
                                        <span style={{ paddingRight: '5px' }}>
                                            <i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i>
                                        </span>
                                    }
                                    {langauges[langauge].archive}
                                </button>
                                <button disabled={archiving} onClick={() => { setShowThreadArchive(false) }} className="btn btn-default ">{langauges[langauge].back}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        threads: state.appState.chatbox.threads,
        roomId: ownProps.roomId,
        permissions: ownProps.permissions,
        adjustHeight: ownProps.adjustHeight,
    };
};

const ThreadsList = connect(mapStateToProps)(_ThreadsList);

export default ThreadsList;