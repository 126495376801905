import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  hideUploadFileUi,
  propogateImage,
  hidePrivateUploadFileUi,
  propogatePrivateImage,
  closePlayMedia,
  closePlayMediaPrivate,
} from './../../redux/actions/appActions';
import C from './../../util/Constants';
import axios from 'axios';

const MediaContainer = ({ dispatch, parentData, prvt }) => {
  const InputFile = useRef(null);


  const [uploadError, setUploadError] = useState('Upload Error.');

  useEffect(() => {

    console.log(prvt);

  }, []);


  const uploadImage = (e) => { };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100%', background: '#fff', zIndex: 999, overflow: 'auto' }}>

      <div style={{ height: '8%', width: '100%' }}>
        <h3 style={{ margin: '5px' }}>Media Viewer</h3>
        <span className="fa fa-times" style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }} onClick={() => { if (!prvt) { dispatch(closePlayMedia()) } else { dispatch(closePlayMediaPrivate()) } }}></span>
      </div>

      <hr style={{ margin: 0 }} />

      <div style={{ height: '84%', width: '100%' }}>
        {typeof parentData.media !== 'undefined' && parentData.media.type === 3 && <div style={{ height: '100%', width: '100%' }}><iframe
          src={parentData.media.extra.data} style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%' }}>
        </iframe></div>}

        {typeof parentData.media !== 'undefined' && parentData.media.type === 1 &&
          <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#000000', padding: '0px' }}><img
            src={parentData.media.extra.data} style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </div>}

        {typeof parentData.media !== 'undefined' && parentData.media.type === 2 &&
          <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#000000', padding: '0px' }}><video
            src={parentData.media.extra.data} style={{ maxWidth: '100%', maxHeight: '100%' }} controls />
          </div>}

      </div>

      <hr style={{ margin: 0 }} />

      <div style={{ height: '8%', width: '100%' }}>
        <div style={{ textAlign: 'center' }}>by <a href="#" style={{}}>@{typeof parentData.media.extra.uploader !== 'undefined' ? parentData.media.extra.uploader : 'Anonymous'}</a></div>
      </div>

    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.private) {
    return {
      parentData: state.appState.Private.box,
      prvt: true
    };
  }
  else {
    return {
      parentData: state.appState.chatbox,
      prvt: false
    };
  }
};

const MediaPlayer = connect(mapStateToProps)(MediaContainer);

export default MediaPlayer;
