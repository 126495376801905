import React from 'react'
import C from './../../util/Constants';
import ThreadImageLight from './ThreadImageLight';

function ThreadGallery({ gallery, from }) {

    const [idx, setIdx] = React.useState(0);

    return (
        <div style={{ position: 'relative' }}>
            {gallery.length > 1 && <div
                style={{
                    position: 'absolute',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    top: 0,
                    left: 0,
                }}
                onClick={() => {
                    // if (loadedFileIndex !== 0)
                    //     setLoadedFileIndex(loadedFileIndex - 1)
                    if (idx !== 0)
                        setIdx(idx - 1)

                }}
            >
                <i className="fa fa-chevron-left" style={{
                    padding: '5px 9px',
                    paddingRight: '10px',
                    background: '#eeeeeec2',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    margin: '5px',
                    zIndex: 8
                }}></i></div>}

            {
                gallery[idx].type === 'image' &&

                (<div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    style={{
                        position: 'relative',
                        borderRadius: '8px',
                        height: from === "ThreadView" ? '50vh' : '30vh',
                        width: '100%'
                    }}>
                    <ThreadImageLight imgstyle={{
                        width: '100%',
                        display: 'block',
                        border: '1px solid #eee',
                        height: '100%',
                        overflow: 'hidden',
                        objectFit: 'contain'
                    }}

                        src={C.API_EP + '/uploads/gallery/' + gallery[idx].name}
                        alt="upload"
                        key={idx}
                    />
                </div>)
            }
            {
                gallery[idx].type === 'video' &&
                <video controls style={{ width: '100%', display: 'block', border: '1px solid #eee', height: '100%', maxHeight: '40vh', overflow: 'hidden', objectFit: 'contain' }} src={C.API_EP + '/uploads/gallery/' + gallery[idx].name} alt="user upload" key={idx} />
            }
            {gallery.length > 1 && <div
                style={{
                    position: 'absolute',
                    top: 0,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    right: 0,
                }}
                onClick={() => {
                    // if (loadedFileIndex !== gallery.length - 1)
                    //     setLoadedFileIndex(loadedFileIndex + 1)
                    if (idx !== gallery.length - 1)
                        setIdx(idx + 1)

                }}

            ><i className="fa fa-chevron-right" style={{
                padding: '5px 9px',
                paddingLeft: '10px',
                background: '#eeeeeec2',
                borderRadius: '50%',
                cursor: 'pointer',
                margin: '5px',
                zIndex: 8
            }}></i></div>}


        </div>
    )
}

export default ThreadGallery
