import React, { useEffect, Fragment, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  sendRoomMessage,
  setPrivateMessageText,
  initSendLoadRoom,
  showUserProfileUI,
  loadUsernameInput,
  showUploadFileUi,
  showRoomDetailsUi,
  hideRoomDetailsUi,
  hideRoomSettingsUi,
  showRoomSettingsUi,
  deleteRoomMessage,
  initHomeView,
  initPrivateBoxData,
  hidePrivateBoxLoad,
  acceptRequest,
  postPrivateMessage,
  privateRequestBlock,
  privateRequestUnBlock,
  showPrivateUploadFileUi,
  privateThreadBanned,
  privateThreadUnbanned,
  unloadLastThread,
  typingPrivate,
  loadMorePrivateMessages,
  setPrivateThread,
  showPrivateUploadVideoUi,
  openPlayMedia,
  openPlayMediaPrivate,
} from '../../../redux/actions/appActions';
import C from './../../../util/Constants';
import UploadFile from './../../util/UploadFile';
import UploadVideo from './../../util/UploadVideo';
import Axios from 'axios';
import UserProfile from '../../util/UserProfile';
import MediaPlayer from '../../util/MediaPlayer';

const ConnectedList = ({ dispatch, privatebox, userProfile }) => {
  var scrollPosition = 100;

  const InputElement = useRef(null);
  const MessageList = useRef(null);
  const [newMessages, setNewMessages] = useState(false);
  const [init, setInit] = useState(false);

  useEffect(() => {
    console.log('Loading details of the privatebox ...');
    console.log(privatebox);
    console.log('Adjusting heights ...');
    adjustHeight();
    console.log('[init] ***** Private box data *****');

    if (typeof privatebox.thread !== 'undefined') {
      if (privatebox.thread.accepted === 1) {
        console.log('[init] ***** Private box data *****');
        console.log(privatebox.thread);
        dispatch(initPrivateBoxData(privatebox.thread._id));
      } else dispatch(hidePrivateBoxLoad());
    } else {
      var lastLoadedPrivateThread = localStorage.getItem(
        C.SP_LOADED_PRIVATE_THREAD
      );
      if (lastLoadedPrivateThread !== null) {
        var scSession = checkForSession();

        if (scSession !== false) {
          Axios.get(
            C.API_EP +
            '/a/private/getSingleThread?email=' +
            scSession.email +
            '&token=' +
            scSession.token +
            '&threadId=' +
            lastLoadedPrivateThread
          ).then((r) => {
            var response = r.data.list[0];
            dispatch(setPrivateThread(response));
            dispatch(initPrivateBoxData(response._id));
          });
        }
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      console.log('******************* UNMOUNTED');

      window.removeEventListener('resize', handleResize);
      console.log(privatebox);
      return;
      dispatch(unloadLastThread(privatebox.thread._id));
    };
  }, []);

  const playMedia = (d, t, un) => {
    console.log(d);
    dispatch(openPlayMediaPrivate(d, t, un));
  }

  const handleResize = () => {
    //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
    adjustHeight();
    console.log(MessageList.current);
    if (MessageList.current !== null) {
      var total =
        (MessageList.current.scrollHeight - MessageList.current.offsetHeight) /
        MessageList.current.offsetHeight;

      var t = (scrollPosition / 100) * total;
      var sT = t * MessageList.current.offsetHeight;
      MessageList.current.scrollTop = sT;
    }
  };

  const getTarget = (thread) => {
    if (thread.initiator === null) {
      return thread.responder;
    } else {
      return thread.initiator;
    }
  };

  useEffect(() => {
    if (InputElement.current) {
      InputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (InputElement.current) {
      InputElement.current.focus();
    }
  }, [privatebox.messageInput]);

  useEffect(() => {
    setTimeout(function () {
      checkIfScrollable();
    }, 100);
  }, [privatebox.data]);

  useEffect(() => {
    if (InputElement.current) {
      InputElement.current.focus();
    }
    scrollToBottom();
    setNewMessages(false);
    setInit(true);
  }, [privatebox.init]);

  useEffect(
    (value) => {
      console.log('Talking taling talk ...');
      console.log(value);
      console.log(privatebox.messageInput.sending);
    },
    [privatebox.messageInput.sending]
  );

  const _acceptRequest = (threadId) => {
    console.log('Accepting Request ....');
    dispatch(acceptRequest(threadId));
  };

  const sendPrivateMessage = () => {
    if (
      typeof privatebox.messageInput !== 'undefined' &&
      privatebox.messageInput.messageText.trim() !== ''
    ) {
      dispatch(
        postPrivateMessage(
          privatebox.messageInput.messageText,
          privatebox.thread._id
        )
      );
    }
  };

  const checkIfScrollable = () => {
    if (typeof MessageList === 'undefined' || MessageList.current === null)
      return;

    //scroll height
    var sh = MessageList.current.scrollHeight;

    //viewable height
    var vh = MessageList.current.offsetHeight;
    console.log(sh);
    console.log(vh);

    //scrollable area
    var sa = sh - vh;

    //30 % of sa
    var sa30 = sa * (10 / 100);

    var sT = MessageList.current.scrollTop;

    //if scroll top is greater than 75% of viewable area
    console.log(
      sT.toString() +
      ' > ' +
      sa.toString() +
      ' - ' +
      sa30.toString() +
      ' = ' +
      (sa - sa30)
    );
    console.log(sa);
    console.log(sa30);

    if (sT > sa - sa30) scrollToBottom();
    else {
      if (init) setNewMessages(true);
    }
  };

  const clearNewMessages = (e) => {
    if (typeof MessageList === 'undefined') return;

    var sh = MessageList.current.scrollHeight;

    var vh = MessageList.current.offsetHeight;

    var sT = MessageList.current.scrollTop;

    var sa = sh - vh;

    console.log(sT);
    console.log(sa);
    console.log(newMessages);

    if (sT === sa && newMessages) {
      setNewMessages(false);
    }
  };

  const checkImageScrollable = (e) => {
    //scroll height
    if (typeof MessageList === 'undefined') return;

    var sh = MessageList.current.scrollHeight;

    //viewable height
    var vh = MessageList.current.offsetHeight;
    console.log('[scroll] ***** Checking Image scrollable *****');

    console.log(sh);
    console.log(vh);

    console.log('[scroll] ***** Image element *****');
    console.log(e.target);
    var imageHeight = e.target.offsetHeight;

    //scrollable area
    var sa = sh - vh;

    //30 % of sa
    var sa30 = sa * (10 / 100);

    var sT = MessageList.current.scrollTop;

    //if scroll top is greater than 75% of viewable area
    console.log(
      sT.toString() +
      ' > ' +
      sa.toString() +
      ' - ' +
      sa30.toString() +
      ' = ' +
      (sa - sa30) +
      ' (image height) ' +
      imageHeight
    );
    console.log(sT + imageHeight);
    console.log(sT + imageHeight - sa);
    console.log(sa);
    console.log(sa30);

    if (sT > sa - sa30) scrollToBottom();
    else {
      setNewMessages(true);
    }
  };

  const scrollToBottom = () => {
    if (MessageList.current) {
      MessageList.current.scrollTop = MessageList.current.scrollHeight;
    }
  };

  //Send private message
  //   const sendMessage = () => {
  //     dispatch(
  //       sendRoomMessage(
  //         chatboxState.messageInput.messageText,
  //         chatboxState.roomId
  //       )
  //     );
  //   };

  const processDate = (date) => {
    var datePosted = Date.parse(date);
    var dateNow = Date.now();
    var seconds = (dateNow - datePosted) / 1000;

    if (seconds < 60) {
      return 'Just Now';
    }

    var minutes = seconds / 60;

    if (minutes < 60) {
      return Math.floor(minutes) + ' minute(s)';
    }

    var hours = minutes / 60;

    if (hours < 24) {
      return Math.floor(hours) + ' hour(s)';
    }

    var days = hours / 24;

    if (days < 30) {
      return Math.floor(days) + ' day(s)';
    }

    var months = days / 30;

    if (months < 12) {
      return Math.floor(months) + ' month(s)';
    }

  };

  //Will delete message from room
  //   const deleteMessage = (messageId) => {
  //     dispatch(deleteRoomMessage(messageId, chatboxState.roomId));
  //   };

  const adjustHeight = () => {
    var winHeight = window.innerHeight;
    var spChatbox = document.getElementsByClassName('sp-privatebox')[0];
    var spChatboxCard = spChatbox.getElementsByClassName('card')[0];

    spChatbox.style.height = winHeight + 'px';
    spChatboxCard.style.height = winHeight + 'px';
    var spChatBoxCardHeader = spChatboxCard.getElementsByClassName(
      'card-header'
    )[0];
    var spChatBoxCardBody = spChatboxCard.getElementsByClassName(
      'card-body'
    )[0];
    var spChatBoxCardBodyList = spChatBoxCardBody.getElementsByTagName('ul')[0];
    var spChatBoxCardFooter = spChatboxCard.getElementsByClassName(
      'card-footer'
    )[0];
    var heightToBeSet =
      winHeight -
      (spChatBoxCardHeader.offsetHeight + spChatBoxCardFooter.offsetHeight);
    console.log(heightToBeSet);
    spChatBoxCardBody.style.height = heightToBeSet + 'px';
    spChatBoxCardBodyList.style.maxHeight = heightToBeSet + 'px';
    //- (heightToBeSet * 8) / 100
  };
  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));

      return spSession;
    } catch (e) {
      return false;
    }
  };

  const _showPrivateUploadFileUi = () => {
    dispatch(showPrivateUploadFileUi());
  };

  const _showPrivateUploadVideoUi = () => {
    dispatch(showPrivateUploadVideoUi());
  };

  const msgListScroll = (e) => {
    //scroll Height
    var sH = e.target.scrollHeight;

    //scroll top
    var sT = e.target.scrollTop;

    //calc position
    var total =
      (e.target.scrollHeight - e.target.offsetHeight) / e.target.offsetHeight;
    var sh = e.target.scrollTop / e.target.offsetHeight;
    var pos = (sh / total) * 100;
    scrollPosition = pos;
    console.log(scrollPosition);

    if (sT === 0) {
      console.log(privatebox.canLoadMore);
      if (
        !privatebox.loadingMore &&
        typeof privatebox.canLoadMore !== 'undefined' &&
        privatebox.canLoadMore &&
        privatebox.data.length !== 0
      )
        dispatch(
          loadMorePrivateMessages(
            privatebox.thread._id,
            privatebox.data[0]._id,
            e.target
          )
        );
    }
  };

  const blockRequest = (id, threadId) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    Axios.post(C.API_EP + '/a/private/request/block', {
      targetId: id,
      email: spSession.email,
      token: spSession.token,
    })
      .then((response) => {
        dispatch(privateRequestBlock(threadId));
      })
      .catch((error) => {
        if (error.status === 403) {
          return;
        }
      });
  };

  const unblockRequest = (id, threadId) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    Axios.post(C.API_EP + '/a/private/request/unblock', {
      targetId: id,
      email: spSession.email,
      token: spSession.token,
    })
      .then((response) => {
        dispatch(privateRequestUnBlock(threadId));
      })
      .catch((error) => {
        if (error.status === 403) {
          return;
        }
      });
  };

  const banThread = (threadId) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    Axios.post(C.API_EP + '/a/private/thread/ban', {
      threadId: threadId,
      email: spSession.email,
      token: spSession.token,
    })
      .then((response) => {
        dispatch(privateThreadBanned(response.data.blockerId, threadId));
      })
      .catch((error) => {
        if (error.status === 403) {
          return;
        }
      });
  };

  const unbanThread = (threadId) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    Axios.post(C.API_EP + '/a/private/thread/unban', {
      threadId: threadId,
      email: spSession.email,
      token: spSession.token,
    })
      .then((response) => {
        dispatch(privateThreadUnbanned(response.data.blockerId, threadId));
      })
      .catch((error) => {
        if (error.status === 403) {
          return;
        }
      });
  };

  return (
    <Fragment>
      <div style={{ width: '100%' }} className='card'>
        <div className='card-header' style={{ padding: 0 }}>
          <ul
            class='sp-chatbox-header '
            style={{ padding: '0 20px', margin: '10px 0' }}
          >
            <li class='sp-chatbox-header-left' style={{ marginRight: '10px' }}>
              <span
                className='fas fa-chevron-left'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(initHomeView({ loadedView: 'sidebar' }));
                }}
              ></span>
            </li>
            <li class='sp-chatbox-header-middle'>
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  var id = getTarget(privatebox.thread)._id;
                  dispatch(showUserProfileUI(id));
                }}
              >
                {typeof privatebox.thread !== 'undefined' &&
                  '@' + getTarget(privatebox.thread).username}
              </span>
              {typeof privatebox.thread !== 'undefined' &&
                typeof privatebox.thread.isTyping !== 'undefined' &&
                privatebox.thread.isTyping && (
                  <span
                    className='animated pulse'
                    style={{
                      fontSize: '15px',
                      marginLeft: '15px',
                      color: '#75b926',
                      display: 'inline-block',
                      animationIterationCount: 'infinite',
                      animationDuration: '0.8s',
                    }}
                  >
                    typing ...
                  </span>
                )}
            </li>
            {typeof privatebox.thread !== 'undefined' &&
              privatebox.thread.accepted === 1 &&
              privatebox.thread.blockers.length === 0 && (
                <li class='sp-chatbox-header-right'>
                  <span
                    className='fas fa-ban'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      banThread(privatebox.thread._id);
                    }}
                  ></span>
                </li>
              )}

            {typeof privatebox.thread !== 'undefined' &&
              privatebox.thread.accepted === 1 &&
              privatebox.thread.blockers.length !== 0 &&
              !privatebox.thread.blockers.includes(
                localStorage.getItem('sp_user_id')
              ) && (
                <li class='sp-chatbox-header-right'>
                  <span
                    className='fas fa-ban'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      banThread(privatebox.thread._id);
                    }}
                  ></span>
                </li>
              )}
          </ul>
        </div>
        <div
          className='card-body'
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column-reverse',
            padding: 0,
          }}
        >
          {typeof privatebox.thread !== 'undefined' &&
            !privatebox.init &&
            privatebox.thread.accepted === 1 &&
            privatebox.thread.blockers.length === 0 && (
              <div class='progress'>
                <div
                  class='progress-bar progress-bar-striped progress-bar-animated'
                  style={{ width: '100%' }}
                ></div>
              </div>
            )}

          {newMessages && (
            <div style={{ position: 'relative', top: 0, textAlign: 'center' }}>
              <span style={{ color: '#63a063', fontSize: '12px' }}>
                New Messages <i className='fas fa-chevron-down'></i>
              </span>
            </div>
          )}

          <ul
            class='list-group'
            style={{
              maxHeight: '',
              overflow: 'auto',
              display: !privatebox.init ? 'none' : 'initial',
            }}
            onScroll={(e) => {
              msgListScroll(e);
              clearNewMessages(e);
            }}
            ref={MessageList}
          >
            {privatebox.loadingMore && (
              <li style={{ textAlign: 'center', marginTop: '30px' }}>
                <div class='spinner-border' role='status'>
                  <span class='sr-only'>Loading...</span>
                </div>
              </li>
            )}
            {typeof privatebox.data !== 'undefined' &&
              privatebox.data.map((msgItem, index) => {
                let lastIndex = index !== 0 ? index - 1 : index;
                let lastMessageWriter = privatebox.data[lastIndex].writer;
                let batchItem =
                  lastMessageWriter._id === msgItem.writer._id ? true : false;
                // console.log(privatebox.data[lastIndex].writer);
                // console.log(msgItem.writer);

                return (
                  <li
                    className={
                      typeof msgItem.singleLoaded !== 'undefined' &&
                        msgItem.singleLoaded
                        ? localStorage.getItem('sp_user_id') === null ||
                          localStorage.getItem('sp_user_id') !==
                          msgItem.writer._id
                          ? 'list-group-item animated sp-message-list-item fadeInLeft'
                          : 'list-group-item animated sp-message-list-item fadeInRight'
                        : 'list-group-item'
                    }
                    style={{
                      border: 'none',
                      padding: '0.3rem 1.25rem',
                    }}
                    key={msgItem._id}
                  >
                    {typeof msgItem.singleLoaded !== 'undefined' &&
                      localStorage.getItem('sp_user_id') !==
                      msgItem.writer._id &&
                      typeof privatebox.sounds !== 'undefined' &&
                      (privatebox.sounds === 1 || privatebox.sounds === 2) && (
                        <audio autoPlay>
                          <source
                            src='/resources/sounds/silentpm.mp3'
                            type='audio/mp3'
                          />
                        </audio>
                      )}
                    <div
                      className={
                        localStorage.getItem('sp_user_id') === null ||
                          localStorage.getItem('sp_user_id') !==
                          msgItem.writer._id
                          ? 'sp-message'
                          : 'sp-message-my'
                      }
                    >
                      {localStorage.getItem('sp_user_id') === null ||
                        (localStorage.getItem('sp_user_id') !==
                          msgItem.writer._id &&
                          !batchItem && (
                            <div className='sp-message-avatar'>
                              <img
                                src={
                                  typeof msgItem.writer.profile !==
                                  'undefined' &&
                                  C.API_EP +
                                  '/avatars/' +
                                  msgItem.writer.profile.picture.split(
                                    '.'
                                  )[0] +
                                  '_sm.' +
                                  msgItem.writer.profile.picture.split('.')[1]
                                }
                                className='rounded-circle sp-message-usericon'
                                style={{
                                  width: '38px',
                                  height: '38px',
                                  float: 'left',
                                }}
                                alt='user-icon'
                                onClick={() => {
                                  //showUserProfile(msgItem.user._id);
                                }}
                              />
                            </div>
                          ))}

                      {(localStorage.getItem('sp_user_id') === null ||
                        localStorage.getItem('sp_user_id') !==
                        msgItem.writer._id) &&
                        batchItem && <div style={{ width: '48px' }}></div>}

                      <div className='sp-message-inner'>
                        {localStorage.getItem('sp_user_id') === null ||
                          (localStorage.getItem('sp_user_id') !==
                            msgItem.writer._id &&
                            !batchItem && (
                              <div
                                className='sp-message-username'
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  //loadUsername(msgItem.user.username);
                                }}
                              >
                                {msgItem.writer.username}
                              </div>
                            ))}

                        {msgItem.message.type === 'text' && (
                          <div
                            className='sp-private-message-content'
                            style={{
                              background:
                                localStorage.getItem('sp_user_id') === null ||
                                  localStorage.getItem('sp_user_id') !==
                                  msgItem.writer._id
                                  ? '#2196F3'
                                  : '#eaeaea',
                              color:
                                localStorage.getItem('sp_user_id') === null ||
                                  localStorage.getItem('sp_user_id') !==
                                  msgItem.writer._id
                                  ? 'white'
                                  : 'black',
                              fontSize: '16px',
                              border:
                                localStorage.getItem('sp_user_id') === null ||
                                  localStorage.getItem('sp_user_id') !==
                                  msgItem.writer._id
                                  ? 'none'
                                  : '2px solid #eee',
                            }}
                          >
                            {msgItem.message.content}
                          </div>
                        )}

                        {msgItem.message.type === 'image' && (
                          <div
                            style={{
                              textAlign:
                                localStorage.getItem('sp_user_id') === null ||
                                  localStorage.getItem('sp_user_id') !==
                                  msgItem.writer._id
                                  ? 'inherit'
                                  : 'right',
                            }}
                          >
                            <img
                              className='sp-message-content-image'
                              src={
                                C.ASSET_EP +
                                '/uploads/images/' +
                                msgItem.message.content
                              }
                              alt='user upload'
                              onLoad={
                                (typeof msgItem.singleLoaded !==
                                  'undefined' &&
                                  msgItem.singleLoaded) ||
                                  (typeof msgItem.dynamicLoaded !==
                                    'undefined' &&
                                    msgItem.dynamicLoaded)
                                  ? (e) => {
                                    checkImageScrollable(e);
                                  }
                                  : scrollToBottom
                              }
                              onClick={() => {
                                playMedia(C.ASSET_EP + '/uploads/images/' + msgItem.message.content, 1, msgItem.writer.username)
                              }}
                              onError={checkIfScrollable}
                            />
                          </div>
                        )}

                        {msgItem.message.type === 'video' && (
                          <div
                            style={{
                              textAlign:
                                localStorage.getItem('sp_user_id') === null ||
                                  localStorage.getItem('sp_user_id') !==
                                  msgItem.writer._id
                                  ? 'inherit'
                                  : 'right',
                            }}
                          >
                            <video className='sp-message-content-image'
                              preload="metadata"
                              onClick={() => {
                                playMedia(C.ASSET_EP + '/uploads/videos/' + msgItem.message.content, 2, msgItem.writer.username)
                              }}
                            >
                              <source src={C.API_EP +
                                '/uploads/videos/' +
                                msgItem.message.content + "#t=0.3"
                              } type="video/mp4" />
                            </video>
                          </div>
                        )}

                        <div className='sp-message-timestamp'>
                          {processDate(msgItem.date)}
                        </div>
                      </div>
                    </div>
                    {typeof privatebox.thread.targetPointer !== 'undefined' &&
                      msgItem._id === privatebox.thread.targetPointer && (
                        <img
                          alt='last seen'
                          src={
                            C.API_EP +
                            '/avatars/' +
                            getTarget(privatebox.thread).profile.picture.split(
                              '.'
                            )[0] +
                            '_sm.' +
                            getTarget(privatebox.thread).profile.picture.split(
                              '.'
                            )[1]
                          }
                          className='rounded-circle sp-message-usericon'
                          style={{
                            width: '14px',
                            height: '14px',
                            position: 'absolute',
                            bottom: '10px',
                            right: 0,
                          }}
                        />
                      )}
                  </li>
                );
              })}

            {typeof privatebox.thread !== 'undefined' &&
              privatebox.thread.blockers.length !== 0 && (
                <div
                  className='alert alert-danger'
                  style={{ textAlign: 'center' }}
                >
                  <p>This Thread Has Been Banned!</p>
                  {privatebox.thread.blockers.includes(
                    localStorage.getItem('sp_user_id')
                  ) && (
                      <button
                        className='btn btn-danger btn-sm'
                        onClick={() => {
                          unbanThread(privatebox.thread._id);
                        }}
                      >
                        Unban
                      </button>
                    )}
                </div>
              )}
          </ul>

          {
            typeof privatebox.thread !== 'undefined' &&
            privatebox.thread.accepted === 0 &&
            privatebox.thread.responder === null && (
              <div style={{ textAlign: 'center' }}>
                <h4>Incoming Private Request</h4>
                <hr />
                <p>
                  <b>{'@' + privatebox.thread.initiator.username}</b> has sent
                  you a private request. Click Accept Request to start chatting
                  with them
                </p>
                <button
                  className='btn btn-success'
                  onClick={() => {
                    _acceptRequest(privatebox.thread._id);
                  }}
                >
                  Accept Request
                </button>
                <button
                  className='btn btn-danger'
                  onClick={() => {
                    blockRequest(
                      privatebox.thread.initiator._id,
                      privatebox.thread._id
                    );
                  }}
                >
                  Block
                </button>
              </div>
            )
          }

          {
            typeof privatebox.thread !== 'undefined' &&
            privatebox.thread.accepted === 0 &&
            privatebox.thread.initiator === null && (
              <div style={{ textAlign: 'center' }}>
                <h4>Outgoing Private Request</h4>
                <hr />
                <p>
                  Your request has been sent to{' '}
                  <b>{'@' + privatebox.thread.responder.username}</b>. Kindly
                  allow them to respond.
                </p>
              </div>
            )
          }
          {
            typeof privatebox.thread !== 'undefined' &&
            privatebox.thread.accepted === -1 &&
            privatebox.thread.responder === null && (
              <div style={{ textAlign: 'center' }}>
                <h4>Incoming Private Request - Blocked</h4>
                <hr />
                <p>
                  You blocked the Private Request from{' '}
                  <b>{'@' + privatebox.thread.initiator.username}</b>
                </p>
                <button
                  className='btn btn-success'
                  onClick={() => {
                    unblockRequest(
                      privatebox.thread.initiator._id,
                      privatebox.thread._id
                    );
                  }}
                >
                  Unblock
                </button>
              </div>
            )
          }

          {
            typeof privatebox.thread !== 'undefined' &&
            privatebox.thread.accepted === -1 &&
            privatebox.thread.initiator === null && (
              <div style={{ textAlign: 'center' }}>
                <h4>Outgoing Private Request - Blocked</h4>
                <hr />
                <p>
                  Your request was blocked by{' '}
                  <b>{'@' + privatebox.thread.responder.username}</b>.
                </p>
              </div>
            )
          }
        </div>
        <div className='card-footer'>
          <div
            className='input-group sp-input-box'
            style={{
              display:
                typeof privatebox.thread !== 'undefined' &&
                  privatebox.thread.accepted === 1 &&
                  privatebox.thread.blockers.length === 0
                  ? 'flex'
                  : 'none',
            }}
          >
            <div className='input-group-prepend'>
              <i
                className='fas fa-image input-group-text'
                style={{ cursor: 'pointer', display: 'flex' }}
                onClick={_showPrivateUploadFileUi}
              />
              <i
                className='fas fa-video input-group-text'
                style={{ cursor: 'pointer', display: 'flex' }}
                onClick={_showPrivateUploadVideoUi}
              />
            </div>
            <input
              type='text'
              className='form-control'
              placeholder='Type your message ...'
              maxLength='250'
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  sendPrivateMessage();
                }
              }}
              ref={InputElement}
              onChange={(e) => {
                dispatch(setPrivateMessageText(e.target.value));
                dispatch(typingPrivate(privatebox.thread._id));
              }}
              disabled={
                typeof privatebox.messageInput !== 'undefined' &&
                privatebox.messageInput.sending === 0
              }
              value={
                typeof privatebox.messageInput !== 'undefined'
                  ? privatebox.messageInput.messageText
                  : ''
              }
            />
            <div className='input-group-append'>
              <button
                className='btn btn-success'
                type='submit'
                disabled={
                  typeof privatebox.messageInput !== 'undefined' &&
                  privatebox.messageInput.sending === 0
                }
                onClick={sendPrivateMessage}
              >
                <span className='fas fa-paper-plane'></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {typeof privatebox.uploadFile !== 'undefined' &&
        privatebox.uploadFile.showUi && (
          <UploadFile
            context='private'
            id={privatebox.thread._id}
            path='/upload/image/private'
          />
        )}

      {typeof privatebox.uploadVideo !== 'undefined' &&
        privatebox.uploadVideo.showUi && (
          <UploadVideo
            context='private'
            id={privatebox.thread._id}
            path='/upload/video/private'
          />
        )}

      {typeof userProfile !== 'undefined' && userProfile.showUi && (
        <UserProfile id={userProfile.roomId} />
      )}

      {typeof privatebox.mediaActive !== 'undefined' && privatebox.mediaActive && <MediaPlayer private={true}></MediaPlayer>}

    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    privatebox: state.appState.Private.box,
    userProfile: state.appState.chatbox.userProfile,
  };
};

const PrivateBox = connect(mapStateToProps)(ConnectedList);

export default PrivateBox;
