import React from 'react'
import { Fragment } from 'react'

function ThreadImageLight({ src, imgstyle }) {
    const [loading, setLoading] = React.useState(true)
    return (
        <Fragment
        >
            <span style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && <i className="fas fa-sync fa-spin"></i>}
            </span>
            <img

                src={src}
                style={imgstyle}
                className="thread-gallery-light-img"
                alt="upload"
                onLoad={(e) => {
                    console.log(e);
                    setLoading(false);
                }}
                onProgress={(e) => {
                    //console.log(e);
                }}
            />
        </Fragment>
    )
}

export default ThreadImageLight
