import Axios from 'axios';
import React from 'react'
import langauges from '../../languages';
import C from './../../util/Constants';
import { processMessage, processMessage2, processMessage3 } from '../../redux/actions/appActions';


export default function CreateThread({ show, roomId }) {

    var langauge = 'english';

    const InputImage = React.useRef(null);
    const InputGallery = React.useRef(null);
    const InputVideo = React.useRef(null);
    const ProgressBar = React.useRef(null);

    const [textData, setTextData] = React.useState('');
    const [file, setFile] = React.useState(null);
    const [gallery, setGallery] = React.useState([]);
    const [lockSend, setLockSend] = React.useState(false);
    const [loadedFileIndex, setLoadedFileIndex] = React.useState(0);
    const [load, setLoad] = React.useState({ status: 2, msg: '' });
    const [more, setMore] = React.useState(false);
    const [showTags, setShowTags] = React.useState(false);
    const [tags, setTags] = React.useState([]);
    const [tag, setTag] = React.useState("");
    const [disableReplies, setDisableReplies] = React.useState(false);
    const [publicThread, setPublicThread] = React.useState(false);

    const _setLoad = (dE, delay) => {
        console.log("_setLoad");
        setLoad(dE);
        setTimeout(() => {
            console.log("Disabling error.")
            setLoad({ status: 2, msg: '' })
        }, delay);
    }

    React.useEffect(() => {

        langauge = localStorage.getItem(C.LANGUAGE);

        console.log(langauge);
        if (langauge === null) {
            langauge = 'english'
        }

        // var ih = setInterval(() => {
        //     var w = ProgressBar.current.style.width;
        //     console.log(w);
        //     var wn = parseInt(w.replace("%", ""));
        //     ProgressBar.current.style.width = (wn + 1) + "%";
        //     if (w === '99%') {
        //         clearInterval(ih);
        //         ProgressBar.current.style.animation = 'loadCreateThread 1s linear 0.1s infinite forwards'
        //     }
        // }, 150)

    }, [])

    const [image, setImage] = React.useState({
        update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
        data: {
            blob: '',
        },
        show: false,
    });

    const [video, setVideo] = React.useState({
        update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
        data: {
            blob: '',
        },
        show: false,
    });

    const uploadImage = () => {
        InputImage.current.click();
    }

    const uploadVideo = () => {
        InputVideo.current.click();
    }

    const uploadGallery = () => {
        InputGallery.current.click();
    }

    const setImageBlob = (blob) => {
        setImage({
            ...image,
            data: { ...image.data, blob: blob },
            show: true
        });
    };

    const setVideoBlob = (blob) => {
        setVideo({
            ...video,
            data: { ...video.data, blob: blob },
            show: true
        });
    };

    const showImage = (e) => {
        console.log(e.target);
        if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setImageBlob(e.target.result);
                setFile({ type: 'image', data: e.target.result });
            };
            reader.readAsDataURL(e.target.files[0]); // convert to base64 string
        }
    };

    const showGallery = (e) => {
        console.log(e.target);
        const files = e.target.files || e.dataTransfer.files;

        if (files.length > 4) {
            alert('You are only allowed to upload a maximum of 4 files at a time');
            return;
        }

        if (files && files[0]) {
            var _gallery = [];
            var count = 0;
            function read(i) {
                var file = {};
                var reader = new FileReader();
                reader.onload = function (e) {
                    _gallery.push({
                        type: (file.type === 'image/png' ||
                            file.type === 'image/jpeg' ||
                            file.type === "image/gif") ? 'image' : 'video', data: e.target.result
                    })

                    if (count === files.length - 1) {
                        console.log("Pushing ...");
                        setGallery(_gallery);
                    }
                    else {
                        console.log(count + " : " + files.length);
                        count++;
                    }
                    // setGallery([...gallery, {
                    //     type: (file.type === 'image/png' ||
                    //         file.type === 'image/jpeg' ||
                    //         file.type === "image/gif") ? 'image' : 'video', data: e.target.result
                    // }]);
                };
                console.log(files[i].name)
                file.type = files[i].type
                reader.readAsDataURL(files[i]); // convert to base64 string                       
            }

            for (var i = 0; i < files.length; i++) {
                read(i)
            }
        }
    };

    const showVideo = (e) => {
        console.log(e.target);
        if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setVideoBlob(e.target.result);
                setFile({ type: 'video', data: e.target.result })
            };
            reader.readAsDataURL(e.target.files[0]); // convert to base64 string
        }
    };

    const checkForSession = () => {
        if (localStorage.getItem(C.SP_SESSION) == null) {
            return false;
        }

        try {
            var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
            return spSession;
        } catch (e) {
            return false;
        }
    };

    const createThreadServer = () => {
        console.log("Creating ...")
        console.log(file);
        if (gallery.length !== 0) {

            var spSession = checkForSession();
            if (spSession === false) {
                return;
            }

            var dataToUpdate = new FormData();
            dataToUpdate.append('roomId', roomId);

            dataToUpdate.append('email', spSession.email);
            dataToUpdate.append('token', spSession.token);

            for (var idx = 0; idx < InputGallery.current.files.length; idx++) {
                dataToUpdate.append('gallery', InputGallery.current.files[idx]);
            }

            dataToUpdate.append('disableReplies', disableReplies);
            dataToUpdate.append('publicThread', publicThread);
            dataToUpdate.append('tags', JSON.stringify(tags));

            if (textData.trim() !== "") {
                var processedMessage = processMessage(textData)
                try {
                    var msg = JSON.stringify(processedMessage);
                } catch (e) {
                    return;
                }
                dataToUpdate.append('msgtext', msg);

            }

            Axios
                .post(C.API_EP + '/thread/create/WithGallery', dataToUpdate, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: function (progressEvent) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                        // var w = ProgressBar.current.style.width;
                        // console.log(w);
                        // var wn = parseInt(w.replace("%", ""));
                        if (percentCompleted > 97) {
                            ProgressBar.current.style.animation = 'loadCreateThread 1s linear 0.1s infinite forwards'
                        }
                        else {
                            ProgressBar.current.style.width = (percentCompleted) + "%";

                        }

                        console.log(percentCompleted);
                    },

                })
                .then((response) => {

                    var data = response.data.data;
                    var threadId = data.threadId;

                    show()

                })
                .catch((error) => {
                    setLockSend(false)
                    if (error.response) {
                        if (error.response.status === 400) {
                            _setLoad({ status: -1, msg: error.response.data.msg }, 3500)
                        }
                    } else if (error.request) {
                        _setLoad({ status: -1, message: langauges[langauge].server_respond }, 3500)
                    } else {
                        _setLoad({ status: -1, message: langauges[langauge].err_req }, 3500)
                    }
                });


        }
        else {
            var spSession = checkForSession();
            if (spSession === false) {
                return;
            }

            var dataToSend = {};
            dataToSend.email = spSession.email;
            dataToSend.token = spSession.token;
            dataToSend.roomId = roomId;
            var processedMessage = processMessage(textData)
            try {
                var msg = JSON.stringify(processedMessage);
            } catch (e) {
                return;
            }
            dataToSend.threadText = msg;
            dataToSend.disableReplies = disableReplies;
            dataToSend.publicThread = publicThread;
            dataToSend.tags = JSON.stringify(tags);

            ProgressBar.current.style.animation = 'loadCreateThread 1s linear 0.1s infinite forwards'

            console.log(dataToUpdate);

            Axios
                .post(C.API_EP + '/a/r/thread/create', dataToSend)
                .then((response) => {

                    var data = response.data.data;
                    var threadId = data.threadId;

                    show();

                })
                .catch((error) => {
                    setLockSend(false)
                    if (error.response) {
                        if (error.response.status === 400) {
                            console.log("Status load ...");
                            _setLoad({ status: -1, msg: error.response.data.msg }, 3500)
                        }
                    } else if (error.request) {
                        _setLoad({ status: -1, message: langauges[langauge].server_respond }, 3500)
                    } else {
                        _setLoad({ status: -1, message: langauges[langauge].err_req }, 3500)
                    }
                });

        }
    }

    return (
        <div className="create-thread">
            <div className="create-thread-wrapper">
                <div className="create-thread-head">
                    <h4>Create Thread</h4>
                    <i className="fa fa-times create-thread-close"
                        onClick={() => {
                            if (!lockSend)
                                show()
                        }}></i>
                </div>
                <div style={{ height: '5px', width: '100%', background: '#28a745', position: 'relative' }}>
                    <div
                        style={{
                            display: lockSend ? 'inherit' : 'none',
                            height: '3px',
                            top: '1px',
                            position: 'absolute',
                            width: '1%',
                            background: '#fff'
                        }}
                        className="create-thread-load-send"
                        ref={ProgressBar}
                    ></div>
                </div>

                {load.status === -1 &&
                    < div style={{ color: 'red' }}>
                        <p>{load.msg}</p>
                    </div>
                }

                <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
                    <div style={{ margin: '20px 0' }}>
                        <textarea
                            style={{
                                width: '100%',
                                border: '0px solid',
                                background: '#fff',
                                borderColor: 'transparent',
                                boxShadow: 'none',
                                overflow: 'auto',
                                outline: 'none'
                            }}
                            maxLength={400}
                            autoFocus
                            placeholder="Create new thread ..."
                            disabled={lockSend}
                            onChange={(e) => {
                                setTextData(e.target.value)
                            }} ></textarea>
                    </div>
                    <div>
                        {image.show && <div className="create-thread-show-img">
                            <i className="fa fa-times create-thread-show-img-close" onClick={() => {
                                setImage({
                                    ...image,
                                    data: { ...image.data, blob: '', },
                                    show: false
                                });
                                setFile(null);
                            }} />
                            <img src={image.data.blob} alt="show-img" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                        </div>
                        }

                        {video.show && <div className="create-thread-show-video">
                            <i className="fa fa-times create-thread-show-video-close" onClick={() => {
                                console.log("Click called!");
                                setVideo({
                                    ...video,
                                    data: { ...image.data, blob: '', },
                                    show: false
                                });
                                setFile(null);
                            }} />
                            <video src={video.data.blob} controls style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                        </div>
                        }

                        {gallery.length !== 0 && <div className="create-thread-show-video">
                            <i className="fa fa-times create-thread-show-video-close"
                                onClick={() => {
                                    if (!lockSend) {
                                        setGallery([]);
                                        setFile(null);
                                    }
                                }} />
                            <div >
                                <span
                                    style={{
                                        position: 'absolute',
                                        padding: '0px 8px',
                                        borderRadius: '14px',
                                        background: '#eee',
                                        top: '3px',
                                        left: '3px'
                                    }}>{loadedFileIndex + 1 + '/'}{gallery.length}{gallery.length === 1 ? ' file' : ' files'} </span>
                                {gallery.length !== 1 &&
                                    <div
                                        style={{
                                            position: 'absolute',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            top: 0,
                                            left: 0,
                                        }}
                                        onClick={() => {
                                            if (loadedFileIndex !== 0)
                                                setLoadedFileIndex(loadedFileIndex - 1)
                                        }}
                                    >
                                        <i className="fa fa-chevron-left" style={{ padding: '5px 9px', paddingRight: '10px', background: '#eeeeeec2', borderRadius: '50%', cursor: 'pointer', margin: '5px', zIndex: 15 }}></i>
                                    </div>
                                }
                                {
                                    gallery[loadedFileIndex].type === "image"
                                        ?
                                        (<img src={gallery[loadedFileIndex].data} alt="upload item" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />)
                                        :
                                        (<video src={gallery[loadedFileIndex].data} controls style={{ objectFit: 'contain', width: '100%', height: '100%' }} />)
                                }
                                {gallery.length !== 1 &&

                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            right: 0,
                                        }}
                                        onClick={() => {
                                            if (loadedFileIndex !== gallery.length - 1)
                                                setLoadedFileIndex(loadedFileIndex + 1)
                                        }}

                                    >
                                        <i className="fa fa-chevron-right" style={{ padding: '5px 9px', paddingLeft: '10px', background: '#eeeeeec2', borderRadius: '50%', cursor: 'pointer', margin: '5px', zIndex: 15 }}></i>
                                    </div>
                                }
                            </div>
                            {/* <p>{gallery.length}</p>
                        {gallery.map((e, index) => {
                            if (e.type === 'image')
                                return <img key={index} src={e.data} alt="upload item" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                            if (e.type === 'video')
                                return 
                        })} */}
                        </div>
                        }


                        <div className="create-thread-show-video"></div>
                        <input type="file" accept='image/gif, image/jpeg, image/png, video/mp4, video/webm, video/ogg'
                            ref={InputGallery} style={{ display: 'none' }} onChange={showGallery} multiple />

                        <input type="file" accept='image/gif, image/jpeg, image/png'
                            ref={InputImage} style={{ display: 'none' }} onChange={showImage} />
                        <input type="file" accept='video/mp4, video/webm, video/ogg'
                            ref={InputVideo} style={{ display: 'none' }} onChange={showVideo} />
                    </div>
                    <div className="create-thread-upload-icons" style={{ display: 'none' }} >
                        <i className="fa fa-image create-thread-upload-icon" style={{ pointerEvents: image.show || video.show || lockSend ? 'none' : 'auto' }} onClick={uploadImage} ></i>
                        <i className="fa fa-video create-thread-upload-icon" style={{ pointerEvents: image.show || video.show || lockSend ? 'none' : 'auto' }} onClick={uploadVideo}></i>
                        <i className="fa fa-images create-thread-upload-icon" style={{ pointerEvents: lockSend ? 'none' : 'auto' }} onClick={uploadGallery} ></i>
                    </div>

                    <div style={{ border: '1px solid #b9b4b4', padding: '2px 10px', borderRadius: '6px', display: 'flex', flexDirection: 'column', margin: '10px 0' }}>
                        <div
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                setMore(!more);
                            }}
                        >
                            <i className="far fa-chevron-right" style={{ marginRight: '5px' }}></i>
                            <span>{langauges[langauge].more}</span>
                        </div>
                        {more &&
                            <div style={{ marginTop: '5px' }}>
                                <div>
                                    <input
                                        type="checkbox"
                                        checked={disableReplies}
                                        disabled={lockSend}
                                        onChange={(e) => {
                                            setDisableReplies(!disableReplies);
                                        }}
                                    />
                                    <span style={{ marginLeft: '4px' }}>{langauges[langauge].disable_replies}</span>
                                </div>
                                <div>
                                    <input
                                        checked={publicThread}
                                        disabled={lockSend}
                                        onChange={(e) => {
                                            setPublicThread(!publicThread);
                                        }}
                                        type="checkbox"
                                    /> <span>{langauges[langauge].public_thread}</span>
                                </div>
                            </div>
                        }

                    </div>

                    <div style={{ border: '1px solid #b9b4b4', padding: '2px 10px', borderRadius: '6px', display: 'flex', flexDirection: 'column' }}>
                        <div
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                setShowTags(!showTags);
                            }}
                        >
                            <i className="far fa-chevron-right" style={{ marginRight: '5px' }}></i>
                            <span>{langauges[langauge].tags}</span>
                        </div>
                        {showTags &&
                            <div style={{ padding: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <input type="text" className="form-control"
                                        style={{
                                            flexGrow: 1,
                                            padding: 'inherit',
                                            height: 'inherit',
                                            borderRadius: '7px'
                                        }}
                                        onChange={(e) => {
                                            var t = e.target.value;
                                            var patt = new RegExp("^[a-z0-9A-Z]*$");
                                            if (patt.test(t))
                                                setTag(t)
                                        }}
                                        disabled={lockSend}
                                        onKeyDown={(e) => {

                                            if (!lockSend && e.keyCode === 13) {
                                                console.log(tags.length)
                                                if (tags.length >= 5) {
                                                    return;
                                                }
                                                if (tag.trim() !== "") {
                                                    setTags([...tags, tag]);
                                                    setTag('');
                                                }
                                            }
                                        }}
                                        value={tag}
                                        maxLength={18}
                                    />
                                    <i className="fa fa-plus" style={{
                                        marginLeft: '10px',
                                        color: 'green',
                                        border: '1px solid green',
                                        borderRadius: '50%',
                                        padding: '3px',
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => {
                                            if (!lockSend) {
                                                console.log(tags.length)
                                                if (tags.length >= 5) {
                                                    return;
                                                }
                                                if (tag.trim() !== "") {
                                                    setTags([...tags, tag]);
                                                    setTag('');
                                                }
                                            }
                                        }}
                                    ></i>
                                </div>
                                <div style={{ marginTop: '5px' }}>
                                    {tags.map((e, i) => {
                                        return <span
                                            key={i}
                                            style={{
                                                border: '1px solid',
                                                borderRadius: '4px',
                                                padding: '1px 7px',
                                                margin: '0 1px'
                                            }}>{e}
                                            <i className="fa fa-times"
                                                style={{
                                                    marginLeft: '4px',
                                                    color: '#fff',
                                                    borderRadius: '50%',
                                                    padding: '1px 3px',
                                                    cursor: 'pointer',
                                                    background: '#777',
                                                    fontSize: '12px'
                                                }}
                                                onClick={() => {
                                                    if (!lockSend) {
                                                        var _tags = JSON.parse(JSON.stringify(tags));
                                                        _tags.splice(i, 1);
                                                        // tags.splice(i, 1);
                                                        setTags(_tags)
                                                    }
                                                }}
                                            ></i>
                                        </span>
                                    })}
                                </div>
                            </div>
                        }

                    </div>
                </div>
                <div style={{
                    display: 'flex', justifyContent: 'space-between', padding: '0 15px', marginTop: '10px'
                }}>
                    <button className="btn btn-primary" disabled={(textData.trim() === "" && gallery.length === 0) || lockSend}
                        onClick={() => {
                            console.log("Clicked ... .")
                            setLockSend(true);

                            createThreadServer();
                        }}
                        style={{ fontSize: '19px' }}
                    >
                        {langauges[langauge].create}
                        {lockSend && <span style={{ paddingLeft: '10px' }}><i style={{ animation: 'rotater 1s infinite' }} className="fa fa-circle-notch"></i> </span>}
                    </button>

                    <div  >
                        <i className="fa fa-images create-thread-upload-icon" style={{ pointerEvents: lockSend ? 'none' : 'auto' }} onClick={uploadGallery} ></i>
                    </div>
                </div>

            </div>
        </div >
    )
}
