import React, { Component, Fragment } from 'react';
import axios from 'axios';
import C from './../util/Constants';
import { Redirect, Link } from 'react-router-dom';
import GuestSideBar from './ghome/GuestSideBar';
import GuestChatbox from './ghome/GuestChatbox';
import GuestMemberList from './ghome/GuestMemberList';
import { connect } from 'react-redux';
import {
  doLogOut,
  initHome,
  initRoomSocket,
  initPrivateSocket,
  initGlobalSocket,
  loadUserSettings,
  checkPrivateNotif,
  doDispatchNotification,
  sendLoadRoom,
  initSendLoadRoom,
  initGuestSocket,
} from './../redux/actions/appActions';
import { Dashboard } from './util/Dashboard';
import PrivateBox from './home/private/PrivateBox';
import { Wait } from './util/Wait';

class Home extends Component {
  constructor({ dispatch }) {
    super();
  }

  state = {
    httpWait: false,
    notification: { status: '', heading: '', msg: '', show: false },
    showSidebar: false,
    showMemberlist: false,
    loadRoom: -2,
    initSuccess: false,
    urlSpecifiedComponent: 0, // 0 means home, 1 means room, 2 means private, 3 means privateroom(hopefully)
    loadError: { status: false, head: '', body: '' }

  };

  fadeAwayNotification = () => {
    var _this = this;
    setTimeout(function () {
      _this.setState({ notification: { show: false } });
    }, 1500);
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  initChecks() {
    console.log('Init checks ....');

    var _this = this;

    if (localStorage.getItem('sp_session') == null) {
      console.log('Session is null ....');
      _this.props.dispatch(doLogOut());
      return;
    }

    console.log('Reading session variables ....');

    try {
      var spSession = JSON.parse(localStorage.getItem('sp_session'));
    } catch (e) {
      _this.props.dispatch(doLogOut());
      return;
    }

    if (typeof (spSession.username) === 'undefined' && typeof (spSession.email) !== 'undefined' && typeof (spSession.token) !== 'undefined') {
      window.location = '/home';
    }

    console.log('Checking status on server ....');

    axios
      .get(
        C.API_EP +
        '/g/status?username=' +
        spSession.username +
        '&token=' +
        spSession.token
      )
      .then((response) => {
        if (response.data === 1) {
          console.log('Initiating sockets ....');
          // _this.props.dispatch(initGuestSocket());
          // _this.props.dispatch(initGlobalSocket());
          _this.setState({ initSuccess: true });
          //init Room Socket
          //get Screen info
          var device = window.matchMedia('(max-width: 768px)').matches ? 2 : 1;
          if (true) {
            if (device === 2) {
              if (localStorage.getItem(C.SP_LOADED_ROOM) !== null)
                _this.props.dispatch(
                  initHome({ device: device, loadedView: 'roomchat' })
                );
              else if (
                localStorage.getItem(C.SP_LOADED_PRIVATE_THREAD) !== null
              ) {
                _this.props.dispatch(
                  initHome({ device: device, loadedView: 'privatebox' })
                );
              } else
                _this.props.dispatch(
                  initHome({ device: device, loadedView: 'sidebar' })
                );
            } else {
              if (localStorage.getItem(C.SP_LOADED_ROOM) !== null)
                _this.props.dispatch(
                  initHome({ device: device, loadedView: 'roomchat' })
                );
              else
                _this.props.dispatch(
                  initHome({ device: device, loadedView: 'welcome' })
                );
            }
          }
        } else {
          setTimeout(function () {
            _this.props.dispatch(doLogOut());
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        if (typeof error.response == 'undefined') {
          console.log('network error.');
          this.setState(
            {
              loadError: {
                status: true,
                head: 'Network Error!',
                body: 'We are unable to reach SparkleChat servers. This could mean that you are not connected to internet.',
              },
            }
          );
          return;
        }
      });
  }

  componentDidMount() {
    console.log(this.props.match);
    if (
      typeof this.props.match !== 'undefined' &&
      Object.keys(this.props.match.params).length !== 0
    ) {
      console.log(this.props.match);
      var params = this.props.match.params;
      console.log(params);
      if (Object.keys(params).length !== 0) {
        if (/^[a-z0-9]+$/i.test(params.roomId)) {
          var roomId = params.roomId;
          // localStorage.removeItem(C.SP_LOADED_ROOM);
          var _this = this;
          setTimeout(function () {
            _this.props.dispatch(initSendLoadRoom(roomId));
          }, 1000);
          return;
        } else {
          console.log('This room was not found!');
          this.setState(
            {
              notification: {
                status: 'danger',
                heading: 'Error',
                msg: 'This room was not found.',
                show: true,
              },
            },
            this.fadeAwayNotification
          );
          return;
        }
      }
    }

    this.initChecks();
    //set sidebars
    if (window.matchMedia('(max-width: 768px)').matches) {
      //if it's a small device: do nothing(let the sidebars be hidden)
    } else {
      //if it's a medium or large device: show sidebars
      this.setState({ showSidebar: true, showMemberlist: true });
    }
  }

  toggleSidebar = () => {
    if (this.state.showSidebar === true) {
      this.setState({ showSidebar: false });
    } else {
      this.setState({ showSidebar: true });
    }
    console.log(this.state.showSidebar);
  };

  toggleMemberList = () => {
    if (this.state.showMemberlist === true) {
      this.setState({ showMemberlist: false });
    } else {
      this.setState({ showMemberlist: true });
    }
    console.log(this.state.showMemberlist);
  };

  startLoadRoom = (id) => {
    console.log('loading room 101 ' + id);
    this.setState({ loadRoom: id });
  };

  render() {
    return (
      <Fragment>
        {typeof this.state !== 'undefined' && !this.state.initSuccess && (
          // <span
          //   class='spinner-grow text-muted'
          //   style={{
          //     position: 'fixed',
          //     left: 0,
          //     right: 0,
          //     top: 0,
          //     bottom: 0,
          //     margin: 'auto',
          //     width: '100px',
          //     height: '100px',
          //     zIndex: 99,
          //   }}
          // ></span>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <img src="/resources/images/logo_t.svg" className={this.state.loadError.status ? 'sc-load-icon' : 'sc-load-icon animawait'} />
            {this.state.loadError.status && <div style={{ textAlign: 'center', padding: '5% 10%' }}>
              <h4 className="sc-pages-heading-flip">{this.state.loadError.head}</h4>
              <p className="sc-load-text flip" style={{ textAlign: 'center', lineHeight: 'normal' }}>{this.state.loadError.body}</p>
            </div>}
          </div>

          // <div>
          //   <div className='animated rotateIn' style={{}}>
          //     <img src='/resources/images/sparkle_logo.png' alt='loader' />
          //   </div>
          // </div>
        )}

        {typeof this.props.spGlobal !== 'undefined' &&
          typeof this.props.spGlobal.redirect !== 'undefined' &&
          this.props.spGlobal.redirect.status === true && (
            <div>
              <Redirect to={this.props.spGlobal.redirect.to} />
              <h3 id={this.props.spGlobal.redirect.to}>
                {this.props.spGlobal.redirect.to}
              </h3>
            </div>
          )}

        {typeof this.state.initSuccess != 'undefined' &&
          this.state.initSuccess && (
            <div>
              {typeof this.props.spGlobal.notification !== 'undefined' &&
                this.props.spGlobal.notification.show && (
                  <div
                    class={
                      'alert alert-' + this.props.spGlobal.notification.status
                    }
                    style={{
                      position: 'fixed',
                      left: '5%',
                      bottom: '5%',
                      zIndex: 999999,
                      boxShadow: '0px 0px 10px -2px',
                    }}
                  >
                    <strong style={{ textTransform: 'uppercase' }}>
                      {this.props.spGlobal.notification.heading}!
                    </strong>{' '}
                    {this.props.spGlobal.notification.msg}
                  </div>
                )}

              <div className='row' style={{ padding: 0, margin: 0 }}>
                {typeof this.props.spGlobal.layout !== 'undefined' &&
                  (this.props.spGlobal.layout.device === 1 ||
                    (this.props.spGlobal.layout.device === 2 &&
                      this.props.spGlobal.layout.loadedView === 'sidebar')) && (
                    <div
                      className={
                        window.matchMedia('(max-width: 768px)').matches === true
                          ? 'sp-sidebar'
                          : 'col-md-3 sp-sidebar'
                      }
                    >
                      <GuestSideBar />
                    </div>
                  )}

                {typeof this.props.spGlobal.layout !== 'undefined' &&
                  this.props.spGlobal.layout.loadedView === 'roomchat' && (
                    <div
                      className='col-md-9 col-sm-12 row'
                      style={{ padding: '0', margin: 0 }}
                    >
                      <div className='col-md-9 col-sm-12 sp-chatbox'>
                        <GuestChatbox

                          sidebarHandler={this.toggleSidebar}
                          memberlistHandler={this.toggleMemberList}
                        />
                      </div>
                      {this.state.showMemberlist && (
                        <div
                          className={
                            window.matchMedia('(max-width: 768px)').matches ===
                              true
                              ? 'sp-memberlist  animated slideInRight'
                              : 'col-md-3 sp-memberlist'
                          }
                          style={
                            this.state.showMemberlist
                              ? { display: 'block', animationDuration: '0.31s' }
                              : { display: 'none' }
                          }
                        >
                          <GuestMemberList />
                        </div>
                      )}
                    </div>
                  )}

                {typeof this.props.spGlobal.layout !== 'undefined' &&
                  this.props.spGlobal.layout.loadedView === 'welcome' && (
                    <div
                      className='col-md-9 col-sm-12 row'
                      style={{ padding: '0', margin: 0 }}
                    >
                      <Dashboard />
                    </div>
                  )}

                {typeof this.props.spGlobal.layout !== 'undefined' &&
                  this.props.spGlobal.layout.loadedView === 'wait' && (
                    <div
                      className='col-md-9 col-sm-12 row sp-privatebox'
                      style={{ padding: '0', margin: 0 }}
                    >
                      <Wait />
                    </div>
                  )}
              </div>
            </div>
          )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spGlobal: state.appState.spGlobal,
  };
};

export default connect(mapStateToProps)(Home);
