import React, { useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import { Error } from "./../util/Error";
import JoinRoom from "./../util/JoinRoom";
import GoogleAds from ".//../util/GoogleAds";

import {
  fetchRoomsJoined,
  sendSearchRoom,
  fetchRoomsCreated,
  initSendLoadRoom,
  sendJoinRoom,
  fetchFeaturedRooms,
  fetchunFeaturedRooms,
  joinRoomByUI,
  sendCreateRoom,
  closeJoinRoomUI,
  initJoinRoomFromUI,
  doLogOut,
  showProfileUI,
  sendLeaveRoom,
  _setSidebarActiveTab,
  _setRoomsActiveTab,
  showAccountUI,
  checkPrivateNotif,
} from "../../redux/actions/appActions";
import CreateRoom from "../util/CreateRoom";
import Profile from "../util/Profile";
import UserAccount from "../util/UserAccount";
import Axios from "axios";
import C from "./../../../src/components/util/../util/../../util/Constants";
import PrivateList from "./private/PrivateList";

const ConnectedList = ({ dispatch, sidebar, layout, changeLoadedView, loadedView }) => {
  const [platform, setPlatform] = useState("web");


  useEffect(() => {
    var uA = window.navigator.userAgent;
    console.log(typeof uA);
    console.log(uA);
    if (uA.includes('Android')) {
      setPlatform("android");
    }
    if (uA.includes('iOS')) {
      setPlatform("iOS");
    }
  }, [])

  useEffect(() => {
    console.log("Loading details of the sidebar ...");
    var d1 = new Date();

    if (!sidebar.loaded) {
      //dispatch(fetchRoomsJoined(d1));
      //dispatch(fetchRoomsCreated(d1));
      //dispatch(fetchFeaturedRooms(d1));
      //dispatch(fetchunFeaturedRooms(d1));
      //dispatch(checkPrivateNotif());
    }

    adjustSidebar();
  }, [dispatch]);

  const [showCreateRoom, setShowCreateRoom] = useState(false);

  const [joinRoomUI, setJoinRoomUI] = useState(false);
  const [joinRoomDetails, setJoinRoomDetails] = useState({});

  const [roomToSearch, setRoomToSearch] = useState("");

  const [unfeaturedActive, setUnfeaturedActive] = useState(false);

  // const [sidebarActiveTab, setSidebarActiveTab] = useState(1);
  // const [roomsActiveTab, setRoomsActiveTab] = useState(1);
  const [profileActiveTab, setProfileActiveTab] = useState(1);

  const [colorLoad, setColorLoad] = useState(2);

  const [color, setColor] = useState("#001f3f");

  const createRoom = (name, type, desc) => {
    dispatch(sendCreateRoom(name, type, desc));
  };

  const setSidebarActiveTab = (tabNumber) => {
    dispatch(_setSidebarActiveTab(tabNumber));
  };

  const setRoomsActiveTab = (tabNumber) => {
    dispatch(_setRoomsActiveTab(tabNumber));
  };

  const searchRoom = () => {
    console.log("Searching Room: " + roomToSearch);
    if (roomToSearch.trim() === "") {
      console.log("Please provide at least one character.");
      return;
    }
    dispatch(sendSearchRoom(roomToSearch));
  };

  const showConfirmLogout = () => {
    dispatch(doLogOut());
  };

  const loadRoom = (id) => {
    //console.log("Loading room : ", id)
    dispatch(initSendLoadRoom(id));
  };

  const showCreateRoomUI = () => {
    setShowCreateRoom(true);
  };

  const leaveRoom = (id) => {
    dispatch(sendLeaveRoom(id));
  };

  const joinRoom = (id, rules, desc, title, type) => {
    // setJoinRoomDetails({roomId: id, roomDetails: {title: title, desc: desc, rules: rules, type: type}});
    // setJoinRoomUI(true);
    //dispatch(sendJoinRoom(id));
    dispatch(initJoinRoomFromUI(id));
  };

  const joinRoomFromUI = (id) => {
    dispatch(joinRoomByUI(id));
  };

  const showProfileModal = () => {
    dispatch(showProfileUI());
  };

  const showAccountModal = () => {
    dispatch(showAccountUI());
  };

  const saveColor = () => {
    console.log(color);
    setColorLoad(0);
    var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
    Axios.post(C.API_EP + "/a/rooms/color", {
      email: spSession.email,
      token: spSession.token,
      color: color,
    })
      .then((response) => {
        setColorLoad(1);
      })
      .catch((error) => {
        setColorLoad(-1);
      });
  };

  const adjustSidebar = () => {
    // if (window.matchMedia('(max-width: 768px)').matches) {
    //   var spChatbox = document.getElementsByClassName('sp-chatbox')[0];
    //   var spChatboxCard = spChatbox.getElementsByClassName('card')[0];
    //   var spChatBoxCardHeader = spChatboxCard.getElementsByClassName(
    //     'card-header'
    //   )[0];
    //   var spSidebar = document.getElementsByClassName('sp-sidebar')[0];
    //   spSidebar.style.top = spChatBoxCardHeader.offsetHeight + 'px';
    //   spSidebar.style.height =
    //     spSidebar.offsetHeight - spChatBoxCardHeader.offsetHeight + 'px';
    // }
  };

  const SideBarComp1 = () => {
    return (
      <div>
        {typeof sidebar.roomsActiveTab !== "undefined" &&
          sidebar.roomsActiveTab === 3 && (
            <div className="card">
              <div className="card-header">
                <h3 className="sidebar-heading">Rooms Joined</h3>
              </div>
              <div className="card-body">
                {typeof sidebar.roomsJoined !== "undefined" &&
                  sidebar.roomsJoined.loading && (
                    <div className="progress" style={{ height: "5px" }}>
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        style={{ width: "100%", height: "5px" }}
                      ></div>
                    </div>
                  )}

                <ul
                  className="list-group"
                  disabled={
                    typeof sidebar.roomsJoined !== "undefined" &&
                    sidebar.roomsJoined.loading
                  }
                >
                  {typeof sidebar.roomsJoined != "undefined" &&
                    typeof sidebar.roomsJoined.data != "undefined" &&
                    sidebar.roomsJoined.data.length > 0 &&
                    sidebar.roomsJoined.data.map((el) => (
                      <li
                        className={
                          localStorage.getItem(C.SP_LOADED_ROOM) === el._id
                            ? "list-group-item d-flex justify-content-between align-items-center clickable active"
                            : "list-group-item d-flex justify-content-between align-items-center clickable"
                        }
                        key={el._id}
                        onClick={() => {
                          loadRoom(el._id, el.title);
                        }}
                      >
                        {el.title}
                        <i
                          className="badge badge-primary badge-pill"
                          onClick={() => {
                            leaveRoom(el._id);
                          }}
                        >
                          <i className="fas fa-times-circle"></i>
                        </i>
                      </li>
                    ))}
                  {typeof sidebar.roomsJoined != "undefined" &&
                    typeof sidebar.roomsJoined.data != "undefined" &&
                    sidebar.roomsJoined.data.length === 0 && (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        You have not joined any rooms yet!
                      </li>
                    )}
                </ul>
              </div>
            </div>
          )}

        {typeof sidebar.roomsActiveTab !== "undefined" &&
          sidebar.roomsActiveTab === 4 && (
            <div className="card">
              <div className="card-header">
                <h3 className="sidebar-heading">Search Rooms</h3>
              </div>
              <div className="card-body">
                <div id="sp-search-room">
                  <div className="form-group">
                    <label>Room Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="room"
                      autoComplete="off"
                      onChange={(e) => {
                        setRoomToSearch(e.target.value);
                      }}
                      disabled={false}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-outline-success btn-block"
                    onClick={searchRoom}
                    disabled={
                      typeof roomToSearch === "undefined" ||
                      roomToSearch.length < 3
                    }
                  >
                    Search
                  </button>
                  <div id="sp-search-room-results">
                    <ul className="list-group">
                      {typeof sidebar.roomsSearched !== "undefined" &&
                        typeof sidebar.roomsSearched.data !== "undefined" &&
                        sidebar.roomsSearched.data.length !== 0 &&
                        sidebar.roomsSearched.data.map((el) => (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center"
                            key={el._id}
                          >
                            <div style={{ display: "inline" }}>
                              {el.title}
                              {el.joined !== 0 && (
                                <i
                                  className="fas fa-check"
                                  style={{
                                    color: "white",
                                    background: "green",
                                    borderRadius: "50%",
                                    padding: "3px",
                                    margin: "2px",
                                  }}
                                ></i>
                              )}
                            </div>

                            {el.joined === 0 ? (
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  joinRoom(
                                    el._id,
                                    el.rules,
                                    el.desc,
                                    el.title,
                                    el.type
                                  );
                                }}
                              >
                                {el.loading ? (
                                  <div className="spinner-grow spinner-grow-sm"></div>
                                ) : (
                                  "Join"
                                )}
                              </button>
                            ) : (
                              <button
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                  loadRoom(el._id);
                                }}
                              >
                                Open
                              </button>
                            )}
                          </li>
                        ))}
                    </ul>
                  </div>
                  {typeof sidebar.roomsSearched !== "undefined" &&
                    typeof sidebar.roomsSearched.error !== "undefined" &&
                    sidebar.roomsSearched.error.status && (
                      <Error message={sidebar.roomsSearched.error.message} />
                    )}
                </div>
              </div>
            </div>
          )}

        {typeof sidebar.roomsActiveTab !== "undefined" &&
          sidebar.roomsActiveTab === 2 && (
            <div className="card">
              <div className="card-header">
                <h3 className="sidebar-heading">
                  Rooms Created
                  <i
                    className="fas fa-plus sp-create-room"
                    onClick={showCreateRoomUI}
                  ></i>
                </h3>
              </div>
              <div className="card-body">
                {typeof sidebar.roomsCreated !== "undefined" &&
                  sidebar.roomsCreated.loading && (
                    <div className="progress" style={{ height: "5px" }}>
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        style={{ width: "100%", height: "5px" }}
                      ></div>
                    </div>
                  )}
                <ul className="list-group">
                  {typeof sidebar.roomsCreated != "undefined" &&
                    typeof sidebar.roomsCreated.data != "undefined" &&
                    sidebar.roomsCreated.data.length > 0 &&
                    sidebar.roomsCreated.data.map((el) => (
                      <li
                        className={
                          localStorage.getItem(C.SP_LOADED_ROOM) === el._id
                            ? "list-group-item d-flex justify-content-between align-items-center clickable active"
                            : "list-group-item d-flex justify-content-between align-items-center clickable"
                        }
                        key={el._id}
                        onClick={() => {
                          loadRoom(el._id, el.title);
                        }}
                      >
                        {el.title}
                      </li>
                    ))}
                  {typeof sidebar.roomsCreated != "undefined" &&
                    typeof sidebar.roomsCreated.data != "undefined" &&
                    sidebar.roomsCreated.data.length === 0 && (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        You have not created any rooms yet!
                      </li>
                    )}
                </ul>
              </div>
            </div>
          )}

        {typeof sidebar.roomsActiveTab !== "undefined" &&
          sidebar.roomsActiveTab === 1 && (
            <div
              className="card"
              style={{
                borderBottom: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
            >
              <div
                className="card-header"
                style={{
                  display: "flex",
                  padding: 0,
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <h3
                  className="sidebar-heading"
                  style={{
                    display: "inline",
                    width: "50%",
                    padding: "15px 0",
                    textAlign: "center",
                    margin: 0,
                    backgroundColor: !unfeaturedActive ? "#eee" : "",
                  }}
                  onClick={() => {
                    setUnfeaturedActive(false);
                  }}
                >
                  Featured
                  <span
                    className="fa fa-check"
                    style={{
                      color: "green",
                      padding: 0,
                      margin: 0,
                      width: "initial",
                      marginLeft: "3px",
                    }}
                  ></span>
                </h3>
                <h3
                  className="sidebar-heading"
                  style={{
                    display: "inline",
                    width: "50%",
                    padding: "15px 0",
                    textAlign: "center",
                    margin: 0,
                    backgroundColor: unfeaturedActive ? "#eee" : "",
                  }}
                  onClick={() => {
                    setUnfeaturedActive(true);
                  }}
                >
                  Non-Featured
                </h3>
              </div>
              <div className="card-body" style={{ padding: 0 }}>
                {typeof sidebar.rank !== "undefined" && sidebar.rank == 0 && (
                  <GoogleAds />
                )}
                {!unfeaturedActive && (
                  <div>
                    {typeof sidebar.featuredRooms !== "undefined" &&
                      sidebar.featuredRooms.loading && (
                        <div className="progress" style={{ height: "5px" }}>
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            style={{ width: "100%", height: "5px" }}
                          ></div>
                        </div>
                      )}

                    <ul className="list-group">
                      {typeof sidebar.featuredRooms != "undefined" &&
                        typeof sidebar.featuredRooms.data != "undefined" &&
                        sidebar.featuredRooms.data.length > 0 &&
                        sidebar.featuredRooms.data.map((el) => (
                          <a
                            href="#"
                            className="list-group-item list-group-item-action flex-column align-items-start"
                            style={{
                              display: "flex",
                              border: "none",
                              borderBottom: "1px solid #eee",
                              background:
                                localStorage.getItem(C.SP_LOADED_ROOM) ===
                                  el._id
                                  ? "#dee2e6"
                                  : "",
                              padding: "15px 9px",
                            }}
                            key={el._id}
                            onClick={
                              el.access === 1
                                ? () => {
                                  loadRoom(el._id);
                                }
                                : () => {
                                  dispatch(initJoinRoomFromUI(el._id));
                                }
                            }
                          >
                            <div className="d-flex w-100 justify-content-between">
                              <h5
                                className="mb-1"
                                style={{
                                  color: "#565050",
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {el.title}
                              </h5>
                              <small
                                style={{ color: "#84a984", fontWeight: "bold" }}
                              >
                                {el.membersCount > 1000
                                  ? (el.membersCount / 1000).toFixed(1) +
                                  "k members"
                                  : el.membersCount + " members"}
                              </small>
                            </div>
                            <p
                              className="mb-1"
                              style={{
                                display: "inline",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                width: "100%",
                                overflow: "hidden",
                              }}
                            >
                              {el.desc}
                            </p>
                          </a>
                        ))}
                      {typeof sidebar.featuredRooms != "undefined" &&
                        typeof sidebar.featuredRooms.data != "undefined" &&
                        sidebar.featuredRooms.data.length === 0 && (
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            No featured rooms found!
                          </li>
                        )}

                      {typeof sidebar.featuredRooms != "undefined" &&
                        typeof sidebar.featuredRooms.error != "undefined" &&
                        sidebar.featuredRooms.error.status === true && (
                          <div className="alert alert-danger">
                            There was some error with the request.{" "}
                            <button className="btn btn-sm btn-danger">
                              Click{" "}
                            </button>{" "}
                            to refresh
                          </div>
                        )}
                    </ul>
                  </div>
                )}

                {unfeaturedActive && (
                  <div>
                    {typeof sidebar.unfeaturedRooms !== "undefined" &&
                      sidebar.unfeaturedRooms.loading && (
                        <div className="progress" style={{ height: "5px" }}>
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            style={{ width: "100%", height: "5px" }}
                          ></div>
                        </div>
                      )}

                    <ul className="list-group">
                      {typeof sidebar.unfeaturedRooms != "undefined" &&
                        typeof sidebar.unfeaturedRooms.data != "undefined" &&
                        sidebar.unfeaturedRooms.data.length > 0 &&
                        sidebar.unfeaturedRooms.data.map((el) => (
                          <a
                            href="#"
                            className="list-group-item list-group-item-action flex-column align-items-start"
                            style={{
                              display: "flex",
                              border: "none",
                              borderBottom: "1px solid #eee",
                              background:
                                localStorage.getItem(C.SP_LOADED_ROOM) ===
                                  el._id
                                  ? "#dee2e6"
                                  : "",
                              padding: "15px 9px",
                            }}
                            key={el._id}
                            onClick={
                              el.access === 1
                                ? () => {
                                  loadRoom(el._id);
                                }
                                : () => {
                                  dispatch(initJoinRoomFromUI(el._id));
                                }
                            }
                          >
                            <div className="d-flex w-100 justify-content-between">
                              <h5
                                className="mb-1"
                                style={{
                                  color: "#565050",
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {el.title}
                              </h5>
                              <small
                                style={{ color: "#84a984", fontWeight: "bold" }}
                              >
                                {el.membersCount > 1000
                                  ? (el.membersCount / 1000).toFixed(1) +
                                  "k members"
                                  : el.membersCount + " members"}
                              </small>
                            </div>
                            <p
                              className="mb-1"
                              style={{
                                display: "inline",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                width: "100%",
                                overflow: "hidden",
                              }}
                            >
                              {el.desc}
                            </p>
                          </a>
                        ))}
                      {typeof sidebar.featuredRooms != "undefined" &&
                        typeof sidebar.featuredRooms.data != "undefined" &&
                        sidebar.featuredRooms.data.length === 0 && (
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            No unfeatured rooms found!
                          </li>
                        )}

                      {typeof sidebar.featuredRooms != "undefined" &&
                        typeof sidebar.featuredRooms.error != "undefined" &&
                        sidebar.featuredRooms.error.status === true && (
                          <div className="alert alert-danger">
                            There was some error with the request.{" "}
                            <button className="btn btn-sm btn-danger">
                              Click{" "}
                            </button>{" "}
                            to refresh
                          </div>
                        )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    );
  };

  const SideBarComp3 = () => {
    return (
      <div>
        {profileActiveTab === 2 && <div className="card"></div>}

        {profileActiveTab === 1 && <div className="card"></div>}
      </div>
    );
  };

  return (
    <Fragment>
      <div id="sp-sidebar-header">
        <span
          className={
            typeof sidebar.sidebarActiveTab !== "undefined" &&
              sidebar.sidebarActiveTab === 1
              ? "fas fa-home sp-sidebar-header-active"
              : "fas fa-home"
          }
          onClick={() => {
            setSidebarActiveTab(1);
          }}
        ></span>
        <span
          className={
            typeof sidebar.sidebarActiveTab !== "undefined" &&
              sidebar.sidebarActiveTab === 3
              ? "fas fa-ellipsis-h sp-sidebar-header-active"
              : "fas fa-ellipsis-h"
          }
          onClick={() => {
            setSidebarActiveTab(3);
          }}
        ></span>
      </div>
      {typeof sidebar.sidebarActiveTab !== "undefined" &&
        sidebar.sidebarActiveTab === 3 && (
          <div className="sp-sidebar-3" style={{ paddingLeft: "15px" }}>
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center clickable">
                Version: 1.0.1
              </li>
              <li
                className="list-group-item d-flex justify-content-between align-items-center clickable"
                onClick={() => {
                  showConfirmLogout();
                }}
              >
                Logout
              </li>
            </ul>
          </div>
        )}

      {typeof sidebar.sidebarActiveTab !== "undefined" &&
        sidebar.sidebarActiveTab === 2 && (
          <div className="sp-sidebar-2" style={{ padding: "15px" }}>
            {
              platform === "android" && (
                <div style={{ display: 'none', flexDirection: 'row', padding: '10px', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #c4c2c2', borderRadius: '3px', marginBottom: '5px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <img src="/resources/images/logo_t.svg" style={{ width: 40, height: 40, borderRadius: '20px' }} />
                    <div style={{ marginLeft: '10px' }}>
                      <b>Sparkle Messenger </b>
                      <span style={{ display: 'block' }}>In Beta</span>
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-success">Open <i className="fab fa-google-play"></i></button>
                  </div>
                </div>
              )
            }

            <PrivateList />
          </div>
        )}

      {typeof sidebar.sidebarActiveTab !== "undefined" &&
        sidebar.sidebarActiveTab === 1 && (
          <div className="sp-sidebar-1" style={{ padding: "10px 2px" }}>
            <li
              style={{ listStyle: 'none', background: loadedView === 'dashboard' ? '#bce2b59c' : '' }}
              onClick={() => {
                //changeLoadedView('dashboard');
                window.location = '/dashboard';
              }}
            >
              <span
                className={
                  "fas fa-tachometer"
                }

              ></span>
              Dashboard
            </li>

            <li
              style={{ listStyle: 'none', background: loadedView === 'users' ? '#bce2b59c' : '' }}
              onClick={() => {
                // setLoadedView('users');
                // changeLoadedView('users');
                window.location = '/users';
              }}
            >
              <span
                className={
                  "fas fa-user"
                }

              ></span>
              Users
            </li>

            <li
              style={{ listStyle: 'none', background: loadedView === 'rooms' ? '#bce2b59c' : '' }}
              onClick={() => {
                // setLoadedView('users');
                // changeLoadedView('users');
                window.location = '/rooms';
              }}
            >
              <span
                className={
                  "fas fa-home"
                }

              ></span>
              Rooms
            </li>

            <li
              style={{ listStyle: 'none' }}
              onClick={() => {
              }}
            >
              <span
                className={
                  "fas fa-user-secret"
                }

              ></span>
              Guests
            </li>

            <li
              style={{ listStyle: 'none' }}
              onClick={() => {
              }}
            >
              <span
                className={
                  "fas fa-envelope"
                }

              ></span>
              PMs
            </li>


            <li
              style={{ listStyle: 'none' }}
              onClick={() => {
              }}
            >
              <span
                className={
                  "fas fa-ban"
                }

              ></span>
              Banned IPs
            </li>

            <li
              style={{ listStyle: 'none' }}
              onClick={() => {
              }}
            >
              <span
                className={
                  "fas fa-flag"
                }

              ></span>
              Reports
            </li>

            <li
              style={{ listStyle: 'none' }}
              onClick={() => {
                window.location = '/hardcoded-messages';
              }}
            >
              <span
                className={
                  "fas fa-envelope"
                }

              ></span>
              Hardcoded Messages
            </li>

            <li
              style={{ listStyle: 'none', background: loadedView === 'settings' ? '#bce2b59c' : '' }}
              onClick={() => {
                window.location = '/settings';
              }}
            >
              <span
                className={
                  "fas fa-cog"
                }

              ></span>
              Settings
            </li>


            <li
              style={{ listStyle: 'none', background: loadedView === 'logs' ? '#bce2b59c' : '' }}
              onClick={() => {
                window.location = '/logs';
              }}
            >
              <span
                className={
                  "fas fa-history"
                }

              ></span>
              Logs
            </li>

          </div>
        )}

      {typeof sidebar.joinRoom !== "undefined" && sidebar.joinRoom.joining && (
        <JoinRoom
          roomId={sidebar.joinRoom.roomId}
          roomDetails={sidebar.joinRoom.roomDetails}
          layout={layout}
          joinRoom={joinRoomFromUI}
          closeCreateRoom={() => {
            dispatch(closeJoinRoomUI());
          }}
        />
      )}

      {joinRoomUI && (
        <JoinRoom
          roomId={joinRoomDetails.roomId}
          roomDetails={joinRoomDetails.roomDetails}
          layout={layout}
          joinRoom={joinRoomFromUI}
          closeCreateRoom={() => {
            dispatch(closeJoinRoomUI());
          }}
        />
      )}

      {showCreateRoom && (
        <CreateRoom
          closeCreateRoom={() => {
            setShowCreateRoom(false);
          }}
          createRoomHandler={createRoom}
        />
      )}

      {typeof sidebar.profile !== "undefined" &&
        sidebar.profile.showUI === true && <Profile />}

      {typeof sidebar.account !== "undefined" &&
        sidebar.account.showUI === true && <UserAccount />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebar: state.appState.sidebar,
    layout: state.appState.spGlobal.layout,
  };
};

const Sidebar = connect(mapStateToProps)(ConnectedList);

export default Sidebar;
