import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  hideUploadVideoUI,
  propogateImage,
  hidePrivateUploadVideoUi,
  propogatePrivateImage,
} from './../../redux/actions/appActions';
import C from './../../util/Constants';
import axios from 'axios';

const VideoUploadContainer = ({ dispatch, context, path, id }) => {
  const InputFile = useRef(null);
  const [video, setVideo] = useState({
    update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
    data: {
      blob: '',
    },
  });

  const [uploadError, setUploadError] = useState('Upload Error.');

  useEffect(() => {}, [dispatch]);

  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
      return spSession;
    } catch (e) {
      return false;
    }
  };

  const setBlob = (blob) => {
    setVideo({
      ...video,
      data: { ...video.data, blob: blob },
    });
  };

  const closeUploadVideoUi = () => {
    dispatch(hideUploadVideoUI());
  };

  const closeUploadFileUiPrivate = () => {
    dispatch(hidePrivateUploadVideoUi());
  };

  const selectFile = () => {
    InputFile.current.click();
  };

  const _uploadVideo = () => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    var dataToUpdate = new FormData();
    setVideo({ ...video, update: 0 });
    // if (oldData.fullname.trim() !== profile.data.fullname.trim())
    //   dataToUpdate.append('fullname', profile.data.fullname);
    // if (oldData.gender !== profile.data.gender)
    //   dataToUpdate.append('gender', profile.data.gender);
    // if (oldData.age !== profile.data.age)
    //   dataToUpdate.append('age', profile.data.age);
    // if (oldData.about !== profile.data.about)
    if (context === 'public') dataToUpdate.append('roomId', id);

    if (context === 'private') dataToUpdate.append('threadId', id);

    dataToUpdate.append('email', spSession.email);
    dataToUpdate.append('token', spSession.token);

    if (typeof video.data.blob !== 'undefined')
      dataToUpdate.append('videoFile', InputFile.current.files[0]);
    // console.log(dataToUpdate);

    //

    axios
      .post(C.API_EP + path, dataToUpdate, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          console.log(percentCompleted);
        }
      })
      .then((response) => {

        var data = response.data.data;
        var messageId = data.messageId;
        var propogateId = data.propogateId;

        if (context === 'public') {
          dispatch(propogateImage(messageId, propogateId, id));
          dispatch(hideUploadVideoUI());
        }

        if (context === 'private') {
          dispatch(propogatePrivateImage(messageId, propogateId, id));
          dispatch(hidePrivateUploadVideoUi());
        }
      })
      .catch((e) => {
        setVideo({ ...video, update: -1 });
        console.log(e.response);
        console.log(e);
        if (typeof e.response !== 'undefined') {
          if (
            typeof e.response.data !== 'undefined' &&
            typeof e.response.data.msg !== 'undefined'
          )
          if(typeof e.response.data.msg === 'object')
          {
            setUploadError(e.response.data.msg.message);
          }
          else{
            setUploadError(e.response.data.msg);
          }
        }
      });
  };

//   const showImage = (e) => {
//     console.log(e.target);
//     if (e.target.files && e.target.files[0]) {
//       var reader = new FileReader();
//       reader.onload = function (e) {
//         setBlob(e.target.result);
//         console.log(e.target.result);
//       };
//       reader.readAsDataURL(e.target.files[0]); // convert to base64 string
//     }
//   };

  return (
    <div className='sp-uploadfile-wrapper'>
      <div className='sp-uploadfile-inner'>
        <div className='card'>
          <div className='card-header'>
            Add Video
            <i
              className='fas fa-times sp-small-icon'
              onClick={
                context === 'public'
                  ? closeUploadVideoUi
                  : closeUploadFileUiPrivate
              }
            ></i>
          </div>
          <div className='card-body'>
            {typeof video !== 'undefined' && video.update === 0 && (
              <div class='progress'>
                <div
                  class='progress-bar progress-bar-striped progress-bar-animated'
                  style={{ width: '100%' }}
                ></div>
              </div>
            )}

            {typeof video !== 'undefined' && video.update === -1 && (
              <div class='alert alert-danger'>{uploadError}</div>
            )}

            {typeof video !== 'undefined' && video.update !== 0 && (
              <div
                className='row'
                style={{ padding: '15px', width: '100%', margin: 0 }}
              >
                <div
                  className=''
                  style={{ textAlign: 'center', width: '100%' }}
                >
                  {typeof video.data.blob !== 'undefined' &&
                    video.data.blob !== '' && (
                      <div
                        style={{
                          maxHeight: '250px',
                          maxWidth: '250px',
                          margin: '5px',
                        }}
                      >
                        <video
                          src={
                            typeof video.data !== 'undefined' &&
                            typeof video.data.blob !== 'undefined' &&
                            video.data.blob
                          }
                          style={{ maxHeight: '250px', maxWidth: '250px' }}
                        />
                      </div>
                    )}
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={InputFile}
                    accept='video/mp4, video/webm, video/ogg'
                    // onChange={showV}
                  />

                  <div className=''>
                    <button
                      className='btn btn-primary btn-sm'
                      style={{}}
                      onClick={selectFile}
                    >
                      Select File
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='card-footer'>
            <button
              className='btn btn-success'
              disabled={
                typeof video.data == 'undefined' ||
                typeof video.data.blob === 'undefined'
              }
              onClick={_uploadVideo}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    parentData: state.appState.chatbox,
  };
};

const UploadVideo = connect(mapStateToProps)(VideoUploadContainer);

export default UploadVideo;
