import React from 'react'

const RoomStats = ({ room }) => {
    return (
        <div style={{ margin: 20 }}>

            <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: 5, border: '1px solid #ddd' }}>
                <div>
                    <b>Title: </b>
                    {room.title}
                </div>

                <div>
                    <b>Allow Guests: </b>
                    {room.allowGuests ? 'Yes' : 'No'}
                </div>

                <div>
                    <b>Access: </b>
                    {room.access === 1 ? 'Public' : 'Private'}
                </div>

            </div>

            <hr />

            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <div style={{ width: '100%' }}>
                    <div>
                        <b style={{ display: 'block', textAlign: 'left' }}>Topic: </b>
                        <textarea style={{ width: '90%' }} disabled={true} rows="3">
                            {room.topic}
                        </textarea>
                    </div>


                    <div>
                        <b style={{ display: 'block', textAlign: 'left' }}>Desc: </b>
                        <textarea style={{ width: '90%' }} disabled={true} rows="5">
                            {room.desc}
                        </textarea>
                    </div>
                </div>

                <div style={{ width: '100%' }}>
                    <b style={{ display: 'block', textAlign: 'left' }}>Rules: </b>
                    <textarea style={{ width: '90%' }} disabled={true} rows="10">
                        {room.rules}
                    </textarea>
                </div>

            </div>


        </div>
    )
}

export default RoomStats
