import Axios from 'axios';
import React from 'react';
import langauges from '../../languages';
import constants from '../../util/Constants';

function DeleteRole({ role, loadedView, roomId, roles, setRoles, setLoadedRole }) {

    const [deleting, setDeleting] = React.useState(false);
    const [deleteStatus, setDeleteStatus] = React.useState({ status: 0, msg: '####' })


    var langauge = 'english';

    React.useEffect(() => {

        langauge = localStorage.getItem(constants.LANGUAGE);

        console.log(langauge);
        if (langauge === null) {
            langauge = 'english'
        }

    }, [])

    const checkForSession = () => {
        if (localStorage.getItem(constants.SP_SESSION) == null) {
            return false;
        }

        try {
            var spSession = JSON.parse(localStorage.getItem(constants.SP_SESSION));
            return spSession;
        } catch (e) {
            return false;
        }
    };


    const deleteRole = () => {

        var spSession = checkForSession();
        if (spSession === false) {
            return;
        }

        setDeleting(true);

        Axios.post(constants.API_EP + '/a/r/c/deleteRole', {
            email: spSession.email,
            token: spSession.token,
            roleId: role._id,
            roomId: roomId
        }
        ).then(r => {
            console.log(r);
            setDeleting(false);
            // setUpdating(false);
            // // _setUpdateStatus({ status: 1, message: 'Update successful!' }, 2500)
            var changedRoles = roles.filter(e =>
                e._id !== role._id
            )

            console.log(changedRoles);
            // setUpdateAllowed(false);
            setLoadedRole(0);
            setRoles(changedRoles)
            loadedView("RolesList")
        }).catch(error => {
            setDeleting(false);
            if (error.response) {
                if (error.response.status === 400) {
                    setDeleteStatus({ status: -1, message: error.response.data.msg }, 3500)
                }
            } else if (error.request) {
                setDeleteStatus({ status: -1, message: langauges[langauge].server_respond }, 3500)
            } else {
                setDeleteStatus({ status: -1, message: langauges[langauge].err_req }, 3500)
            }
        })
    }

    return (
        <div>
            <i onClick={() => {
                loadedView("RolesList")
            }} className="fa fa-arrow-left hoverable" style={{ cursor: 'pointer' }} ><span style={{ paddingLeft: '10px' }}>{langauges[langauge].delete_role}</span></i>
            <hr />
            <div
                style={{}}
            >
                <h5>Are you sure you want to delete "{role.title}"?</h5>


                <hr />

                {deleteStatus.status === -1 && (<><p style={{ color: '#dc3545', background: 'white', borderRadius: '5px', textAlign: 'center' }}>{deleteStatus.message}</p>
                    <hr /></>)
                }

                <button className="btn btn-danger" disabled={deleting} onClick={() => {
                    deleteRole()
                }} >
                    {langauges[langauge].delete}
                    {deleting && <span style={{ paddingLeft: '10px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                </button>

            </div>

        </div>
    )
}

export default DeleteRole
