import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import C from './../../util/Constants';

const RoomDetails = (props) => {
  const [roomDetails, setRoomDetails] = useState({});
  const [load, setLoad] = useState(2);
  const closeRoomDetails = () => {
    props.closeHandler();
  };

  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
      return spSession;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    setLoad(0);
    var spSession = checkForSession();
    if (spSession === false) {
      setLoad(-1);
      return;
    }

    Axios.get(
      C.API_EP +
        '/a/rooms/details?email=' +
        spSession.email +
        '&token=' +
        spSession.token +
        '&roomId=' +
        props.id
    )
      .then((response) => {
        setRoomDetails(response.data.roomDetails);
        console.log(response);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className='sp-joinroom-wrapper'>
      <div className='sp-joinroom-inner'>
        <div className='card'>
          <div className='card-header'>
            Room Details
            <i
              className='fas fa-times sp-small-icon'
              onClick={closeRoomDetails}
            ></i>
          </div>
          <div className='card-body' style={{ padding: '15px' }}>
            <b>Title:</b> {roomDetails.title}
            {roomDetails.isFeatured && (
              <span class='badge badge-success'>Featured</span>
            )}
            <hr />
            <b>Type:</b> {roomDetails.type}
            <hr />
            <b>Creator:</b>
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              {'@'}
              {typeof roomDetails.creator !== 'undefined' &&
                roomDetails.creator.username}
            </span>
            <hr />
            <b>Member Count:</b> {roomDetails.members}/50
            <hr />
            <b>Desc: </b>
            <br />
            <textarea rows="4" style={{width: '100%'}} value={roomDetails.desc} disabled={true}></textarea>
          </div>
          <div className='card-footer'>
            <button
              className='btn btn-primary btn-sm'
              onClick={closeRoomDetails}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
