import React, { useEffect, Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  sendRoomMessage,
  setMessageText,
  initSendLoadRoom,
  showUserProfileUI,
  loadUsernameInput,
  showUploadFileUi,
  showRoomDetailsUi,
  hideRoomDetailsUi,
  hideRoomSettingsUi,
  showRoomSettingsUi,
  deleteRoomMessage,
  initHomeView,
  loadUsernameInputFromSuggestions,
  setRoomCommands,
  loadInputFromCommands,
  processExtend,
  processClear,
  processBan,
  processUnban,
  loadEmojiBox,
  hideEmojiBox,
  showEmojiBox,
  setEmoji,
  addAdToList,
  hideRoomMembers,
  loadMoreMessages,
  showUploadBox,
  hideUploadBox,
  sendGuestRoomMessage,
  initGuestLoadRoom
} from "../../redux/actions/appActions";
import C from "./../../util/Constants";
import UserProfile from "./../util/UserProfile";
import UploadFile from "./../util/UploadFile";
import RoomDetails from "../util/RoomDetails";
import RoomSettings from "../util/RoomSettings";
import Members from "../util/Members";
import GoogleAds from ".//../util/GoogleAds";

var scrollPosition = 100;

const ConnectedList = ({
  dispatch,
  sidebarHandler,
  memberlistHandler,
  chatboxState,
  privateNotif,
  device,
  rank,
}) => {
  const InputElement = useRef(null);
  const MessageList = useRef(null);
  const CommandSuggestionsBox = useRef(null);
  const UserSuggestionBox = useRef(null);
  const EmojiBox = useRef(null);

  useEffect(() => {
    console.log("Loading details of the chatbox ...");
    console.log(chatboxState);
    console.log("Adjusting heights ...");
    adjustHeight();

    var savedRoom = localStorage.getItem(C.SP_LOADED_ROOM);
    if (savedRoom != null) {
      // dispatch(initSendLoadRoom(savedRoom));
      dispatch(initGuestLoadRoom(savedRoom));

      // dispatch(loadEmojiBox());
    }
    // sidebarHandler();

    window.addEventListener("resize", handleResize);
   
    return () => {
      console.log("******************* UNMOUNTED");

      window.removeEventListener("resize", function (e) {
        handleResize(e, scrollPosition);
      });
      // dispatch(unloadLastThread());
    };
  }, [dispatch]);

  const handleResize = (e, sP) => {
    //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
    adjustHeight();
    //scrollToBottom();
    console.log(MessageList.current);
    if (MessageList.current !== null) {
      var total =
        (MessageList.current.scrollHeight - MessageList.current.offsetHeight) /
        MessageList.current.offsetHeight;

      var t = (scrollPosition / 100) * total;
      var sT = t * MessageList.current.offsetHeight;
      MessageList.current.scrollTop = sT;
    }
  };

  useEffect(() => {
    if (InputElement.current) {
      InputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (InputElement.current) {
      InputElement.current.focus();
    }
  }, [chatboxState.messageInput]);

  useEffect(() => {
    if (CommandSuggestionsBox.current !== null)
      CommandSuggestionsBox.current.scrollTop =
        CommandSuggestionsBox.current.scrollHeight;
  }, [chatboxState.commandSuggestions]);

  useEffect(() => {
    if (UserSuggestionBox.current !== null)
      UserSuggestionBox.current.scrollTop =
        UserSuggestionBox.current.scrollHeight;
  }, [chatboxState.userSuggestions]);

  useEffect(() => {
    setTimeout(function () {
      checkIfScrollable();
    }, 100);
  }, [chatboxState.roomMessages]);

  useEffect(() => {
    console.log("Permissions updated!");
  }, [chatboxState.permissions]);

  useEffect(() => {
    if (InputElement.current) {
      InputElement.current.focus();
    }
    scrollToBottom();
  }, [chatboxState.initRoom]);

  const checkIfScrollable = () => {
    if (MessageList.current === null) {
      return;
    }
    //scroll height
    var sh = MessageList.current.scrollHeight;

    //viewable height
    var vh = MessageList.current.offsetHeight;
    console.log(sh);
    console.log(vh);

    //scrollable area
    var sa = sh - vh;

    //30 % of sa
    var sa30 = sa * (10 / 100);

    var sT = MessageList.current.scrollTop;

    //if scroll top is greater than 75% of viewable area
    console.log(
      sT.toString() +
        " > " +
        sa.toString() +
        " - " +
        sa30.toString() +
        " = " +
        (sa - sa30)
    );
    console.log(sa);
    console.log(sa30);

    if (sT > sa - sa30) scrollToBottom();
  };

  const checkImageScrollable = (e) => {
    if (MessageList.current === null) {
      return;
    }
    //scroll height
    var sh = MessageList.current.scrollHeight;

    //viewable height
    var vh = MessageList.current.offsetHeight;
    console.log("[scroll] ***** Checking Image scrollable *****");

    console.log(sh);
    console.log(vh);

    console.log("[scroll] ***** Image element *****");
    console.log(e.target);
    var imageHeight = e.target.offsetHeight;

    //scrollable area
    var sa = sh - vh;

    //30 % of sa
    var sa30 = sa * (10 / 100);

    var sT = MessageList.current.scrollTop;

    //if scroll top is greater than 75% of viewable area
    console.log(
      sT.toString() +
        " > " +
        sa.toString() +
        " - " +
        sa30.toString() +
        " = " +
        (sa - sa30) +
        " (image height) " +
        imageHeight
    );
    console.log(sT + imageHeight);
    console.log(sT + imageHeight - sa);
    console.log(sa);
    console.log(sa30);

    if (sT > sa - sa30) scrollToBottom();
  };

  const scrollToBottom = () => {
    if (MessageList.current) {
      MessageList.current.scrollTop = MessageList.current.scrollHeight;
      if (typeof MessageList.current !== "undefined") {
        // calc position
        var total =
          (MessageList.current.scrollHeight -
            MessageList.current.offsetHeight) /
          MessageList.current.offsetHeight;
        var sh =
          MessageList.current.scrollTop / MessageList.current.offsetHeight;
        var pos = (sh / total) * 100;
        scrollPosition = pos;
        console.log("Going all the way down");
        //console.log(scrollToBottom.caller);
        // alert(scrollToBottom.caller);
        console.log(getErrorWithStack());
        console.log(scrollPosition);
      }
    }
  };

  const getErrorWithStack = () => {
    "use strict";
    if (true)
      try {
        // Doing something naughty in strict mode here to trigger a specific error
        // that can be explicitely ignored in debugger's exception settings.
        // If we'd just throw new Error() here, IE's debugger's exception settings
        // will just consider it as "exception thrown by javascript code" which is
        // something you wouldn't want it to ignore.
        console.log(getErrorWithStack.arguments);
        throw new Error(); // Fallback if above line don't throw.
      } catch (e) {
        return e;
      }
    return new Error();
  };

  //const [messageText, setMessageText] = useState('');

  const sendMessage = () => {
    dispatch(
      sendGuestRoomMessage(
        chatboxState.messageInput.messageText,
        chatboxState.roomId
      )
    );
  };

  const checkIsCommand = (msg) => {
    console.log(" *** DETECTING IS COMMAND ***");
    console.log(msg);
    var a = msg.trim().match(/^\/[a-z0.9]+\s?(@[a-z0-9]+)?\s?([a-z0-9]+)?$/);
    console.log(a);
    return a;
  };

  const toggleEmojiBox = () => {
    if (
      typeof chatboxState.emojis !== "undefined" &&
      chatboxState.emojis.show
    ) {
      dispatch(hideEmojiBox());
    } else {
      dispatch(showEmojiBox());
    }
  };

  const loadEmoji = (emoji) => {
    var sS = InputElement.current.selectionStart;
    dispatch(setEmoji(emoji, sS));
    InputElement.current.selectionStart = 0;
  };

  const processDate = (date) => {
    var datePosted = Date.parse(date);
    var dateNow = Date.now();
    var seconds = (dateNow - datePosted) / 1000;

    if (seconds < 60) {
      return "Just Now";
    }

    var minutes = seconds / 60;

    if (minutes < 60) {
      return Math.floor(minutes) + " minute(s)";
    }

    var hours = minutes / 60;

    if (hours < 24) {
      return Math.floor(hours) + " hour(s)";
    }

    var days = hours / 24;

    if (days < 30) {
      return Math.floor(days) + " day(s)";
    }
  };

  const processDateTS = (date) => {
    var datePosted = new Date(date);
    return (
      datePosted.getDate() +
      "/" +
      datePosted.getMonth() +
      "  " +
      datePosted.getHours() +
      ":" +
      datePosted.getMinutes()
    );
  };

  const showUserProfile = (id) => {
    dispatch(showUserProfileUI(id));
  };

  const _showUploadFileUi = () => {
    dispatch(showUploadFileUi());
  };

  const closeRoomDetails = () => {
    dispatch(hideRoomDetailsUi());
  };

  const closeRoomSettings = () => {
    dispatch(hideRoomSettingsUi());
  };

  const closeRoomMembers = () => {
    dispatch(hideRoomMembers());
  };

  const loadUsername = (username) => {
    var sS = InputElement.current.selectionStart;
    dispatch(loadUsernameInput(username, sS));
  };

  const loadUsernameSuggestions = (username) => {
    var sS = InputElement.current.selectionStart;
    dispatch(loadUsernameInputFromSuggestions(username, sS));
  };

  const loadCommandSuggestions = (c) => {
    var sS = InputElement.current.selectionStart;
    dispatch(loadInputFromCommands(c, sS));
  };

  const getUsername = (data) => {
    var username = /[[[[a-zA-Z0-9]+,[a-zA-Z0-9]+]]]/i.exec(data)[0];
    return username.substring(3, username.indexOf("]]]")).split(",")[0];
  };

  const getUserId = (data) => {
    var username = /[[[[a-zA-Z0-9]+,[a-zA-Z0-9]+]]]/i.exec(data)[0];
    return username.substring(3, username.indexOf("]]]")).split(",")[1];
  };

  const getBefore = (data) => {
    var username = /[[[[a-zA-Z0-9]+,[a-zA-Z0-9]+]]]/i.exec(data)[0];
    return data.split(username)[0];
  };

  const getAfter = (data) => {
    var username = /[[[[a-zA-Z0-9]+,[a-zA-Z0-9]+]]]/i.exec(data)[0];
    return data.split(username)[1];
  };

  const openRoomSettingsUi = (roomId) => {
    dispatch(showRoomSettingsUi(roomId));
  };

  const deleteMessage = (messageId) => {
    dispatch(deleteRoomMessage(messageId, chatboxState.roomId));
  };

  const msgListScroll = (e) => {
    //scroll Height
    var sH = e.target.scrollHeight;

    //scroll top
    var sT = e.target.scrollTop;

    //calc position
    var total =
      (e.target.scrollHeight - e.target.offsetHeight) / e.target.offsetHeight;
    var sh = e.target.scrollTop / e.target.offsetHeight;
    var pos = (sh / total) * 100;
    scrollPosition = pos;
    console.log(scrollPosition);

    if (sT === 0) {
      console.log(chatboxState.canLoadMore);
      if (
        !chatboxState.loadingMore &&
        typeof chatboxState.canLoadMore !== "undefined" &&
        chatboxState.canLoadMore &&
        chatboxState.roomMessages.length !== 0
      )
        dispatch(
          loadMoreMessages(
            chatboxState.roomId,
            chatboxState.roomMessages[0]._id,
            e.target
          )
        );
    }
  };

  const adjustHeight = () => {
    var winHeight = window.innerHeight;
    var spChatbox = document.getElementsByClassName("sp-chatbox")[0];
    if (typeof spChatbox === "undefined") {
      return;
    }
    var spChatboxCard = spChatbox.getElementsByClassName("card")[0];

    spChatbox.style.height = winHeight + "px";
    spChatboxCard.style.height = winHeight + "px";
    var spChatBoxCardHeader = spChatboxCard.getElementsByClassName(
      "card-header"
    )[0];
    var spChatBoxCardBody = spChatboxCard.getElementsByClassName(
      "card-body"
    )[0];
    var spChatBoxCardBodyList = spChatBoxCardBody.getElementsByTagName("ul")[0];
    var spChatBoxCardFooter = spChatboxCard.getElementsByClassName(
      "card-footer"
    )[0];
    var heightToBeSet =
      winHeight -
      (spChatBoxCardHeader.offsetHeight + spChatBoxCardFooter.offsetHeight);
    console.log(heightToBeSet);
    spChatBoxCardBody.style.height = heightToBeSet + "px";
    spChatBoxCardBodyList.style.maxHeight = heightToBeSet + "px";
    //- (heightToBeSet * 8) / 100
  };

  return (
    chatboxState.roomType !== 'adult' ?     <Fragment>
    <div style={{ height: "" }} className="card">
      <div className="card-header" style={{ padding: 0 }}>
        <ul
          class="sp-chatbox-header"
          style={{
            padding: "0 20px",
            margin: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <li class="sp-chatbox-header-left" style={{ marginRight: "10px" }}>
            <span
              className="fas fa-chevron-left"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(initHomeView({ loadedView: "sidebar" }));
              }}
            >
              {chatboxState.privateNotif && device === 2 && (
                <i
                  style={{
                    height: "10px",
                    width: "10px",
                    backgroundColor: "red",
                    position: "absolute",
                    borderRadius: "50%",
                    boxShadow: "red 0px 0px 7px 1px",
                    left: "15px",
                    top: "17px",
                  }}
                ></i>
              )}
            </span>
          </li>
          <li
            class="sp-chatbox-header-middle"
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
            }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(showRoomDetailsUi(chatboxState.roomId));
              }}
            >
              {chatboxState.roomTitle}
            </span>
            <span
              style={{
                fontSize: "15px",
                color: "#777",
                fontWeight: "normal",
              }}
            >
              {chatboxState.roomDesc}
            </span>
          </li>
          <li class="sp-chatbox-header-right" style={{ display: "flex" }}>
            {chatboxState.iOwn && (
              <span
                className="fas fa-cog"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  openRoomSettingsUi(chatboxState.roomId);
                }}
              ></span>
            )}
            <span
              className="fas fa-users"
              onClick={memberlistHandler}
              style={{ margin: "0 8px", cursor: "pointer"}}
            ></span>
          </li>
        </ul>
      </div>
      <div
        className="card-body"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",

          height: "100%",
          display: "flex",
          flexDirection: "column-reverse",
          backgroundImage:
            typeof chatboxState.background === "undefined"
              ? ""
              : chatboxState.background.trim() === ""
              ? "url(/resources/bg/default_bg.svg)"
              : "url(" + chatboxState.background + ")",
        }}
      >
        {chatboxState.roomMessagesLoading && (
          <div class="progress">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              style={{ width: "100%" }}
            ></div>
          </div>
        )}
        <ul
          class="list-group"
          style={{
            maxHeight: "",
            overflow: "auto",
            maxWidth: "100%",
            overflowX: "hidden",
          }}
          ref={MessageList}
          onScroll={(e) => {
            msgListScroll(e);
          }}
        >
          {chatboxState.loadingMore && (
            <li style={{ textAlign: "center", marginTop: "30px" }}>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </li>
          )}
          {typeof chatboxState.roomMessages !== "undefined" &&
            (typeof chatboxState.permissions !== "undefined"
              ? chatboxState.permissions.Read
              : true) &&
            chatboxState.roomMessages.map((msgItem, i) => {
              //let rand = Math.ceil(Math.random() * 150);

              if (
                typeof msgItem.isAd !== "undefined" &&
                msgItem.isAd &&
                rank == 0
              ) {
                return (
                  <li key={msgItem._id}>
                    <GoogleAds />
                  </li>
                );
              }

              if (!msgItem.isNotif) {
                if (chatboxState.ignoreList.includes(msgItem.member.user._id))
                  return (
                    <li className="alert alert-danger sc-alert-danger">
                      <div style={{ textAlign: "center" }}>
                        This Message Was Ignored!
                      </div>
                    </li>
                  );
                else if (localStorage.getItem("old_layout") !== null) {
                  return (
                    <li
                      className={
                        typeof msgItem.singleLoaded !== "undefined" &&
                        msgItem.singleLoaded
                          ? localStorage.getItem("sp_user_id") === null ||
                            localStorage.getItem("sp_user_id") !==
                              msgItem.user._id
                            ? "list-group-item animated sp-message-list-item fadeInLeft"
                            : "list-group-item animated sp-message-list-item fadeInRight"
                          : "list-group-item"
                      }
                      style={{
                        border: "none",
                        padding: "0.3rem 1.25rem",
                        background: "none",
                      }}
                      key={msgItem._id}
                      id={"rm" + msgItem._id}
                    >
                      <div
                        className={
                          localStorage.getItem("sp_user_id") === null ||
                          localStorage.getItem("sp_user_id") !==
                            msgItem.user._id
                            ? "sp-message"
                            : "sp-message-my"
                        }
                      >
                        {localStorage.getItem("sp_user_id") === null ||
                          (localStorage.getItem("sp_user_id") !==
                            msgItem.user._id && (
                            <div className="sp-message-avatar">
                              <img
                                src={
                                  typeof msgItem.member.user.profile !==
                                    "undefined" &&
                                  C.ASSET_EP +
                                    "/avatars/" +
                                    msgItem.member.user.profile.picture.split(
                                      "."
                                    )[0] +
                                    "_sm." +
                                    msgItem.member.user.profile.picture.split(".")[1]
                                }
                                className="rounded-circle sp-message-usericon"
                                style={{
                                  width: "38px",
                                  height: "38px",
                                  float: "left",
                                }}
                                alt="user-icon"
                                onClick={() => {
                                  showUserProfile(msgItem.user._id);
                                }}
                              />
                            </div>
                          ))}

                        <div className="sp-message-inner">
                          {localStorage.getItem("sp_user_id") === null ||
                            (localStorage.getItem("sp_user_id") !==
                              msgItem.user._id && (
                              <div
                                className="sp-message-username"
                                style={{
                                  cursor: "pointer",
                                  color: msgItem.member.role.color,
                                }}
                                onClick={() => {
                                  loadUsername(msgItem.user.username);
                                }}
                              >
                                {typeof msgItem.isCreator !== "undefined" && (
                                  <span
                                    className="fas fa-crown"
                                    style={{ color: "#e6ca32" }}
                                  ></span>
                                )}
                                {typeof msgItem.user.profile.fullname !==
                                  "undefined" &&
                                msgItem.user.profile.fullname.trim() !== ""
                                  ? msgItem.user.profile.fullname
                                  : msgItem.user.username}
                              </div>
                            ))}

                          {msgItem.message.type === "text" && (
                            <div
                              className="sp-message-content"
                              style={{
                                background:
                                  typeof msgItem.user.settings !== "undefined"
                                    ? msgItem.user.settings.color
                                    : "#6e866e",
                              }}
                            >
                              {/[[[[a-zA-Z0-9]+,[a-zA-Z0-9]+]]]/i.test(
                                msgItem.message.content
                              ) &&
                                getUserId(msgItem.message.content) ===
                                  localStorage.getItem("sp_user_id") &&
                                typeof msgItem.singleLoaded !== "undefined" &&
                                msgItem.singleLoaded &&
                                typeof chatboxState.sounds !== "undefined" &&
                                chatboxState.sounds === 1 && (
                                  <audio autoPlay>
                                    <source
                                      src="/resources/sounds/tagged.mp3"
                                      type="audio/mp3"
                                    />
                                  </audio>
                                )}

                              {/[[[[a-zA-Z0-9]+,[a-zA-Z0-9]+]]]/i.test(
                                msgItem.message.content
                              ) ? (
                                <span>
                                  {getBefore(msgItem.message.content)}
                                  <span
                                    onClick={() => {
                                      showUserProfile(
                                        getUserId(msgItem.message.content)
                                      );
                                    }}
                                    className={
                                      getUserId(msgItem.message.content) ===
                                      localStorage.getItem("sp_user_id")
                                        ? "sp-message-tagged"
                                        : "sp-message-mentioned"
                                    }
                                  >
                                    @{getUsername(msgItem.message.content)}
                                  </span>
                                  {getAfter(msgItem.message.content)}
                                </span>
                              ) : (
                                msgItem.message.content
                              )}
                            </div>
                          )}

                          {msgItem.message.type === "hybrid" && (
                            <span
                              style={{
                                background:
                                  typeof msgItem.user.settings !== "undefined"
                                    ? msgItem.user.settings.color
                                    : "#6e866e",
                              }}
                              className="sp-message-content"
                            >
                              {JSON.parse(msgItem.message.content).map(
                                (e) => {
                                  //return <h1>this : {JSON.stringify(e)}</h1>;
                                  if (e.type === "text")
                                    return <span>{e.data}</span>;

                                  if (e.type === "mention")
                                    return (
                                      <span
                                        onClick={() => {
                                          showUserProfile(e.extras._id);
                                        }}
                                        className={
                                          e.extras._id ===
                                          localStorage.getItem("sp_user_id")
                                            ? "sp-message-tagged"
                                            : "sp-message-mentioned"
                                        }
                                      >
                                        @{e.data}
                                      </span>
                                    );
                                }
                              )}
                            </span>
                          )}

                          {msgItem.message.type === "image" && (
                            <div
                              style={{
                                textAlign:
                                  localStorage.getItem("sp_user_id") ===
                                    null ||
                                  localStorage.getItem("sp_user_id") !==
                                    msgItem.user._id
                                    ? "inherit"
                                    : "right",
                              }}
                            >
                              <a
                                href={
                                  C.API_EP +
                                  "/uploads/images/" +
                                  msgItem.message.content
                                }
                                target="_blank"
                              >
                                <img
                                  className="sp-message-content-image"
                                  src={
                                    C.API_EP +
                                    "/uploads/images/" +
                                    msgItem.message.content
                                  }
                                  alt="user upload"
                                  onLoad={
                                    (typeof msgItem.singleLoaded !==
                                      "undefined" &&
                                      msgItem.singleLoaded) ||
                                    (typeof msgItem.dynamicLoaded !==
                                      "undefined" &&
                                      msgItem.dynamicLoaded)
                                      ? (e) => {
                                          checkImageScrollable(e);
                                        }
                                      : scrollToBottom
                                  }
                                  onError={checkIfScrollable}
                                />
                              </a>
                            </div>
                          )}
                          {chatboxState.iOwn ||
                          (typeof chatboxState.permissions !== "undefined" &&
                            chatboxState.permissions.Delete) ? (
                            <i
                              className="fas fa-times sp-message-timestamp"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                deleteMessage(msgItem._id);
                              }}
                            ></i>
                          ) : (
                            <div className="sp-message-timestamp">
                              {processDate(msgItem.date)}
                            </div>
                          )}

                          {chatboxState.iOwn && (
                            <div className="sp-message-timestamp">
                              {processDate(msgItem.date)}
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li
                      className="list-group-item"
                      style={{
                        border: "none",
                        padding: "0 10px",
                        background: "white",
                        boxShadow: "rgb(0, 0, 0) 1px 1px 8px -11px",
                        paddingBottom:
                          i === chatboxState.roomMessages.length - 1
                            ? "10px"
                            : "0",
                      }}
                      key={msgItem._id}
                      id={"rm" + msgItem._id}
                    >
                      <div
                        className="sp-message"
                        style={{
                          alignItems:
                            msgItem.message.type === "hybrid" ? "center" : "",
                        }}
                      >
                        <div
                          style={{
                            background: "#ffffff",
                            display: "flex",
                            alignSelf: "start",
                            margin: "2px 0",
                          }}
                        >
                          <div className="sp-message-avatar">
                            <img
                              src={
                                typeof msgItem.member.user.profile !== "undefined" &&
                                C.ASSET_EP +
                                  "/avatars/" +
                                  msgItem.member.user.profile.picture.split(".")[0] +
                                  "_sm." +
                                  msgItem.member.user.profile.picture.split(".")[1]
                              }
                              className="rounded-circle sp-message-usericon"
                              style={{
                                width: "30px",
                                height: "30px",
                                float: "left",
                              }}
                              alt="user-icon"
                              onClick={() => {
                                showUserProfile(msgItem.member.user._id);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="sp-message-inner-lgcy"
                          style={{
                            flexGrow: 1,
                            background: "",
                            maxWidth: "100%",
                            textAlign: "justify",
                            margin: "2px 0",
                          }}
                        >
                          <div
                            className="sp-message-username"
                            style={{
                              cursor: "pointer",
                              color: typeof msgItem.member.role !== 'undefined' ? msgItem.member.role.color: msgItem.isCreator ? 'red' : '',
                              fontSize: "16px",
                              alignSelf: "center",
                              wordBreak: "keep-all",
                              whiteSpace: "nowrap",
                              display: "inline",
                            }}
                            onClick={() => {
                              loadUsername(msgItem.member.user.username);
                            }}
                          >
                            {msgItem.member.user.username}
                          </div>

                          <span style={{ margin: "3px", fontWeight: "bold" }}>
                            :
                          </span>

                          {msgItem.message.type === "text" && (
                            <div
                              className="sp-message-content"
                              style={{
                                background:
                                  typeof msgItem.user.settings !== "undefined"
                                    ? msgItem.user.settings.color
                                    : "#6e866e",
                                fontSize: "15px",
                              }}
                            >
                              {/[[[[a-zA-Z0-9]+,[a-zA-Z0-9]+]]]/i.test(
                                msgItem.message.content
                              ) &&
                                getUserId(msgItem.message.content) ===
                                  localStorage.getItem("sp_user_id") &&
                                typeof msgItem.singleLoaded !== "undefined" &&
                                msgItem.singleLoaded &&
                                typeof chatboxState.sounds !== "undefined" &&
                                chatboxState.sounds === 1 && (
                                  <audio autoPlay>
                                    <source
                                      src="/resources/sounds/tagged.mp3"
                                      type="audio/mp3"
                                    />
                                  </audio>
                                )}

                              {/[[[[a-zA-Z0-9]+,[a-zA-Z0-9]+]]]/i.test(
                                msgItem.message.content
                              ) ? (
                                <span>
                                  {getBefore(msgItem.message.content)}
                                  <span
                                    onClick={() => {
                                      showUserProfile(
                                        getUserId(msgItem.message.content)
                                      );
                                    }}
                                    className={
                                      getUserId(msgItem.message.content) ===
                                      localStorage.getItem("sp_user_id")
                                        ? "sp-message-tagged"
                                        : "sp-message-mentioned"
                                    }
                                  >
                                    @{getUsername(msgItem.message.content)}
                                  </span>
                                  {getAfter(msgItem.message.content)}
                                </span>
                              ) : (
                                msgItem.message.content
                              )}
                            </div>
                          )}

                          {msgItem.message.type === "hybrid" && (
                            <div style={{ display: "inline" }}>
                              <span
                                style={{
                                  background:
                                    typeof msgItem.member.user.settings !==
                                    "undefined"
                                      ? msgItem.member.user.settings.color
                                      : "",
                                  color:
                                    typeof msgItem.member.user.settings !==
                                    "undefined"
                                      ? msgItem.member.user.settings.tcolor
                                      : "",
                                  padding: 0,
                                  borderRadius: 0,
                                  fontFamily: "sans-serif",
                                  fontSize: "15px",
                                  padding: "4px",
                                  wordBreak: "break-word",
                                  borderRadius: "5px",
                                  letterSpacing: "-0.4px",
                                }}
                                className="sp-message-content"
                              >
                                {JSON.parse(msgItem.message.content).map(
                                  (e) => {
                                    //return <h1>this : {JSON.stringify(e)}</h1>;
                                    if (e.type === "text")
                                      return <span>{e.data}</span>;

                                    if (e.type === "mention")
                                      return (
                                        <span
                                          onClick={() => {
                                            showUserProfile(e.extras._id);
                                          }}
                                          className={
                                            e.extras._id ===
                                            localStorage.getItem("sp_user_id")
                                              ? "sp-message-tagged"
                                              : "sp-message-mentioned"
                                          }
                                        >
                                          @{e.data}
                                        </span>
                                      );
                                  }
                                )}
                              </span>
                            </div>
                          )}

                          {msgItem.message.type === "image" && (
                            <span style={{ display: "block" }}>
                              <img
                                src={
                                  C.API_EP +
                                  "/uploads/images/" +
                                  msgItem.message.content
                                }
                                style={{
                                  maxWidth: device === 2 ? "80vw" : "300px",
                                  maxHeight: "40vh",
                                }}
                                alt="User upload"
                              />
                              <div style={{ display: "none" }}>
                                <a
                                  href={
                                    C.API_EP +
                                    "/uploads/images/" +
                                    msgItem.message.content
                                  }
                                  target="_blank"
                                >
                                  <img
                                    className="sp-message-content-image"
                                    src={
                                      C.API_EP +
                                      "/uploads/images/" +
                                      msgItem.message.content
                                    }
                                    alt="user upload"
                                    onLoad={
                                      (typeof msgItem.singleLoaded !==
                                        "undefined" &&
                                        msgItem.singleLoaded) ||
                                      (typeof msgItem.dynamicLoaded !==
                                        "undefined" &&
                                        msgItem.dynamicLoaded)
                                        ? (e) => {
                                            checkImageScrollable(e);
                                          }
                                        : scrollToBottom
                                    }
                                    onError={checkIfScrollable}
                                  />
                                </a>
                              </div>
                            </span>
                          )}
                        </div>

                        <div
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {(chatboxState.iOwn ||
                            (typeof chatboxState.permissions !==
                              "undefined" &&
                              chatboxState.permissions.Delete)) && (
                            <i
                              className="fas fa-times sp-message-timestamp"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                deleteMessage(msgItem._id);
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                      <div
                        className="sp-message-timestamp"
                        style={{
                          position: "absolute",
                          top: "-6px",
                          right: "8px",
                        }}
                      >
                        {processDateTS(msgItem.date)}
                      </div>
                    </li>
                  );
                }
              } else {
                if (msgItem.message.type === "text") {
                  if (localStorage.getItem("old_layout") !== null) {
                    return (
                      <li>
                        <div className="sc-notification">
                          <span>{msgItem.message.content}</span>
                        </div>
                      </li>
                    );
                  } else {
                    return (
                      <li style={{ background: "#fff" }}>
                        <div className="sc-notification">
                          <span>{msgItem.message.content}</span>
                        </div>
                      </li>
                    );
                  }
                } else {
                  var messageObj = JSON.parse(msgItem.message.content);
                  return (
                    <li className="list-group-item" style= {{border: 'none', padding: '0px 10px', background: 'white', boxShadow: 'rgb(0, 0, 0) 1px 1px 8px -11px;'}} >
                         
                      <div
                        style={{
                          background: "rgb(255,255,255)",
                          dispatch: "flex",
                          alignSelf: "start",
                          margin: "2px 0",
                        }}
                        className="sp-message"
                      >
                        <div className='sp-message-avatar'>
                              <img
                                src={
                                 
                                  C.ASSET_EP +
                                    '/avatars/default_sm.png' 
                                   
                                }
                                className='rounded-circle sp-message-usericon'
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  float: 'left',
                                }}
                                alt='user-icon'
                                onClick={() => {
                                  showUserProfile(msgItem.user._id);
                                }}
                              />
                            </div>
                        <div style={{background: 'rgb(255,255,255)' , display: 'none', alignSelf: 'start', margin: '2px 0px'}} >
                        <div class="sp-message-avatar" >
                          <img
                            src={C.ASSET_EP + "/avatars/default_sm.png"}
                            class="rounded-circle sp-message-usericon"
                            alt="user-icon"
                            style={{
                              width: "30px",
                              height: "30px",
                              float: "left",
                            }}
                          />
                        </div>
                      </div>
                      <div className="sp-message-inner-lgcy" style={{flexGrow:1, maxWidth: '100%', textAlign:'justify', margin: '2px 0px'}} >
                        <div className="sp-message-username" style={{color: 'grey', fontSize: '16px', alignSelf: 'center', wordBreak: 'keep-all', whiteSpace: 'nowrap', display: 'inline'}} > {messageObj.username}</div>
                        <span style= {{margin:'3px', fontWeight: 'bold'}} >:</span>
                        <div style={{display: 'inline'}}>
                          <span className="sp-message-content" style={{background: 'white', color: 'black'}}>
                          {messageObj.message}
                          </span>
                        </div>
                      </div>
                      </div>
                    </li>
                  );
                }
              }
            })}

          {typeof chatboxState.permissions !== "undefined" &&
            !chatboxState.permissions.Read && (
              <div
                className="alert alert-danger sc-alert-danger"
                style={{ textAlign: "center" }}
              >
                You don't have permissions to read messages in this room.
              </div>
            )}
        </ul>
      </div>

      {typeof chatboxState.userSuggestions !== "undefined" &&
        chatboxState.userSuggestions.data.length !== 0 && (
          <ul ref={UserSuggestionBox} className="sc-suggesstion-box">
            {chatboxState.userSuggestions.data.map((e) => (
              <li
                key={e._id}
                onClick={() => {
                  loadUsernameSuggestions(e.user.username);
                }}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <img
                    src={C.ASSET_EP + "/avatars/" + e.user.profile.picture}
                    style={{ height: "20px", width: "20px" }}
                    alt="user icon"
                    className="rounded-circle"
                  />
                  <b style={{ color: e.role.color }}>@{e.user.username}</b>
                  <small style={{ color: "#777" }}>[{e.role.title}]</small>
                </div>
              </li>
            ))}
          </ul>
        )}

      {typeof chatboxState.commandSuggestions !== "undefined" &&
        chatboxState.commandSuggestions.show &&
        chatboxState.commandSuggestions.data.length !== 0 && (
          <ul className="sc-suggesstion-box" ref={CommandSuggestionsBox}>
            {chatboxState.commandSuggestions.data.map((e, i) => (
              <li
                key={i}
                onClick={() => {
                  loadCommandSuggestions(e);
                }}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <b>/{e}</b>
                </div>
              </li>
            ))}
          </ul>
        )}

      <div className="card-footer" style={{ padding: "5px 0" }}>
        <div className="input-group sp-input-box">
          {typeof chatboxState.permissions !== "undefined" &&
            chatboxState.permissions.Upload && (
              <div className="input-group-prepend">
                <i
                  className="fas fa-file input-group-text"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    background:
                      typeof chatboxState.uploadBox !== "undefined" &&
                      chatboxState.uploadBox.show
                        ? "rgb(199, 199, 199)"
                        : "",
                  }}
                  onClick={() => {
                    if (
                      typeof chatboxState.uploadBox !== "undefined" &&
                      chatboxState.uploadBox.show
                    )
                      dispatch(hideUploadBox());
                    else dispatch(showUploadBox());
                  }}
                />
              </div>
            )}

          <input
            type="text"
            className="form-control sc-input-text"
            placeholder="Type your message ..."
            maxLength="250"
            autoFocus={
              typeof chatboxState.messageInput !== "undefined" &&
              !chatboxState.messageInput.sendingMessage
            }
            onKeyDown={(e) => {
              
              if (e.keyCode === 13) {
                if(chatboxState.messageInput.messageText.trim() === "")
                {
                  return;
                }
                sendMessage();
              }
              if (e.keyCode === 8) {
                console.log("Checking suggesstions ...");
                if (
                  chatboxState.messageInput.messageText.trim() === "" &&
                  typeof chatboxState.userSuggestions !== "undefined" &&
                  chatboxState.userSuggestions.data.length !== 0
                ) {
                  console.log("Reset");
                  dispatch({ type: "RESET_ROOM_USER_SUGGESTIONS" });
                }
                //dispatch(setMessageText(e.target.value, e.target));
              }
            }}
            ref={InputElement}
            onChange={(e) => {
              dispatch(setMessageText(e.target.value, e.target));
            }}
            disabled={
              (typeof chatboxState.messageInput !== "undefined" &&
                chatboxState.messageInput.sendingMessage) ||
              (typeof chatboxState.permissions !== "undefined" &&
                !chatboxState.permissions.Write)
            }
            style={{}}
            value={
              typeof chatboxState.messageInput !== "undefined"
                ? chatboxState.messageInput.messageText
                : ""
            }
          />
          <div className="input-group-append">
            <button
              className="btn btn-success"
              type="submit"
              onClick={sendMessage}
              disabled={
                (typeof chatboxState.messageInput !== "undefined" &&
                  chatboxState.messageInput.sendingMessage) ||
                (typeof chatboxState.permissions !== "undefined" &&
                  !chatboxState.permissions.Write)
              }
            >
              <span className="fas fa-paper-plane"></span>
            </button>
          </div>
        </div>
      </div>

      {typeof chatboxState.uploadBox !== "undefined" &&
        chatboxState.uploadBox.show && (
          <div className="sc-upload-box">
            <div className="sc-upload-box-item" onClick={_showUploadFileUi}>
              <i className="fas fa-image"></i>
            </div>
            <div className="sc-upload-box-item" disabled={true}>
              <i className="fas fa-video"></i>
            </div>
            <div className="sc-upload-box-item" disabled={true}>
              <i className="fas fa-paperclip"></i>
            </div>
          </div>
        )}

      {typeof chatboxState.emojis !== "undefined" && (
        <div style={{ display: chatboxState.emojis.show ? "block" : "none" }}>
          {typeof chatboxState.emojis !== "undefined" &&
            chatboxState.emojis.list.length !== 0 && (
              <ul ref={EmojiBox} className="sc-emojis-box animated slideInUp">
                {chatboxState.emojis.list.map((e, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      loadEmoji(e);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <b style={{ color: "white" }}>{e}</b>
                  </li>
                ))}
              </ul>
            )}
        </div>
      )}
    </div>

    {typeof chatboxState.userProfile !== "undefined" &&
      chatboxState.userProfile.showUi && (
        <UserProfile id={chatboxState.userProfile.roomId} />
      )}

    {typeof chatboxState.uploadFile !== "undefined" &&
      chatboxState.uploadFile.showUi && (
        <UploadFile
          context="public"
          id={chatboxState.roomId}
          path="/upload/image"
        />
      )}

    {typeof chatboxState.roomDetails !== "undefined" &&
      chatboxState.roomDetails.showUi && (
        <RoomDetails
          id={chatboxState.roomDetails.roomId}
          closeHandler={closeRoomDetails}
        />
      )}

    {typeof chatboxState.roomSettings !== "undefined" &&
      chatboxState.roomSettings.showUi && (
        <RoomSettings
          id={chatboxState.roomSettings.roomId}
          closeHandler={closeRoomSettings}
        />
      )}

    {typeof chatboxState.roomMembers !== "undefined" &&
      chatboxState.roomMembers.showUi && (
        <Members
          id={chatboxState.roomMembers.roomId}
          closeHandler={closeRoomMembers}
        />
      )}
  </Fragment>
: 
  <Fragment>
  

    <div style={{ height: "" }} className="card">
      <div className="card-header" style={{ padding: 0 }}>
        <ul
          class="sp-chatbox-header"
          style={{
            padding: "0 20px",
            margin: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <li class="sp-chatbox-header-left" style={{ marginRight: "10px" }}>
            <span
              className="fas fa-chevron-left"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(initHomeView({ loadedView: "sidebar" }));
              }}
            >
              {chatboxState.privateNotif && device === 2 && (
                <i
                  style={{
                    height: "10px",
                    width: "10px",
                    backgroundColor: "red",
                    position: "absolute",
                    borderRadius: "50%",
                    boxShadow: "red 0px 0px 7px 1px",
                    left: "15px",
                    top: "17px",
                  }}
                ></i>
              )}
            </span>
          </li>
          <li
            class="sp-chatbox-header-middle"
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
            }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(showRoomDetailsUi(chatboxState.roomId));
              }}
            >
              {chatboxState.roomTitle}
            </span>
            <span
              style={{
                fontSize: "15px",
                color: "#777",
                fontWeight: "normal",
              }}
            >
              {chatboxState.roomDesc}
            </span>
          </li>
          <li class="sp-chatbox-header-right" style={{ display: "flex" }}>
            {chatboxState.iOwn && (
              <span
                className="fas fa-cog"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  openRoomSettingsUi(chatboxState.roomId);
                }}
              ></span>
            )}
            <span
              className="fas fa-users"
              onClick={memberlistHandler}
              style={{ margin: "0 8px", cursor: "pointer", display:'none' }}
            ></span>
          </li>
        </ul>
      </div>
      <div
        className="card-body"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",

          height: "100%",
          display: "flex",
          flexDirection: "column-reverse",
          backgroundImage:
            typeof chatboxState.background === "undefined"
              ? ""
              : chatboxState.background.trim() === ""
              ? "url(/resources/bg/default_bg.svg)"
              : "url(" + chatboxState.background + ")",
        }}
      >
        <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center'}}><div style={{width:'100%', textAlign: 'center'}}><h3>Access Restricted!</h3><p>Create an account with Sparklechat to view contents of this room.</p></div></div>

      </div>



      <div className="card-footer" style={{ padding: "5px 0", pointerEvents: 'none' }}>
        <div className="input-group sp-input-box">
          {typeof chatboxState.permissions !== "undefined" &&
            chatboxState.permissions.Upload && (
              <div className="input-group-prepend">
                <i
                  className="fas fa-file input-group-text"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    background:
                      typeof chatboxState.uploadBox !== "undefined" &&
                      chatboxState.uploadBox.show
                        ? "rgb(199, 199, 199)"
                        : "",
                  }}
                  onClick={() => {
                    if (
                      typeof chatboxState.uploadBox !== "undefined" &&
                      chatboxState.uploadBox.show
                    )
                      dispatch(hideUploadBox());
                    else dispatch(showUploadBox());
                  }}
                />
              </div>
            )}

          <input
            type="text"
            className="form-control sc-input-text"
            placeholder="Access Restricted"
            maxLength="250"
            autoFocus={
              typeof chatboxState.messageInput !== "undefined" &&
              !chatboxState.messageInput.sendingMessage
            }
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                sendMessage();
              }
              if (e.keyCode === 8) {
                console.log("Checking suggesstions ...");
                if (
                  chatboxState.messageInput.messageText.trim() === "" &&
                  typeof chatboxState.userSuggestions !== "undefined" &&
                  chatboxState.userSuggestions.data.length !== 0
                ) {
                  console.log("Reset");
                  dispatch({ type: "RESET_ROOM_USER_SUGGESTIONS" });
                }
                //dispatch(setMessageText(e.target.value, e.target));
              }
            }}
            ref={InputElement}
            onChange={(e) => {
              dispatch(setMessageText(e.target.value, e.target));
            }}
            disabled="true"
            style={{}}
            value={
              typeof chatboxState.messageInput !== "undefined"
                ? chatboxState.messageInput.messageText
                : ""
            }
          />
          <div className="input-group-append">
            <button
              className="btn btn-success"
              type="submit"
              onClick={sendMessage}
              disabled={
              true
              }
            >
              <span className="fas fa-paper-plane"></span>
            </button>
          </div>
        </div>
      </div>

    

    
    </div>


  </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    chatboxState: state.appState.chatbox,
    privateNotif: state.appState.sidebar.privateNotif,
    device: state.appState.spGlobal.layout.device,
    rank: state.appState.sidebar.rank,
  };
};

const Chatbox = connect(mapStateToProps)(ConnectedList);

export default Chatbox;
