import Axios from 'axios';
import React from 'react'
import ThreadItem from '../home/ThreadItem';
import ReplyThread from '../ReplyThread';
import C from './../../util/Constants';
import ThreadDislikes from './ThreadDislikes';
import ThreadLikes from './ThreadLikes';
import langauges from '../../languages';
import { connect } from 'react-redux';
import { clearReplies, loadMoreRepliesSuccess, loadReplies, loadRepliesSuccess, pinReplyToThread, pinReplyTothread, unpinReplyFromThread } from '../../redux/actions/appActions';
import { Fragment } from 'react';
import ThreadGallery from './ThreadGallery';
import ReplyItem from './ReplyItem';


function _ThreadView({ dispatch, replies, thread, permissions, closeHandle, roomId, parent, profileHandler }) {

    var langauge = 'english';

    const [videoState, setVideoState] = React.useState(false);
    const [showLikes, setShowLikes] = React.useState(false);
    const [showDislikes, setShowDislikes] = React.useState(false);
    // const [replies, setReplies] = React.useState([]);
    const Video = React.useRef(null);
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState({ status: 0, msg: '' })
    const [showThreadView, setShowThreadView] = React.useState(false);
    const [threadLoaded, setThreadLoaded] = React.useState({})
    const _thread = React.useRef(null);
    const _replyBox = React.useRef(null);
    const _replies = React.useRef(null);
    const _pinnedReplies = React.useRef(null);

    const _padder = React.useRef(null);
    const [loadingMore, setLoadingMore] = React.useState(false);

    const [showThreadPin, setShowThreadPin] = React.useState(false);
    const [pining, setPining] = React.useState(false);
    const [threadPinId, setThreadPinId] = React.useState(0);
    const [pinError, setPinError] = React.useState({ status: false, msg: '' })

    const [showThreadUnpin, setShowThreadUnpin] = React.useState(false);
    const [unpining, setUnpining] = React.useState(false);
    const [threadUnpinId, setThreadUnpinId] = React.useState(0);
    const [unpinError, setUnpinError] = React.useState({ status: false, msg: '' });

    const [threadDeleteId, setThreadDeleteId] = React.useState(0);
    const [deleting, setDeleting] = React.useState(false);
    const [deleteError, setDeleteError] = React.useState({ status: false, msg: '' });
    const [showThreadDelete, setShowThreadDelete] = React.useState(false);


    const [showThreadReport, setShowThreadReport] = React.useState(false);
    const [threadReportId, setThreadReportId] = React.useState(0);
    const [reportData, setReportData] = React.useState({ reason: 1, details: '' })
    const [reporting, setReporting] = React.useState(false)
    const [reportError, setReportError] = React.useState({ status: false, msg: '' });
    const [reported, setReported] = React.useState(false)

    const _setShowThreadReport = (tid) => {
        setShowThreadReport(true);
        setThreadReportId(tid);
    }

    const _setDeleteError = (dE, delay) => {
        setDeleteError(dE);
        setTimeout(() => {
            setDeleteError({ status: false, msg: '' })
        }, delay);
    }

    const _setShowThreadDelete = (tid) => {
        setShowThreadDelete(true);
        setThreadDeleteId(tid);
    }

    const _setReportError = (dE, delay) => {
        setReportError(dE);
        setTimeout(() => {
            setReportError({ status: false, msg: '' })
        }, delay);
    }

    const _setUnpinError = (dE, delay) => {
        setUnpinError(dE);
        setTimeout(() => {
            setUnpinError({ status: false, msg: '' })
        }, delay);
    }

    const _setShowThreadUnpin = (tid) => {
        setShowThreadUnpin(true);
        setThreadUnpinId(tid);
    }

    const unpinThread = (tid) => {
        setUnpining(true);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }

        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios
            .post(C.API_EP + '/a/r/reply/unpin', {
                email: spSession.email,
                token: spSession.token,
                threadId: thread._id,
                replyId: tid,
                roomId: roomId
            })
            .then((res) => {
                setUnpining(false)
                // setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                console.log(data);
                //setShowThreadDelete(false);
                setShowThreadUnpin(false);
                dispatch(unpinReplyFromThread(tid, thread._id, data))
                //dispatch(changePinnedThread(tid));
                //dispatch(removePinnedThread())
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {

                console.log("Error");

                setUnpining(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        _setUnpinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.status === 404) {
                        _setUnpinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.state === 500) {
                        _setUnpinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                } else if (error.request) {
                    _setUnpinError({ status: true, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    _setUnpinError({ status: true, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }

    const deleteReply = (tid) => {
        setDeleting(true);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }

        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios
            .post(C.API_EP + '/a/r/reply/delete', {
                email: spSession.email,
                token: spSession.token,
                threadId: thread._id,
                roomId: roomId,
                replyId: tid
            })
            .then((res) => {
                setDeleting(false)
                // setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                console.log(data);
                setShowThreadDelete(false);
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {
                setDeleting(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        _setDeleteError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.state === 500) {
                        _setDeleteError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                } else if (error.request) {
                    _setDeleteError({ status: true, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    _setDeleteError({ status: true, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }

    React.useEffect(() => {
        parsedMessage(thread.message)
        console.log(thread)
    }, [])

    const [parsedData, setParsedData] = React.useState([]);

    const parsedMessage = (message) => {
        setParsedData(JSON.parse(message.content));
    }

    const reportThread = (tid) => {
        setReporting(true);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }

        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios
            .post(C.API_EP + '/a/thread/report', {
                email: spSession.email,
                token: spSession.token,
                reason: reportData.reason,
                details: reportData.details,
                id: tid,
            })
            .then((res) => {
                setReporting(false)
                // setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                console.log(data);
                setReported(true);
                setTimeout(function () {
                    setShowThreadReport(false)
                }, 1500)

                //setShowThreadDelete(false);
                //setShowThreadPin(false);
                //dispatch(changePinnedThread(tid));
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {

                console.log("Error");

                setReporting(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        _setReportError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.status === 404) {
                        _setReportError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.state === 500) {
                        _setReportError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                } else if (error.request) {
                    _setReportError({ status: true, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    _setReportError({ status: true, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }


    const getDate = (d) => {
        var oldDate = new Date(d);
        var newDate = new Date();
        var diff = newDate - oldDate;
        var sec = diff / 1000;

        if (sec < 60) {
            return 'Just Now';
        }

        var min = sec / 60;

        if (min < 60) {
            if (Math.round(min) === 1)
                return Math.round(min) + ' minute';
            else
                return Math.round(min) + ' minutes';
        }

        var hours = min / 60;

        if (hours < 24) {
            if (Math.round(hours) === 1)
                return Math.round(hours) + " hour";
            else
                return Math.round(hours) + " hours";

        }

        var days = hours / 24;

        if (days < 7) {
            if (Math.round(days) === 1)
                return Math.round(days) + " day";
            else
                return Math.round(days) + " days";
        }

        var weeks = days / 7;

        if (weeks < 4) {
            if (Math.round(weeks) === 1)
                return Math.round(weeks) + ' week';
            else
                return Math.round(weeks) + ' weeks';
        }

        var months = weeks / 4;

        if (months < 12) {
            if (Math.round(months) === 1)
                return Math.round(months) + ' month';
            else
                return Math.round(months) + ' months';

        }

        var years = months / 12;

        if (years <= 5) {
            if (Math.round(months) === 1)
                return Math.round(years) + ' year';
            else
                return Math.round(years) + ' years';

        }

        return oldDate.getFullYear();
    }

    const checkForSession = () => {
        if (localStorage.getItem(C.SP_SESSION) == null) {
            return false;
        }

        try {
            var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
            return spSession;
        } catch (e) {
            return false;
        }
    };

    const addReply = (reply) => {
        //setReplies([...replies, reply]);
    }

    React.useEffect(() => {

        adjustHeightForParent();

        dispatch(loadReplies(thread))

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }


        langauge = localStorage.getItem(C.LANGUAGE);

        if (langauge === null) {
            langauge = 'english'
        }

        var spSession = checkForSession();
        if (spSession === false) {
            return;
        }

        setLoading(true)

        Axios
            .get(
                C.API_EP +
                '/a/r/getTopReplies?roomId=' +
                roomId +
                '&email=' +
                spSession.email +
                '&token=' +
                spSession.token +
                '&threadId=' + thread._id
            )
            .then((res) => {
                setLoading(false)
                setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                // setReplies(data);
                dispatch(loadRepliesSuccess(data, thread._id, res.data.canLoadMore, res.data.pinnedReplies))
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
                if (parent !== null)
                    adjustHeightForParent();
            })
            .catch(error => {
                setLoading(false)
                console.log(error);
                if (error.response) {
                    if (error.response.status === 400) {
                        setLoad({ status: -1, msg: error.response.data.msg })
                    }
                    if (error.response.status === 404) {
                        setLoad({ status: -1, msg: "Error loading replies." })
                    }
                } else if (error.request) {
                    setLoad({ status: -1, msg: langauges[langauge].server_respond })
                } else {
                    setLoad({ status: -1, msg: langauges[langauge].err_req })
                }
            })


        return function cleanup() {
            dispatch(clearReplies(thread._id));
        };

    }, [])

    const adjustHeightForParent = () => {
        var height = window.screen.height;
        var h1 = _thread.current.offsetHeight;
        var h2 = _replyBox.current !== null ? _replyBox.current.offsetHeight : 0;
        var h3 = _replies.current.offsetHeight;
        var ht = h1 + h2 + h3;
        console.log(ht + " : " + height);
        if (ht < height) {
            _padder.current.style.height = height - ht + "px";
            _thread.current.scrollIntoView();
        }

    }

    const loadMoreReplies = (replyId) => {

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }


        langauge = localStorage.getItem(C.LANGUAGE);

        if (langauge === null) {
            langauge = 'english'
        }


        var spSession = checkForSession();
        if (spSession === false) {
            return;
        }

        setLoadingMore(true);

        console.log("GGGG");

        Axios
            .get(
                C.API_EP +
                '/a/r/getMoreReplies?threadId=' +
                thread._id +
                '&email=' +
                spSession.email +
                '&token=' +
                spSession.token +
                '&roomId=' + roomId +
                '&replyId=' + replies[thread._id].data[replies[thread._id].data.length - 1]._id
            )
            .then((res) => {
                setLoadingMore(false);
                //setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;

                if (data.length !== 0)
                    dispatch(loadMoreRepliesSuccess(data, thread._id, res.data.canLoadMore));

                // if (res.data.pinned !== 0)
                //     setPinned(res.data.pinned);
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {

                console.log(error);

                setLoadingMore(false);
                if (error.response) {
                    if (error.response.status === 400) {
                        setLoad({ status: -1, msg: error.response.data.msg })
                    }
                } else if (error.request) {
                    setLoad({ status: -1, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    setLoad({ status: -1, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }

    const _setShowThreadPin = (tid) => {
        setShowThreadPin(true);
        setThreadPinId(tid);
    }

    const _setPinError = (dE, delay) => {
        setPinError(dE);
        setTimeout(() => {
            setPinError({ status: false, msg: '' })
        }, delay);
    }

    const pinReply = (tid) => {
        setPining(true);

        if (roomId === 0 || typeof roomId === 'undefined') {
            return;
        }

        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios
            .post(C.API_EP + '/a/r/reply/pin', {
                email: spSession.email,
                token: spSession.token,
                threadId: thread._id,
                replyId: tid,
                roomId: roomId
            })
            .then((res) => {
                setPining(false)
                // setLoad({ status: 1, msg: 'Threads load success!' })
                var data = res.data.data;
                console.log(data);
                //setShowThreadDelete(false);
                setShowThreadPin(false);
                dispatch(pinReplyToThread(tid, thread._id, data))
                //dispatch(changePinnedThread(tid));
                // dispatch(loadRoomThreadsSuccess(data));
                // setTimeout(function () {
                //     adjustHeight()
                // }, 100)
            })
            .catch(error => {

                console.log("Error");
                console.log(error);

                setPining(false)
                if (error.response) {
                    if (error.response.status === 400) {
                        _setPinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.status === 404) {
                        _setPinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                    if (error.response.state === 500) {
                        _setPinError({ status: true, msg: error.response.data.msg }, 3500)
                    }
                } else if (error.request) {
                    _setPinError({ status: true, msg: langauges[langauge].server_respond }, 3500)
                } else {
                    _setPinError({ status: true, msg: langauges[langauge].err_req }, 3500)
                }
            })


    }


    React.useEffect(() => { console.log("Changed") }, [replies])

    return (
        <div className="thread-view" style={{ height: '100vh', overflow: 'auto' }}>
            <div className="thread-menu">
                <h4>Thread View</h4>
                <i className="fa fa-times thread-view-close" onClick={() => { closeHandle(false) }} ></i>
            </div>
            {parent !== null && <div
                style={{
                    paddingLeft: '20px',
                    marginLeft: '40px',
                    borderLeft: '2px solid #ae8d8d',
                    pointerEvents: 'none'
                }}
            ><ThreadItem
                    clickHandler={(event) => {
                        // console.log("Expanding thread ...");
                        // setThreadLoaded(e);
                        // setShowThreadView(true);
                    }}
                    permissions={permissions}
                    // deleteHandler={_setShowThreadDelete}
                    thread={parent}
                    roomId={roomId} /></div>}
            <div ref={_thread} style={{ padding: '15px 5px' }}>
                <div className="thread-item-item thread-item-head">
                    <div className="thread-item-item" style={{ alignItems: 'center' }}>
                        <img
                            src={typeof thread.member.user.profile !==
                                'undefined' &&
                                C.ASSET_EP +
                                '/avatars/' +
                                thread.member.user.profile.picture.split(
                                    '.'
                                )[0] +
                                '_sm.' +
                                thread.member.user.profile.picture.split('.')[1]}
                            alt="User Avatar"
                            style={{ borderRadius: '20px', width: '40px', height: '40px', cursor: 'pointer' }}
                            onClick={(e) => { e.stopPropagation(); profileHandler(thread.member.user._id) }}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', alignItems: 'flex-start' }}>
                            <span style={{
                                fontWeight: 'bold',
                                fontSize: 'medium',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    display: 'inline-block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '20ch'
                                }}>{thread.member.user.profile.fullname}</span>
                                <span style={{ color: '#bdbdbd', fontWeight: 'normal' }}>@{thread.member.user.username}</span>
                            </span>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ background: thread.isCreator ? '#e51024' : thread.member.role.color, color: "white", borderRadius: '10px', padding: '1px 4px', fontSize: 'small', fontWeight: 'bold' }}>{thread.isCreator ? 'creator' : thread.member.role.title}</span>
                                <span style={{ padding: '0 8px', fontWeight: 'bold' }}>&#183;</span>
                                <span style={{ fontSize: 'small', color: '#777' }}> {getDate(thread.date)}</span>
                            </span>
                        </div>
                    </div>
                    <div style={{ display: 'none' }}>
                        <i className="fa fa-ellipsis-h"></i>
                    </div>
                </div>
                <div className="thread-view-body" style={{ padding: '5px 10px 5px 20px' }}>
                    {
                        parsedData.map((item, index) => {
                            if (item.type === 'text') {
                                //3px 20px
                                return <span style={{ padding: '', margin: '0', whiteSpace: 'pre-wrap' }} key={index}>{item.data}</span>;
                            }
                            if (item.type === "mention") {
                                return <span onClick={(e) => { e.stopPropagation(); profileHandler(item.extras._id) }} style={{ padding: '', margin: '0 2px', color: 'blue', cursor: 'pointer' }} key={index}>{'@'}<span style={{ textDecoration: 'underline' }}>{item.data}</span></span>;
                            }

                            if (item.type === 'image') {
                                return <img style={{
                                    width: '100%',
                                    display: 'block',
                                    border: '1px solid #eee',
                                    height: '100%',
                                    maxHeight: '40vh',
                                    overflow: 'hidden',
                                    objectFit: 'contain'
                                }}
                                    src={C.API_EP + '/uploads/images/' + item.data}
                                    alt="user upload"
                                    key={index}
                                />
                            }
                            if (item.type === 'video') {
                                return (
                                    <div style={{ position: 'relative' }} key={index}>
                                        <video style={{ width: '100%', display: 'block', border: '1px solid #eee', height: '100%', maxHeight: '40vh', overflow: 'hidden', objectFit: 'contain' }} src={C.API_EP + '/uploads/videos/' + item.data} alt="user upload" controls={videoState} ref={Video} />
                                        {!videoState &&
                                            <div style={{ position: 'absolute', top: '0', left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', background: '#6d6a6ac2' }}>
                                                <i className="fa fa-play" style={{ fontSize: '65px', cursor: 'pointer' }} onClick={() => {
                                                    setVideoState(true);
                                                    Video.current.play();
                                                }}></i>
                                            </div>
                                        }
                                    </div>)
                            }

                            if (item.type === 'gallery') {
                                return <ThreadGallery gallery={item.data} from={"ThreadView"} key={index} />
                            }


                        })
                    }
                    {true
                        &&
                        <div style={{ padding: '5px 10px 5px 10px' }}>
                            {thread.tags.map(e =>
                                <span style={{
                                    fontSize: 'small',
                                    margin: '3px',
                                    border: '1px solid #777',
                                    padding: '1px 5px',
                                    borderRadius: '9px',
                                }}>{e}</span>
                            )}
                        </div>
                    }
                </div>

                <hr style={{ margin: 0 }} />

                {true &&
                    <div className="thread-item-item" disabled={permissions.ReactThread} style={{ justifyContent: 'space-between', padding: '5px 15px', marginTop: '5px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <div onClick={() => { if (thread.likes.length !== 0) setShowLikes(true) }} style={{ margin: '0 8px', cursor: 'pointer' }}>{thread.likes.length !== 0 && thread.likes.length} <i className={permissions.ReactThread ? "fal fa-arrow-alt-up" : "fal fa-arrow-alt-up"} style={{ fontSize: '20px', padding: '10px' }}></i></div>

                            <div onClick={() => { if (thread.dislikes.length !== 0) setShowDislikes(true) }} style={{ margin: '0 8px', cursor: 'pointer' }}>{thread.dislikes.length !== 0 && thread.dislikes.length} <i className={permissions.ReactThread ? "fal fa-arrow-alt-down" : "fal fa-arrow-alt-down "} style={{ fontSize: '20px', padding: '10px' }}></i></div>

                        </div>
                        {!(Array.isArray(thread.views)) && thread.views !== 0
                            &&
                            <div
                                style={{
                                    marginRight: '20px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>{thread.views}
                                <i >&nbsp;
                                    {thread.views === 1 ? 'view' : 'views'}
                                </i>
                            </div>}
                    </div>
                }

                <hr style={{ margin: 0 }} />

            </div>

            {loading &&
                <div style={{ width: '100%', height: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="lds-ripple"><div></div><div></div></div>
                </div>
            }

            {load.status === -1 &&
                <div style={{ textAlign: 'center' }}>
                    <p style={{ color: 'red' }}>{load.msg}</p>
                </div>
            }

            {!loading && !thread.archived &&

                (<div ref={_replyBox} style={{ marginLeft: '30px' }} className="reply-thread-margin"><ReplyThread roomId={roomId} replyTo={thread._id} addReply={addReply} />
                </div>
                )}


            {!loading && thread.archived &&

                (<div ref={_replyBox} style={{ margin: '10px' }} className="reply-thread-margin-not">
                    <p style={{ color: 'red', textAlign: 'center', fontSize: 'medium' }}>This thread is archived and it does not accept new replies.</p>
                </div>
                )}


            {/* {JSON.stringify(replies)}
            <br />
            {JSON.stringify(replies[thread._id])} */}

            <div ref={_pinnedReplies} style={{
                paddingLeft: '20px',
                margin: '30px 0',
                marginLeft: '40px',
                borderLeft: '2px solid #139631'
            }} className="replies-margin">

                {
                    typeof (replies[thread._id]) !== 'undefined' &&
                    typeof (replies[thread._id].pinnedReplies) !== 'undefined' &&
                    replies[thread._id].pinnedReplies.length === 1 && <span
                        style={{
                            color: '#777',
                            fontSize: 'small',
                            marginLeft: '20px',
                            background: '#f5f5f5',
                            padding: '3px',
                            borderRadius: '4px',
                            fontWeight: 'bold'
                        }}
                    >
                        <i className="fas fa-thumbtack" style={{ verticalAlign: 'middle' }}></i>
                        &nbsp;Pinned Reply
                    </span>
                }

                {
                    typeof (replies[thread._id]) !== 'undefined' &&
                    typeof (replies[thread._id].pinnedReplies) !== 'undefined' &&
                    replies[thread._id].pinnedReplies.length > 1 && <span
                        style={{
                            color: '#777',
                            fontSize: 'small',
                            marginLeft: '20px',
                            background: '#f5f5f5',
                            padding: '3px',
                            borderRadius: '4px',
                            fontWeight: 'bold'
                        }}
                    >
                        <i className="fas fa-thumbtack" style={{ verticalAlign: 'middle' }}></i>
                        &nbsp;Pinned Replies
                    </span>
                }

                {
                    typeof (replies[thread._id]) !== 'undefined' &&
                    typeof (replies[thread._id].pinnedReplies) !== 'undefined' &&
                    replies[thread._id].pinnedReplies.map((e, i) => {
                        if (e !== null) {
                            return (
                                <Fragment>
                                    <ReplyItem
                                        clickHandler={(event) => {
                                            console.log("Expanding thread ...");
                                            setThreadLoaded(e);
                                            setShowThreadView(true);
                                        }}
                                        permissions={permissions}
                                        // deleteHandler={_setShowThreadDelete}
                                        key={e._id}
                                        thread={e}
                                        roomId={roomId}
                                        pinned={true}
                                        unPinHandler={_setShowThreadUnpin}
                                        profileHandler={profileHandler}
                                        reportHandler={_setShowThreadReport}
                                        pinnable={localStorage.getItem(C.SP_USER_ID) === thread.member.user._id}
                                        deleteHandler={_setShowThreadDelete}

                                    />
                                    {i !== replies[thread._id].pinnedReplies.length - 1 && <hr style={{ margin: 0, marginRight: '20px', marginLeft: '20px' }} />}
                                </Fragment>
                            )
                        }

                    }
                    )}


            </div>
            <div ref={_replies} style={{
                paddingLeft: '20px',
                marginLeft: '40px',
                borderLeft: '2px solid #777'
            }} className="replies-margin">
                {
                    typeof (replies[thread._id]) !== 'undefined' &&
                    typeof (replies[thread._id].data) !== 'undefined' &&
                    replies[thread._id].data.map((e, i) => {
                        if (e !== null) {
                            return (
                                <Fragment>
                                    <ReplyItem
                                        clickHandler={(event) => {
                                            console.log("Expanding thread ...");
                                            setThreadLoaded(e);
                                            setShowThreadView(true);
                                        }}
                                        permissions={permissions}
                                        // deleteHandler={_setShowThreadDelete}
                                        key={e._id}
                                        thread={e}
                                        roomId={roomId}
                                        pinHandler={_setShowThreadPin}
                                        profileHandler={profileHandler}
                                        reportHandler={_setShowThreadReport}
                                        pinnable={localStorage.getItem(C.SP_USER_ID) === thread.member.user._id}
                                        deleteHandler={_setShowThreadDelete}

                                    />
                                    {i !== replies[thread._id].data.length - 1 && <hr style={{ margin: 0, marginRight: '20px', marginLeft: '20px' }} />}
                                </Fragment>
                            )
                        }

                    }
                    )}


            </div>

            {!loadingMore && typeof (replies[thread._id]) !== 'undefined' &&
                typeof (replies[thread._id].canLoadMore) !== 'undefined' &&
                replies[thread._id].canLoadMore &&
                <div style={{ textAlign: 'center', padding: '30px' }}>
                    <a href="#" onClick={() => {
                        loadMoreReplies()
                    }} >Load More</a>
                </div>
            }

            {loadingMore && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="lds-ripple"><div></div><div></div></div>
            </div>}


            <div ref={_padder} className="padding"></div>

            {showLikes && <ThreadLikes threadId={thread._id} roomId={roomId} closeHandler={setShowLikes} />}

            {showDislikes && <ThreadDislikes threadId={thread._id} roomId={roomId} closeHandler={setShowDislikes} />}

            {showThreadView
                &&
                <ThreadView
                    closeHandle={setShowThreadView}
                    thread={threadLoaded}
                    permissions={permissions}
                    roomId={roomId}
                    parent={thread}
                    profileHandler={profileHandler}
                />}


            {showThreadPin &&
                <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                    <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                            <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                                <h4>{langauges[langauge].pin_reply_heading}</h4>
                                <p style={{ fontSize: 'small' }}>{langauges[langauge].pin_reply_body} </p>
                                {pinError.status && <p style={{ color: 'red' }}>{pinError.msg}</p>}
                                <button className="btn btn-success" disabled={pining} onClick={() => {
                                    // deleteThread(threadDeleteId);
                                    pinReply(threadPinId);
                                }}>
                                    {pining && <span style={{ paddingRight: '5px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                    {langauges[langauge].pin}
                                </button>
                                <button disabled={pining} onClick={() => { setShowThreadPin(false) }} className="btn btn-default ">{langauges[langauge].back}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showThreadUnpin &&
                <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                    <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                                <h4>{langauges[langauge].unpin_reply_heading}</h4>
                                <p style={{ fontSize: 'small' }}>{langauges[langauge].unpin_reply_body} </p>
                                {unpinError.status && <p style={{ color: 'red' }}>{unpinError.msg}</p>}
                                <button className="btn btn-danger" disabled={unpining} onClick={() => {
                                    // deleteThread(threadDeleteId);
                                    unpinThread(threadUnpinId);
                                }}>
                                    {unpining && <span style={{ paddingRight: '5px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                    {langauges[langauge].unpin}
                                </button>
                                <button disabled={unpining} onClick={() => { setShowThreadUnpin(false) }} className="btn btn-default ">{langauges[langauge].back}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showThreadReport && <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                            <h4>{langauges[langauge].report_reply}</h4>

                            <hr />
                            <select className="form-control"
                                onChange={(e) => {
                                    // alert(e.target.value);
                                    setReportData({ ...reportData, reason: e.target.value })
                                }}
                                disabled={reported || reporting}
                            >
                                <option value="1">{langauges[langauge].report_sexual}</option>
                                <option value="2">{langauges[langauge].report_spam}</option>
                                <option value="3">{langauges[langauge].report_harassment}</option>
                                <option value="4">{langauges[langauge].report_violent}</option>
                                <option value="5">{langauges[langauge].report_sensitive}</option>
                                <option value="6">{langauges[langauge].report_hate}</option>
                                <option value="7">{langauges[langauge].report_other}</option>
                            </select>
                            <br />
                            <textarea
                                rows={6}
                                maxLength={500}
                                className="form-control"
                                placeholder={langauges[langauge].report_thread_more_details}
                                onChange={(e) => {
                                    setReportData({ ...reportData, details: e.target.value })
                                }}
                                value={reportData.details}
                                disabled={reported || reporting}
                            >
                            </textarea>
                            <hr />
                            {reportError.status && <Fragment><p style={{ color: 'red' }}>{reportError.msg}</p> <hr /></Fragment>}

                            <button
                                disabled={reported || reporting}
                                className="btn btn-primary"
                                onClick={() => {
                                    reportThread(threadReportId);
                                }}>
                                {langauges[langauge].report}
                                {reporting && <span style={{ paddingRight: '5px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                {reported &&
                                    <span style={{ paddingLeft: '10px' }}>
                                        <i style={{ fontSize: '19px', color: 'white' }} className="fas fa-check"></i>
                                    </span>
                                }
                            </button>
                            <button className="btn btn-default" onClick={() => { setShowThreadReport(false) }} >{langauges[langauge].back}</button>
                        </div>
                    </div>
                </div>
            </div>
            }

            {showThreadDelete &&
                <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: '10' }}>
                    <div style={{ height: '100%', width: '100%', background: '#0000006e' }}></div>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                            <div style={{ background: '#fff', padding: '15px 30px', borderRadius: '9px', maxWidth: '350px' }}>
                                <h4>{langauges[langauge].delete_reply_heading}</h4>
                                <p style={{ fontSize: 'small' }}>{langauges[langauge].delete_reply_body} </p>
                                {deleteError.status && <p style={{ color: 'red' }}>{deleteError.msg}</p>}
                                <button disabled={deleting} onClick={() => { setShowThreadDelete(false) }} className="btn btn-default ">Back</button>
                                <button className="btn btn-danger" disabled={deleting} onClick={() => {
                                    //deleteThread(threadDeleteId);
                                    deleteReply(threadDeleteId);
                                }}>
                                    {deleting && <span style={{ paddingRight: '5px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                    {langauges[langauge].delete}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div >
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        replies: state.appState.chatbox.replies,
        thread: ownProps.thread,
        roomId: ownProps.roomId,
        permissions: ownProps.permissions,
        closeHandle: ownProps.closeHandle,
    };
};

const ThreadView = connect(mapStateToProps)(_ThreadView);

export default ThreadView