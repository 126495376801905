import React from 'react'

const UserRooms = ({ rooms }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly'
            }}
        >

            <div>
                <h3>Rooms Created</h3>

                {
                    rooms.created.map(e =>
                        <div>
                            <p>{e.title}</p>
                        </div>
                    )
                }

                {
                    rooms.created.length === 0 && <p>Nothing here.</p>
                }

            </div>
            <div>
                <h3>Rooms Joined</h3>
                {
                    rooms.joined.map(e =>
                        <div>
                            <p>{e.title}</p>
                        </div>
                    )
                }
                {
                    rooms.joined.length === 0 && <p>Nothing here.</p>
                }

            </div>


        </div>
    )
}

export default UserRooms
