import Axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import C from './../../util/Constants';
import { Loader } from './Loader';

export const Dashboard = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {


    try {
      var spSession = JSON.parse(localStorage.getItem('sp_session'));
    } catch (e) {
      return;
    }

    Axios
      .get(
        C.API_EP +
        '/api/admin/dashboard?email=' +
        spSession.email +
        '&token=' +
        spSession.token
      )
      .then((response) => {
        setLoading(false);
        setData(response.data)
      })
      .catch((error) => {
        setLoading(false)
        console.log("Error loading status: ", error);
        if (typeof error.response == 'undefined') {
          console.log('network error.');
          return;
        }
      });


    return () => {
    }
  }, [])

  return (
    <div className='sp-content-area'>

      {loading &&
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader />
          <span style={{ marginTop: '35px', color: '#1a73e8', fontWeight: 'bold' }}>Loading Dashboard ...</span>
        </div>
      }

      {!loading &&

        <div
          style={{
            height: '100%',
          }}
        >
          <div
            style={{
              textAlign: 'left',
              padding: '10px',
              paddingLeft: '30px',
              color: 'white',
              background: '#1a73e8'
            }}
          >
            <h3>Dashboard</h3>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-evenly'
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
            >
              <div
                style={{
                  background: 'white',
                  borderRadius: '10px',
                  padding: '20px',
                  color: 'white',
                  background: '#1a73e8'
                }}
              >
                <p>Total Users</p>
                <span
                  style={{
                    fontSize: '45px',
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >{data.users}</span>
              </div>
              <div
                style={{
                  background: 'white',
                  borderRadius: '10px',
                  padding: '20px',
                  color: 'white',
                  background: '#1a73e8'

                }}
              >
                <p>Total Rooms</p>
                <span
                  style={{
                    fontSize: '45px',
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >{data.rooms}</span>
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
            >
              <div
                style={{
                  background: 'white',
                  borderRadius: '10px',
                  padding: '20px',
                  color: 'white',
                  background: '#1a73e8'
                }}
              >
                <p>Total Guests</p>
                <span
                  style={{
                    fontSize: '45px',
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >{data.guests}</span>
              </div>
              <div
                style={{
                  background: 'white',
                  borderRadius: '10px',
                  padding: '20px',
                  color: 'white',
                  background: '#1a73e8'
                }}
              >
                <p>Total Threads</p>
                <span
                  style={{
                    fontSize: '45px',
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >{data.threads}</span>
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
            >
              <div
                style={{
                  background: 'white',
                  borderRadius: '10px',
                  padding: '20px',
                  color: 'white',
                  background: '#1a73e8'
                }}
              >
                <p>Room Messages</p>
                <span
                  style={{
                    fontSize: '45px',
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >{data.roomMessages > 10000 ? (data.roomMessages / 1000).toFixed(1) + "k" : data.roomMessages}</span>
              </div>
              <div
                style={{
                  background: 'white',
                  borderRadius: '10px',
                  padding: '20px',
                  color: 'white',
                  background: '#1a73e8'
                }}
              >
                <p>Total PMs</p>
                <span
                  style={{
                    fontSize: '45px',
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >{data.privateMessages > 10000 ? (data.privateMessages / 1000).toFixed(1) + "k" : data.privateMessages}</span>
              </div>
            </div>
          </div>
        </div>

      }

    </div>
  );
};
