import Axios from 'axios';
import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Loader } from './Loader';
import DataTable from 'react-data-table-component';

import C from './../../util/Constants';
import { UserList } from './UserList';

const columns = [

    {
        name: 'Username',
        selector: row => row.username,
    },
    {
        name: 'Email',
        selector: row => row.email,
        wrap: true
    },

    {
        name: 'IP',
        selector: row => row.ip,
    },

    {
        name: 'Rank',
        selector: row => row.globalRank,
    },

    {
        name: 'A/C Status',
        selector: row => row.accountStatus,
    },

    {
        name: 'Verified',
        selector: row => row.verified,
    },

    {
        name: 'Date Joined',
        selector: row => row.date,
    },


];

export const AdminLogs = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);


    useEffect(() => {


        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .get(
                C.API_EP +
                '/api/admin/logs?email=' +
                spSession.email +
                '&token=' +
                spSession.token
            )
            .then((response) => {
                setLoading(false);
                setData(response.data)

            })
            .catch((error) => {
                setLoading(false)
                console.log("Error loading status: ", error);
                if (typeof error.response == 'undefined') {
                    console.log('network error.');
                    return;
                }
            });


        return () => {
        }
    }, [])


    const getDate = (d) => {
        var r = new Date(d);
        return r.toLocaleDateString();
    }

    const getTime = (d) => {
        var r = new Date(d);
        return r.toLocaleTimeString();
    }

    return (
        <div className="sp-content-area">
            {loading &&
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Loader />
                    <span style={{ marginTop: '35px', color: '#1a73e8', fontWeight: 'bold' }}>Loading Users ...</span>
                </div>
            }
            {!loading &&
                <div>
                    <div
                        style={{
                            textAlign: 'left',
                            padding: '10px',
                            paddingLeft: '30px',
                            color: 'white',
                            background: '#1a73e8'
                        }}
                    >
                        <h3>Logs</h3>
                    </div>


                    {data.map((e) =>
                        <div style={{
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '10px'
                        }}>
                            <span style={{
                                border: '1px solid black',
                                padding: '2px',
                                borderRadius: '8px'
                            }}>
                                <span style={{
                                    fontWeight: 'bold'
                                }}>{e.admin ? e.admin.username : ''}</span>
                                {' ' + e.logText}
                            </span>
                            <span>{getDate(e.date) + " " + getTime(e.date)}</span>
                        </div>
                    )}


                </div>
            }
        </div>
    )
}
