import { combineReducers } from 'redux';

import appReducer from './appReducer';
import globalReducer from './globalReducer';

const rootReducer = combineReducers({
  appState: appReducer,
  global: globalReducer,
});

export default rootReducer;
