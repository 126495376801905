import React from 'react';

export const Wait = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 'auto',
        minWidth: '200px',
        textAlign: 'center',
      }}
    >
      <b>Loading data ....</b>
      <div class='progress'>
        <div
          class='progress-bar progress-bar-striped progress-bar-animated'
          style={{ width: '100%' }}
        ></div>
      </div>
    </div>
  );
};
