export const GET_ROOMS_JOINED = "GET_ROOMS_JOINED";
export const GET_ROOMS_JOINED_SUCCESS = "GET_ROOMS_JOINED_SUCCESS";
export const GET_ROOMS_JOINED_FAILURE = "GET_ROOMS_JOINED_FAILURE";
export const GET_ROOMS_CREATED = "GET_ROOMS_CREATED";
export const GET_ROOMS_CREATED_SUCCESS = "GET_ROOMS_CREATED_SUCCESS";
export const GET_ROOMS_CREATED_FAILURE = "GET_ROOMS_CREATED_FAILURE";

export const GET_FEATURED_ROOMS = "GET_FEATURED_ROOMS";
export const GET_FEATURED_ROOMS_SUCCESS = "GET_FEATURED_ROOMS_SUCCESS";
export const GET_FEATURED_ROOMS_FAILURE = "GET_FEATURED_ROOMS_FAILURE";

export const GET_UNFEATURED_ROOMS = "GET_UNFEATURED_ROOMS";
export const GET_UNFEATURED_ROOMS_SUCCESS = "GET_UNFEATURED_ROOMS_SUCCESS";
export const GET_UNFEATURED_ROOMS_FAILURE = "GET_UNFEATURED_ROOMS_FAILURE";

export const SHOW_JOIN_ROOM_UI = "SHOW_JOIN_ROOM_UI";
export const JOIN_ROOM_BYUI_SUCCESS = "JOIN_ROOM_BYUI_SUCCESS";
export const CLOSE_JOINROOM_UI = "CLOSE_JOINROOM_UI";

export const CREATE_ROOM = "CREATE_ROOM";
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";
export const CREATE_ROOM_FAILURE = "CREATE_ROOM_FAILURE";
export const SEARCH_ROOM = "SEARCH_ROOM";
export const SEARCH_ROOM_SUCCESS = "SEARCH_ROOM_SUCCESS";
export const SEARCH_ROOM_FAILURE = "SEARCH_ROOM_FAILURE";

export const LOAD_ROOM = "LOAD_ROOM";
export const LOAD_ROOM_SUCCESS = "LOAD_ROOM_SUCCESS";
export const LOAD_ROOM_FAILURE = "LOAD_ROOM_FAILURE";

export const LOAD_PROFILE = "LOAD_PROFILE";
export const LOAD_PROFILE_SUCCESS = "LOAD_PROFILE_SUCCESS";
export const LOAD_PROFILE_ERROR = "LOAD_PROFILE_ERROR";

export const JOIN_ROOM = "JOIN_ROOM";
export const JOIN_ROOM_SUCCESS = "JOIN_ROOM_SUCCESS";
export const JOIN_ROOM_FAILURE = "JOIN_ROOM_FAILURE";

export const LEAVE_ROOM = "LEAVE_ROOM";
export const LEAVE_ROOM_SUCCESS = "LEAVE_ROOM_SUCCESS";
export const LEAVE_ROOM_FAILURE = "LEAVE_ROOM_FAILURE";

export const UNLOAD_ROOM = "UNLOAD_ROOM";

export const ROOM_MESSAGE = "ROOM_MESSAGE";
export const ROOM_MESSAGE_SUCCESS = "ROOM_MESSAGE_SUCCESS";
export const ROOM_MESSAGE_FAILURE = "ROOM_MESSAGE_FAILURE";

export const PRIVATE_MESSAGE = "PRIVATE_MESSAGE";
export const PRIVATE_MESSAGE_SUCCESS = "PRIVATE_MESSAGE_SUCCESS";
export const PRIVATE_MESSAGE_FAILURE = "PRIVATE_MESSAGE_FAILURE";

export const NEW_PRIVATE_MESSAGE = "NEW_PRIVATE_MESSAGE";
export const NEW_PRIVATE_MESSAGE_NOTIF = "NEW_PRIVATE_MESSAGE_NOTIF";

export const INCOMING_MESSAGE = "INCOMING_MESSAGE";
export const INCOMING_MESSAGE_SUCCESS = "INCOMING_MESSAGE_SUCCESS";
export const INCOMING_MESSAGE_FAILURE = "INCOMING_MESSAGE_FAILURE";

export const SORT_ROOM_MESSAGES = "SORT_ROOM_MESSAGES";

export const SET_INPUT_TEXT = "SET_INPUT_TEXT";
export const SET_PRIVATE_INPUT_TEXT = "SET_PRIVATE_INPUT_TEXT";

export const DISPATCH_NOTIFICATION = "DISPATCH_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export const LOGOUT = "LOGOUT";

export const RESET_REDIRECT = "RESET_REDIRECT";

export const SHOW_PROFILE_UI = "SHOW_PROFILE_UI";
export const HIDE_PROFILE_UI = "HIDE_PROFILE_UI";

export const SHOW_ACCOUNT_UI = "SHOW_ACCOUNT_UI";
export const HIDE_ACCOUNT_UI = "HIDE_ACCOUNT_UI";

export const SHOW_USER_PROFILE_UI = "SHOW_USER_PROFILE_UI";
export const HIDE_USER_PROFILE_UI = "HIDE_USER_PROFILE_UI";

export const UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE";

export const USER_JOINED_ROOM = "USER_JOINED_ROOM";
export const USER_LEFT_ROOM = "USER_LEFT_ROOM";

export const LOAD_USERNAME = "LOAD_USERNAME";

export const SHOW_UPLOAD_FILE_UI = "SHOW_UPLOAD_FILE_UI";
export const HIDE_UPLOAD_FILE_UI = "HIDE_UPLOAD_FILE_UI";

export const SHOW_UPLOAD_VIDEO_UI = "SHOW_UPLOAD_VIDEO_UI";
export const HIDE_UPLOAD_VIDEO_UI = "HIDE_UPLOAD_VIDEO_UI";

export const SHOW_ROOM_DETAILS_UI = "SHOW_ROOM_DETAILS_UI";
export const HIDE_ROOM_DETAILS_UI = "HIDE_ROOM_DETAILS_UI";

export const SHOW_ROOM_SETTINGS_UI = "SHOW_ROOM_SETTINGS_UI";
export const HIDE_ROOM_SETTINGS_UI = "HIDE_ROOM_SETTINGS_UI";

export const DELETE_MESSAGE = "DELETE_MESSAGE";

export const INIT_HOME = "INIT_HOME";
export const INIT_HOME_VIEW = "INIT_HOME_VIEW";

export const INIT_PRIVATE_BOX = "INIT_PRIVATE_BOX";

export const INIT_CHATBOX_ONLY = "INIT_CHATBOX_ONLY";
export const LOAD_PRIVATE_LIST = "LOAD_PRIVATE_LIST";
export const SET_PRIVATE_LIST = "SET_PRIVATE_LIST";

export const LOAD_MORE_PRIVATE_LIST = "LOAD_MORE_PRIVATE_LIST";
export const SET_MORE_PRIVATE_LIST = "SET_MORE_PRIVATE_LIST";

export const PRIVATEBOX_DATA_LOADED = "PRIVATEBOX_DATA_LOADED";
export const HIDE_PRIVATEBOX_LOAD = "HIDE_PRIVATEBOX_LOAD";
export const SHOW_PRIVATEBOX_LOAD = "SHOW_PRIVATEBOX_LOAD";

export const PRIVATE_REQUEST_ACCEPTED = "PRIVATE_REQUEST_ACCEPTED";
export const PRIVATE_REQUEST_BLOCKED = "PRIVATE_REQUEST_BLOCKED";
export const PRIVATE_REQUEST_UNBLOCKED = "PRIVATE_REQUEST_UNBLOCKED";

export const PRIVATE_THREAD_BANNED = "PRIVATE_THREAD_BANNED";
export const PRIVATE_THREAD_UNBANNED = "PRIVATE_THREAD_UNBANNED";

export const PRIVATE_REQUEST_RECEIVED = "PRIVATE_REQUEST_RECEIVED";

export const CLEAR_PRIVATE_INPUT = "CLEAR_PRIVATE_INPUT";

export const RESET_PRIVATEBOX = "RESET_PRIVATEBOX";
export const LOAD_WAIT_COMPONENT = "LOAD_WAIT_COMPONENT";

export const SHOW_PRIVATE_UPLOAD_FILE_UI = "SHOW_PRIVATE_UPLOAD_FILE_UI";
export const HIDE_PRIVATE_UPLOAD_FILE_UI = "HIDE_PRIVATE_UPLOAD_FILE_UI";

export const SHOW_PRIVATE_UPLOAD_VIDEO_UI = "SHOW_PRIVATE_UPLOAD_VIDEO_UI";
export const HIDE_PRIVATE_UPLOAD_VIDEO_UI = "HIDE_PRIVATE_UPLOAD_VIDEO_UI";

export const SET_SIDEBAR_ACTIVE_TAB = "SET_SIDEBAR_ACTIVE_TAB";
export const SET_ROOMS_ACTIVE_TAB = "SET_ROOMS_ACTIVE_TAB";
export const UPDATE_PRIVATE_POINTER = "UPDATE_PRIVATE_POINTER";
export const SHOW_PRIVATE_TYPING = "SHOW_PRIVATE_TYPING";
export const HIDE_PRIVATE_TYPING = "HIDE_PRIVATE_TYPING";

export const SET_ROOM_USER_SUGGESTIONS = "SET_ROOM_USER_SUGGESTIONS";
export const RESET_ROOM_USER_SUGGESTIONS = "RESET_ROOM_USER_SUGGESTIONS";
export const LOAD_USERNAME_FROM_SUGGESTIONS = "LOAD_USERNAME_FROM_SUGGESTIONS";
export const SET_ROOM_COMMANDS = "SET_ROOM_COMMANDS";
export const SHOW_ROOM_COMMANDS = "SHOW_ROOM_COMMANDS";
export const HIDE_ROOM_COMMANDS = "HIDE_ROOM_COMMANDS";
export const RESET_ROOM_COMMANDS = "RESET_ROOM_COMMANDS";
export const LOAD_COMMAND_FROM_SUGGESTIONS = "LOAD_COMMAND_FROM_SUGGESTIONS";
// export const EXTEND_COMMAND_PROCESS = 'EXTEND_COMMAND_PROCESS';

export const ROLE_UPDATED = "ROLE_UPDATED";
export const CLEAR_ROOM_MESSAGES = "CLEAR_ROOM_MESSAGES";
export const LOAD_EMOJI_BOX = "LOAD_EMOJI_BOX";
export const SHOW_EMOJI_BOX = "SHOW_EMOJI_BOX";
export const HIDE_EMOJI_BOX = "HIDE_EMOJI_BOX";
export const LOAD_USER_SETTINGS = "LOAD_USER_SETTINGS";

export const SHOW_ROOM_MEMBERS = "SHOW_ROOM_MEMBERS";
export const HIDE_ROOM_MEMBERS = "HIDE_ROOM_MEMBERS";

export const SHOW_LOADING_MORE_ROOM_MESSAGES =
  "SHOW_LOADING_MORE_ROOM_MESSAGES";
export const HIDE_LOADING_MORE_ROOM_MESSAGES =
  "HIDE_LOADING_MORE_ROOM_MESSAGES";
export const MORE_ROOM_MESSAGES_LOADED = "MORE_ROOM_MESSAGES_LOADED";

export const SHOW_LOADING_MORE_PRIVATE_MESSAGES =
  "SHOW_LOADING_MORE_PRIVATE_MESSAGES";
export const HIDE_LOADING_MORE_PRIVATE_MESSAGES =
  "HIDE_LOADING_MORE_PRIVATE_MESSAGES";
export const MORE_PRIVATE_MESSAGES_LOADED = "MORE_PRIVATE_MESSAGES_LOADED";

export const UNREAD_NOTIF = "UNREAD_NOTIF";

export const SHOW_PRIVATE_LOAD = "SHOW_PRIVATE_LOAD";
export const HIDE_PRIVATE_LOAD = "HIDE_PRIVATE_LOAD";
export const SET_USER_SUGGESTION_LOADING = "SET_USER_SUGGESTION_LOADING";
export const UNSET_USER_SUGGESTION_LOADING = "UNSET_USER_SUGGESTION_LOADING";

export const SET_PRIVATE_THREAD = "SET_PRIVATE_THREAD";

export const SHOW_UPLOAD_BOX = "SHOW_UPLOAD_BOX";
export const HIDE_UPLOAD_BOX = "HIDE_UPLOAD_BOX";

export const SHOW_PLAY_MEDIA = "SHOW_PLAY_MEDIA";
export const HIDE_PLAY_MEDIA = "HIDE_PLAY_MEDIA";

export const SHOW_PLAY_MEDIA_PRIVATE = "SHOW_PLAY_MEDIA_PRIVATE";
export const HIDE_PLAY_MEDIA_PRIVATE = "HIDE_PLAY_MEDIA_PRIVATE";

export const LOAD_ROOM_THREADS = 'LOAD_ROOM_THREADS';

export const LOAD_MORE_ROOM_THREADS = 'LOAD_MORE_ROOM_THREADS';

export const LOAD_ROOM_THREADS_SUCCESS = 'LOAD_ROOM_THREADS_SUCCESS';

export const LOAD_MORE_ROOM_THREADS_SUCCESS = 'LOAD_MORE_ROOM_THREADS_SUCCESS';

export const LOAD_ROOM_THREADS_ERROR = 'LOAD_ROOM_THREADS_ERROR';

export const LOAD_MORE_ROOM_THREADS_ERROR = 'LOAD_MORE_ROOM_THREADS_ERROR';

export const LOAD_THREAD = 'LOAD_THREAD';
export const PUSH_THREAD = 'PUSH_THREAD';
export const DELETE_THREAD = 'DELETE_THREAD';
export const LIKE_THREAD = 'LIKE_THREAD';
export const UNLIKE_THREAD = 'UNLIKE_THREAD';
export const DISLIKE_THREAD = 'DISLIKE_THREAD';
export const UNDISLIKE_THREAD = 'UNDISLIKE_THREAD';
export const LOAD_REPLIES_SUCCESS = 'LOAD_REPLIES_SUCCESS';

export const LOAD_MORE_REPLIES_SUCCESS = 'LOAD_MORE_REPLIES_SUCCESS';

export const LOAD_REPLIES = 'LOAD_REPLIES';
export const CLEAR_REPLIES = 'CLEAR_REPLIES';
export const PUSH_REPLY = 'PUSH_REPLY';
export const CHANGE_PIN_THREAD = 'CHANGE_PIN_THREAD';
export const REMOVE_PIN_THREAD = 'REMOVE_PIN_THREAD';

export const PIN_REPLY_TO_THREAD = 'PIN_REPLY_TO_THREAD';
export const UNPIN_REPLY_FROM_THREAD = 'UNPIN_REPLY_FROM_THREAD';

export const ARCHIVE_THREAD = 'ARCHIVE_THREAD';
export const DELETE_REPLY = 'DELETE_REPLY';

export const SET_ROOM_SOCKET = 'SET_ROOM_SOCKET';
export const LOAD_TAG_SEARCH_THREADS_SUCCESS = 'LOAD_TAG_SEARCH_THREADS_SUCCESS';
export const ADD_AD_TO_LIST = "ADD_AD_TO_LIST";
export const AUDIO_STREAM_PEEK = 'AUDIO_STREAM_PEEK';
export const JOIN_BROADCAST = 'JOIN_BROADCAST';
export const JOIN_BROADCAST_SUCCESS = 'JOIN_BROADCAST_SUCCESS';
export const JOIN_BROADCAST_FAILURE = 'JOIN_BROADCAST_FAILURE';
export const UPGRADE_TO_SPEAKER = 'UPGRADE_TO_SPEAKER';
export const DOWNGRADE_FROM_SPEAKER = 'DOWNGRADE_FROM_SPEAKER';
export const LISTENER_JOINED = 'LISTENER_JOINED';
export const LISTENER_LEFT = 'LISTENER_LEFT';

export const SPEAKER_JOINED = 'SPEAKER_JOINED';
export const ME_SPEAKER_JOINED = 'ME_SPEAKER_JOINED';

export const SPEAKER_LEFT = 'SPEAKER_LEFT';
export const ME_SPEAKER_LEFT = 'ME_SPEAKER_LEFT';

export const LEAVE_BROADCAST_SUCCESS = 'LEAVE_BROADCAST_SUCCESS';


export const INIT_AUDIO_STREAM_BUFFER = 'INIT_AUDIO_STREAM_BUFFER';
export const UNINIT_AUDIO_STREAM_BUFFER = 'UNINIT_AUDIO_STREAM_BUFFER';

export const INIT_AUDIO_STREAM_SPEAKER = 'INIT_AUDIO_STREAM_SPEAKER';
export const APPEND_TO_AUDIO_STREAM_BUFFER = 'APPEND_TO_AUDIO_STREAM_BUFFER';
export const CONSUMED_AUDIO_STREAM_CHUNK = 'CONSUMED_AUDIO_STREAM_CHUNK';

