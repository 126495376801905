import Axios from 'axios';
import React from 'react'
import langauges from '../../languages';
import constants from '../../util/Constants';

export default function EditRole({ role, loadedView, roomId, setRoles, roles }) {

    var langauge = 'english';

    //local role
    const [_role, setRole] = React.useState(role);
    const [updating, setUpdating] = React.useState(false);
    const [updateStatus, setUpdateStatus] = React.useState({ status: 0, msg: '####' })
    const [updateAllowed, setUpdateAllowed] = React.useState(false);

    React.useEffect(() => {
        if (JSON.stringify(_role) === JSON.stringify(role)) {
            setUpdateAllowed(false);
        }
        else {
            setUpdateAllowed(true);
        }
    }, [_role])

    React.useEffect(() => {

        console.log(_role);
        console.log(loadedView)

        langauge = localStorage.getItem(constants.LANGUAGE);

        console.log(langauge);
        if (langauge === null) {
            langauge = 'english'
        }

    }, [])

    const _setUpdateStatus = (status, time) => {
        setUpdateStatus(status);
        setTimeout(function (params) {
            setUpdateStatus({ status: 0, msg: '' });
        }, time);
    }

    const checkForSession = () => {
        if (localStorage.getItem(constants.SP_SESSION) == null) {
            return false;
        }

        try {
            var spSession = JSON.parse(localStorage.getItem(constants.SP_SESSION));
            return spSession;
        } catch (e) {
            return false;
        }
    };

    const updateRole = () => {
        var spSession = checkForSession();
        if (spSession === false) {
            return;
        }
        setUpdating(true);
        var sendData =
        {
            email: spSession.email,
            token: spSession.token,
            roomId: roomId,
            roleId: _role._id,
            title: _role.title,
            rank: _role.rank,
            color: _role.color,
            p_write: _role.permissions.Write,
            p_read: _role.permissions.Read,
            p_upload: _role.permissions.Upload,
            p_broadcast: _role.permissions.Broadcast,
            p_mention: _role.permissions.Mention,
            p_delete: _role.permissions.Delete,
            p_read_thread: _role.permissions.ReadThread,
            p_write_thread: _role.permissions.WriteThread,
            p_react_thread: _role.permissions.ReactThread,
            p_ban: _role.permissions.Ban,
            p_ipban: _role.permissions.IpBan,
            p_extend: _role.permissions.Extend,
            p_manage_thread: _role.permissions.ManageThread
        }
        console.log(sendData);
        Axios.post(constants.API_EP + '/a/r/c/updateRole', sendData
        ).then(r => {
            console.log(r);
            setUpdating(false);
            _setUpdateStatus({ status: 1, message: langauges[langauge].update_success }, 2500)
            var changedRoles = roles.map(e => {
                if (e._id === role._id) {
                    return { ..._role }
                }
                else {
                    return e
                }
            })

            console.log(changedRoles);
            setUpdateAllowed(false);
            setRoles(changedRoles)
        }).catch(e => {
            setUpdating(false);
            _setUpdateStatus({ status: -1, message: langauges[langauge].update_error }, 3500)
        })
    }

    return (
        <div>
            <i onClick={() => {
                loadedView("RolesList")
            }} className="fa fa-arrow-left hoverable" style={{ cursor: 'pointer' }} ><span style={{ paddingLeft: '10px' }}>{langauges[langauge].update_role}</span></i>
            <hr />
            <div
                style={{}}
            >
                <h5>{langauges[langauge].title}</h5> <input className="fancy-input" type="text" onChange={(e) => {
                    if (e.target.value.match(/^[a-z]{2,9}$/))
                        setRole({ ..._role, title: e.target.value })
                }} value={_role.title} />
                <h5>{langauges[langauge].strength}: </h5> <input className="fancy-input" onChange={(e) => {
                    if (e.target.value <= 99 && e.target.value >= -99)
                        setRole({ ..._role, rank: parseInt(e.target.value) })
                }} type="number" value={_role.rank} />
                <h5>{langauges[langauge].color}</h5> <input className="fancy-input" type="color" onChange={(e) => {
                    setRole({ ..._role, color: e.target.value })
                }} value={_role.color} />
                <hr />
                <div className='sc-roles-item'>
                    <b>Read Message</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.Read}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, Read: !_role.permissions.Read } })
                        }}
                    />

                </div>
                <div className='sc-roles-item'>
                    <b>Write Message</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.Write}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, Write: !_role.permissions.Write } })
                        }}
                    />
                </div>
                <div className='sc-roles-item'>
                    <b>Upload Media</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.Upload}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, Upload: !_role.permissions.Upload } })
                        }}
                    />
                </div>
                <div className='sc-roles-item'>
                    <b>Broadcast</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.Broadcast}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, Broadcast: !_role.permissions.Broadcast } })
                        }}
                    />
                </div>
                <div className='sc-roles-item'>
                    <b>Mention</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.Mention}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, Mention: !_role.permissions.Mention } })
                        }}
                    />
                </div>
                <div className='sc-roles-item'>
                    <b>Delete</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.Delete}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, Delete: !_role.permissions.Delete } })
                        }}
                    />
                </div>
                <div className='sc-roles-item'>
                    <b>Read Thread</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.ReadThread}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, ReadThread: !_role.permissions.ReadThread } })
                        }}
                    />
                </div>
                <div className='sc-roles-item'>
                    <b>Write Thread</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.WriteThread}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, WriteThread: !_role.permissions.WriteThread } })
                        }}
                    />
                </div>
                <div className='sc-roles-item'>
                    <b>React Thread</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.ReactThread}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, ReactThread: !_role.permissions.ReactThread } })
                        }}
                    />
                </div>

                <div className='sc-roles-item'>
                    <b>Manage Thread</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.ManageThread}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, ManageThread: !_role.permissions.ManageThread } })
                        }}
                    />
                </div>

                <div className='sc-roles-item'>
                    <b>Can Ban</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, Ban: !_role.permissions.Ban } })
                        }}
                        checked={_role.permissions.Ban}
                    />
                </div>
                <div className='sc-roles-item'>
                    <b style={{ color: '#ec7979' }}>Can IpBan</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.IpBan}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, IpBan: !_role.permissions.IpBan } })
                        }}

                    />
                </div>
                <div className='sc-roles-item'>
                    <b style={{ color: '#ec7979' }}>Extend</b>
                    <input
                        type='checkbox'
                        className='checkbox-inline'
                        checked={_role.permissions.Extend}
                        onChange={(e) => {
                            setRole({ ..._role, permissions: { ..._role.permissions, Extend: !_role.permissions.Extend } })
                        }}
                    />
                </div>

                <hr />
                {updateStatus.status === 1 && (<><p style={{ color: '#28a745', background: 'white', borderRadius: '5px', textAlign: 'center' }}>{updateStatus.message}</p>
                    <hr /></>)
                }
                {updateStatus.status === -1 && (<><p style={{ color: '#dc3545', background: 'white', borderRadius: '5px', textAlign: 'center' }}>{updateStatus.message}</p>
                    <hr /></>)
                }
                <button className="btn btn-success" disabled={!updateAllowed || updating} onClick={() => {
                    updateRole()
                }} >
                    {langauges[langauge].update}
                    {updating && <span style={{ paddingLeft: '10px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                </button>

            </div>

        </div>
    )
}
