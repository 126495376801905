import React, { Component, Fragment } from 'react';
import Login from './components/Login';
import Register from './components/Register';
import { BrowserRouter, Route, Switch, Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import C from './util/Constants';
import Home from './components/Home';
import Launch from './components/Launch';
import Verify from './components/util/Verify';
import { NotFound } from './components/util/NotFound';
import { About } from './components/pages/About';
import { Privacy } from './components/pages/Privacy';
import { Terms } from './components/pages/Terms';
import GuestLogin from './components/GuestLogin';
import GuestHome from './components/GuestHome';
import PublicThread from './components/util/PublicThread';
import Tests from './components/Tests';

class App extends Component {
  state = {
    sending: false,
    notification: { status: '', heading: '', msg: '', show: false },
    redirect: false,
    redirectTo: '',
  };

  initChecks() {
    if (localStorage.getItem('sp_session') == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem('sp_session'));
    } catch (e) {
      localStorage.removeItem('sp_session');
      return false;
    }

    axios
      .get(
        C.API_EP +
        '/user/status?email=' +
        spSession.email +
        '&token=' +
        spSession.token
      )
      .then((response) => {
        console.log(response);
        if (response.data === 1) {
          var _this = this;
          setTimeout(function () {
            _this.setState({ redirectTo: '/home' });
            _this.setState({ redirect: true });
          }, 1000);
        } else {
          _this = this;
          setTimeout(function () {
            _this.setState({ redirectTo: '/login' });
            _this.setState({ redirect: true });
          }, 1000);
        }
      })
      .catch((error) => {
        if (typeof error.status == 'undefined') {
          console.log('network error.');
          this.setState(
            {
              notification: {
                status: 'danger',
                heading: 'Error',
                msg: 'Network error.',
                show: true,
              },
            },
            this.fadeAwayNotification
          );
          return;
        }
        var _this = this;
        setTimeout(function () {
          _this.setState({ redirectTo: '/login' });
          _this.setState({ redirect: true });
        }, 1000);
      });
  }

  componentDidMount() {
    //this.initChecks();
  }

  render() {
    return (
      <Fragment>
        {this.state.sending && (
          <span
            class='spinner-grow text-muted'
            style={{
              position: 'fixed',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
              width: '100px',
              height: '100px',
              zIndex: 99,
            }}
          ></span>
        )}
        {this.state.notification.show && (
          <div
            class={'alert alert-' + this.state.notification.status}
            style={{
              position: 'fixed',
              left: '5%',
              bottom: '5%',
              zIndex: 99,
              boxShadow: '0px 0px 10px -2px',
            }}
          >
            <strong>{this.state.notification.heading}!</strong>{' '}
            {this.state.notification.msg}
          </div>
        )}

        {this.state.redirect && <Redirect to={this.state.redirectTo} />}

        <BrowserRouter>
          <Switch>
            <Route path='/login' exact component={Login} />
            <Route path='/' component={Home} exact />

            <Route path='/dashboard' exact component={Home} />
            <Route path='/hardcoded-messages' exact component={Home} />
            <Route path='/home' exact component={Home} />
            <Route path='/users' exact component={Home} />
            <Route path='/rooms' exact component={Home} />
            <Route path='/settings' exact component={Home} />
            <Route path='/logs' exact component={Home} />

            {/* 
              <Provider store={store}>
                <Home />
              </Provider>
            </Route> */}

            <Route path='*' component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
}

export default App;
