import { ADD_ARTICLE } from '../constants/action-types';

const globalState = {
  redirect: { do: false, to: '/login' },
  notification: { status: '', heading: '', msg: '', show: false },
};

function globalReducer(state = globalState, action) {
  //   if (action.type === ADD_ARTICLE) {
  //     return Object.assign({}, state, {
  //       articles: state.articles.concat(action.payload),
  //     });
  //   }
  return state;
}

export default globalReducer;
