import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import constants from '../../../../util/Constants';
import { Loader } from '../../Loader';
import { AddHCMessage } from './AddHCMessage';


function SAHardcodedMessages() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [editToggle, setEditToggle] = useState(false);
    const [addNewToggle, setAddNewToggle] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {


        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .get(
                constants.API_EP +
                '/api/admin/hc/list?email=' +
                spSession.email +
                '&token=' +
                spSession.token
            )
            .then((response) => {
                setLoading(false);
                setData(response.data.list);
            })
            .catch((error) => {
                setLoading(false)
                console.log("Error loading status: ", error);
                if (typeof error.response == 'undefined') {
                    console.log('network error.');
                    return;
                }
            });


        return () => {
        }
    }, [])

    const updateSettings = (uid, theKey, theValue) => {
        // alert("Updating user with " + uid);
        // console.log({ data })
        setData(
            data.map((e) => {
                if (e._id === uid) {
                    //alert("And theKey=" + theKey + " and theValue=" + theValue)
                    var updatedE = e;
                    e[theKey] = theValue;
                    console.log(updatedE)
                    return updatedE;
                }
                else {
                    return e;
                }
            })
        );
    }

    return (
        <div className="sp-content-area">
            {loading &&
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Loader />
                    <span style={{ marginTop: '35px', color: '#1a73e8', fontWeight: 'bold' }}>Loading HC Messages ...</span>
                </div>
            }

            {!loading &&
                <div>
                    <div
                        style={{
                            textAlign: 'left',
                            padding: '10px',
                            paddingLeft: '30px',
                            color: 'white',
                            background: '#1a73e8'
                        }}
                    >
                        <h3>Hardcoded Messages</h3>
                    </div>

                    <button style={{ float: 'right', margin: '10px' }} className='btn btn-success' onClick={() => {
                        setAddNewToggle(!addNewToggle)
                    }} disabled={false}><i className='fa fa-plus'></i> Add New</button>

                    <div style={{ margin: '10px' }}>
                        <table style={{ fontSize: '14px' }}>
                            <thead>
                                <tr>
                                    <th >
                                        ID
                                    </th>

                                    <th >
                                        Message
                                    </th>

                                    {/* <th >
                                        Value
                                    </th> */}

                                    <th >
                                        Actions
                                    </th>

                                </tr>
                            </thead>
                            <tbody>

                                {data.map(e => {
                                    return (
                                        <tr >
                                            <td>{e._id}</td>
                                            <td>{e.content}</td>
                                            {/* <td>{e.value}</td> */}
                                            <td>
                                                <button className='btn btn-primary' onClick={(ev) => {
                                                    var coordinates = ev.target.getBoundingClientRect();
                                                    // setEditKVP(e);
                                                    // setEditor({ x: coordinates.x, y: coordinates.y });
                                                    setEditToggle(!editToggle);
                                                }}>Edit</button>
                                                <SAHardcodedMessagesDelete key={e._id} setList={setData} data={data} id={e._id} />
                                            </td>
                                        </tr>
                                    );
                                })}
                                {data.length === 0 && <tr><td colSpan={4}><b>Nothing here.</b></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            {/* {editToggle && <EditSetting editKVP={editKVP} editor={editor} closeHandler={() => { setEditToggle(false) }} updateSettings={updateSettings} />} */}

            {addNewToggle && <AddHCMessage appendList={(obj) => {
                setData([...data, obj])
                setAddNewToggle(false)
            }} closeHandler={() => { setAddNewToggle(false) }} />}
        </div>
    );
}

export default SAHardcodedMessages;


function SAHardcodedMessagesDelete(props) {

    const [deleting, setDeleting] = useState(false);

    const deleteFunc = () => {
        setDeleting(true)
        console.log(props)

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .post(constants.API_EP + "/api/admin/hc/remove", {
                email: spSession.email,
                token: spSession.token,
                _id: props.id
            })
            .then((response) => {
                if (response.data.status) {
                    setTimeout(() => {
                        console.log(response.data.msgObject)
                        props.setList(props.data.filter(e => e._id != props.id));
                    }, 300)
                }
                else {
                    setTimeout(() => {
                        setDeleting(false)
                    }, 300)
                }
            })
            .catch((error) => {
                setTimeout(() => {
                    setDeleting(false)
                }, 300)
            });
    }

    return (
        <>
            <button className='btn btn-danger' disabled={false} onClick={deleteFunc}>
                Delete
                {deleting && <span style={{ paddingLeft: '10px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
            </button>

        </>
    )
}
