const constants = {
  API_EP: "https://api.sparkle-chat.com",
  SOCKET_EP: "https://socket.sparkle-chat.com",

  // API_EP: "http://localhost:3030",
  // SOCKET_EP: "http://localhost:3031",

  // API_EP: "http://localhost:3030",
  // SOCKET_EP: "http://localhost:3031",

  // API_EP: "http://192.168.100.245:3030",
  // SOCKET_EP: "http://192.168.100.245:3031",

  // API_EP: "https://api2.sparkle-chat.com",
  // SOCKET_EP: "https://socket2.sparkle-chat.com",

  // API_EP: "http://192.168.100.129:3030",
  // SOCKET_EP: "http://192.168.100.129:3031",

  // API_EP: "https://apisandbox.sparkle-chat.com",
  // SOCKET_EP: 'https://socketsandbox.sparkle-chat.com',

  ASSET_EP: "https://api.sparkle-chat.com",
  // ASSET_EP: "https://apisandbox.sparkle-chat.com",

  URL_EP: 'https://sparkle-chat.com',

  // URL_EP: 'http://localhost:3000',

  SP_SESSION: "sp_session",
  SP_LOADED_ROOM: "sp_loaded_room",
  SP_LOADED_PRIVATE_THREAD: "sp_loaded_private_thread",
  GLOBAL_NOTIFICATION_DURATION: 2500,
  SP_LOADED_THREAD: "sp_loaded_thread",
  SP_USER_ID: "sp_user_id",
  EMOJIS: [
    "😀",
    "😁",
    "😂",
    "🤣",
    "😄",
    "😅",
    "😆",
    "😉",
    "😊",
    "😋",
    "😎",
    "😍",
    "😘",
    "🥰",
    "😗",
    "😙",
    "😚",
    "🙂",
    "🤗",
    "🤩",
    "🤔",
    "🤨",
    "😐",
    "😑",
    "😶",
    "🙄",
    "😏",
    "😣",
    "😥",
    "😮",
    "🤐",
    "😯",
    "😪",
    "😫",
    "😴",
    "😌",
    "😛",
    "😜",
    "😝",
    "🤤",
    "😒",
    "😓",
    "😔",
    "😕",
    "🙃",
    "🤑",
    "😲",
    "☹️",
    "🙁",
    "😖",
    "😞",
    "😟",
    "😤",
    "😢",
    "😭",
    "😦",
    "😧",
    "😨",
    "😩",
    "🤯",
    "😬",
    "😰",
    "😱",
    "🥵",
    "🥶",
    "😳",
    "🤪",
    "😵",
    "😡",
    "😠",
    "🤬",
    "😷",
    "🤒",
    "🤕",
    "🤢",
    "🤮",
    "🤧",
    "😇",
    "🤠",
    "🤡",
    "🥳",
    "🥴",
    "🥺",
    "🤥",
    "🤫",
    "🤭",
    "🧐",
    "🤓",
    "😈",
    "👿",
    "👹",
    "👺",
    "💀",
    "👻",
    "👽",
    "🤖",
    "💩",
    "😺",
    "😾",
    "💃",
    "🤷‍♀️",
    "🤦‍♀️",
    "💁‍♀️",
    "🙅‍♀️",
    "🙆‍♀️",
    "🙋‍♀️",
    "🚶‍♀️",
    "🏃‍♀️",
    "🤲",
    "👐",
    "🙌",
    "👏",
    "🤝",
    "👍",
    "👎",
    "👊",
    "✊",
    "🤛",
    "🤜",
    "🤞",
    "✌️",
    "🤟",
    "🤘",
    "👌",
    "👈",
    "👉",
    "👆",
    "👇",
    "☝️",
    "✋",
    "🤚",
    "🖐",
    "🖖",
    "👋",
    "🤙",
    "💪",
    "🦵",
    "🦶",
    "🖕",
    "✍️",
    "🙏",
    "💍",
    "💄",
    "💋",
    "👄",
    "👅",
    "👂",
    "👃",
    "👣",
    "👁",
    "👀",
    "🧠",
    "🦴",
    "🦷",
    "❤️",
    "💔",
    "❣️",
    "💕",
    "💞",
    "💯",
    "❌",
    "✅",
    "🎵",
    "🎶",
    "⛔️",
    "🌐",
    "🔇",
    "🔉",
    "🔊",
  ],
  LANGUAGE: 'language'
};

export default constants;
