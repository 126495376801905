import Axios from 'axios';
import React from 'react'
import langauges from '../languages';
import C from './../util/Constants';
import { processMessage } from './../redux/actions/appActions';
// import { processMessage2} from './../redux/actions/appActions';


function ReplyThread({ roomId, show, replyTo, addReply }) {

    var langauge = 'english';

    const InputImage = React.useRef(null);
    const InputVideo = React.useRef(null);

    const [showReply, setShowReply] = React.useState(false);

    const [textData, setTextData] = React.useState('');
    const [file, setFile] = React.useState(null);
    const [lockSend, setLockSend] = React.useState(false);
    const [load, setLoad] = React.useState({ status: 2, msg: '' });
    const [sending, setSending] = React.useState(false);

    const [image, setImage] = React.useState({
        update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
        data: {
            blob: '',
        },
        show: false,
    });

    const [video, setVideo] = React.useState({
        update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
        data: {
            blob: '',
        },
        show: false,
    });

    const uploadImage = () => {
        InputImage.current.click();
    }

    const uploadVideo = () => {
        InputVideo.current.click();
    }
    const setImageBlob = (blob) => {
        setImage({
            ...image,
            data: { ...image.data, blob: blob },
            show: true
        });
    };

    const setVideoBlob = (blob) => {
        setVideo({
            ...video,
            data: { ...video.data, blob: blob },
            show: true
        });
    };

    const showImage = (e) => {
        console.log(e.target);
        if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setImageBlob(e.target.result);
                setFile({ type: 'image', data: e.target.result })
            };
            reader.readAsDataURL(e.target.files[0]); // convert to base64 string
        }
    };

    const showVideo = (e) => {
        console.log(e.target);
        if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setVideoBlob(e.target.result);
                setFile({ type: 'video', data: e.target.result })
            };
            reader.readAsDataURL(e.target.files[0]); // convert to base64 string
        }
    };

    const checkForSession = () => {
        if (localStorage.getItem(C.SP_SESSION) == null) {
            return false;
        }

        try {
            var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
            return spSession;
        } catch (e) {
            return false;
        }
    };

    const createThreadServer = () => {
        console.log("Creating ...")
        console.log(file);
        if (file !== null) {
            if (file.type === "image") {
                var spSession = checkForSession();
                if (spSession === false) {
                    return;
                }

                var dataToUpdate = new FormData();
                dataToUpdate.append('roomId', roomId);

                dataToUpdate.append('email', spSession.email);
                dataToUpdate.append('token', spSession.token);
                dataToUpdate.append('replyTo', replyTo);

                dataToUpdate.append('imageFile', InputImage.current.files[0]);

                if (textData.trim() !== "") {
                    var processedMessage = processMessage(textData)
                    try {
                        var msg = JSON.stringify(processedMessage);
                    } catch (e) {
                        return;
                    }
                    dataToUpdate.append('msgtext', msg);
                }

                Axios
                    .post(C.API_EP + '/thread/reply/WithImage', dataToUpdate, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: function (progressEvent) {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            console.log(percentCompleted);
                        }
                    })
                    .then((response) => {

                        var data = response.data.data;
                        var threadId = data.threadId;


                        setImage({
                            ...image,
                            data: { ...image.data, blob: '', },
                            show: false
                        });

                        setFile(null);

                        setTextData('');

                        show();

                    })
                    .catch((error) => {
                        setLockSend(false)
                        if (error.response) {
                            if (error.response.status === 400) {
                                setLoad({ status: -1, msg: error.response.data.msg })
                            }
                        } else if (error.request) {
                            setLoad({ status: -1, message: langauges[langauge].server_respond }, 3500)
                        } else {
                            setLoad({ status: -1, message: langauges[langauge].err_req }, 3500)
                        }
                    });

            }
            if (file.type === "video") {
                var spSession = checkForSession();
                if (spSession === false) {
                    return;
                }

                var dataToUpdate = new FormData();
                dataToUpdate.append('roomId', roomId);

                dataToUpdate.append('email', spSession.email);
                dataToUpdate.append('token', spSession.token);

                dataToUpdate.append('videoFile', InputVideo.current.files[0]);

                if (textData.trim() !== "")
                    dataToUpdate.append('msgtext', textData);

                Axios
                    .post(C.API_EP + '/thread/create/WithVideo', dataToUpdate, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: function (progressEvent) {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            console.log(percentCompleted);
                        }
                    })
                    .then((response) => {

                        var data = response.data.data;
                        var threadId = data.threadId;

                    })
                    .catch((error) => {
                        setLockSend(false)
                        if (error.response) {
                            if (error.response.status === 400) {
                                setLoad({ status: -1, msg: error.response.data.msg })
                            }
                        } else if (error.request) {
                            setLoad({ status: -1, message: langauges[langauge].server_respond }, 3500)
                        } else {
                            setLoad({ status: -1, message: langauges[langauge].err_req }, 3500)
                        }
                    });

            }
        }
        else {
            var spSession = checkForSession();
            if (spSession === false) {
                return;
            }

            var dataToUpdate = {};
            dataToUpdate.email = spSession.email;
            dataToUpdate.token = spSession.token;
            dataToUpdate.roomId = roomId;

            var processedMessage = processMessage(textData)
            try {
                var msg = JSON.stringify(processedMessage);
            } catch (e) {
                return;
            }

            dataToUpdate.threadText = msg;

            dataToUpdate.replyTo = replyTo;

            console.log(dataToUpdate);
            setSending(true);

            Axios
                .post(C.API_EP + '/a/r/thread/reply', dataToUpdate)
                .then((response) => {

                    setSending(false);

                    var data = response.data.data;
                    // var thrd = response.data.thrd;
                    var threadId = data.threadId;
                    // addReply(thrd);

                    setTextData('');

                    show();

                })
                .catch((error) => {
                    setSending(false);
                    setLockSend(false)
                    if (error.response) {
                        if (error.response.status === 400) {
                            setLoad({ status: -1, msg: error.response.data.msg })
                        }
                    } else if (error.request) {
                        setLoad({ status: -1, message: langauges[langauge].server_respond }, 3500)
                    } else {
                        setLoad({ status: -1, message: langauges[langauge].err_req }, 3500)
                    }
                });

        }
    }


    return (
        <div>
            {showReply && (
                <div className="" style={{ pointerEvents: sending ? 'none' : 'auto', position: 'relative' }}>
                    <i className="fa fa-times"
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '25px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setShowReply(false)
                        }}
                    ></i>
                    <div className=""
                        style={{
                            padding: '10px',
                            margin: '10px',
                            border: '1px solid #e2e2e2',
                            boxShadow: 'rgb(0 0 0) 0px 0px 20px -18px'
                        }}>
                        {load.status === -1 &&
                            < div style={{ color: 'red' }}>
                                <p>{load.msg}</p>
                            </div>}
                        <div style={{ margin: '20px 0' }} >
                            <textarea
                                style={{
                                    width: '100%',
                                    border: '0px solid',
                                    background: '#fff',
                                    borderColor: 'transparent',
                                    boxShadow: 'none',
                                    overflow: 'auto',
                                    outline: 'none'
                                }}
                                autoFocus
                                placeholder="Write your reply"
                                disabled={sending}
                                value={textData}
                                onChange={(e) => {
                                    setTextData(e.target.value)
                                }} ></textarea>
                        </div>
                        <div>
                            {image.show && <div className="create-thread-show-img">
                                <i className="fa fa-times create-thread-show-img-close" onClick={() => {
                                    setImage({
                                        ...image,
                                        data: { ...image.data, blob: '', },
                                        show: false
                                    });
                                    setFile(null);
                                }} />
                                <img src={image.data.blob} alt="show-img" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                            </div>
                            }

                            {video.show && <div className="create-thread-show-video">
                                <i className="fa fa-times create-thread-show-video-close" onClick={() => {
                                    console.log("Click called!");
                                    setVideo({
                                        ...video,
                                        data: { ...image.data, blob: '', },
                                        show: false
                                    });
                                    setFile(null);
                                }} />
                                <video src={video.data.blob} controls style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                            </div>
                            }

                            <div className="create-thread-show-video"></div>
                            <input type="file" accept='image/gif, image/jpeg, image/png'
                                ref={InputImage} style={{ display: 'none' }} onChange={showImage} />
                            <input type="file" accept='video/mp4, video/webm, video/ogg'
                                ref={InputVideo} style={{ display: 'none' }} onChange={showVideo} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 15px', marginTop: '10px' }}>

                            <button className="btn btn-primary" disabled={(textData.trim() === "" && file === null) || lockSend}
                                onClick={() => {
                                    console.log("Clicked ... .")
                                    setLockSend(true);
                                    createThreadServer()
                                }} >Reply
                                {sending && <span style={{ paddingLeft: '10px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}

                            </button>
                            <div style={{ display: '' }} >
                                <i className="fa fa-image create-thread-upload-icon" style={{ pointerEvents: image.show || video.show || lockSend ? 'none' : 'auto' }} onClick={uploadImage} ></i>
                                {/* <i className="fa fa-video create-thread-upload-icon" style={{ pointerEvents: image.show || video.show || lockSend ? 'none' : 'auto' }} onClick={uploadVideo}></i> */}
                            </div>
                        </div>

                    </div>
                </div >
            )}
            {!showReply && (
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <button
                        className="btn btn-success"
                        style={{
                            width: '80%',
                            background: 'white',
                            color: '#1e7e34',
                            fontWeight: 'bold'
                        }}
                        onClick={() => {
                            setShowReply(true);
                        }}
                    > <i className="fa fa-plus"></i> Reply</button>
                </div>
            )}
        </div>
    )
}

export default ReplyThread
