import { UserStats } from './UserStats';
import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import C from './../../util/Constants';
import UserProfile from './UserProfile';
import UserRooms from './UserRooms';
import UserDisable from './admin/UserDisable';
import UserVerified from './admin/UserVerified';
import UserBan from './admin/UserBan';

export const UserCard = ({ user, closeHandler, updateUser, deleteUserParent }) => {

    const topBar = useRef(null);
    const restBody = useRef(null);

    const [activeTab, setActiveTab] = useState(1);

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    const [updatedRank, setUpdatedRank] = useState(0);
    const [updatedRankError, setUpdatedRankError] = useState("");

    const [usernameInput, setUsernameInput] = useState("");
    const [userDeleteLoading, setUserDeleteLoading] = useState(false);
    const [userDeleteError, setUserDeleteError] = useState("");

    useEffect(() => {


        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .get(
                C.API_EP +
                '/api/admin/user?email=' +
                spSession.email +
                '&token=' +
                spSession.token + "&uid=" + user._id
            )
            .then((response) => {
                setLoading(false);
                setData(response.data)
                setUpdatedRank(response.data.user.globalRank);
            })
            .catch((error) => {
                setLoading(false)
                console.log("Error loading status: ", error);
                if (typeof error.response == 'undefined') {
                    console.log('network error.');
                    return;
                }
            });


        adjustHeight();

        return () => {
        }
    }, [])

    const adjustHeight = () => {
        let h = topBar.current.offsetHeight
        restBody.current.style.height = 'calc(100vh - ' + h + 'px)';
        restBody.current.style.overflow = 'auto';
    }

    const updateRank = () => {

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .post(C.API_EP + "/api/admin/user/updateRank", {
                email: spSession.email,
                token: spSession.token,
                uid: user._id,
                rank: updatedRank,
            })
            .then((response) => {
                if (response.data.status) {
                    setData({ ...data, user: { ...data.user, globalRank: updatedRank } })
                    updateUser(user._id, "globalRank", updatedRank)
                }
                else {
                    setUpdatedRankError(response.data.message)
                }
            })
            .catch((error) => {

            });
    }



    const deleteUser = () => {

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        setUserDeleteLoading(true);

        Axios
            .post(C.API_EP + "/api/admin/user/delete", {
                email: spSession.email,
                token: spSession.token,
                username: usernameInput,
            })
            .then((response) => {
                setUserDeleteLoading(false);
                if (response.data.status) {
                    deleteUserParent(user._id);
                    closeHandler(false)
                }
                else {
                    setUserDeleteError(response.data.message)
                }
            })
            .catch((error) => {
                setUserDeleteLoading(false);
            });
    }


    return (
        <div style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'white',
        }}>
            <div
                ref={topBar}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px 35px'
                    }}
                >
                    <span>User Data for <span style={{ fontWeight: 'bold' }}>@{user.username}</span></span>
                    <button
                        onClick={() => {
                            closeHandler(false)
                        }
                        }
                        className="btn btn-danger">Close</button>
                </div>

                <div style={{ display: 'flex' }}>
                    <button
                        className={
                            activeTab === 1 ?
                                "btn btn-success" :
                                "btn btn-primary"
                        }
                        onClick={() => {
                            setActiveTab(1)
                        }}
                    >
                        Stats
                    </button>

                    <button
                        className={
                            activeTab === 2 ?
                                "btn btn-success" :
                                "btn btn-primary"
                        }
                        onClick={() => {
                            setActiveTab(2)
                        }}
                    >
                        Profile
                    </button>
                    <button
                        className={
                            activeTab === 3 ?
                                "btn btn-success" :
                                "btn btn-primary"
                        }
                        onClick={() => {
                            setActiveTab(3)
                        }}
                    >
                        Rooms
                    </button>

                    {false &&
                        <button
                            className={
                                activeTab === 4 ?
                                    "btn btn-success" :
                                    "btn btn-primary"
                            }
                            onClick={() => {
                                setActiveTab(4)
                            }}
                        >
                            Logins
                        </button>
                    }

                    <button
                        className={
                            activeTab === 5 ?
                                "btn btn-success" :
                                "btn btn-primary"
                        }
                        onClick={() => {
                            setActiveTab(5)
                        }}
                    >
                        Options
                    </button>

                </div>


                <hr />

            </div>

            <div ref={restBody}>
                {
                    activeTab === 1 && data.stats !== undefined && <UserStats stats={data.stats} />
                }

                {
                    activeTab === 2 && data.user !== undefined && <UserProfile profile={data.user} />
                }

                {
                    activeTab === 3 && data.user !== undefined && <UserRooms rooms={data.rooms} />
                }

                {
                    activeTab === 5 && data.user !== undefined &&
                    <div>
                        <div
                            className="form-group user-option-area"
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <div style={{
                                display: 'flex'
                            }}>
                                <label>Update GlobalRank To </label>
                                <select
                                    // disabled={!rankEnabled}
                                    onChange={(e) => {
                                        setUpdatedRank(e.target.value)
                                    }}
                                    className="form-control"
                                >
                                    <option selected={data.user.globalRank === 0} value="0">0</option>
                                    <option selected={data.user.globalRank === 1} value="1">1</option>
                                    <option selected={data.user.globalRank === 2} value="2">2</option>
                                    <option selected={data.user.globalRank === 3} value="3">3</option>
                                    <option selected={data.user.globalRank === 4} value="4">4</option>
                                    <option selected={data.user.globalRank === 5} value="5">5</option>
                                    <option selected={data.user.globalRank === 6} value="6">6</option>
                                    <option selected={data.user.globalRank === 7} value="7">7</option>
                                    <option selected={data.user.globalRank === 8} value="8">8</option>
                                    <option selected={data.user.globalRank === 9} value="9">9</option>
                                </select>
                            </div>
                            {updatedRankError !== "" &&
                                <div
                                    style={{
                                        background: '#d55a5a',
                                        color: 'white',
                                        display: 'flex',
                                        flexDirection: 'row-reverse',
                                        justifyContent: 'space-between',
                                        padding: '20px',
                                        margin: '10px',
                                        borderRadius: '3px'
                                    }}
                                >
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className="fa fa-times"
                                        onClick={() => {
                                            setUpdatedRankError("")
                                        }}
                                    ></span>
                                    <p
                                        style={{
                                            margin: 0,
                                            padding: 0
                                        }}
                                    >{updatedRankError}</p>
                                </div>
                            }
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'right'
                                }}
                            >
                                <button

                                    disabled={
                                        updatedRank === data.user.globalRank
                                    }

                                    className="btn btn-primary"

                                    onClick={() => {
                                        updateRank()
                                    }}

                                >Update</button>
                            </div>
                        </div>

                        <UserBan data={data} setData={setData} updateUser={updateUser} />

                        <UserVerified data={data} setData={setData} updateUser={updateUser} />

                        <UserDisable data={data} setData={setData} updateUser={updateUser} />

                        <div
                            className="form-group user-option-area"
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <b>Deleting this account will delete user account, profile, all settings, any rooms created, all Private Threads. It will also remove the user from any rooms joined. Are you sure you wanna do this?</b>
                            <div style={{
                                display: 'flex'
                            }}>
                                <p>Exact Username</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="usernameFilter"
                                    placeholder={"Username"}
                                    onChange={(e) => {
                                        setUsernameInput(e.target.value)
                                    }}
                                // disabled={false}
                                />
                            </div>
                            {userDeleteLoading &&
                                <div style={{
                                    background: '#d1cece',
                                    padding: '10px',
                                    margin: '10px'
                                }}>
                                    <i className="fa fa-info-circle" style={{ margin: '0 10px' }}></i>
                                    <span>Deleting user takes time ... Be patient and don't reload this page</span>
                                </div>
                            }
                            {userDeleteError !== "" &&
                                <div
                                    style={{
                                        background: '#d55a5a',
                                        color: 'white',
                                        display: 'flex',
                                        flexDirection: 'row-reverse',
                                        justifyContent: 'space-between',
                                        padding: '20px',
                                        margin: '10px',
                                        borderRadius: '3px'
                                    }}
                                >
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className="fa fa-times"
                                        onClick={() => {
                                            setUserDeleteError("")
                                        }}
                                    ></span>
                                    <p
                                        style={{
                                            margin: 0,
                                            padding: 0
                                        }}
                                    >{userDeleteError}</p>
                                </div>
                            }
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'right'
                                }}
                            >

                                <button
                                    onClick={() => {
                                        deleteUser()
                                    }}
                                    className="btn btn-danger"
                                    disabled={usernameInput !== data.user.username || userDeleteLoading}
                                >
                                    {userDeleteLoading && <span style={{ paddingLeft: '10px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                    Delete</button>
                            </div>
                        </div>


                    </div>
                }

            </div>

        </div>
    )
}
