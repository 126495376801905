import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  hideUploadFileUi,
  propogateImage,
  hidePrivateUploadFileUi,
  propogatePrivateImage,
} from './../../redux/actions/appActions';
import C from './../../util/Constants';
import axios from 'axios';

const FileUploadContainer = ({ dispatch, context, path, id, closer }) => {
  const InputFile = useRef(null);
  const [image, setImage] = useState({
    update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
    data: {
      blob: '',
    },
  });

  const [uploadError, setUploadError] = useState('Upload Error.');

  useEffect(() => {}, [dispatch]);

  // const uploadImage = () => {
  //   setImage({ ...image, update: 0 });

  //   var spSession = checkForSession();
  //   if (spSession === false) {
  //     return;
  //   }

  //   axios
  //     .get(
  //       C.API_EP +
  //         '/a/profile?email=' +
  //         spSession.email +
  //         '&token=' +
  //         spSession.token
  //     )
  //     .then((response) => {
  //       var res = response.data.profile;
  //       setProfile({ ...profile, load: 1, data: res });
  //     })
  //     .catch((error) => {
  //       if (error.status === 403) {
  //         return;
  //       }
  //       setProfile({ load: -1 });
  //     });
  // }

  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
      return spSession;
    } catch (e) {
      return false;
    }
  };

  const setBlob = (blob) => {
    setImage({
      ...image,
      data: { ...image.data, blob: blob },
    });
  };

  const closeUploadFileUi = () => {
    dispatch(hideUploadFileUi());
  };

  const closeUploadFileUiPrivate = () => {
    dispatch(hidePrivateUploadFileUi());
  };

  const selectFile = () => {
    InputFile.current.click();
  };

  const updateProfile = () => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    var dataToUpdate = new FormData();
    setImage({ ...image, update: 0 });
    // if (oldData.fullname.trim() !== profile.data.fullname.trim())
    //   dataToUpdate.append('fullname', profile.data.fullname);
    // if (oldData.gender !== profile.data.gender)
    //   dataToUpdate.append('gender', profile.data.gender);
    // if (oldData.age !== profile.data.age)
    //   dataToUpdate.append('age', profile.data.age);
    // if (oldData.about !== profile.data.about)
    if (context === 'public') dataToUpdate.append('roomId', id);

    if (context === 'private') dataToUpdate.append('threadId', id);

    dataToUpdate.append('email', spSession.email);
    dataToUpdate.append('token', spSession.token);

    if (typeof image.data.blob !== 'undefined')
      dataToUpdate.append('imageFile', InputFile.current.files[0]);
    // console.log(dataToUpdate);

    //

    //dispatch(closer());


    axios
      .post(C.API_EP + path, dataToUpdate, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {

        var data = response.data.data;
        var messageId = data.messageId;
        var propogateId = data.propogateId;

        if (context === 'public') {
          dispatch(propogateImage(messageId, propogateId, id));
          dispatch(hideUploadFileUi());
        }

        if (context === 'private') {
          dispatch(propogatePrivateImage(messageId, propogateId, id));
          dispatch(hidePrivateUploadFileUi());
        }
      })
      .catch((e) => {
        setImage({ ...image, update: -1 });
        console.log(e.response);
        if (typeof e.response !== 'undefined') {
          if (
            typeof e.response.data !== 'undefined' &&
            typeof e.response.data.msg !== 'undefined'
          )
          if(typeof e.response.data.msg === 'object')
          {
            setUploadError(e.response.data.msg.message);
          }
          else{
            setUploadError(e.response.data.msg);
          }
        }
      });
  };

  const showImage = (e) => {
    console.log(e.target);
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setBlob(e.target.result);
        console.log(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]); // convert to base64 string
    }
  };

  const uploadImage = (e) => {};

  return (
    <div className='sp-uploadfile-wrapper'>
      <div className='sp-uploadfile-inner'>
        <div className='card'>
          <div className='card-header'>
            Add Image
            <i
              className='fas fa-times sp-small-icon'
              onClick={
                context === 'public'
                  ? closeUploadFileUi
                  : closeUploadFileUiPrivate
              }
            ></i>
          </div>
          <div className='card-body'>
            {typeof image !== 'undefined' && image.update === 0 && (
              <div class='progress'>
                <div
                  class='progress-bar progress-bar-striped progress-bar-animated'
                  style={{ width: '100%' }}
                ></div>
              </div>
            )}

            {typeof image !== 'undefined' && image.update === -1 && (
              <div class='alert alert-danger'>{uploadError}</div>
            )}

            {typeof image !== 'undefined' && image.update !== 0 && (
              <div
                className='row'
                style={{ padding: '15px', width: '100%', margin: 0 }}
              >
                <div
                  className=''
                  style={{ textAlign: 'center', width: '100%' }}
                >
                  {typeof image.data.blob !== 'undefined' &&
                    image.data.blob !== '' && (
                      <div
                        style={{
                          maxHeight: '250px',
                          maxWidth: '250px',
                          margin: '5px',
                        }}
                      >
                        <img
                          src={
                            typeof image.data !== 'undefined' &&
                            typeof image.data.blob !== 'undefined' &&
                            image.data.blob
                          }
                          alt='upload file preview'
                          style={{ maxHeight: '250px', maxWidth: '250px' }}
                        />
                      </div>
                    )}
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={InputFile}
                    accept='image/gif, image/jpeg, image/png'
                    onChange={showImage}
                  />

                  <div className=''>
                    <button
                      className='btn btn-primary btn-sm'
                      style={{}}
                      onClick={selectFile}
                    >
                      Select File
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='card-footer'>
            <button
              className='btn btn-success'
              disabled={
                typeof image.data == 'undefined' ||
                typeof image.data.blob === 'undefined'
              }
              onClick={updateProfile}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    parentData: state.appState.chatbox,
  };
};

const UploadFile = connect(mapStateToProps)(FileUploadContainer);

export default UploadFile;
