import { UserStats } from './UserStats';
import Axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import C from './../../util/Constants';
import UserProfile from './UserProfile';
import UserRooms from './UserRooms';
import { Loader } from './Loader';
import RoomStats from './RoomStats';
import RoomCardAction from './admin/RoomCardAction';
import RoomAccess from './admin/RoomAccess';

export const RoomCard = ({ room, closeHandler, updateRoom, deleteRoomParent }) => {

    const topBar = useRef(null);
    const restBody = useRef(null);

    const [activeTab, setActiveTab] = useState(1);

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    const [updatedRank, setUpdatedRank] = useState(0);
    const [updatedRankError, setUpdatedRankError] = useState("");

    const [updatedVerified, setUpdatedVerified] = useState(0);
    const [updatedVerifiedError, setUpdatedVerifiedError] = useState("");
    const [updatedVerifiedSuccess, setUpdatedVerifiedSuccess] = useState("");


    const [updatedFeatured, setUpdatedFeatured] = useState(0);
    const [updatedFeaturedError, setUpdatedFeaturedError] = useState("");

    const [roomTitleInput, setRoomTitleInput] = useState("");
    const [roomDeleteLoading, setRoomDeleteLoading] = useState(false);
    const [roomDeleteError, setRoomDeleteError] = useState("");

    const [usernameInput, setUsernameInput] = useState("");
    const [transferOwnershipLoading, setTransferOwnershipLoading] = useState(false);
    const [transferOwnershipError, setTransferOwnershipError] = useState("");
    const [transferOwnershipSuccess, setTransferOwnershipSuccess] = useState("");


    useEffect(() => {

        setUpdatedFeatured(room.isFeatured ? 1 : 0)

        setLoading(true);

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .get(
                C.API_EP +
                '/api/admin/room?email=' +
                spSession.email +
                '&token=' +
                spSession.token + "&rid=" + room._id
            )
            .then((response) => {
                setLoading(false);
                setData(response.data)
                //setUpdatedVerified(response.data.user.verified)
                adjustHeight();
                console.log(response.data.room.allowGuests)
                //setUpdatedAllowedGuests(response.data.room.allowGuests)

            })
            .catch((error) => {
                setLoading(false)
                console.log("Error loading status: ", error);
                if (typeof error.response == 'undefined') {
                    console.log('network error.');
                    return;
                }
            });



        return () => {
        }
    }, [])

    const adjustHeight = () => {
        let h = topBar.current.offsetHeight
        restBody.current.style.height = 'calc(100vh - ' + h + 'px)';
        restBody.current.style.overflow = 'auto';
    }


    const updateVerified = () => {

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .post(C.API_EP + "/api/admin/room/updateFeatured", {
                email: spSession.email,
                token: spSession.token,
                rid: room._id,
                featured: updatedFeatured,
            })
            .then((response) => {
                if (response.data.status) {
                    setData({ ...data, user: { ...data.user, verified: updatedVerified } })
                    updateRoom(room._id, "isFeatured", updatedFeatured == 0 ? false : true);
                    setUpdatedVerifiedSuccess("Operation successful !");
                }
                else {
                    setUpdatedVerifiedError(response.data.message)
                }
            })
            .catch((error) => {

            });
    }

    const transferOwnership = () => {

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        setTransferOwnershipLoading(true);

        Axios
            .post(C.API_EP + "/api/admin/room/transfer", {
                email: spSession.email,
                token: spSession.token,
                roomId: room._id,
                newOwner: usernameInput,
            })
            .then((response) => {
                setTransferOwnershipLoading(false);

                if (response.data.status) {
                    //setData({ ...data, user: { ...data.user, verified: updatedVerified } })
                    //updateRoom(room._id, "isFeatured", updatedFeatured == 0 ? false : true);
                    setTransferOwnershipSuccess("Operation successful!");
                    setUsernameInput("")
                }
                else {
                    setTransferOwnershipError(response.data.message)
                }
            })
            .catch((error) => {
                setTransferOwnershipError("An error occured!")
                setTransferOwnershipLoading(false);
            });
    }


    const deleteRoom = () => {

        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        setRoomDeleteLoading(true);

        Axios
            .post(C.API_EP + "/api/admin/room/delete", {
                email: spSession.email,
                token: spSession.token,
                roomId: data.room._id,
            })
            .then((response) => {
                setRoomDeleteLoading(false);
                if (response.data.status) {
                    deleteRoomParent(data.room._id);

                    closeHandler(false)
                }
                else {
                    //setUserDeleteError(response.data.message)
                    setRoomDeleteError(response.data.message);
                }
            })
            .catch((error) => {
                // setUserDeleteLoading(false);
                setRoomDeleteLoading(false);
            });
    }

    return (
        <div style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'white',
        }}>
            {loading &&
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Loader />
                    <span
                        style={{
                            marginTop: '35px',
                            color: '#1a73e8',
                            fontWeight: 'bold'
                        }}>
                        Loading {room.title} ...
                    </span>
                </div>
            }
            {!loading &&
                <Fragment>
                    <div
                        ref={topBar}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '10px 35px'
                            }}
                        >
                            <span>Room Data for <span style={{ fontWeight: 'bold' }}>{room.title}</span></span>
                            <button
                                onClick={() => {
                                    closeHandler(false)
                                }
                                }
                                className="btn btn-danger">Close</button>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <button
                                className={
                                    activeTab === 1 ?
                                        "btn btn-success" :
                                        "btn btn-primary"
                                }
                                onClick={() => {
                                    setActiveTab(1)
                                }}
                            >
                                Details
                            </button>

                            <button
                                className={
                                    activeTab === 2 ?
                                        "btn btn-success" :
                                        "btn btn-primary"
                                }
                                onClick={() => {
                                    setActiveTab(2)
                                }}
                            >
                                Options
                            </button>


                        </div>


                        <hr />

                    </div>

                    <div ref={restBody}>
                        {
                            activeTab === 1 && data.room !== undefined && <RoomStats room={data.room} />
                        }

                        {
                            activeTab === 2 && data.room !== undefined &&
                            <div>

                                <div
                                    className="form-group user-option-area"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <label>Is Featured </label>
                                        <select
                                            onChange={(e) => {
                                                setUpdatedVerified(e.target.value)
                                                setUpdatedFeatured(e.target.value);
                                            }}
                                            className="form-control"
                                        >
                                            <option selected={data.room.isFeatured} value="1">Yes</option>
                                            <option selected={!data.room.isFeatured} value="0">No</option>
                                        </select>
                                    </div>


                                    {updatedVerifiedError !== "" &&
                                        <div
                                            style={{
                                                background: '#d55a5a',
                                                color: 'white',
                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between',
                                                padding: '20px',
                                                margin: '10px',
                                                borderRadius: '3px'
                                            }}
                                        >
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                className="fa fa-times"
                                                onClick={() => {
                                                    setUpdatedVerifiedError("")
                                                }}
                                            ></span>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            >{updatedVerifiedError}</p>
                                        </div>
                                    }

                                    {updatedVerifiedSuccess !== "" &&
                                        <div
                                            style={{
                                                background: '#4caf50',
                                                color: 'white',
                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between',
                                                padding: '20px',
                                                margin: '10px',
                                                borderRadius: '3px'
                                            }}
                                        >
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                className="fa fa-times"
                                                onClick={() => {
                                                    setUpdatedVerifiedSuccess("")
                                                }}
                                            ></span>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            >{updatedVerifiedSuccess}</p>
                                        </div>
                                    }


                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'right'
                                        }}
                                    >
                                        <button
                                            disabled={room.isFeatured ? updatedFeatured == 1 : updatedFeatured == 0}
                                            onClick={() => {
                                                updateVerified()
                                            }}
                                            className="btn btn-primary"
                                        >Update</button>
                                    </div>
                                </div>

                                <RoomCardAction data={data} setData={setData} />

                                <RoomAccess data={data} setData={setData} updateRoom={updateRoom} />

                                <div
                                    className="form-group user-option-area"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <b style={{ textAlign: 'left', margin: '5px', }}>Tranfer Room:</b>
                                    <i style={{ fontSize: '14px', textAlign: 'right' }}>All room messages and threads from current creator will also be transfered to new creator.</i>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <p>Username</p>
                                        <input
                                            style={{
                                                margin: '5px'
                                            }}
                                            type="text"
                                            className="form-control"
                                            name="usernameFilter"
                                            placeholder={"Username"}
                                            onChange={(e) => {
                                                setUsernameInput(e.target.value)
                                            }}
                                            // disabled={false}
                                            autoComplete='off'
                                        />
                                    </div>
                                    {transferOwnershipLoading &&
                                        <div style={{
                                            background: '#d1cece',
                                            padding: '10px',
                                            margin: '10px'
                                        }}>
                                            <i className="fa fa-info-circle" style={{ margin: '0 10px' }}></i>
                                            <span>Changing Ownership ... Be patient and don't reload this page</span>
                                        </div>
                                    }
                                    {transferOwnershipError !== "" &&
                                        <div
                                            style={{
                                                background: '#d55a5a',
                                                color: 'white',
                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between',
                                                padding: '20px',
                                                margin: '10px',
                                                borderRadius: '3px'
                                            }}
                                        >
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                className="fa fa-times"
                                                onClick={() => {
                                                    // setUserDeleteError("")
                                                    setTransferOwnershipError("");
                                                }}
                                            ></span>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            >{transferOwnershipError}</p>
                                        </div>
                                    }
                                    {transferOwnershipSuccess !== "" &&
                                        <div
                                            style={{
                                                background: '#4caf50',
                                                color: 'white',
                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between',
                                                padding: '20px',
                                                margin: '10px',
                                                borderRadius: '3px'
                                            }}
                                        >
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                className="fa fa-times"
                                                onClick={() => {
                                                    // setUserDeleteError("")
                                                    setTransferOwnershipSuccess("");
                                                }}
                                            ></span>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            >{transferOwnershipSuccess}</p>
                                        </div>
                                    }
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'right'
                                        }}
                                    >

                                        <button
                                            onClick={() => {
                                                //deleteRoom()
                                                transferOwnership();
                                            }}
                                            className="btn btn-danger"
                                            disabled={usernameInput === "" || transferOwnershipLoading}
                                        >
                                            {transferOwnershipLoading && <span style={{ paddingLeft: '10px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                            Transfer</button>
                                    </div>
                                </div>

                                <div
                                    className="form-group user-option-area"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <b>Deleting this room will delete room messages, threads, roles, permissions in the room. It will also remove all the members for the room. Are you sure you wanna do this?</b>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <p>Exact Room Title</p>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="usernameFilter"
                                            placeholder={"Room Title"}
                                            onChange={(e) => {
                                                // setUsernameInput(e.target.value)
                                                setRoomTitleInput(e.target.value)
                                            }}
                                            // disabled={false}
                                            autoComplete='off'
                                        />
                                    </div>
                                    {roomDeleteLoading &&
                                        <div style={{
                                            background: '#d1cece',
                                            padding: '10px',
                                            margin: '10px'
                                        }}>
                                            <i className="fa fa-info-circle" style={{ margin: '0 10px' }}></i>
                                            <span>Deleting room takes time ... Be patient and don't reload this page</span>
                                        </div>
                                    }
                                    {roomDeleteError !== "" &&
                                        <div
                                            style={{
                                                background: '#d55a5a',
                                                color: 'white',
                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                                justifyContent: 'space-between',
                                                padding: '20px',
                                                margin: '10px',
                                                borderRadius: '3px'
                                            }}
                                        >
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                className="fa fa-times"
                                                onClick={() => {
                                                    // setUserDeleteError("")
                                                    setRoomDeleteError("");
                                                }}
                                            ></span>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            >{roomDeleteError}</p>
                                        </div>
                                    }
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'right'
                                        }}
                                    >

                                        <button
                                            onClick={() => {
                                                deleteRoom()
                                            }}
                                            className="btn btn-danger"
                                            disabled={roomTitleInput !== data.room.title || roomDeleteLoading}
                                        >
                                            {roomDeleteLoading && <span style={{ paddingLeft: '10px' }}><i style={{ animation: 'rotater 1s infinite', fontSize: '19px' }} className="fa fa-circle-notch"></i> </span>}
                                            Delete</button>
                                    </div>
                                </div>


                            </div>
                        }

                    </div>
                </Fragment>
            }

        </div >
    )
}
