const english = {
    update: 'Update',
    create: 'Create',
    back: 'Back',
    title: 'Title',
    strength: 'Strength',
    color: 'Color',
    update_role: 'Update Role',
    delete_role: 'Delete Role',
    delete: 'Delete',
    create_role: 'Create Role',
    create: 'Create',
    update_success: 'Update successful!',
    update_error: 'Update Error!',
    create_error: 'Create Error!',
    server_respond: 'Server did not respond.',
    err_req: 'Error with the request being sent.',

    delete_thread_heading: 'Delete this Thread?',
    delete_thread_body: 'Deleting this thread will remove it from room threads list premanently with all replies and sub-replies. This action can not be undone.',

    delete_reply_heading: 'Delete this Reply?',
    delete_reply_body: 'Deleting this reply will remove it from the thread replies permanently. Any sub-replies will also be deleted. This action can not be undone.',

    pin: 'Pin',
    pin_thread_heading: 'Pin this Thread?',
    pin_thread_body: 'It will be shown at the top of the threads list in the room.',

    archive: 'Archive',
    archive_thread_heading: 'Archive this Thread?',
    archive_thread_body: 'Archiving this thread will disable future likes/dislikes and replies. This action can not be undone.',

    pin_reply_heading: 'Pin this Reply?',
    pin_reply_body: 'It will be shown at the top of the replies list of the thread.',

    embed_thread_heading: 'Embed Thread',
    embed_thread_body: 'Use this link to embed thread to your webpages.',

    unpin: 'Unpin',
    unpin_thread_heading: 'Unpin this Thread?',
    unpin_thread_body: 'It will be removed as pinned thread and there might be no other pinned thread in the room.',

    unpin_reply_heading: 'Unpin this Reply?',
    unpin_reply_body: 'It will be removed from Thread Reply list.',

    report_thread: 'Report Thread',
    report_reply: 'Report Reply',

    report_sexual: 'Sexual Content',
    report_spam: 'Spam or Scam',
    report_harassment: 'Harassment',
    report_violent: 'Violent Content',
    report_sensitive: 'Sensitive Content',
    report_hate: 'Hate Speech',
    report_other: 'Other',
    report: 'Report',
    report_thread_more_details: 'Provide more details...',

    unknown_error: 'An unknown error occured!',
    liked_by: 'Liked by',
    disliked_by: 'Disliked by',
    more: 'More',
    disable_replies: 'Disable Replies',
    public_thread: 'Public Thread',
    tags: 'Tags'
}

export default english
