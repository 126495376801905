import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  loadPrivateList,
  initPrivateBox,
  resetPrivateBox,
  updatePrivatePointer,
  loadMoreMessages,
  loadPrivateListMore,
} from './../../../redux/actions/appActions';
import C from './../../../util/Constants';

const PrivateListComp = ({ dispatch, list, canLoadMore }) => {
  const [activeThread, setActiveThread] = React.useState('');
  useEffect(() => {
    if (typeof list.load != 'undefined' && list.load === 2) {
      dispatch(loadPrivateList());
      adjustPrivateList();
    }
  }, []);

  const getTarget = (thread) => {
    if (thread.initiator === null) {
      return thread.responder;
    } else {
      return thread.initiator;
    }
  };

  const adjustPrivateList = () => {
    var spSidebar = document.getElementById('sp-sidebar-header');
    var offsetHeight = spSidebar.offsetHeight;
    console.log(offsetHeight);

    var privateList = document.getElementsByClassName('sp-sidebar-2')[0];
    var finalHeightValue = 'calc(100vh-' + offsetHeight + 'px)';
    console.log(finalHeightValue);
    privateList.style.maxHeight = finalHeightValue;
    privateList.style.overflow = 'auto';
  };

  const loadPrivateBox = (thread) => {
    console.log('[reset] ***** Private box if already installed *****');

    localStorage.removeItem(C.SP_LOADED_ROOM);
    localStorage.setItem(C.SP_LOADED_PRIVATE_THREAD, thread._id);

    setActiveThread(thread._id);
    dispatch(resetPrivateBox());
    setTimeout(function () {
      dispatch(initPrivateBox(thread));
      if (thread.lastMessage !== null) {
        dispatch(updatePrivatePointer(thread._id, thread.lastMessage._id));
      }
    }, 300);
  };

  const _loadPrivateListMore = () => {
    var index = list.data.length / 10;
    console.log(index);
    dispatch(loadPrivateListMore(index));
  }

  return (
    <div>
      {typeof list !== 'undefined' && list.load === 2 && (
        <div class='progress' style={{ height: '7px' }}>
          <div
            class='progress-bar progress-bar-striped progress-bar-animated'
            style={{ width: '100%' }}
          ></div>
        </div>
      )}
      <ul className='list-group sc-pm-list'>
        {typeof list !== 'undefined' &&
          Array.isArray(list.data) &&
          list.data.length !== 0 &&
          list.data.map((thread) => {
            var target = getTarget(thread);
            return (
              <li
                key={thread._id}
                className={
                  activeThread === thread._id
                    ? 'list-group-item d-flex justify-content-between align-items-center sc-pm-list-item-selected'
                    : 'list-group-item d-flex justify-content-between align-items-center sc-pm-list-item'
                }
                style={{
                  border: 'none',
                  borderRadius: '0',
                  fontWeight:
                    (typeof thread.new !== 'undefined' && thread.new) ||
                      (typeof thread.unreadMessages !== 'undefined' &&
                        thread.unreadMessages !== 0) ||
                      thread.accepted === 0
                      ? 'bolder'
                      : 'normal',
                  opacity: thread.blockers.length === 0 ? '1' : '0.3',
                }}
                onClick={() => {
                  loadPrivateBox(thread);
                }}
              >
                {
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      width: '100%',
                    }}
                  >
                    <div
                      className='sc-pm-avaar'
                      style={{ display: 'inline-flex', marginRight: '15px' }}
                    >
                      <img
                        src={
                          typeof target.profile !== 'undefined' &&
                            thread.blockers.length === 0
                            ? C.ASSET_EP +
                            '/avatars/' +
                            target.profile.picture.split('.')[0] +
                            '_sm.' +
                            target.profile.picture.split('.')[1]
                            : C.ASSET_EP + '/avatars/default_sm.png'
                        }
                        alt='user avatar'
                        className='rounded-circle'
                        style={{ height: '45px', width: '45px' }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth: 'calc(100% - 40px)',
                      }}
                      className='sc-pm-content'
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span
                          style={{
                            color: '#525252',
                            fontSize: '20px',
                            fontFamily: 'sans-serif',
                          }}
                        >
                          {thread.blockers.length === 0
                            ? target.username
                            : 'Blocked User'}
                        </span>
                        <div
                          className='sc-pm-date'
                          style={{ fontSize: '10px' }}
                        >
                          {thread.lastMessage === null
                            ? new Date(thread.date).toLocaleDateString()
                            : new Date(
                              thread.lastMessage.date
                            ).toLocaleDateString()}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <i
                          style={{
                            color: '#969691',
                            width: '80%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontStyle: 'normal',
                          }}
                        >
                          {thread.lastMessage !== null &&
                            thread.lastMessage.writer._id ===
                            localStorage.getItem(C.SP_USER_ID)
                            ? 'You: '
                            : ''}

                          {thread.lastMessage === null
                            ? thread.accepted === 1
                              ? 'Conversation not started'
                              : thread.accepted === 0
                                ? 'Private Request Pending'
                                : 'Private Request Rejected!'
                            : thread.lastMessage.message.type === 'image'
                              ? <span className="fa fa-image"></span>
                              : thread.lastMessage.message.type === 'video' ? <span className="fa fa-video"></span> : thread.lastMessage.message.content}
                        </i>
                        {thread.unreadMessages !== 0 && (
                          <span
                            class='badge badge-danger badge-pill'
                            style={{
                              borderRadius: '50%',
                              padding: '5px 8px',
                            }}
                          >
                            {thread.unreadMessages}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </li>
            );
          })}
        {list.canLoadMore ? <span onClick={_loadPrivateListMore} style={{ textAlign: 'center', color: '#0e0e10', textDecoration: 'underline', cursor: 'pointer' }}>Load more ...</span> : <span></span>}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.appState.Private.list,
  };
};

const PrivateList = connect(mapStateToProps)(PrivateListComp);

export default PrivateList;
