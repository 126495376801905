import Axios from 'axios';
import React from 'react'
import MediaPlayerPortable from '../util/MediaPlayerPortable';
import ThreadGalleryLight from '../util/ThreadGalleryLight';
import C from './../../util/Constants';


export default function ThreadItem({
    roomId,
    thread,
    permissions,
    clickHandler,
    deleteHandler,
    pinHandler,
    iOwn,
    unPinHandler,
    profileHandler,
    embedHandler,
    reportHandler,
    pinned,
    archiveHandler,
    tagHandler
}) {
    //position can be 1 - meaning first, -1 - meaning last, or 0 - meaning middle child

    const [videoState, setVideoState] = React.useState(false);
    const [showOptions, setShowOptions] = React.useState(false);
    const [showMediaPlayer, setShowMediaPlayer] = React.useState(false);
    const [media, setMedia] = React.useState({});
    const Video = React.useRef(null);
    const _ref = React.useRef(null);

    const checkForSession = () => {
        if (localStorage.getItem(C.SP_SESSION) == null) {
            return false;
        }

        try {
            var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
            return spSession;
        } catch (e) {
            return false;
        }
    };

    const likeThread = () => {
        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios.post(C.API_EP + '/a/r/thread/like', {
            email: spSession.email,
            token: spSession.token,
            threadId: thread._id,
            roomId: roomId
        }
        ).then(r => {
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                }
            } else if (error.request) {
            } else {
            }
        })
    }

    const dislikeThread = () => {
        var spSession = checkForSession();

        if (spSession === false) {
            return;
        }

        Axios.post(C.API_EP + '/a/r/thread/dislike', {
            email: spSession.email,
            token: spSession.token,
            threadId: thread._id,
            roomId: roomId
        }
        ).then(r => {
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                }
            } else if (error.request) {
            } else {
            }
        })
    }

    React.useEffect(() => {
        parsedMessage(thread.message)
        console.log(thread)
    }, [])

    const handleClickOutside = () => {
        console.log("Clicked somewhere else.");
    }

    React.useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (_ref.current && !_ref.current.contains(event.target)) {
                console.log("You clicked outside of me!");
                setShowOptions(false)

            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [_ref]);

    const [parsedData, setParsedData] = React.useState([]);

    const parsedMessage = (message) => {
        setParsedData(JSON.parse(message.content));
    }

    const getDate = (d) => {
        var oldDate = new Date(d);
        var newDate = new Date();
        var diff = newDate - oldDate;
        var sec = diff / 1000;

        if (sec < 60) {
            return 'Just Now';
        }

        var min = sec / 60;

        if (min < 60) {
            if (Math.round(min) === 1)
                return Math.round(min) + ' minute';
            else
                return Math.round(min) + ' minutes';
        }

        var hours = min / 60;

        if (hours < 24) {
            if (Math.round(hours) === 1)
                return Math.round(hours) + " hour";
            else
                return Math.round(hours) + " hours";

        }

        var days = hours / 24;

        if (days < 7) {
            if (Math.round(days) === 1)
                return Math.round(days) + " day";
            else
                return Math.round(days) + " days";
        }

        var weeks = days / 7;

        if (weeks < 4) {
            if (Math.round(weeks) === 1)
                return Math.round(weeks) + ' week';
            else
                return Math.round(weeks) + ' weeks';
        }

        var months = weeks / 4;

        if (months < 12) {
            if (Math.round(months) === 1)
                return Math.round(months) + ' month';
            else
                return Math.round(months) + ' months';

        }

        var years = months / 12;

        if (years <= 5) {
            if (Math.round(years) === 1)
                return Math.round(years) + ' year';
            else
                return Math.round(years) + ' years';

        }

        return oldDate.getFullYear();
    }

    return (
        <div className="thread-item" onClick={thread.allowedReplies ? clickHandler : () => { }} ref={_ref}>
            {typeof thread.archived !== 'undefined' &&
                thread.archived &&
                <div>
                    <span
                        style={{
                            color: '#da5c69',
                            fontSize: '11px',
                            marginLeft: '20px',
                            background: '#fff',
                            padding: '3px',
                            borderRadius: '4px',
                            fontWeight: 'bold'
                        }}
                    >
                        <i className="fas fa-archive" style={{ verticalAlign: 'middle' }}></i>
                        &nbsp;Archived Thread
                    </span>
                </div>
            }

            <div className="thread-item-item thread-item-head">
                <div className="thread-item-item" style={{ alignItems: 'center' }}>
                    <img
                        src={typeof thread.member.user.profile !==
                            'undefined' &&
                            C.ASSET_EP +
                            '/avatars/' +
                            thread.member.user.profile.picture.split(
                                '.'
                            )[0] +
                            '_sm.' +
                            thread.member.user.profile.picture.split('.')[1]}
                        alt="User Avatar"
                        style={{ borderRadius: '20px', width: '40px', height: '40px', cursor: 'pointer' }}
                        onClick={(e) => { e.stopPropagation(); profileHandler(thread.member.user._id) }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', alignItems: 'flex-start' }}>
                        <span
                            style={{
                                fontWeight: 'bold',
                                fontSize: 'medium',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <span style={{
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '13ch'
                            }}>{thread.member.user.profile.fullname}</span>
                            <span style={{ color: '#bdbdbd', fontWeight: 'normal' }}>@{thread.member.user.username}</span></span>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ background: thread.isCreator ? '#e51024' : thread.member.role.color, color: "white", borderRadius: '10px', padding: '1px 4px', fontSize: 'small', fontWeight: 'bold' }}>{thread.isCreator ? 'creator' : thread.member.role.title}</span>
                            <span style={{ padding: '0 8px', fontWeight: 'bold' }}>&#183;</span>
                            <span style={{ fontSize: 'small', color: '#777' }}> {getDate(thread.date)}</span>
                        </span>
                    </div>
                </div>
                <div>
                    <i className={"fal fa-ellipsis-h"}
                        onClick={(e) => { e.stopPropagation(); setShowOptions(!showOptions) }}
                        style={{ padding: '10px', fontWeight: 'initial' }}
                    ></i>
                    {showOptions && <div className="thread-options">

                        {(permissions.ManageThread || localStorage.getItem(C.SP_USER_ID) === thread.member.user._id) && <span className="thread-options-item"
                            onClick={(e) => { e.stopPropagation(); deleteHandler(thread._id) }}
                        > <i className="fal fa-trash"></i> &nbsp; Delete Thread</span>}

                        {!thread.archived && (permissions.ManageThread || localStorage.getItem(C.SP_USER_ID) === thread.member.user._id) && <span className="thread-options-item"
                            onClick={(e) => { e.stopPropagation(); archiveHandler(thread._id) }}
                        > <i className="fal fa-archive"></i> &nbsp; Archive Thread</span>}

                        {iOwn && pinned && <span className="thread-options-item"
                            onClick={(e) => { e.stopPropagation(); unPinHandler(thread) }}
                        ><i className="fal fa-thumbtack" style={{ transform: 'rotateX(180deg)' }}></i> &nbsp; Unpin Thread</span>}

                        {iOwn && !pinned && <span className="thread-options-item"
                            onClick={(e) => { e.stopPropagation(); pinHandler(thread) }}
                        ><i className="fal fa-thumbtack"></i> &nbsp; Pin Thread</span>}

                        <span
                            className="thread-options-item"
                            onClick={(e) => { e.stopPropagation(); reportHandler(thread._id) }}
                        >
                            <i className="fal fa-flag"></i> &nbsp; Report Thread
                        </span>

                        {thread.public && <span className="thread-options-item"
                            onClick={(e) => { e.stopPropagation(); embedHandler(thread) }}
                        ><i className="fal fa-link"></i> &nbsp; Embed Thread</span>}

                    </div>
                    }
                </div>
            </div>
            <div className="thread-item-body" style={{ padding: '5px 10px 5px 20px' }}>
                {
                    parsedData.map((item, index) => {
                        if (item.type === 'text') {
                            //3px 20px
                            return <span style={{ padding: '', margin: '0', whiteSpace: 'pre-wrap' }} key={index}>{item.data}</span>;
                        }
                        if (item.type === "mention") {
                            return <span onClick={(e) => { e.stopPropagation(); profileHandler(item.extras._id) }} style={{ padding: '', margin: '0 2px', color: 'blue', cursor: 'pointer' }} key={index}>{'@'}<span style={{ textDecoration: 'underline' }}>{item.data}</span></span>;
                        }
                        if (item.type === 'image') {
                            return <img style={{
                                width: '100%',
                                display: 'block',
                                border: '1px solid #eee',
                                height: '100%',
                                maxHeight: '40vh',
                                overflow: 'hidden',
                                objectFit: 'contain'
                            }}
                                src={C.API_EP + '/uploads/images/' + item.data}
                                alt="user upload"
                                key={index}
                            />
                        }
                        if (item.type === 'video') {
                            return (
                                <div style={{ position: 'relative' }} key={index}>
                                    <video style={{ width: '100%', display: 'block', border: '1px solid #eee', height: '100%', maxHeight: '40vh', overflow: 'hidden', objectFit: 'contain' }} src={C.API_EP + '/uploads/videos/' + item.data} alt="user upload" controls={videoState} ref={Video} />
                                    {!videoState &&
                                        <div style={{ position: 'absolute', top: '0', left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', background: '#6d6a6ac2' }}>
                                            <i className="fa fa-play" style={{ fontSize: '65px', cursor: 'pointer' }} onClick={(e) => {
                                                e.stopPropagation()
                                                setVideoState(true);
                                                Video.current.play();
                                            }}></i>
                                        </div>
                                    }
                                </div>)
                        }
                        if (item.type === 'gallery') {
                            return <ThreadGalleryLight gallery={item.data} setMedia={setMedia} setShowMediaPlayer={setShowMediaPlayer} key={index} />
                        }
                    })
                }
            </div>

            {true
                &&
                <div style={{ padding: '5px 10px 5px 20px' }}>
                    {thread.tags.map(e =>
                        <span
                            style={{
                                fontSize: 'small',
                                margin: '3px',
                                border: '1px solid #777',
                                padding: '1px 5px',
                                borderRadius: '9px',
                            }}
                            onClick={(ev) => { ev.stopPropagation(); tagHandler(e) }}
                        >{e}</span>
                    )}
                </div>
            }

            {
                true &&
                <div className="thread-item-item" disabled={permissions.ReactThread} style={{ justifyContent: 'space-between', padding: '5px 15px', marginTop: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                            onClick={(e) => { e.stopPropagation(); likeThread() }}
                            style={{
                                margin: '0 8px', pointerEvents: thread.archived ? 'none' : 'auto',
                                color: thread.archived ? '#c2c2c2' : ''
                            }}
                        >
                            <i
                                className={permissions.ReactThread ? thread.likes.includes(localStorage.getItem(C.SP_USER_ID)) ? "fal fa-arrow-alt-up like-done" : "fal fa-arrow-alt-up likeable" : "fal fa-arrow-alt-up"}
                                style={{ fontSize: '20px', padding: '10px', color: thread.archived ? '#c2c2c2' : '' }}>

                            </i>
                        </div>
                        {thread.likes.length - thread.dislikes.length !== 0 && <span className="score" style={{ display: 'flex', alignItems: 'center' }} >{thread.likes.length - thread.dislikes.length}</span>}
                        <div
                            style={{
                                margin: '0 8px', pointerEvents: thread.archived ? 'none' : 'auto'

                            }}
                            onClick={(e) => { e.stopPropagation(); dislikeThread() }}
                        >
                            <i className={
                                permissions.ReactThread
                                    ? thread.dislikes.includes(localStorage.getItem(C.SP_USER_ID))
                                        ? "fal fa-arrow-alt-down dislike-done"
                                        : "fal fa-arrow-alt-down dislikeable"
                                    : "fal fa-arrow-alt-down "}
                                style={{
                                    fontSize: '20px',
                                    padding: '10px',
                                    color: thread.archived ? '#c2c2c2' : ''
                                }}
                            ></i>
                        </div>
                    </div>
                    <div
                        style={{}}
                    >
                        {thread.replies !== 0 && thread.replies}
                        <i style={{ fontSize: '20px', padding: '6px', color: thread.allowedReplies && !thread.archived ? '' : '#c2c2c2' }} className="fal fa-reply"></i>
                    </div>
                </div>
            }
            {showMediaPlayer &&
                <MediaPlayerPortable media={media} closeHandler={setShowMediaPlayer} />
            }
        </div >
    )
}
