import React, { useState, useEffect } from 'react'
import io from "socket.io-client";
import { checkForSession } from '../redux/actions/appActions';
import C from './../util/Constants';

var mediaRecorder;
var roomSocket;
var i = 1;
var actualStream;
var source;
var captureNode;
var recordAudioProcessor;
const audioContext = new AudioContext();
const playerContext = new AudioContext();
var primaryGainControl;
var sentFirstPacket = 0;
var reload = 4;
const buffer = audioContext.createBuffer(
    1,
    audioContext.sampleRate * 1,
    audioContext.sampleRate
);

function Tests() {

    const [recording, setRecording] = useState(false)
    const [init, setInit] = useState(false)

    useEffect(() => {
        var spSession = checkForSession();
        roomSocket = io(C.SOCKET_EP + "/room", {
            query: { email: spSession.email, token: spSession.token },
        });
        setInit(true);

        // let primaryGainControlPlayer = audioContext.createGain();
        // primaryGainControlPlayer.gain.setValueAtTime(0.05, 0);
        // primaryGainControlPlayer.connect(audioContext.destination);



        const playerBuffer = playerContext.createBuffer(
            1,
            playerContext.sampleRate * 1,
            playerContext.sampleRate
        );


        //console.log(recordAudioProcessor);

        roomSocket.on('audioSliceTestBroadcast', (data) => {
            var packet = new Float32Array(data);
            // playPacket(array);
            const whiteNoiseSource = playerContext.createBufferSource();
            playerBuffer.getChannelData(0).set(packet)
            whiteNoiseSource.buffer = playerBuffer;
            whiteNoiseSource.connect(playerContext.destination);
            whiteNoiseSource.start();
            console.log("Playing ...")
        })
    }, [])

    const playPacket = (packet) => {
        var channelData = buffer.getChannelData(0);

        for (let i = 0; i < buffer.length; i++) {
            channelData[i] = packet[i];
        }
        var source = audioContext.createBufferSource()
        source.buffer = buffer
        source.connect(primaryGainControl)

    }

    const startRecord = () => {
        navigator
            .mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then(handleSuccess);
    }

    const handleSuccess = async (stream) => {

        actualStream = stream;

        let track = stream.getAudioTracks()[0];
        console.log(track.getCapabilities());
        console.log(actualStream)

        source = audioContext.createMediaStreamSource(stream);
        primaryGainControl = audioContext.createGain();
        primaryGainControl.gain.setValueAtTime(0.1, 0);

        primaryGainControl.connect(audioContext.destination);


        // captureNode = audioContext.createScriptProcessor(16384, 1, 1);
        // captureNode.addEventListener('audioprocess', (e) => {
        //     var inputData = e.inputBuffer.getChannelData(0);
        //     var outputData = e.outputBuffer.getChannelData(0);
        //     const rawLeftChannelData = e.inputBuffer.getChannelData(0);
        //     console.log(sentFirstPacket);
        //     if (sentFirstPacket < 939) {
        //         //console.log(rawLeftChannelData);
        //         //console.log(rawLeftChannelData.buffer)
        //         roomSocket.binary(true).emit('audioSliceTest', rawLeftChannelData.buffer);
        //         sentFirstPacket++;
        //     }
        //     // rawLeftChannelData is now a typed array with floating point samples
        //     // for (var sample = 0; sample < e.inputBuffer.length; sample++) {
        //     //     // make output equal to the same as the input
        //     //     outputData[sample] = inputData[sample];
        //     // }
        // });

        // captureNode.connect(primaryGainControl);

        await audioContext.audioWorklet.addModule('/assets/scripts/recordAudioProcessor.js');
        recordAudioProcessor = new AudioWorkletNode(audioContext, 'record-audio-processor');




        recordAudioProcessor.connect(audioContext.destination);
        source.connect(recordAudioProcessor);

        setRecording(true);
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
            {
                init && recording
                    ?
                    <button className="btn btn-primary" onClick={() => {

                        // console.log(source)
                        actualStream.getTracks()[0].stop();
                        // source.mediaStream.getTracks()[0].stop();
                        audioContext.close();
                        source.disconnect();
                        recordAudioProcessor.disconnect();
                        setRecording(false);
                        console.log(recordAudioProcessor)
                    }} >Stop Record</button>
                    :
                    <button className="btn btn-primary" onClick={() => {
                        startRecord();
                    }} >Start Record</button>
            }
        </div>
    )
}

export default Tests
