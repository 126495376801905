import React from 'react'

const UserProfile = ({ profile }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <img
          style={{
            maxWidth: '150px',
            maxHeight: '150px',
            border: '2px solid #777',
            padding: '5px',
            borderRadius: '10px'
          }}
          src={'https://api2.sparkle-chat.com/avatars/' + profile.profile.picture}
        />
        <div
          style={{
            textAlign: 'left',
            padding: '5px 15px'
          }}
        >
          <div>
            <span>{profile.profile.fullname}</span>@
            <span style={{ fontWeight: 'bold' }}>{profile.username}</span>
          </div>
          <div>
            {profile.profile.gender === 2 && 'Female, '}
            {profile.profile.gender === 1 && 'Male, '}
            {profile.profile.age} years old
          </div>
          <br />
          <div
            style={{
              maxHeight: '200px',
              overflow: 'auto'
            }}
          >
            {profile.profile.about}
          </div>
        </div>
      </div>

      <hr />

      <div>
        <p
          style={{
            background: profile.settings.color,
            color: profile.settings.tcolor
          }}
        >Sample Message</p>
      </div>

    </div>
  )
}

export default UserProfile
