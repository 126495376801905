import * as actions from "../constants/action-types";
import C from "../../util/Constants";
import axios from "axios";
import io from "socket.io-client";
import store from "./../store/index";
import socket from "socket.io-client/lib/socket";

var roomSocket = null;
var guestSocket = null;
var privateSocket = null;
var globalSocket = null;

export function initRoomSocket() {
  return async (dispatch) => {
    var spSession = checkForSession();

    console.log("**** SETTING UP SOCKETS ****");

    if (roomSocket === null) {
      roomSocket = io(C.SOCKET_EP + "/room", {
        query: { email: spSession.email, token: spSession.token },
      });

      //console.log(roomSocket);
      console.log(store.getState().appState.chatbox.socket);
      dispatch({
        type: actions.SET_ROOM_SOCKET,
        payload: roomSocket,
      })
      console.log(store.getState().appState.chatbox.socket);
    }

    if (typeof roomSocket._callbacks["$roomMessages"] === "undefined") {
      roomSocket.on("sendMessageAck", function (data) {
        console.log("Data status ....");
        console.log(data);
        console.log(data.status);
        if (data.status !== 0) {
          dispatch(fetchIncomingMessage(data.messageId));
          dispatch(roomMessageSuccess());
        } else {
          dispatch(roomMessageFailure());
        }
      });

      roomSocket.on("roomMessages", function (data) {
        //console.log('Just got room messages ....');
        var loadRoomIntervalEnd = new Date();
        console.log("Interval: ", loadRoomIntervalEnd - loadRoomInterval);

        console.log(new Date());
        console.log(data);
        var sortedData = data.data.sort(sorter);
        dispatch(
          loadRoomSuccess(
            data.roomDetails,
            sortedData,
            data.onlineUsers,
            data.creator,
            data.role,
            data.ignoreList,
            data.canLoadMore
          )
        );
      });


      roomSocket.on('newThread', function (threadId) {
        var spSession = checkForSession();
        var roomId = store.getState().appState.chatbox.roomId;
        axios.get(C.API_EP + '/a/r/getSingleThreadShort?email=' + spSession.email + "&token=" + spSession.token + "&roomId=" + roomId + "&threadId=" + threadId)
          .then((response) => {
            dispatch(pushThread(response.data.thrd));
          })
          .catch((error) => {
            console.log(error);
          })
      })

      roomSocket.on('deleteThread', function (data) {
        var roomId = store.getState().appState.chatbox.roomId;
        if (roomId === data.roomId) {
          dispatch(deleteThread(data.threadId))
        }
      })

      roomSocket.on('deleteReply', function (data) {
        var roomId = store.getState().appState.chatbox.roomId;
        if (roomId === data.roomId) {
          dispatch(deleteReply(data.replyId, data.threadId))
        }
      })

      roomSocket.on('newReply', function (data) {
        var roomId = store.getState().appState.chatbox.roomId;
        // var threadId = store.getState().appState.chatbox.replies.thread._id;
        var replyId = data.replyId;

        // alert("zest");
        console.log(roomId + " : " + data.roomId);
        // console.log(threadId + " : " + data.threadId);
        console.log(replyId);

        //&& threadId === data.threadId
        if (roomId === data.roomId) {
          // dispatch(likeThread(data.threadId, data.userId))
          // alert("hello");
          axios.get(C.API_EP + '/a/r/getSingleThreadShort?email=' + spSession.email + "&token=" + spSession.token + "&roomId=" + roomId + "&threadId=" + replyId)
            .then((response) => {
              console.log(response.data.thrd);
              dispatch(pushReply(response.data.thrd, data.threadId));
              //
            })
            .catch((error) => {
              console.log(error);
            })
        }

      })


      roomSocket.on('likeThread', function (data) {
        var roomId = store.getState().appState.chatbox.roomId;
        if (roomId === data.roomId) {
          dispatch(likeThread(data.threadId, data.userId))
        }
      })

      roomSocket.on('unlikeThread', function (data) {
        var roomId = store.getState().appState.chatbox.roomId;
        if (roomId === data.roomId) {
          dispatch(unlikeThread(data.threadId, data.userId))
        }
      })

      roomSocket.on('dislikeThread', function (data) {
        var roomId = store.getState().appState.chatbox.roomId;
        if (roomId === data.roomId) {
          dispatch(dislikeThread(data.threadId, data.userId))
        }
      })

      roomSocket.on('undislikeThread', function (data) {
        var roomId = store.getState().appState.chatbox.roomId;
        if (roomId === data.roomId) {
          dispatch(undislikeThread(data.threadId, data.userId))
        }
      })

      roomSocket.on('archiveThread', function (data) {
        var roomId = store.getState().appState.chatbox.roomId;
        if (roomId === data.roomId) {
          dispatch(archiveThread(data.threadId, data.userId))
        }
      })


      roomSocket.on("newMessage", function (data) {
        console.log("New message ...");
        console.log(data);
        dispatch(fetchIncomingMessage(data));
      });

      roomSocket.on("loadRoomError", function (data) {
        // dispatch(
        //   doDispatchNotification({
        //     status: 'danger',
        //     heading: "Load Room Error",
        //     msg: data.msg,
        //   })
        // );

        dispatch(loadRoomFailure("Can't retrieve data!", data.msg));
      });

      roomSocket.on("userJoined", function (data) {
        console.log("User Joined!");
        console.log(data);
        var loadedView = store.getState().appState.spGlobal.layout.loadedView;
        var currentRoomId = store.getState().appState.chatbox.roomId;
        if (loadedView === "roomchat" && data.room._id === currentRoomId) {
          dispatch({ type: actions.USER_JOINED_ROOM, payload: data.user });
          // dispatch(
          //   doDispatchNotification({
          //     status: 'success',
          //     heading: 'User Joined',
          //     msg: data.user.username + ' joined the room.',
          //   })
          // );
        }
      });

      roomSocket.on("userLeft", function (data) {
        console.log("User Left!");
        console.log(data);
        var loadedView = store.getState().appState.spGlobal.layout.loadedView;
        var currentRoomId = store.getState().appState.chatbox.roomId;
        if (loadedView === "roomchat" && data.room._id === currentRoomId) {
          console.log("User Left Room in process ...");
          dispatch({ type: actions.USER_LEFT_ROOM, payload: data });
          // dispatch(
          //   doDispatchNotification({
          //     status: 'danger',
          //     heading: 'User Left',
          //     msg: data.user.username + ' left the room.',
          //   })
          // );
        }
      });

      roomSocket.on("upDeleteMessageAck", function (data) {
        console.log(data.messageId);
        dispatch({ type: actions.DELETE_MESSAGE, messageId: data.messageId });
      });

      roomSocket.on("downDeleteMessage", function (data) {
        console.log(data);
        dispatch({ type: actions.DELETE_MESSAGE, messageId: data });
      });

      roomSocket.on("downError", function (data) {
        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: data.heading,
            msg: data.msg,
          })
        );
      });

      roomSocket.on("roomCleared", function ({ roomId, username }) {
        var loadedView = store.getState().appState.spGlobal.layout.loadedView;
        var currentRoomId = store.getState().appState.chatbox.roomId;
        if (loadedView === "roomchat" && roomId === currentRoomId) {
          dispatch(
            doDispatchNotification({
              status: "success",
              heading: "Clear",
              msg: "This room was cleared by " + username + ".",
            })
          );
          dispatch({ type: actions.CLEAR_ROOM_MESSAGES });
        }
      });

      roomSocket.on("disconnect", function () {
        console.log("SOCKET  *****disconnected");
        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Network Error!",
            msg: "Can't access internet!",
          })
        );
      });
      roomSocket.on("reconnecting", function () {
        console.log("Reconnecting ....");
      });
      roomSocket.on("reconnect", function () {
        var roomId = store.getState().appState.chatbox.roomId;
        var loadedView = store.getState().appState.spGlobal.layout.loadedView;
        if (
          typeof loadedView !== "undefined" &&
          loadedView === "roomchat" &&
          typeof roomId !== "undefined" &&
          /^[a-z0-9]+$/.test(roomId)
        ) {
          var spSession = checkForSession();

          roomSocket.emit("loadRoom", { cred: spSession, id: roomId });
          dispatch(
            doDispatchNotification({
              status: "success",
              heading: "Back Online!",
              msg: "We are connected again!",
            })
          );
        }
      });
    }
  };
}


export function joinRoomBroadcast(id) {
  return (dispatch) => {

    dispatch({ type: actions.JOIN_BROADCAST });


    //alert()
    //console.log(roomSocket._callbacks['$listenerJoined'])
    if (roomSocket._callbacks['$listenerJoined'] === undefined) {
      roomSocket.on("listenerJoined", function (data) {
        // console.log(data);
        // var userObj = JSON.parse(user);
        // console.log({ userObj })
        // dispatch(appendToAudioStreamBuffer(userObj.username, userObj.avatar, data));
        console.log({ data });
        dispatch({ type: actions.LISTENER_JOINED, listener: data.user },);
      });

      roomSocket.on("listenerLeft", function (user) {
        // console.log(data);
        // var userObj = JSON.parse(user);
        // console.log({ userObj })
        // dispatch(appendToAudioStreamBuffer(userObj.username, userObj.avatar, data));
        console.log({ user });
        dispatch({ type: actions.LISTENER_LEFT, username: user.username },);
      });


      roomSocket.on('userLeftBroadcast', function (username) {
        dispatch({ type: actions.SPEAKER_LEFT, username: username })
        dispatch({ type: actions.LISTENER_LEFT, username: username },);
      })

      roomSocket.on('userJoinedBroadcast', function (data) {
        dispatch({ type: actions.LISTENER_JOINED, listener: data.user },);
      })

      roomSocket.on("speakerJoined", function (data, packets) {
        // console.log(data);
        // var userObj = JSON.parse(user);
        // console.log({ userObj })
        // dispatch(appendToAudioStreamBuffer(userObj.username, userObj.avatar, data));
        var { user } = data;
        // console.log({ user })
        // console.log(user.username)
        // console.log(user.avatar)
        // return
        console.log("Zest: 92", packets)
        dispatch({ type: actions.SPEAKER_JOINED, username: user.username, avatar: user.avatar },);
        if (packets)
          packets.map(e => {
            dispatch(appendToAudioStreamBuffer(user.username, user.avatar, e));

          })
      });

      roomSocket.on("meSpeakerJoined", function (data) {
        // console.log(data);
        // var userObj = JSON.parse(user);
        // console.log({ userObj })
        // dispatch(appendToAudioStreamBuffer(userObj.username, userObj.avatar, data));
        var { user } = data;
        // console.log({ user })
        // console.log(user.username)
        // console.log(user.avatar)
        // return
        //alert("meSpeakerJoined")
        dispatch({ type: actions.ME_SPEAKER_JOINED, username: user.username, avatar: user.avatar },);

      });


      roomSocket.on("meSpeakerLeft", function (data) {
        // console.log(data);
        // var userObj = JSON.parse(user);
        // console.log({ userObj })
        // dispatch(appendToAudioStreamBuffer(userObj.username, userObj.avatar, data));
        //alert(JSON.stringify(data));
        dispatch({ type: actions.ME_SPEAKER_LEFT, username: data.username })
      });

      roomSocket.on("speakerLeft", function (data) {
        // console.log(data);
        // var userObj = JSON.parse(user);
        // console.log({ userObj })
        // dispatch(appendToAudioStreamBuffer(userObj.username, userObj.avatar, data));
        //alert(JSON.stringify(data));
        dispatch({ type: actions.SPEAKER_LEFT, username: data.username })
      });


      setTimeout(() => {
        roomSocket.on("audioStream", function (user, data) {
          console.log(data);
          var userObj = JSON.parse(user);
          console.log({ userObj })
          dispatch(appendToAudioStreamBuffer(userObj.username, userObj.avatar, data));

        });
      }, 1)

    }

    roomSocket.emit("joinBroadcast", { id: id }, (status, permission, listeners, speakers) => {
      if (status) {
        dispatch({ type: actions.JOIN_BROADCAST_SUCCESS, permission: permission, listeners: listeners });
        speakers.map(({ username, avatar }) => {
          alert(username + " : " + avatar);
          var audio = store.getState().appState.chatbox.stream.audio;
          console.log(audio);
          var alreadyInit = audio.some((e) => e.username === username);

          console.log(alreadyInit);

          if (!alreadyInit) {
            var arr = [];
            arr[username] = [];

            dispatch({
              type: actions.INIT_AUDIO_STREAM_SPEAKER,
              username: username,
              avatar: avatar
            });

            dispatch({
              type: actions.INIT_AUDIO_STREAM_BUFFER,
              username: username
            });
          }
        })
      }
    });

  }
}

export function upgradeToSpeaker(id) {
  return (dispatch) => {

    // dispatch({ type: actions.JOIN_BROADCAST });

    roomSocket.emit("upgradeToSpeaker", { id: id }, (status) => {
      if (status) {
        dispatch({ type: actions.UPGRADE_TO_SPEAKER });
      }
    });

  }
}

export function downgradeFromSpeaker(id) {
  return (dispatch) => {

    // dispatch({ type: actions.JOIN_BROADCAST });

    roomSocket.emit("downgradeFromSpeaker", { id: id }, (status) => {
      if (status) {
        dispatch({ type: actions.DOWNGRADE_FROM_SPEAKER });
      }
    });

  }
}

export function leaveRoomBroadcast(id, silent) {
  return (dispatch) => {

    roomSocket.emit("leaveBroadcast", id, (status, data) => {
      if (status && !silent) {
        dispatch({
          type: actions.LEAVE_BROADCAST_SUCCESS,
          disconnect: true
        })
      }

      if (status && silent) {
        dispatch({
          type: actions.LEAVE_BROADCAST_SUCCESS,
          disconnect: false
        })
      }

    });

  }
}

export function peekRoomBroadcast(id) {
  return (dispatch) => {

    roomSocket.emit("peekBroadcast", { id: id }, (status, data) => {
      if (status) {
        console.log(data + " users in the room.")
        dispatch({
          type: actions.AUDIO_STREAM_PEEK,
          count: data,
        })
      }
    });

  }
}

export function consumedAudioStreamChunk(username, index) {
  return (dispatch) => {
    console.log(username);
    console.log(index);
    dispatch({
      type: actions.CONSUMED_AUDIO_STREAM_CHUNK,
      username: username,
      index: index
    });
  }
}

export function uninitAudioStreamBuffer(username) {
  return (dispatch) => {
    dispatch({
      type: actions.UNINIT_AUDIO_STREAM_BUFFER,
      username: username,
    });
  };
}

export function appendToAudioStreamBuffer(username, avatar, buffer) {
  return (dispatch) => {
    var audio = store.getState().appState.chatbox.stream.audio;
    var audioSpeakers = store.getState().appState.chatbox.stream.audioSpeakers;

    console.log(audio);
    var alreadyInit = audio.some((e) => e.username === username);
    var speakerAlreadyInit = audioSpeakers.some((e) => e.username === username);

    console.log(alreadyInit);

    if (!alreadyInit) {
      var arr = [];
      arr[username] = [];

      if (!speakerAlreadyInit) {
        return;
        dispatch({
          type: actions.INIT_AUDIO_STREAM_SPEAKER,
          username: username,
          avatar: avatar
        });
      }

      dispatch({
        type: actions.INIT_AUDIO_STREAM_BUFFER,
        username: username
      });
    }
    if (true) {
      dispatch({
        type: actions.APPEND_TO_AUDIO_STREAM_BUFFER,
        username: username,
        buffer: buffer
      });
    }
  };
}

export function initGuestSocket() {
  return async (dispatch) => {
    var spSession = checkForSession();

    console.log("**** SETTING UP SOCKETS ****");

    if (guestSocket === null) {
      guestSocket = io(C.SOCKET_EP + "/guest", {
        query: { username: spSession.username, token: spSession.token },
      });
      console.log(guestSocket);
    }

    if (typeof guestSocket._callbacks["$roomMessages"] === "undefined") {
      guestSocket.on("sendGuestMessageAck", function (data) {
        console.log("Data status ....");
        console.log(data);
        console.log(data.status);
        if (data.status !== 0) {
          dispatch(fetchIncomingMessageForGuest(data.messageId));
          dispatch(roomMessageSuccess());
        } else {
          dispatch(roomMessageFailure());
        }
      });

      guestSocket.on("getKicked", function (data) {
        console.log("Kicked!");
      });

      guestSocket.on("roomMessages", function (data) {
        //console.log('Just got room messages ....');
        console.log(data);
        var sortedData = data.data.sort(sorter);
        dispatch(
          loadRoomSuccess(
            data.roomDetails,
            sortedData,
            data.onlineUsers,
            data.creator,
            data.role,
            data.ignoreList,
            data.canLoadMore
          )
        );
      });

      guestSocket.on("newMessage", function (data) {
        console.log("New message ...");
        console.log(data);
        dispatch(fetchIncomingMessageForGuest(data));
      });

      guestSocket.on("downJoinRoom", function (data) {
        //dispatch(initJoinRoom(data));
      });

      guestSocket.on("userJoined", function (data) {
        console.log("User Joined!");
        console.log(data);
        var loadedView = store.getState().appState.spGlobal.layout.loadedView;
        var currentRoomId = store.getState().appState.chatbox.roomId;
        if (loadedView === "roomchat" && data.room._id === currentRoomId) {
          dispatch({ type: actions.USER_JOINED_ROOM, payload: data.user });
          // dispatch(
          //   doDispatchNotification({
          //     status: 'success',
          //     heading: 'User Joined',
          //     msg: data.user.username + ' joined the room.',
          //   })
          // );
        }
      });

      guestSocket.on("userLeft", function (data) {
        console.log("User Left!");
        console.log(data);
        var loadedView = store.getState().appState.spGlobal.layout.loadedView;
        var currentRoomId = store.getState().appState.chatbox.roomId;
        if (loadedView === "roomchat" && data.room._id === currentRoomId) {
          dispatch({ type: actions.USER_LEFT_ROOM, payload: data });
          // dispatch(
          //   doDispatchNotification({
          //     status: 'danger',
          //     heading: 'User Left',
          //     msg: data.user.username + ' left the room.',
          //   })
          // );
        }
      });

      guestSocket.on("upDeleteMessageAck", function (data) {
        console.log(data.messageId);
        dispatch({ type: actions.DELETE_MESSAGE, messageId: data.messageId });
      });

      guestSocket.on("downDeleteMessage", function (data) {
        console.log(data);
        dispatch({ type: actions.DELETE_MESSAGE, messageId: data });
      });

      guestSocket.on("downError", function (data) {
        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: data.heading,
            msg: data.msg,
          })
        );
      });

      guestSocket.on("roomCleared", function ({ roomId, username }) {
        var loadedView = store.getState().appState.spGlobal.layout.loadedView;
        var currentRoomId = store.getState().appState.chatbox.roomId;
        if (loadedView === "roomchat" && roomId === currentRoomId) {
          dispatch(
            doDispatchNotification({
              status: "success",
              heading: "Clear",
              msg: "This room was cleared by " + username + ".",
            })
          );
          dispatch({ type: actions.CLEAR_ROOM_MESSAGES });
        }
      });

      guestSocket.on("disconnect", function () {
        console.log("SOCKET  *****disconnected");
        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Network Error!",
            msg: "Can't access internet!",
          })
        );
      });
      guestSocket.on("reconnecting", function () {
        console.log("Reconnecting ....");
      });
      guestSocket.on("reconnect", function () {
        var roomId = store.getState().appState.chatbox.roomId;
        var loadedView = store.getState().appState.spGlobal.layout.loadedView;
        if (
          typeof loadedView !== "undefined" &&
          loadedView === "roomchat" &&
          typeof roomId !== "undefined" &&
          /^[a-z0-9]+$/.test(roomId)
        ) {
          var spSession = checkForSession();

          guestSocket.emit("loadRoom", { cred: spSession, id: roomId });
          dispatch(
            doDispatchNotification({
              status: "success",
              heading: "Back Online!",
              msg: "We are connected again!",
            })
          );
        }
      });
    }
  };
}

export function initGlobalSocket() {
  return async (dispatch) => {
    var spSession = checkForSession();

    console.log("**** SETTING UP GLOBAL SOCKET ****");

    if (globalSocket === null) {
      globalSocket = io(C.SOCKET_EP + "/global", {
        query: { email: spSession.email, token: spSession.token },
      });
      console.log(globalSocket);
    }

    globalSocket.on("privateRequestAccepted", function (data) {
      console.log("[response] ***** GLOBAL SOCKET - THREAD ACCEPTED *****");
      dispatch({
        type: actions.PRIVATE_REQUEST_ACCEPTED,
        payload: data.threadId,
      });
      dispatch(
        doDispatchNotification({
          status: "success",
          heading: "Private Request Accepted!",
          msg: data.username + " has accepted your private request.",
        })
      );
      dispatch(initPrivateBoxData(data.threadId));
    });

    globalSocket.on("privateRequestBlocked", function (data) {
      console.log("[response] ***** GLOBAL SOCKET - THREAD BLOCKED *****");
      dispatch({
        type: actions.PRIVATE_REQUEST_BLOCKED,
        payload: data.threadId,
      });
      dispatch(
        doDispatchNotification({
          status: "danger",
          heading: "Private Request blocked!",
          msg: data.username + " has blocked your private request.",
        })
      );
    });

    globalSocket.on("privateThreadBanned", function (data) {
      dispatch({
        type: actions.PRIVATE_THREAD_BANNED,
        payload: { blocker: data.blockerId, threadId: data.threadId },
      });
    });

    globalSocket.on("privateThreadUnbanned", function (data) {
      dispatch({
        type: actions.PRIVATE_THREAD_UNBANNED,
        payload: { blocker: data.blockerId, threadId: data.threadId },
      });
    });

    globalSocket.on("newPrivateMessageNotif", function (data) {
      console.log("Got new private message notification");
      console.log(data);
      var loadedView = store.getState().appState.spGlobal.layout.loadedView;

      var currentThreadId =
        typeof store.getState().appState.Private.box.thread !== "undefined"
          ? store.getState().appState.Private.box.thread._id
          : -1;

      if (loadedView !== "privatebox" || currentThreadId !== data.threadId) {
        axios
          .get(
            C.API_EP +
            "/a/private/getSingle?email=" +
            spSession.email +
            "&token=" +
            spSession.token +
            "&privateMessageId=" +
            data.privateMessageId +
            "&threadId=" +
            data.threadId
          )
          .then((resposne) => {
            console.log(resposne.data.data);
            var message = resposne.data.data;
            dispatch({
              type: actions.NEW_PRIVATE_MESSAGE_NOTIF,
              payload: message,
              threadId: data.threadId,
            });
            dispatch(
              doDispatchNotification({
                status: "success",
                heading: "Private Message",
                msg: "New PM from " + message.writer.username,
              })
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    globalSocket.on("privateRequestUnblocked", function (data) {
      console.log("[response] ***** GLOBAL SOCKET - THREAD UNBLOCKED *****");
      dispatch({
        type: actions.PRIVATE_REQUEST_UNBLOCKED,
        payload: data.threadId,
      });
      dispatch(
        doDispatchNotification({
          status: "success",
          heading: "Private Request Unblocked!",
          msg: data.username + " has unblocked your private request.",
        })
      );
    });

    globalSocket.on("test", function (data) {
      console.log(data);
    });

    globalSocket.on("roleUpdated", function ({ roomId, permissions, title }) {
      var loadedView = store.getState().appState.spGlobal.layout.loadedView;
      var currentRoomId = store.getState().appState.chatbox.roomId;
      if (loadedView === "roomchat" && roomId === currentRoomId) {
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Role Updated",
            msg: "Your role has been updated to " + title.toUpperCase(),
          })
        );
        console.log(permissions);
        dispatch({ type: actions.ROLE_UPDATED, payload: permissions });
      }
    });

    globalSocket.on("requestReceived", function (data) {
      console.log("[received] ***** Request Received! *****");
      console.log(data);
      dispatch(
        doDispatchNotification({
          status: "success",
          heading: "New Private Request",
          msg: data.username + " has sent you a private request.",
        })
      );

      dispatch({
        type: actions.PRIVATE_REQUEST_RECEIVED,
        payload: data.thread,
      });
    });

    globalSocket.emit("listen");
  };
}

export function loadRoomThreads() {
  return (dispatch) => {
    dispatch({
      type: actions.LOAD_ROOM_THREADS,
    });
  };
}

export function loadRepliesSuccess(data, tid, canLoadMore, pinnedReplies) {
  return (dispatch) => {
    var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

    data = data.sort(function (a, b) {
      if (a === null || b == null) {
        return 1;
      }
      return new Date(b.date) - new Date(a.date);
    })

    console.log(repliesData);

    repliesData[tid.toString()] = { data: data, canLoadMore: canLoadMore, pinnedReplies: pinnedReplies }

    console.log(repliesData);

    dispatch({
      type: actions.LOAD_REPLIES_SUCCESS,
      payload: repliesData,
    });
  };
}

export function loadMoreRepliesSuccess(data, tid, canLoadMore) {
  return (dispatch) => {
    var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

    data = data.sort(function (a, b) {
      if (a === null || b == null) {
        return 1;
      }
      return new Date(b.date) - new Date(a.date);
    })

    console.log(repliesData);

    console.log(tid);
    console.log(typeof tid)

    repliesData[tid] = { data: [...repliesData[tid].data, ...data], canLoadMore: canLoadMore }

    console.log(repliesData);

    dispatch({
      type: actions.LOAD_MORE_REPLIES_SUCCESS,
      payload: repliesData,
    });
  };
}

export function sendAudioSlice(slice, id) {
  return (dispatch) => {
    console.log("HERE: ", slice)
    roomSocket.binary(true).emit('audioSlice', id, slice);
  };
}

export function loadReplies(thread) {

  var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

  repliesData[thread._id.toString()] = {}

  console.log(repliesData);

  return (dispatch) => {
    dispatch({
      type: actions.LOAD_REPLIES,
      payload: repliesData,
    });
  };
}

export function clearReplies(id) {
  return (dispatch) => {

    var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

    delete repliesData[id]

    console.log(repliesData);

    dispatch({
      type: actions.CLEAR_REPLIES,
      payload: repliesData
    });
  };
}

export function deleteThread(threadId) {
  return (dispatch) => {
    dispatch({
      type: actions.DELETE_THREAD,
      payload: threadId
    });
  };
}

export function deleteReply(replyId, tid) {

  var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

  // delete repliesData[id]

  // console.log(repliesData);
  for (var tid in repliesData) {

    var replies = repliesData[tid].data.filter((e, index) => {
      if (e._id === replyId)
        return false;
      else
        return true
    });


    var pinned = repliesData[tid].pinnedReplies.filter((e, index) => {
      if (e._id === replyId)
        return false;
      else
        return true
    });

    repliesData[tid].data = replies;
    repliesData[tid].pinnedReplies = pinned;
  }


  return (dispatch) => {
    dispatch({
      type: actions.DELETE_REPLY,
      replies: repliesData
    });
  };
}

export function likeThread(threadId, userId) {

  var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

  // delete repliesData[id]

  // console.log(repliesData);
  for (var tid in repliesData) {

    var replies = repliesData[tid].data;
    for (var reply in replies) {
      var _id = replies[reply]._id;
      //console.log(_id);
      if (_id === threadId) {
        replies[reply].likes.push(userId);
        replies[reply].dislikes = replies[reply].dislikes.filter(
          (e) => e !== userId
        )
      }
    }

    var pinned = repliesData[tid].pinnedReplies;
    for (var pinIndex in pinned) {
      var _id = pinned[pinIndex]._id;
      //console.log(_id);
      if (_id === threadId) {
        pinned[pinIndex].likes.push(userId);
        pinned[pinIndex].dislikes = pinned[pinIndex].dislikes.filter(
          (e) => e !== userId
        )
      }
    }

  }

  return (dispatch) => {
    dispatch({
      type: actions.LIKE_THREAD,
      threadId: threadId,
      userId: userId,
      replies: repliesData
    });
  };
}

export function unlikeThread(threadId, userId) {
  var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

  // delete repliesData[id]

  // console.log(repliesData);
  for (var tid in repliesData) {
    var replies = repliesData[tid].data;
    for (var reply in replies) {
      var _id = replies[reply]._id;
      //console.log(_id);
      if (_id === threadId) {
        replies[reply].likes = replies[reply].likes.filter(
          (e) => e !== userId
        )
      }
    }
    var pinned = repliesData[tid].pinnedReplies;
    for (var pinIndex in pinned) {
      var _id = pinned[pinIndex]._id;
      //console.log(_id);
      if (_id === threadId) {
        pinned[pinIndex].likes = pinned[pinIndex].likes.filter(
          (e) => e !== userId
        )
      }
    }
  }
  return (dispatch) => {
    dispatch({
      type: actions.UNLIKE_THREAD,
      threadId: threadId,
      userId: userId,
      replies: repliesData
    });
  };
}

export function archiveThread(threadId, userId) {

  return (dispatch) => {
    dispatch({
      type: actions.ARCHIVE_THREAD,
      payload: threadId
    });
  };

}

export function dislikeThread(threadId, userId) {

  var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

  // delete repliesData[id]

  // console.log(repliesData);
  for (var tid in repliesData) {
    var replies = repliesData[tid].data;
    for (var reply in replies) {
      var _id = replies[reply]._id;
      //console.log(_id);
      if (_id === threadId) {
        replies[reply].dislikes.push(userId);
        replies[reply].likes = replies[reply].likes.filter(
          (e) => e !== userId
        )
      }
    }
    var pinned = repliesData[tid].pinnedReplies;
    for (var pinIndex in pinned) {
      var _id = pinned[pinIndex]._id;
      //console.log(_id);
      if (_id === threadId) {
        pinned[pinIndex].dislikes.push(userId);
        pinned[pinIndex].likes = pinned[pinIndex].likes.filter(
          (e) => e !== userId
        )
      }
    }
  }

  return (dispatch) => {
    dispatch({
      type: actions.DISLIKE_THREAD,
      threadId: threadId,
      userId: userId,
      replies: repliesData
    });
  };
}

export function pinReplyToThread(replyId, threadId, thread) {
  // console.log("Changing pinned thread : " + tid);
  var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

  var replyThread = repliesData[threadId];
  var replyThreadData = replyThread.data;

  replyThread.data = replyThreadData.filter(e => { console.log(e._id + " : " + replyId); console.log(e._id !== replyId); if (e._id !== replyId) return true; else return false; })
  replyThread.pinnedReplies.push(thread);

  // for (var replyIndex in replyThreadData) {
  //   var _id = replyThreadData[replyIndex]._id;
  //   if (_id === replyId) {

  //   }
  // }

  // for (var tid in repliesData) {
  //   var replies = repliesData[tid].data;
  //   for (var reply in replies) {
  //     var _id = replies[reply]._id;
  //     if (_id === threadId) {

  //     }
  //   }
  // }

  return (dispatch) => {
    dispatch({
      type: actions.PIN_REPLY_TO_THREAD,
      threadId: threadId,
      userId: 'userId',
      replies: repliesData
    });
  };
}

export function unpinReplyFromThread(replyId, threadId, thread) {
  // console.log("Changing pinned thread : " + tid);
  var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

  var replyThread = repliesData[threadId];
  var replyThreadData = replyThread.pinnedReplies;

  console.log(thread);

  replyThread.pinnedReplies = replyThreadData.filter(e => { console.log(e._id + " : " + replyId); console.log(e._id !== replyId); if (e._id !== replyId) return true; else return false; })
  replyThread.data.push(thread);

  // for (var replyIndex in replyThreadData) {
  //   var _id = replyThreadData[replyIndex]._id;
  //   if (_id === replyId) {

  //   }
  // }

  // for (var tid in repliesData) {
  //   var replies = repliesData[tid].data;
  //   for (var reply in replies) {
  //     var _id = replies[reply]._id;
  //     if (_id === threadId) {

  //     }
  //   }
  // }
  console.log(repliesData);

  return (dispatch) => {
    dispatch({
      type: actions.UNPIN_REPLY_FROM_THREAD,
      threadId: threadId,
      userId: 'userId',
      replies: repliesData
    });
  };
}

export function undislikeThread(threadId, userId) {

  var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));

  // delete repliesData[id]

  // console.log(repliesData);
  for (var tid in repliesData) {
    var replies = repliesData[tid].data;
    for (var reply in replies) {
      var _id = replies[reply]._id;
      //console.log(_id);
      if (_id === threadId) {
        replies[reply].dislikes = replies[reply].dislikes.filter(
          (e) => e !== userId
        )
      }
    }
    var pinned = repliesData[tid].pinnedReplies;
    for (var pinIndex in pinned) {
      var _id = pinned[pinIndex]._id;
      //console.log(_id);
      if (_id === threadId) {
        pinned[pinIndex].dislikes = pinned[pinIndex].dislikes.filter(
          (e) => e !== userId
        )
      }
    }
  }

  return (dispatch) => {
    dispatch({
      type: actions.UNDISLIKE_THREAD,
      threadId: threadId,
      userId: userId,
      replies: repliesData
    });
  };
}

export function pushReply(thread, id) {

  var repliesData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.replies));
  var threadsData = JSON.parse(JSON.stringify(store.getState().appState.chatbox.threads));

  for (var tid in threadsData.data) {
    if (threadsData.data[tid]._id === id) {
      threadsData.data[tid].replies = threadsData.data[tid].replies + 1;
    }
  }

  for (var tid in threadsData.pinnedThreads) {
    if (threadsData.pinnedThreads[tid]._id === id) {
      threadsData.pinnedThreads[tid].replies = threadsData.pinnedThreads[tid].replies + 1;
    }
  }

  var _data = null;

  if (typeof (repliesData[id]) === 'undefined')
    _data = [thread];
  else
    _data = [...repliesData[id].data, thread].sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

  repliesData[id] = { ...repliesData[id], data: _data }

  console.log(repliesData);

  for (var tid in repliesData) {

    var replies = repliesData[tid].data;
    for (var reply in replies) {
      var _id = replies[reply]._id;
      //console.log(_id);
      if (_id === id) {
        replies[reply].replies = replies[reply].replies + 1;
      }
    }

    var pinned = repliesData[tid].pinnedReplies;
    for (var pinIndex in pinned) {
      var _id = pinned[pinIndex]._id;
      //console.log(_id);
      if (_id === id) {
        pinned[pinIndex].replies = pinned[pinIndex].replies + 1;
      }
    }

  }
  console.log(threadsData);

  return (dispatch) => {
    dispatch({
      type: actions.PUSH_REPLY,
      payload: repliesData,
      threads: threadsData
    });
  };
}

export function pushThread(thread) {
  return (dispatch) => {
    dispatch({
      type: actions.PUSH_THREAD,
      payload: thread
    });
  };
}

export function changePinnedThread(tid, thread) {
  console.log("Changing pinned thread : " + tid);
  console.log("also ", thread)
  return (dispatch) => {
    dispatch({
      type: actions.CHANGE_PIN_THREAD,
      payload: tid,
      thread: thread
    });
  };
}

export function removePinnedThread(tid, thread) {
  console.log("Here we go ...");
  return (dispatch) => {
    dispatch({
      type: actions.REMOVE_PIN_THREAD,
      payload: tid,
      thread: thread
    });
  };
}

export function loadTagSearchThreadsSuccess(data, canLoadMore) {
  console.log("Threads load success");
  console.log(data);
  return (dispatch) => {
    dispatch({
      type: actions.LOAD_TAG_SEARCH_THREADS_SUCCESS,
      payload: data,
      canLoadMore: canLoadMore,
    });
  };
}

export function loadRoomThreadsSuccess(data, canLoadMore, pinnedThreads) {
  console.log("Threads load success");
  console.log(data);
  return (dispatch) => {
    dispatch({
      type: actions.LOAD_ROOM_THREADS_SUCCESS,
      payload: data,
      canLoadMore: canLoadMore,
      pinnedThreads: pinnedThreads
    });
  };
}

export function loadMoreRoomThreadsSuccess(data, canLoadMore) {
  console.log("Threads load success");
  // console.log(data);
  return (dispatch) => {
    dispatch({
      type: actions.LOAD_MORE_ROOM_THREADS_SUCCESS,
      payload: data,
      canLoadMore: canLoadMore
    });
  };
}

export function loadMoreRoomThreads() {
  return (dispatch) => {
    dispatch({
      type: actions.LOAD_MORE_ROOM_THREADS,
    });
  };
}

export function loadMoreRoomThreadsError() {
  return (dispatch) => {
    dispatch({
      type: actions.LOAD_MORE_ROOM_THREADS_ERROR,
    });
  };
}

export function loadThread(threadId) {
  return (dispatch) => {
    dispatch({
      type: actions.LOAD_THREAD,
      payload: threadId
    });
  };
}

export function privateRequestBlock(threadId) {
  return (dispatch) => {
    dispatch({
      type: actions.PRIVATE_REQUEST_BLOCKED,
      payload: threadId,
    });
  };
}

export function privateThreadBanned(blockerId, threadId) {
  return (dispatch) => {
    dispatch({
      type: actions.PRIVATE_THREAD_BANNED,
      payload: { blocker: blockerId, threadId: threadId },
    });
  };
}

export function privateThreadUnbanned(blockerId, threadId) {
  return (dispatch) => {
    dispatch({
      type: actions.PRIVATE_THREAD_UNBANNED,
      payload: { blocker: blockerId, threadId: threadId },
    });
  };
}

export function privateRequestUnBlock(threadId) {
  return (dispatch) => {
    dispatch({
      type: actions.PRIVATE_REQUEST_UNBLOCKED,
      payload: threadId,
    });
  };
}

export function initPrivateSocket() {
  return async (dispatch) => {
    var spSession = checkForSession();

    console.log("**** SETTING UP PRIVATE SOCKET ****");

    if (privateSocket === null) {
      // privateSocket = io(C.SOCKET_EP + "/private", {
      //   query: { email: spSession.email, token: spSession.token },
      // });
      // console.log(privateSocket);
    }

    // privateSocket.on("down.loadList", function (data) {
    //   var t2 = new Date();
    //   console.log("Response Time to Load Private Threads: ");
    //   console.log(t2 - startPrivateListLoading);
    //   //console.log(data);
    //   dispatch({
    //     type: actions.SET_PRIVATE_LIST,
    //     payload: data,
    //   });
    // });

    // privateSocket.on("down.loadListMore", function (data) {
    //   var t2 = new Date();
    //   console.log("Response Time to Load Private Threads: ");
    //   console.log(t2 - startPrivateListLoading);
    //   //console.log(data);
    //   dispatch({
    //     type: actions.SET_MORE_PRIVATE_LIST,
    //     payload: data,
    //   });
    // });

    // privateSocket.on("typingPrivateProcess", function (data) {
    //   var loadedView = store.getState().appState.spGlobal.layout.loadedView;
    //   var threadId = store.getState().appState.Private.box.thread._id;
    //   console.log("Recieved Typing");

    //   if (loadedView !== "privatebox") {
    //     return;
    //   }

    //   if (
    //     data.userId !== localStorage.getItem(C.SP_USER_ID) &&
    //     data.threadId === threadId
    //   ) {
    //     dispatch(doShowPrivateTyping());
    //   }
    // });

    // privateSocket.on("updatePrivatePointer", function (data) {
    //   console.log("[update] ***** Updating pointer of private thread *****");
    //   console.log(data);
    //   if (data.userId !== localStorage.getItem(C.SP_USER_ID)) {
    //     dispatch({ type: actions.UPDATE_PRIVATE_POINTER, payload: data });
    //   }
    // });

    // privateSocket.on("newPrivateMessage", (data) => {
    //   var loadedView = store.getState().appState.spGlobal.layout.loadedView;
    //   var threadId = store.getState().appState.Private.box.thread._id;
    //   console.log("New Private Message ...");
    //   console.log(loadedView);
    //   console.log(loadedView !== "privatebox");
    //   console.log(data);
    //   console.log(threadId);
    //   if (loadedView !== "privatebox") {
    //     return;
    //   }

    //   if (threadId !== data.threadId) {
    //     return;
    //   }
    //   console.log("Going on ...");

    //   console.log("Got new private message");
    //   console.log(data);
    //   axios
    //     .get(
    //       C.API_EP +
    //       "/a/private/getSingle?email=" +
    //       spSession.email +
    //       "&token=" +
    //       spSession.token +
    //       "&privateMessageId=" +
    //       data.privateMessageId +
    //       "&threadId=" +
    //       data.threadId
    //     )
    //     .then((resposne) => {
    //       console.log(resposne);
    //       var message = resposne.data.data;
    //       dispatch({
    //         type: actions.NEW_PRIVATE_MESSAGE,
    //         payload: message,
    //         threadId: data.threadId,
    //       });
    //       dispatch(updatePrivatePointer(data.threadId, message._id));
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // });

    // privateSocket.on("disconnect", function () {
    //   console.log("Private socket disconnected!");
    // });

    // privateSocket.on("reconnect", function () {
    //   console.log("Reconnected private socket!");
    //   var thread = store.getState().appState.Private.box.thread;
    //   var loadedView = store.getState().appState.spGlobal.layout.loadedView;
    //   if (
    //     typeof loadedView !== "undefined" &&
    //     loadedView === "privatebox" &&
    //     typeof thread !== "undefined" &&
    //     /^[a-z0-9]+$/.test(thread._id)
    //   ) {
    //     privateSocket.emit(
    //       "loadThreadData",
    //       thread._id,
    //       (data, targetPointer, canLoadMore) => {
    //         console.log(" ***** Load Thread Data *****");
    //         console.log(data);
    //         console.log(targetPointer);
    //         console.log(canLoadMore);
    //         dispatch({
    //           type: actions.PRIVATEBOX_DATA_LOADED,
    //           payload: data,
    //           targetPointer: targetPointer,
    //           canLoadMore: canLoadMore,
    //         });
    //       }
    //     );

    //     dispatch(
    //       doDispatchNotification({
    //         status: "success",
    //         heading: "Back Online!",
    //         msg: "We are connected again!",
    //       })
    //     );
    //   } else {
    //     console.log(loadedView);
    //     console.log(thread);
    //     dispatch(recheckPrivateNotif());
    //   }
    // });
  };
}

export function processExtend(roomId, username, role) {
  return async (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    dispatch({ type: actions.ROOM_MESSAGE });

    axios
      .post(C.API_EP + "/a/r/extend", {
        email: spSession.email,
        token: spSession.token,
        target: username,
        roomId: roomId,
        role: role,
      })
      .then((response) => {
        dispatch({ type: actions.ROOM_MESSAGE_SUCCESS });
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Command Successful!",
            msg: response.data.msg,
          })
        );
      })
      .catch((error) => {
        dispatch({ type: actions.ROOM_MESSAGE_FAILURE });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Command Error!",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function processClear(roomId) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    dispatch({ type: actions.ROOM_MESSAGE });

    axios
      .post(C.API_EP + "/a/r/clear", {
        email: spSession.email,
        token: spSession.token,
        roomId: roomId,
      })
      .then((response) => {
        dispatch({ type: actions.ROOM_MESSAGE_SUCCESS });
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Command Successful!",
            msg: response.data.msg,
          })
        );
      })
      .catch((error) => {
        dispatch({ type: actions.ROOM_MESSAGE_FAILURE });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Command Error!",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function processBan(roomId, username) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    dispatch({ type: actions.ROOM_MESSAGE });

    axios
      .post(C.API_EP + "/a/r/ban", {
        email: spSession.email,
        token: spSession.token,
        roomId: roomId,
        target: username,
      })
      .then((response) => {
        dispatch({ type: actions.ROOM_MESSAGE_SUCCESS });
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Command Successful!",
            msg: response.data.msg,
          })
        );
      })
      .catch((error) => {
        dispatch({ type: actions.ROOM_MESSAGE_FAILURE });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Command Error!",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function kickGuest(roomId, username) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    dispatch({ type: actions.ROOM_MESSAGE });

    axios
      .post(C.API_EP + "/a/r/kickGuest", {
        email: spSession.email,
        token: spSession.token,
        roomId: roomId,
        target: username,
      })
      .then((response) => {
        dispatch({ type: actions.ROOM_MESSAGE_SUCCESS });
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Command Successful!",
            msg: response.data.msg,
          })
        );
      })
      .catch((error) => {
        dispatch({ type: actions.ROOM_MESSAGE_FAILURE });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Command Error!",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function banGuest(roomId, username) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    dispatch({ type: actions.ROOM_MESSAGE });

    axios
      .post(C.API_EP + "/a/r/banGuest", {
        email: spSession.email,
        token: spSession.token,
        roomId: roomId,
        target: username,
      })
      .then((response) => {
        dispatch({ type: actions.ROOM_MESSAGE_SUCCESS });
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Command Successful!",
            msg: response.data.msg,
          })
        );
      })
      .catch((error) => {
        dispatch({ type: actions.ROOM_MESSAGE_FAILURE });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Command Error!",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function processUnban(roomId, username) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    dispatch({ type: actions.ROOM_MESSAGE });

    axios
      .post(C.API_EP + "/a/r/unban", {
        email: spSession.email,
        token: spSession.token,
        roomId: roomId,
        target: username,
      })
      .then((response) => {
        dispatch({ type: actions.ROOM_MESSAGE_SUCCESS });
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Command Successful!",
            msg: response.data.msg,
          })
        );
      })
      .catch((error) => {
        dispatch({ type: actions.ROOM_MESSAGE_FAILURE });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Command Error!",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function processIpBan(roomId, username) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    dispatch({ type: actions.ROOM_MESSAGE });

    axios
      .post(C.API_EP + "/a/r/ipban", {
        email: spSession.email,
        token: spSession.token,
        roomId: roomId,
        target: username,
      })
      .then((response) => {
        dispatch({ type: actions.ROOM_MESSAGE_SUCCESS });
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Command Successful!",
            msg: response.data.msg,
          })
        );
      })
      .catch((error) => {
        dispatch({ type: actions.ROOM_MESSAGE_FAILURE });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Command Error!",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function processIgnore(roomId, username) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    dispatch({ type: actions.ROOM_MESSAGE });

    axios
      .post(C.API_EP + "/a/r/ignore", {
        email: spSession.email,
        token: spSession.token,
        roomId: roomId,
        target: username,
      })
      .then((response) => {
        dispatch({ type: actions.ROOM_MESSAGE_SUCCESS });
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Command Successful!",
            msg: response.data.msg,
          })
        );
      })
      .catch((error) => {
        dispatch({ type: actions.ROOM_MESSAGE_FAILURE });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Command Error!",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function processRignore(roomId, username) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    dispatch({ type: actions.ROOM_MESSAGE });

    axios
      .post(C.API_EP + "/a/r/rignore", {
        email: spSession.email,
        token: spSession.token,
        roomId: roomId,
        target: username,
      })
      .then((response) => {
        dispatch({ type: actions.ROOM_MESSAGE_SUCCESS });
        dispatch(
          doDispatchNotification({
            status: "success",
            heading: "Command Successful!",
            msg: response.data.msg,
          })
        );
      })
      .catch((error) => {
        dispatch({ type: actions.ROOM_MESSAGE_FAILURE });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Command Error!",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function loadEmojiBox() {
  return (dispatch) => {
    dispatch({ type: actions.LOAD_EMOJI_BOX, payload: C.EMOJIS });
  };
}

export function showEmojiBox() {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_EMOJI_BOX });
  };
}

export function hideEmojiBox() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_EMOJI_BOX });
  };
}

export function showPrivateTyping() {
  return async (dispatch) => {
    dispatch({ type: actions.SHOW_PRIVATE_TYPING });
  };
}

export function hidePrivateTyping() {
  return async (dispatch) => {
    dispatch({ type: actions.HIDE_PRIVATE_TYPING });
  };
}

var typingTimeout = 0;

export function doShowPrivateTyping() {
  return async (dispatch) => {
    console.log("Dispatching notification ....");
    dispatch(showPrivateTyping());
    if (typingTimeout !== 0) {
      clearTimeout(typingTimeout);
    }
    typingTimeout = setTimeout(function () {
      dispatch(hidePrivateTyping());
      typingTimeout = 0;
    }, 800);
  };
}

export function unloadLastThread(threadId) {
  console.log(threadId + " is being unmounted! ");
  return (dispatch) => {
    privateSocket.emit("leaveThread", threadId);
  };
}

export function typingPrivate(threadId) {
  return (dispatch) => {
    privateSocket.emit("typingPrivate", threadId);
  };
}

var startPrivateListLoading = new Date();

export function loadPrivateList() {
  return (dispatch) => {
    var spSession = checkForSession();
    startPrivateListLoading = new Date();
    privateSocket.emit("up.loadList", { cred: spSession });
  };
}

export function loadPrivateListMore(index) {
  return (dispatch) => {
    var spSession = checkForSession();
    startPrivateListLoading = new Date();
    privateSocket.emit("up.loadListMore", { cred: spSession, index: index });
  };
}

export function checkPrivateNotif() {
  return (dispatch) => {
    // privateSocket.emit("checkNotif", function (unreadNotif) {
    //   console.log("UnreadNotif ..." + unreadNotif);
    //   if (unreadNotif) {
    //     dispatch({ type: actions.UNREAD_NOTIF });
    //   }
    // });
  };
}

export function recheckPrivateNotif() {
  return (dispatch) => {
    privateSocket.emit("checkNotif", function (unreadNotif) {
      console.log("UnreadNotif ..." + unreadNotif);
      if (unreadNotif) {
        dispatch({ type: actions.UNREAD_NOTIF });
        dispatch(loadPrivateList());
      }
    });
  };
}

export function propogateImage(messageId, propogateId, roomId) {
  return (dispatch) => {
    console.log("sending propogate");

    var spSession = checkForSession();
    if (spSession === false) {
      return 0;
    }

    var propogateObject = {
      cred: spSession,
      id: roomId,
      messageId: messageId,
      propogateId: propogateId,
    };

    roomSocket.emit("propogate", propogateObject);
  };
}

export function propogatePrivateImage(messageId, propogateId, threadId) {
  return (dispatch) => {
    console.log("sending propogate");

    var spSession = checkForSession();
    if (spSession === false) {
      return 0;
    }

    var propogateObject = {
      cred: spSession,
      id: threadId,
      messageId: messageId,
      propogateId: propogateId,
    };

    privateSocket.emit("propogate", propogateObject, function (data) {
      var loadedView = store.getState().appState.spGlobal.layout.loadedView;
      console.log("New Private Message ...");
      console.log(loadedView);
      console.log(loadedView !== "privatebox");
      if (loadedView !== "privatebox") {
        return;
      }
      console.log("Going on ...");
      console.log("Got new private message");
      console.log(data);
      axios
        .get(
          C.API_EP +
          "/a/private/getSingle?email=" +
          spSession.email +
          "&token=" +
          spSession.token +
          "&privateMessageId=" +
          data.privateMessageId +
          "&threadId=" +
          data.threadId
        )
        .then((resposne) => {
          console.log(resposne);
          var message = resposne.data.data;
          dispatch({
            type: actions.NEW_PRIVATE_MESSAGE,
            payload: message,
            threadId: threadId,
          });
          dispatch(updatePrivatePointer(threadId, message._id));
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
}

export function _setSidebarActiveTab(tabNumber) {
  return (dispatch) => {
    dispatch({ type: actions.SET_SIDEBAR_ACTIVE_TAB, payload: tabNumber });
  };
}

export function _setRoomsActiveTab(tabNumber) {
  return (dispatch) => {
    dispatch({ type: actions.SET_ROOMS_ACTIVE_TAB, payload: tabNumber });
  };
}

export function loadUsernameInput(username, inputStart) {
  return (dispatch) => {
    console.log(inputStart);
    console.log(username);
    dispatch({
      type: actions.LOAD_USERNAME,
      payload: { inputStart: inputStart, username: username },
    });
  };
}

//util functions
const sorter = (a, b) => {
  return (
    parseInt((new Date(a.date).getTime() / 1000).toFixed(0)) -
    parseInt((new Date(b.date).getTime() / 1000).toFixed(0))
  );
};

export function showUploadFileUi() {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_UPLOAD_FILE_UI });
  };
}

export function showUploadVideoUi() {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_UPLOAD_VIDEO_UI });
  };
}

export function loadMorePrivateMessages(threadId, msgId, e) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    var initialHeight = e.scrollHeight;
    var newHeight = 0;

    dispatch({ type: actions.SHOW_LOADING_MORE_PRIVATE_MESSAGES });

    axios
      .get(
        C.API_EP +
        "/a/private/getMessagesBefore?email=" +
        spSession.email +
        "&token=" +
        spSession.token +
        "&threadId=" +
        threadId +
        "&privateMessageId=" +
        msgId
      )
      .then((response) => {
        var data = response.data.msgs;
        console.log(response.data.msgs);
        data = data.sort(sorter);
        dispatch({
          type: actions.MORE_PRIVATE_MESSAGES_LOADED,
          payload: data.map((e) => {
            return { ...e, dynamicLoaded: true };
          }),
          canLoadMore: response.data.canLoadMore,
        });

        newHeight = e.scrollHeight;
        console.log(initialHeight);
        console.log(newHeight);
        e.scrollTop = newHeight - initialHeight - 75;

        // var e = document.getElementById('rm' + msgId);
        // e.scrollIntoView();
      })
      .catch((error) => {
        dispatch({ type: actions.HIDE_LOADING_MORE_PRIVATE_MESSAGES });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
      });
  };
}

export function loadMoreMessages(roomId, msgId, e) {
  return (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    var initialHeight = e.scrollHeight;
    var newHeight = 0;

    dispatch({ type: actions.SHOW_LOADING_MORE_ROOM_MESSAGES });

    axios
      .get(
        C.API_EP +
        "/a/r/getMessagesBefore?email=" +
        spSession.email +
        "&token=" +
        spSession.token +
        "&roomId=" +
        roomId +
        "&messageId=" +
        msgId
      )
      .then((response) => {
        var data = response.data.msgs;
        console.log(response.data.msgs);
        data = data.sort(sorter);
        dispatch({
          type: actions.MORE_ROOM_MESSAGES_LOADED,
          payload: data.map((e) => {
            return { ...e, dynamicLoaded: true };
          }),
          canLoadMore: response.data.canLoadMore,
        });

        newHeight = e.scrollHeight;
        console.log(initialHeight);
        console.log(newHeight);
        e.scrollTop = newHeight - initialHeight - 75;
        // var e = document.getElementById('rm' + msgId);
        // e.scrollIntoView();
      })
      .catch((error) => {
        dispatch({ type: actions.HIDE_LOADING_MORE_ROOM_MESSAGES });
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
      });
  };
}

export function showPrivateUploadFileUi() {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_PRIVATE_UPLOAD_FILE_UI });
  };
}

export function hidePrivateUploadFileUi() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_PRIVATE_UPLOAD_FILE_UI });
  };
}

export function showPrivateUploadVideoUi() {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_PRIVATE_UPLOAD_VIDEO_UI });
  };
}

export function hidePrivateUploadVideoUi() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_PRIVATE_UPLOAD_VIDEO_UI });
  };
}

export function showRoomDetailsUi(id) {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_ROOM_DETAILS_UI, payload: id });
  };
}

export function hideRoomDetailsUi() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_ROOM_DETAILS_UI });
  };
}

export function showRoomSettingsUi(id) {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_ROOM_SETTINGS_UI, payload: id });
  };
}

export function hideRoomSettingsUi() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_ROOM_SETTINGS_UI });
  };
}

export function showRoomMembers(id) {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_ROOM_MEMBERS, payload: id });
  };
}

export function hideRoomMembers() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_ROOM_MEMBERS });
  };
}

export function hideUploadFileUi() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_UPLOAD_FILE_UI });
  };
}

export function hideUploadVideoUI() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_UPLOAD_VIDEO_UI });
  };
}

var started = false;

export function setMessageText(value, ele) {
  return async (dispatch) => {
    dispatch({ type: actions.SET_INPUT_TEXT, payload: value });
    var d = store.getState().appState.chatbox.messageInput.messageText;
    var a = d.match(/@[a-z0-9]{2,}/);
    if (a !== null) {
      console.log("Inside first if");
      var actualPointer = ele.selectionStart;
      var supposedPointer = a.index + a[0].length;
      // console.log(actualPointer);
      // console.log(supposedPointer);
      if (actualPointer === supposedPointer) {
        console.log("Inside second if");
        var username = a[0];
        if (username.length < 2) {
          return;
        }
        started = true;
        // console.log(username);
        var roomId = store.getState().appState.chatbox.roomId;
        var spSession = checkForSession();
        if (spSession === false) {
          return;
        }
        dispatch({ type: actions.SET_USER_SUGGESTION_LOADING });
        axios
          .post(C.API_EP + "/a/r/memberSuggestion", {
            email: spSession.email,
            token: spSession.token,
            roomId: roomId,
            keywords: username.substr(1),
          })
          .then((r) => {
            dispatch({ type: actions.UNSET_USER_SUGGESTION_LOADING });
            dispatch({
              type: actions.SET_ROOM_USER_SUGGESTIONS,
              payload: r.data.suggestions,
            });
          })
          .catch((e) => {
            dispatch({ type: actions.UNSET_USER_SUGGESTION_LOADING });
            dispatch({
              type: actions.SET_ROOM_USER_SUGGESTIONS,
              payload: [],
            });
          });
        console.log("Inside first if");
      } else {
        if (started === true) {
          dispatch({ type: actions.RESET_ROOM_USER_SUGGESTIONS });
          started = false;
        }
      }
    } else {
      if (started === true) {
        dispatch({ type: actions.RESET_ROOM_USER_SUGGESTIONS });
        started = false;
      }
    }

    //check command
    if (d.length !== 0 && d[0] === "/") {
      //It is a command - load command suggesstions
      var a = d.match(/^\/[a-z0.9]+$/);

      if (a !== null) {
        var c = store.getState().appState.chatbox.commands;

        console.log(c);
        //matching commands
        var mc = [];
        var i = 0;
        if (typeof c === "undefined") return;
        c.map((e) => {
          console.log(("/" + e).match(a));
          if (("/" + e).match(a) !== null) {
            mc[i] = e;
            i++;
          }
          return true;
        });
        console.log(mc);
        dispatch(resetRoomCommands(mc));
        dispatch(showRoomCommands());
      } else {
        if (
          typeof store.getState().appState.chatbox.commandSuggestions !==
          "undefined" &&
          store.getState().appState.chatbox.commandSuggestions.show
        ) {
          dispatch(hideRoomCommands());
        }
      }
    } else {
      if (
        d.length !== 0 &&
        typeof store.getState().appState.chatbox.commandSuggestions !==
        "undefined" &&
        store.getState().appState.chatbox.commandSuggestions.show
      ) {
        dispatch(hideRoomCommands());
      }
    }

    console.log("Called");
  };
}

export function setEmoji(e, sS) {
  return (dispatch) => {
    if (typeof store.getState().appState.chatbox.messageInput !== "undefined") {
      var d = store.getState().appState.chatbox.messageInput.messageText;
      var firstPart = d.substr(0, sS);
      var secondPart = d.substr(sS);
      var finalValue = firstPart + e + secondPart;

      console.log("Debug values ...");
      console.log(d);
      console.log(sS);
      console.log(firstPart);
      console.log(secondPart);
      console.log(finalValue);
      dispatch({
        type: actions.SET_INPUT_TEXT,
        payload: d.length === sS ? d + e : finalValue,
      });
    } else {
      dispatch({
        type: actions.SET_INPUT_TEXT,
        payload: e,
      });
    }
  };
}

export function setRoomCommands(commands) {
  return (dispatch) => {
    dispatch({ type: actions.SET_ROOM_COMMANDS, payload: commands });
  };
}

export function resetRoomCommands(commands) {
  return (dispatch) => {
    dispatch({ type: actions.RESET_ROOM_COMMANDS, payload: commands });
  };
}

export function showRoomCommands(commands) {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_ROOM_COMMANDS });
  };
}

export function hideRoomCommands(commands) {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_ROOM_COMMANDS });
  };
}

export function loadUsernameInputFromSuggestions(username, selectionStart) {
  return (dispatch) => {
    var d = store.getState().appState.chatbox.messageInput.messageText;
    var reverse = d.split("").reverse().join("");

    //short name
    var sN = reverse
      .substr(0, reverse.indexOf("@"))
      .split("")
      .reverse()
      .join("");
    var finalResult = d.replace(sN, username) + " ";
    dispatch({
      type: actions.LOAD_USERNAME_FROM_SUGGESTIONS,
      payload: finalResult,
    });
    if (started === true) {
      dispatch({ type: actions.RESET_ROOM_USER_SUGGESTIONS });
      started = false;
    }
  };
}

export function loadInputFromCommands(c, selectionStart) {
  return (dispatch) => {
    var d = store.getState().appState.chatbox.messageInput.messageText;
    var reverse = d.split("").reverse().join("");

    //short name
    var sN = reverse
      .substr(0, reverse.indexOf("/"))
      .split("")
      .reverse()
      .join("");
    var finalResult = d.replace(sN, c) + " ";
    dispatch({
      type: actions.LOAD_COMMAND_FROM_SUGGESTIONS,
      payload: finalResult,
    });
    if (store.getState().appState.chatbox.commandSuggestions.show) {
      dispatch(hideRoomCommands());
    }
  };
}

export function setPrivateMessageText(value) {
  return async (dispatch) => {
    dispatch({ type: actions.SET_PRIVATE_INPUT_TEXT, payload: value });
  };
}

export function initJoinRoomFromUI(id) {
  return async (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
        "/a/rooms/details?email=" +
        spSession.email +
        "&token=" +
        spSession.token +
        "&roomId=" +
        id
      )
      .then((response) => {
        console.log(response);
        var data = response.data.roomDetails;
        dispatch(showJoinRoomUI(data));
        //dispatch(sortRoomMessages());
      })
      .catch((error) => {
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Error",
            msg: error.response.data.msg,
          })
        );
        //dispatch(incomingMessageFailure());
      });
  };
}

export function showJoinRoomUI(data) {
  return async (dispatch) => {
    dispatch({
      type: actions.SHOW_JOIN_ROOM_UI,
      payload: data,
    });
  };
}

export const sortRoomMessages = () => ({
  type: actions.SORT_ROOM_MESSAGES,
});

export const incomingMessage = () => ({
  type: actions.INCOMING_MESSAGE,
});

export const incomingMessageSuccess = (data) => ({
  type: actions.INCOMING_MESSAGE_SUCCESS,
  payload: data,
});

export const addAdToList = () => ({
  type: actions.ADD_AD_TO_LIST,
});

export const incomingMessageFailure = () => ({
  type: actions.INCOMING_MESSAGE_FAILURE,
});

export function fetchIncomingMessageForGuest(id) {
  return async (dispatch, getState) => {
    dispatch(incomingMessage());

    var roomId = store.getState().appState.chatbox.roomId;

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
        "/g/a/getSingleMessage?username=" +
        spSession.username +
        "&token=" +
        spSession.token +
        "&roomId=" +
        roomId +
        "&messageId=" +
        id
      )
      .then((response) => {
        console.log(response);
        var data = response.data.msg;
        dispatch(incomingMessageSuccess(data));
        //dispatch(sortRoomMessages());
      })
      .catch((error) => {
        if (typeof error.status == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
        dispatch(incomingMessageFailure());
      });
  };
}

export function fetchIncomingMessage(id) {
  return async (dispatch, getState) => {
    dispatch(incomingMessage());

    var roomId = store.getState().appState.chatbox.roomId;

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
        "/a/r/getSingleMessage?email=" +
        spSession.email +
        "&token=" +
        spSession.token +
        "&roomId=" +
        roomId +
        "&messageId=" +
        id
      )
      .then((response) => {
        console.log(response);
        var data = response.data.msg;
        dispatch(incomingMessageSuccess(data));
        //dispatch(sortRoomMessages());
      })
      .catch((error) => {
        if (typeof error.status == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
        dispatch(incomingMessageFailure());
      });
  };
}

export const roomMessageSuccess = (data) => ({
  type: actions.ROOM_MESSAGE_SUCCESS,
});
export const roomMessageFailure = (data) => ({
  type: actions.ROOM_MESSAGE_FAILURE,
});

export const getRoomsJoined = () => ({
  type: actions.GET_ROOMS_JOINED,
});

export const getRoomsJoinedSuccess = (roomsJoined) => ({
  type: actions.GET_ROOMS_JOINED_SUCCESS,
  payload: roomsJoined,
});

export const getRoomsJoinedFailure = (type) => ({
  type: actions.GET_ROOMS_JOINED_FAILURE,
  payload: type,
});

export const getRoomsCreated = () => ({
  type: actions.GET_ROOMS_CREATED,
});

export const getRoomsCreatedSuccess = (roomsCreated) => ({
  type: actions.GET_ROOMS_CREATED_SUCCESS,
  payload: roomsCreated,
});

export const getRoomsCreatedFailure = (type) => ({
  type: actions.GET_ROOMS_CREATED_FAILURE,
  payload: type,
});

export function fetchRoomsCreated(d1) {
  return async (dispatch) => {
    dispatch(getRoomsCreated());

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
        "/a/rooms/created?email=" +
        spSession.email +
        "&token=" +
        spSession.token
      )
      .then((response) => {
        console.log(response);
        var data = response.data.created;
        dispatch(getRoomsCreatedSuccess(data));
        var d2 = new Date();

        console.log("Response Time To Load Created Rooms: ");
        console.log(d2 - d1);
      })
      .catch((error) => {
        if (typeof error.status == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
        dispatch(getRoomsCreatedFailure(0));
      });
  };
}

export const getFeaturedRooms = () => ({
  type: actions.GET_FEATURED_ROOMS,
});

export const getFeaturedRoomsSuccess = (featuredRooms) => ({
  type: actions.GET_FEATURED_ROOMS_SUCCESS,
  payload: featuredRooms,
});

export const getFeaturedRoomsFailure = (type) => ({
  type: actions.GET_FEATURED_ROOMS_FAILURE,
});

export function fetchFeaturedRooms(d1) {
  return async (dispatch) => {
    dispatch(getFeaturedRooms());

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
        "/a/rooms/featured?email=" +
        spSession.email +
        "&token=" +
        spSession.token
      )
      .then((response) => {
        console.log(response);
        var data = response.data.featured;
        dispatch(getFeaturedRoomsSuccess(data));

        var d2 = new Date();

        console.log("Response Time To Load Featured Rooms: ");
        console.log(d2 - d1);
      })
      .catch((error) => {
        if (typeof error.status == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
        dispatch(getFeaturedRoomsFailure());
      });
  };
}

export const getunFeaturedRooms = () => ({
  type: actions.GET_UNFEATURED_ROOMS,
});

export const getunFeaturedRoomsSuccess = (unfeaturedRooms) => ({
  type: actions.GET_UNFEATURED_ROOMS_SUCCESS,
  payload: unfeaturedRooms,
});

export const getunFeaturedRoomsFailure = (type) => ({
  type: actions.GET_UNFEATURED_ROOMS_FAILURE,
});

export function fetchunFeaturedRooms(d1) {
  return async (dispatch) => {
    dispatch(getunFeaturedRooms());

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
        "/a/rooms/unfeatured?email=" +
        spSession.email +
        "&token=" +
        spSession.token
      )
      .then((response) => {
        console.log(response);
        var data = response.data.unfeatured;
        dispatch(getunFeaturedRoomsSuccess(data));

        var d2 = new Date();

        console.log("Response Time To Load unFeatured Rooms: ");
        console.log(d2 - d1);
      })
      .catch((error) => {
        if (typeof error.status == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
        dispatch(getunFeaturedRoomsFailure());
      });
  };
}

export const logOut = () => ({
  type: actions.LOGOUT,
});

export const resetRedirect = () => ({ type: actions.RESET_REDIRECT });

export function doLogOut() {
  return async (dispatch) => {
    var oldId = localStorage.getItem(C.SP_LOADED_ROOM);
    if (oldId !== null) {
      initUnloadRoom(oldId);
      dispatch(unloadRoom());
    }
    if (roomSocket !== null) roomSocket.disconnect();
    localStorage.clear();
    await dispatch(logOut());
    //await dispatch(resetRedirect());
    window.location = "/login";
  };
}

export function showProfileUI() {
  return async (dispatch) => {
    dispatch({ type: actions.SHOW_PROFILE_UI });
  };
}

export function hideProfileUI() {
  return async (dispatch) => {
    console.log("Hiding profile UI");
    dispatch({ type: actions.HIDE_PROFILE_UI });
  };
}

export function loadUserSettings() {
  return async (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
        "/a/user/settings?email=" +
        spSession.email +
        "&token=" +
        spSession.token
      )
      .then((response) => {
        console.log(response);
        var data = response.data.settings;
        var rank = response.data.rank;
        dispatch({
          type: actions.LOAD_USER_SETTINGS,
          payload: data,
          rank: rank,
        });
      })
      .catch((error) => {
        if (typeof error.status == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
      });
  };
}

export function showAccountUI() {
  return async (dispatch) => {
    dispatch({ type: actions.SHOW_ACCOUNT_UI });
  };
}

export function hideAccountUI() {
  return async (dispatch) => {
    console.log("Hiding account UI");
    dispatch({ type: actions.HIDE_ACCOUNT_UI });
  };
}

export function showUserProfileUI(id) {
  return async (dispatch) => {
    console.log("Showing User Profile ... x2");
    dispatch({ type: actions.SHOW_USER_PROFILE_UI, payload: id });
  };
}

export function hideUserProfileUI() {
  return async (dispatch) => {
    dispatch({ type: actions.HIDE_USER_PROFILE_UI });
  };
}

export function uploadProfilePicture(inputFile) {
  return async (dispatch) => {
    dispatch({ type: actions.UPLOAD_PROFILE_PICTURE });
  };
}

export function loadProfileData() {
  return async (dispatch) => {
    dispatch(sendLoadProfile());
  };
}

export function fetchRoomsJoined(d1) {
  return async (dispatch) => {
    dispatch(getRoomsJoined());

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
        "/a/rooms/joined?email=" +
        spSession.email +
        "&token=" +
        spSession.token
      )
      .then((response) => {
        console.log(response);
        var data = response.data.joined;
        dispatch(getRoomsJoinedSuccess(data));
        var d2 = new Date();

        console.log("Response Time To Load Joined Rooms: ");
        console.log(d2 - d1);
      })
      .catch((error) => {
        if (typeof error.status == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
        dispatch(getRoomsJoinedFailure(0));
      });
  };
}

export const createRoom = () => ({ type: actions.CREATE_ROOM });

export const searchRoom = () => ({ type: actions.SEARCH_ROOM });

export const checkForSession = () => {
  if (localStorage.getItem(C.SP_SESSION) == null) {
    doLogOut();
    return false;
  }

  try {
    var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
    return spSession;
  } catch (e) {
    doLogOut();
    return false;
  }
};

export const serializeCredentials = (cred) => {
  return "email=" + cred.email + "&token=" + cred.token;
};

export const createRoomSuccess = (data) => ({
  type: actions.CREATE_ROOM_SUCCESS,
  payload: data,
});

export const createRoomFailure = (errMsg) => ({
  type: actions.CREATE_ROOM_FAILURE,
  payload: errMsg,
});

export const searchRoomSuccess = (data) => ({
  type: actions.SEARCH_ROOM_SUCCESS,
  payload: data,
});

export const searchRoomFailure = (errMsg) => ({
  type: actions.SEARCH_ROOM_FAILURE,
  payload: errMsg,
});

export function sendCreateRoom(name, type, desc) {
  return async (dispatch) => {
    dispatch(createRoom());

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .post(C.API_EP + "/a/create/room", {
        email: spSession.email,
        token: spSession.token,
        title: name,
        type: type,
        desc: desc,
      })
      .then((response) => {
        var data = response.data.created;
        console.log(response.data);
        dispatch(createRoomSuccess(data));
      })
      .catch((error) => {
        if (typeof error.response == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        console.log(error.response.data.msg);

        dispatch(createRoomFailure(error.response.data.msg));
        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Error",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export function sendSearchRoom(keywords) {
  return async (dispatch) => {
    dispatch(searchRoom());

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    var getCred = serializeCredentials(spSession);

    axios
      .get(C.API_EP + "/a/rooms/search?" + getCred + "&search=" + keywords)
      .then((response) => {
        var data = response.data.searched;
        dispatch(searchRoomSuccess(data));
      })
      .catch((error) => {
        if (typeof error.status == "undefined") {
          console.log("network error.");
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        dispatch(searchRoomFailure(error.response.msg));
      });
  };
}

export const loadProfile = () => ({ type: actions.LOAD_PROFILE });

export const loadProfileSuccess = (data) => ({
  type: actions.LOAD_PROFILE_SUCCESS,
  payload: data,
});

export const loadProfileFailure = () => ({
  type: actions.LOAD_PROFILE_ERROR,
});

export function sendLoadProfile() {
  return async (dispatch) => {
    dispatch(loadProfile());

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
        "/a/user/profile?email=" +
        spSession.email +
        "&token=" +
        spSession.token
      )
      .then((response) => {
        var data = response.data.profile;
        dispatch(loadProfileSuccess(data));
      })
      .catch((error) => {
        dispatch(loadProfileFailure());

        if (error.status === 403) {
          doLogOut();
          return;
        }
      });
  };
}

export function initHome(data) {
  return (dispatch) => {
    dispatch({ type: actions.INIT_HOME, payload: data });
  };
}

export function initPrivateBox(thread) {
  console.log(thread);
  return (dispatch) => {
    dispatch({
      type: actions.INIT_PRIVATE_BOX,
      payload: thread,
    });
  };
}

export function updatePrivatePointer(threadId, messageId) {
  return (dispatch) => {
    console.log("Updating private pointer ....");
    privateSocket.emit(
      "updatePointer",
      { threadId: threadId, messageId: messageId },
      (data) => {
        console.log(data);
        console.log("Pointer updated!");
      }
    );
  };
}

export function acceptRequest(threadId) {
  return (dispatch) => {
    dispatch({ type: actions.SHOW_PRIVATEBOX_LOAD });

    console.log("Accepting Request");

    privateSocket.emit("acceptRequest", threadId, (data) => {
      console.log(data);
      if (data !== -1) {
        // dispatch({ type: actions.PRIVATEBOX_DATA_LOADED, data: data });
        dispatch({ type: actions.HIDE_PRIVATEBOX_LOAD });
        dispatch({ type: actions.PRIVATE_REQUEST_ACCEPTED, payload: data });
        dispatch(initPrivateBoxData(threadId));
      }
    });
  };
}

export function hidePrivateBoxLoad() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_PRIVATEBOX_LOAD });
  };
}

export function initPrivateBoxData(threadId) {
  return (dispatch) => {
    privateSocket.emit(
      "loadThreadData",
      threadId,
      (messages, targetPointer, canLoadMore) => {
        console.log(" ***** Load Thread Data *****");
        console.log(messages);
        console.log(targetPointer);
        dispatch({
          type: actions.PRIVATEBOX_DATA_LOADED,
          payload: messages,
          targetPointer: targetPointer,
          canLoadMore: canLoadMore,
        });
      }
    );
  };
}

export function showPrivateLoad() { }

export function hidePrivateLoad() { }

export function initHomeView(data) {
  return (dispatch) => {
    dispatch({ type: actions.INIT_HOME_VIEW, payload: data });
  };
}

export function setPrivateThread(data) {
  console.log(data);
  return (dispatch) => {
    dispatch({ type: actions.SET_PRIVATE_THREAD, payload: data });
  };
}

export const loadRoom = () => ({ type: actions.LOAD_ROOM });

export const showUploadBox = () => ({ type: actions.SHOW_UPLOAD_BOX });

export const hideUploadBox = () => ({ type: actions.HIDE_UPLOAD_BOX });

export const loadRoomSuccess = (
  roomDetails,
  data,
  onlineUsers,
  creator,
  role,
  ignoreList,
  canLoadMore
) => ({
  type: actions.LOAD_ROOM_SUCCESS,
  payload: {
    data: data,
    roomDetails: roomDetails,
    onlineUsers: onlineUsers.reverse(),
    creator: creator,
    role: role,
    ignoreList: ignoreList,
    canLoadMore: canLoadMore,
  },
  //dispatch(initHome({ device: 2, loadedView: 'roomchat' }))
  // initHome({ device: '1', loadedView: 'roomchat' });
});

export const loadRoomFailure = (head, body) => ({
  type: actions.LOAD_ROOM_FAILURE,
  payload: { head: head, body: body },
});

var loadRoomInterval = 0;

export async function sendLoadRoom(dispatch, id, spSession) {
  /*
  axios
    .get(
      C.API_EP +
        '/a/r/message?email=' +
        spSession.email +
        '&token=' +
        spSession.token +
        '&roomId=' +
        id
    )
    .then((response) => {
      var data = response.data.msgs;
      var roomDetails = response.data.roomDetails;
      dispatch(loadRoomSuccess(roomDetails, data));
    })
    .catch((error) => {
      if (typeof error.status == 'undefined') {
        console.log('network error.');
        return;
      }
      if (error.status === 403) {
        localStorage.removeItem('sp_session');
        doLogOut();
        return;
      }

      dispatch(loadRoomFailure(error.response.msg));
    });
    */
  loadRoomInterval = new Date();
  roomSocket.emit("loadRoom", { cred: spSession, id: id });
}

export const joinRoom = (itemId) => ({
  type: actions.JOIN_ROOM,
  itemId: itemId,
});

export const joinRoomSuccess = (itemId, roomDetails) => ({
  type: actions.JOIN_ROOM_SUCCESS,
  itemId: itemId,
  payload: roomDetails,
});

export const joinRoomFailure = (itemId) => ({
  type: actions.JOIN_ROOM_FAILURE,
  itemId: itemId,
});

export const leaveRoom = (itemId) => ({
  type: actions.LEAVE_ROOM,
  itemId: itemId,
});

export const leaveRoomSuccess = (itemId) => ({
  type: actions.LEAVE_ROOM_SUCCESS,
  itemId: itemId,
});

export const leaveRoomFailure = (itemId) => ({
  type: actions.LEAVE_ROOM_FAILURE,
  itemId: itemId,
});

export function sendLeaveRoom(id) {
  return async (dispatch) => {
    dispatch(leaveRoom(id));
    console.log("Leaving room ... " + id);
    initUnloadRoom(id);
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .post(C.API_EP + "/a/r/leave", {
        email: spSession.email,
        token: spSession.token,
        roomId: id,
      })
      .then((response) => {
        //var data = response.data.joined;
        dispatch(leaveRoomSuccess(id));
        // sendLoadRoom(dispatch, id, spSession);
      })
      .catch((error) => {
        dispatch(leaveRoomFailure(id));

        if (typeof error.status == "undefined") {
          console.log(error);
          dispatch(
            doDispatchNotification({
              status: "danger",
              heading: "Error",
              msg: "Network error or resource not found.",
            })
          );
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        doDispatchNotification({
          status: "danger",
          heading: "Error",
          msg: error.response.msg,
        });
      });
  };
}

export function sendJoinRoom(id) {
  return async (dispatch) => {
    dispatch(joinRoom(id));

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .post(C.API_EP + "/a/rooms/join", {
        email: spSession.email,
        token: spSession.token,
        roomId: id,
      })
      .then((response) => {
        var data = response.data.joined;
        dispatch(joinRoomSuccess(id, data));
        sendLoadRoom(dispatch, id, spSession);
      })
      .catch((error) => {
        dispatch(joinRoomFailure(id));

        if (typeof error.response == "undefined") {
          console.log(error);
          dispatch(
            doDispatchNotification({
              status: "danger",
              heading: "Error",
              msg: "Network error or resource not found.",
            })
          );
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Error",
            msg: error.response.data.msg,
          })
        );
      });
  };
}

export const joinRoomByUISuccess = (itemId, data) => ({
  type: actions.JOIN_ROOM_BYUI_SUCCESS,
  itemId: itemId,
  payload: data,
});

export function closeJoinRoomUI() {
  return async (dispatch) => {
    dispatch({ type: actions.CLOSE_JOINROOM_UI });
  };
}

export function joinRoomByUI(id) {
  return async (dispatch) => {
    //var id = store.getState().appState.sidebar.joinRoom.roomId;

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .post(C.API_EP + "/a/rooms/join", {
        email: spSession.email,
        token: spSession.token,
        roomId: id,
      })
      .then((response) => {
        var data = response.data.joined;
        dispatch(joinRoomByUISuccess(id, data));
        dispatch(initSendLoadRoom(id));
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response)

        if (!error.response) {
          dispatch(
            doDispatchNotification({
              status: "danger",
              heading: "Error",
              msg: "Network error or resource not found.",
            })
          );
          return;
        }

        if (error.status === 403) {
          doLogOut();
          return;
        }

        dispatch(
          doDispatchNotification({
            status: "danger",
            heading: "Error",
            msg: error.response.data.msg,
          })
        );

      });
  };
}

export const unloadRoom = () => ({ type: actions.UNLOAD_ROOM });

export function initUnloadRoom(id) {
  var spSession = checkForSession();
  if (spSession !== null) {
    if (roomSocket !== null) {
      roomSocket.emit("unloadRoom", { id: id, cred: spSession });
    }
    localStorage.removeItem(C.SP_LOADED_ROOM);
  }
}

export function initGuestLoadRoom(id) {
  return async (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return 0;
    }

    localStorage.setItem(C.SP_LOADED_ROOM, id);
    guestSocket.emit("loadRoom", { cred: spSession, id: id });
  };
}

export function initSendLoadRoom(id) {
  //console.trace("Loading room");
  return async (dispatch) => {
    var roomId = store.getState().appState.chatbox.roomId;

    console.log(roomId);
    console.log(id);
    if (id === roomId) {
      dispatch({ type: actions.INIT_CHATBOX_ONLY });
      return;
    }

    dispatch(loadRoom());

    var spSession = checkForSession();
    if (spSession === false) {
      return 0;
    }

    var oldId = localStorage.getItem(C.SP_LOADED_ROOM);
    if (oldId !== null) {
      initUnloadRoom(oldId);
      dispatch(unloadRoom);
    }

    localStorage.setItem(C.SP_LOADED_ROOM, id);
    sendLoadRoom(dispatch, id, spSession);
  };
}

export const dismissNotification = () => ({
  type: actions.DISMISS_NOTIFICATION,
});

export function doDismissNotification() {
  return async (dispatch) => {
    dispatch(dismissNotification());
  };
}

export const dispatchNotification = (notification) => ({
  type: actions.DISPATCH_NOTIFICATION,
  payload: notification,
});

export function doDispatchNotification(notification) {
  return async (dispatch) => {
    console.log("Dispatching notification ....");
    console.log(notification);
    dispatch(dispatchNotification(notification));
    setTimeout(function () {
      dispatch(doDismissNotification());
    }, C.GLOBAL_NOTIFICATION_DURATION);
  };
}

export const roomMessage = () => ({ type: actions.ROOM_MESSAGE });

export const privateMessage = () => ({ type: actions.PRIVATE_MESSAGE });

export function sendRoomMessage(messageText, roomId) {
  return async (dispatch) => {
    dispatch(roomMessage());

    var spSession = checkForSession();
    if (spSession === false) {
      return 0;
    }

    var processedMessage = processMessage(messageText);
    console.log(processedMessage);

    try {
      var msg = JSON.stringify(processedMessage);
    } catch (e) {
      return;
    }

    console.log(msg);

    var messageObject = {
      cred: spSession,
      id: roomId,
      message: msg,
      type: "hybrid",
    };

    roomSocket.emit("sendMessage", messageObject);
  };
}

export function sendGuestRoomMessage(messageText, roomId) {
  return async (dispatch) => {
    dispatch(roomMessage());

    var spSession = checkForSession();
    if (spSession === false) {
      return 0;
    }

    var messageObject = {
      cred: spSession,
      id: roomId,
      message: messageText,
      type: "text",
    };

    guestSocket.emit("sendMessage", messageObject);
  };
}

export function processMessage(t) {
  var rV = [];
  var a = t.match(/@[a-z0-9]+/g);
  console.log(a);
  if (a === null) return processMessage2(t);
  a.map((v) => {
    //var r = t.split(v);
    var index = t.indexOf(v);

    console.log(index);
    var r = [];
    r[0] = t.substring(0, index);
    r[1] = t.substring(index + v.length);
    console.log(r);
    if (r[0] !== "") rV.push({ type: "text", data: r[0] });
    rV.push({ type: "mention", data: v.substr(1) });
    t = r[1];
  });
  if (t !== "") {
    rV.push({ type: "text", data: t });
  }
  return rV;
}

export function processMessage2(t) {
  var rV = [];
  var a = t.match(/https?:\/\/(w{3}\.)?youtube\.com\/watch\?.+?(\s|$)/g);
  console.log(a);
  if (a === null) return processMessage3(t);
  a.map((v) => {
    //var r = t.split(v);
    var index = t.indexOf(v);

    console.log(index);
    var r = [];
    r[0] = t.substring(0, index);
    r[1] = t.substring(index + v.length);
    console.log(r);
    if (r[0] !== "") rV.push({ type: "text", data: r[0] });
    rV.push({ type: "yt", data: v.substr(0) });
    t = r[1];
  });
  if (t !== "") {
    rV.push({ type: "text", data: t });
  }
  return rV;
}

export function processMessage3(t) {
  var rV = [];
  var a = t.match(/https?:\/\/(w{,3}\.)?youtu\.be\/.+?(\s|$)/g);
  console.log(a);
  if (a === null) return [{ type: "text", data: t }];
  a.map((v) => {
    //var r = t.split(v);
    var index = t.indexOf(v);

    console.log(index);
    var r = [];
    r[0] = t.substring(0, index);
    r[1] = t.substring(index + v.length);
    console.log(r);
    if (r[0] !== "") rV.push({ type: "text", data: r[0] });
    rV.push({
      type: "yt",
      data:
        "https://youtube.com/embed/" +
        v.substr(0).replace("https://youtu.be/", ""),
    });
    t = r[1];
  });
  if (t !== "") {
    rV.push({ type: "text", data: t });
  }
  return rV;
}

export function openPlayMedia(d, t, un) {
  return (dispatch) => {
    dispatch({
      type: actions.SHOW_PLAY_MEDIA,
      payload: { type: t, extra: { data: d, uploader: un } },
    });
  };
}

export function closePlayMedia() {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_PLAY_MEDIA });
  };
}

export function openPlayMediaPrivate(d, t, un) {
  return (dispatch) => {
    dispatch({
      type: actions.SHOW_PLAY_MEDIA_PRIVATE,
      payload: { type: t, extra: { data: d, uploader: un } },
    });
  };
}

export function closePlayMediaPrivate() {
  return (dispatch) => {
    console.log("Closing private media");
    dispatch({ type: actions.HIDE_PLAY_MEDIA_PRIVATE });
  };
}

export function resetPrivateBox() {
  return (dispatch) => {
    dispatch({ type: actions.LOAD_WAIT_COMPONENT });
    dispatch({ type: actions.RESET_PRIVATEBOX });
  };
}

export function postPrivateMessage(messageText, threadId) {
  return async (dispatch) => {
    dispatch(privateMessage());

    var spSession = checkForSession();
    if (spSession === false) {
      return 0;
    }

    var messageObject = {
      threadId: threadId,
      message: messageText,
    };

    privateSocket.emit("send", messageObject, function (data) {
      console.log("[socket incoming] ***** message ack *****");
      console.log(data);
      axios
        .get(
          C.API_EP +
          "/a/private/getSingle?email=" +
          spSession.email +
          "&token=" +
          spSession.token +
          "&privateMessageId=" +
          data +
          "&threadId=" +
          threadId
        )
        .then((resposne) => {
          console.log(resposne);
          var message = resposne.data.data;
          dispatch({
            type: actions.NEW_PRIVATE_MESSAGE,
            payload: message,
            threadId: threadId,
          });
          dispatch({ type: actions.CLEAR_PRIVATE_INPUT });

          var loadedView = store.getState().appState.spGlobal.layout.loadedView;
          if (loadedView === "privatebox") {
            dispatch(updatePrivatePointer(threadId, message._id));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
}
export function deleteRoomMessage(messageId, roomId) {
  return async (dispatch) => {
    var spSession = checkForSession();
    if (spSession === false) {
      return 0;
    }

    var messageObject = {
      cred: spSession,
      id: roomId,
      messageId: messageId,
    };

    roomSocket.emit("upDeleteMessage", messageObject);
  };
}
