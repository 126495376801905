import React, { useState } from 'react'
import { UserCard } from './UserCard';

export const UserList = ({ columns, data, loading, updateUser, deleteUser }) => {

    const [showUser, setShowUser] = useState(false);
    const [user, setUser] = useState([]);

    const geTDate = (d) => {
        var r = new Date(d);
        return r;
    }

    return (
        <div style={{ padding: '15px' }}>
            {/* {JSON.stringify(columns)} */}
            {/* <hr />
            {JSON.stringify(data)}
             */}
            {loading && <p>Loading</p>}
            {!loading && data.length !== 0 &&
                <table style={{ fontSize: '14px' }}>
                    <thead>
                        <tr>
                            {columns.map((e, index) =>

                                <th key={index}>
                                    {e.name}
                                </th>

                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(e =>

                            <tr onClick={() => {
                                //alert(e.username)
                                setUser(e);
                                setShowUser(true);
                            }}
                                key={e._id}
                                style={{
                                    cursor: 'pointer'
                                }}
                            >
                                <td style={{
                                    fontWeight: 'bold'
                                }}>@{e.username}</td>
                                <td>{e.email}</td>
                                <td onClick={(e) => {
                                    e.stopPropagation()
                                }}><a target="_blank" href={"https://whatismyipaddress.com/ip/" + e.ip}>{e.ip}</a></td>
                                <td>{e.globalRank}</td>
                                <td>{e.accountStatus === 1 ? 'Enabled' : 'Disabled'}</td>
                                <td>{e.verified === 1 ? 'Verified' : 'Not Verified'}</td>
                                <td>{geTDate(e.date).toLocaleDateString()}</td>
                            </tr>

                        )}
                    </tbody>
                </table>
            }
            {!loading && data.length === 0 && <p>No records here!</p>}

            {showUser && <UserCard deleteUserParent={deleteUser} updateUser={updateUser} user={user} closeHandler={setShowUser} />}

        </div>
    )
}
