import React from 'react';

export const NotFound = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src='/resources/images/notfound.gif'
        alt='404 not found '
        style={{ maxWidth: '100vw', maxHeight: '100vh' }}
      />
    </div>
  );
};
