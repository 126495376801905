import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
  showUserProfileUI,
  showRoomMembers,
} from '../../redux/actions/appActions';
import C from './../../util/Constants';
import Axios from 'axios';

const ConnectedList = ({ dispatch, memberlist }) => {
  useEffect(() => {
    console.log('Loading details of the memberlist ...');
    adjustMemeberList();
  }, [dispatch]);

  const loadUser = (id) => {
    dispatch(showUserProfileUI(id));
  };

  const getUser = (id) => {
    var spSession = checkForSession();

    if (spSession === false) {
      return;
    }

    Axios.get(
      C.API_EP +
        '/a/user/profile?id=' +
        id +
        '&email=' +
        spSession.email +
        '&token=' +
        spSession.token
    )
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {});
  };

  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
      return spSession;
    } catch (e) {
      return false;
    }
  };

  const adjustMemeberList = () => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      var spChatbox = document.getElementsByClassName('sp-chatbox')[0];
      var spChatboxCard = spChatbox.getElementsByClassName('card')[0];
      var spChatBoxCardHeader = spChatboxCard.getElementsByClassName(
        'card-header'
      )[0];
      var spSidebar = document.getElementsByClassName('sp-memberlist')[0];
      spSidebar.style.top = spChatBoxCardHeader.offsetHeight + 'px';
    }
  };

  return (
    <Fragment>
      <div className='card'>
        <div className='card-header'>
          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Members Details
          </div>
        </div>
        <div
          className='card-body'
          style={{ maxHeight: '80vh', overflow: 'auto' }}
        >
          <div style={{ fontWeight: 'bold', fontSize: '15px' }}>Creator</div>

          <ul class='list-group'>
            {typeof memberlist !== 'undefined' &&
              memberlist.creator.map((e) => {
                return (
                  <li
                    class='list-group-item d-flex justify-content-between align-items-center clickable'
                    onClick={() => {
                      loadUser(e._id);
                    }}
                    key={e._id}
                    style={{ fontSize: '12px' }}
                  >
                    <div style={{ display: 'flex' }}>
                     <img
                        // src={C.ASSET_EP + '/avatars/' + e.profile.picture}
                        src={
                          typeof e.profile !== 'undefined' &&
                          C.ASSET_EP +
                            '/avatars/' +
                            e.profile.picture.split('.')[0] +
                            '_sm.' +
                            e.profile.picture.split('.')[1]
                        }
                        style={{ height: '23px', width: '23px' }}
                        alt='user icon'
                        className='rounded-circle'
                      />

                    <span                         
                        style={{
                          fontWeight: 'bold',
                          marginLeft: '7px',
                          fontSize: '14px'
                        }}
                    >                    
                        {e.username}
                    </span>
                    <span class='badge badge-primary badge-pill'                         
                    >
                      {e.globalRank}
                    </span>
                    </div>
                  </li>
                );
              })}
            {/* {typeof memberlist !== 'undefined' &&
              memberlist.online.length === 0 && (
                <li class='list-group-item d-flex justify-content-between align-items-center'>
                  No users here.
                </li>
              )} */}
          </ul>

          <hr />

          <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
            Users Online
            <span
              style={{
                background: '#2ab52a',
                verticalAlign: 'super',
                display: 'inline-block',
                height: '7px',
                width: '7px',
                margin: '2px',
                border: '1px solid #2ab52a',
                borderRadius: '50%',
              }}
            ></span>
          </div>

          <ul class='list-group'>
            {typeof memberlist !== 'undefined' &&
              memberlist.online.map((e, index) => {
                if(!e.isGuest)
                return (
                  <li
                    class='list-group-item d-flex justify-content-between align-items-center clickable'
                    onClick={() => {
                      loadUser(e._id);
                    }}
                    key={e._id}
                    style={{ fontSize: '12px' }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img
                        // src={C.ASSET_EP + '/avatars/' + e.profile.picture}
                        src={
                          typeof e.user !== 'undefined' &&
                          C.ASSET_EP +
                            '/avatars/' +
                            e.user.profile.picture.split('.')[0] +
                            '_sm.' +
                            e.user.profile.picture.split('.')[1]
                        }
                        style={{ height: '23px', width: '23px' }}
                        alt='user icon'
                        className='rounded-circle'
                      />

                      <span
                        class=''
                        style={{
                          fontWeight: 'bold',
                          marginLeft: '7px',
                          fontSize: '14px',
                          color: typeof(e.user.role) !== 'undefined' ? e.user.role.color : ''
                        }}
                      >
                        {e.username}
                      </span>
                    </div>
                  </li>
                );
                else 
                return (
                  <li
                    class='list-group-item d-flex justify-content-between align-items-center clickable'
                    onClick={() => {
                    }}
                    key={e._id}
                    style={{ fontSize: '12px', pointerEvents: 'none' }}
                  >
                    <div style={{ display: 'flex' }}>
                      {/* <img
                        // src={C.ASSET_EP + '/avatars/' + e.profile.picture}
                        src={
                          typeof e.user !== 'undefined' &&
                          C.ASSET_EP +
                            '/avatars/default_sm.png'
                        }
                        style={{ height: '23px', width: '23px' }}
                        alt='user icon'
                        className='rounded-circle'
                      /> */}

                      <span
                        class=''
                        style={{
                          marginLeft: '7px',
                          fontSize: '14px',
                          color: 'black',
                          fontWeight:'italic'
                        }}
                      >[G] 
                        {e.username}
                      </span>
                    </div>
                  </li>
                );

              })}
            {typeof memberlist !== 'undefined' &&
              memberlist.online.length === 0 && (
                <li class='list-group-item d-flex justify-content-between align-items-center'>
                  No users here.
                </li>
              )}
          </ul>

        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    memberlist: state.appState.memberlist,
  };
};

const MemberList = connect(mapStateToProps)(ConnectedList);

export default MemberList;
