import * as actions from "../constants/action-types";
import { act } from "react-dom/test-utils";

const initialSidebar = () => {
  return {
    roomsJoined: {
      data: [],
      load: false,
      error: {},
    },
    roomsCreated: {
      data: [],
      loading: false,
      error: {},
    },
    featuredRooms: {
      data: [],
      loading: false,
      error: {},
    },
    unfeaturedRooms: {
      data: [],
      loading: false,
      error: {},
    },
    privateChats: [],
    currentRoom: 0,
    privateChatsLoading: false,
    privateChatsLoadingError: false,
    focusPrivate: false,
    loaded: false,
    sidebarActiveTab: 1,
    roomsActiveTab: 1,
    privateNotif: false,
    sounds: 0,
    rank: 0,
    createRoom: {
      creating: false,
      status: -1,
      errMsg: "",
    },
    joinRoom: {
      joining: false,
      roomId: 0,
      roomDetails: {},
    },
    roomsSearched: {
      data: [],
      loading: false,
      error: {},
    },
    profile: {
      showUI: false,
      load: 2 /* -1 for error, 0 for loading, 1 for sccess, 2 for not loading */,
      update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
      data: {},
    },
    account: {
      showUI: false,
      load: 2 /* -1 for error, 0 for loading, 1 for sccess, 2 for not loading */,
      update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
      data: {},
    },
  };
};

function initialChatbox() {
  return {
    roomMessages: [],
    roomId: 0,
    roomTitle: "",
    roomDesc: "",
    roomTopic: "",
    mountedView: 0,
    background: "",
    iOwn: true,
    mediaActive: false,
    roomMessagesLoading: false,
    incomingMessage: false,
    loadingMore: false,
    canLoadMore: true,
    privateNotif: false,
    media: {
      type: 0,
      extra: "",
    },
    emojis: {
      show: false,
      list: [],
    },
    userSuggestions: {
      loading: false,
      data: [],
    },
    commands: [],
    commandSuggestions: {
      show: false,
      data: [],
    },
    messageInput: {
      sendingMessage: false,
      messageText: "",
    },
    roomLoadError: {
      status: false,
      head: "",
      body: "",
    },
    showFooter: true,
    initRoom: false,
    userProfile: {
      showUi: false,
      userId: false,
    },
    roomDetails: {
      showUi: false,
      roomId: 0,
    },
    roomSettings: {
      showUi: false,
      roomId: 0,
    },
    roomMembers: {
      showUi: false,
      roomId: 0,
    },

    uploadFile: {
      showUI: false,
      load: 2 /* -1 for error, 0 for loading, 1 for sccess, 2 for not loading */,
      update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
      data: {},
    },

    uploadVideo: {
      showUI: false,
      load: 2 /* -1 for error, 0 for loading, 1 for sccess, 2 for not loading */,
      update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
      data: {},
    },

    permissions: {},
    sounds: 0,
    ignoreList: [],
    uploadBox: {
      show: false,
    },
    stream: {
      audio: [],
      audioSpeakers: [],
      meSpeaker: [],
      init: false,
      joining: false,
      joined: false,
      count: 0,
      speaking: false,
      permission: false,
      audioListeners: [],
      muted: true,
      disconnected: false
    },
    socket: null,
    threads: {
      loading: false,
      loadingMore: false,
      canLoadMore: false,
      data: [],
      pinnedThreads: [],
    },
    tagSearch: [],
    replies: {
      // thread: {},
      // loading: true,
      // data: []
    }

  };
}

function initialMemberlist() {
  return {
    roomId: "",
    members: [],
    online: [],
    creator: [],
  };
}

function initialState() {
  var sidebar = initialSidebar();

  var chatbox = initialChatbox();

  var memberlist = initialMemberlist();

  var Private = {
    list: { data: [], load: 2, canLoadMore: false },
    box: {
      init: false,
      canLoadMore: false,
      loadingMore: false,
      mediaActive: false,
      media: {
        type: 0,
        extra: "",
      },
      sounds: 0,
      data: [],
      load: 2,
      messageInput: {
        sending: 2,
        messageText: "",
      },
      uploadFile: {
        showUi: false,
        load: 2 /* -1 for error, 0 for loading, 1 for sccess, 2 for not loading */,
        update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
        data: {},
      },
      uploadVideo: {
        showUI: false,
        load: 2 /* -1 for error, 0 for loading, 1 for sccess, 2 for not loading */,
        update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
        data: {},
      },

    },
  };

  var spGlobal = {
    redirect: { status: false, to: "/login" },
    notification: { status: "", heading: "", msg: "", show: false },
    settings: {},
  };
  return {
    sidebar: sidebar,
    chatbox: chatbox,
    memberlist: memberlist,
    spGlobal: spGlobal,
    Private: Private,
    layout: {
      device: 1, // 1 means desktop, 2 means mobile
      loadedView: "roomchat", //initially values can be [only on mobile: sidebar] roomchat, privatechat, welcome,
    },
  };
}
const appState = initialState();

function appReducer(state = appState, action) {
  switch (action.type) {
    case actions.SET_PRIVATE_LIST:
      return {
        ...state,
        Private: {
          ...state.Private,
          list: {
            ...state.Private.list,
            data: action.payload.list.map((e) => ({ ...e })),
            load: 1,
            canLoadMore: action.payload.canLoadMore,
          },
        },
      };

    case actions.INIT_AUDIO_STREAM_BUFFER:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            audio: [...state.chatbox.stream.audio, { username: action.username, data: [] }]
          },
        },
      };

    case actions.UNINIT_AUDIO_STREAM_BUFFER:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            audio: [...state.chatbox.stream.audio.filter(e => e.username !== action.username)]
          },
        },
      };

    case actions.JOIN_BROADCAST:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            joining: true
          },
        },
      };

    case actions.UPGRADE_TO_SPEAKER:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            speaking: true,
          },
        },
      };


    case actions.LISTENER_LEFT:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            audioListeners: state.chatbox.stream.audioListeners.filter(e => e.username !== action.username)
          },
        },
      };

    case actions.ME_SPEAKER_LEFT:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            meSpeaker: state.chatbox.stream.meSpeaker.filter(e => e.username !== action.username)
          },
        },
      };

    case actions.SPEAKER_LEFT:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            audioSpeakers: state.chatbox.stream.audioSpeakers.filter(e => e.username !== action.username)
          },
        },
      };

    case actions.ME_SPEAKER_JOINED:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            meSpeaker: [...state.chatbox.stream.meSpeaker, { username: action.username, avatar: action.avatar }]
          },
        },
      };

    case actions.SPEAKER_JOINED:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            audioSpeakers: [...state.chatbox.stream.audioSpeakers, { username: action.username, avatar: action.avatar }]
          },
        },
      };

    case actions.LISTENER_JOINED:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            audioListeners: [...state.chatbox.stream.audioListeners, action.listener]
          },
        },
      };

    case actions.DOWNGRADE_FROM_SPEAKER:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            speaking: false,
          },
        },
      };

    case actions.JOIN_BROADCAST_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            joining: false,
            joined: true,
            permission: action.permission,
            audioListeners: action.listeners,
          },
        },
      };

    case actions.LEAVE_BROADCAST_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            joining: false,
            joined: false,
            permission: action.permission,
            audioListeners: [],
            audioSpeakers: [],
            count: 0,
            init: false,
            disconnected: action.disconnect
          },
        },
      };

    case actions.AUDIO_STREAM_PEEK:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            init: true,
            count: action.count,
            disconnected: false
          },
        },
      };

    case actions.INIT_AUDIO_STREAM_SPEAKER:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            audioSpeakers: [...state.chatbox.stream.audioSpeakers, { username: action.username, avatar: action.avatar }]
          },
        },
      };

    case actions.CONSUMED_AUDIO_STREAM_CHUNK:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            audio: state.chatbox.stream.audio.map(e => {
              if (e.username !== action.username)
                return e;
              else
                return {
                  ...e, data: e.data.map((ee, index) => {
                    if (index === action.index)
                      return [true, ee[1]];
                    else return ee;
                  })
                }
            })
          },
        },
      };

    case actions.APPEND_TO_AUDIO_STREAM_BUFFER:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          stream: {
            ...state.chatbox.stream,
            audio: state.chatbox.stream.audio.map(e => {
              if (e.username !== action.username)
                return e;
              else
                return { ...e, data: [...e.data, [false, action.buffer]] }
            })
          },
        },
      };

    case actions.LOAD_REPLIES_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          replies: action.payload
        }
      }

    case actions.LOAD_MORE_REPLIES_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          replies: action.payload
        }
      }

    case actions.LOAD_REPLIES:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          replies: action.payload
        }
      }

    case actions.CLEAR_REPLIES:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          replies: action.payload
        }
      }


    case actions.PUSH_REPLY:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          replies: action.payload,
          threads: action.threads
        }
      }


    case actions.LOAD_ROOM_THREADS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            loading: true
          }
        }
      }

    case actions.LOAD_MORE_ROOM_THREADS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            loadingMore: true
          }
        }
      }

    case actions.LOAD_MORE_ROOM_THREADS_ERROR:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            loadingMore: false
          }
        }
      }

    case actions.LOAD_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            data: action.payload.map(e => { if (e._id === action.payload) return { ...e, loaded: true }; else return e })
          }
        }
      }

    case actions.CHANGE_PIN_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            pinnedThreads: [...state.chatbox.threads.pinnedThreads, action.thread],
            data: state.chatbox.threads.data.filter(e => e._id !== action.payload),
          }
        }
      }

    case actions.REMOVE_PIN_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            data:
              state.chatbox.threads.canLoadMore ?
                state.chatbox.threads.data[state.chatbox.threads.data.length - 1].date <= action.thread.date
                  ?
                  [...state.chatbox.threads.data, action.thread].sort(function (a, b) {
                    // if (typeof (a) === 'undefined' || typeof (b) === 'undefined')
                    //   return 0;
                    return new Date(b.date) - new Date(a.date);
                  })
                  :
                  [...state.chatbox.threads.data]
                :
                [...state.chatbox.threads.data, action.thread].sort(function (a, b) {
                  // if (typeof (a) === 'undefined' || typeof (b) === 'undefined')
                  //   return 0;
                  return new Date(b.date) - new Date(a.date);
                })
            ,
            pinnedThreads: state.chatbox.threads.pinnedThreads.filter(e => e._id !== action.payload),
          }
        }
      }

    case actions.DELETE_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            data: state.chatbox.threads.data.filter(e => { if (e._id === action.payload) return false; else return true }),
            pinnedThreads: state.chatbox.threads.pinnedThreads.filter(e => { if (e._id === action.payload) return false; else return true })
          }
        }
      }

    case actions.DELETE_REPLY:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          replies: action.replies
        }
      }

    case actions.LIKE_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            data: state.chatbox.threads.data
              .map(e => {
                if (e._id === action.threadId)
                  return {
                    ...e,
                    likes: [...e.likes, action.userId],
                    dislikes: e.dislikes.filter(
                      (e) => e
                        !== action.userId
                    )
                  };
                else
                  return e;
              }),
            pinnedThreads: state.chatbox.threads.pinnedThreads
              .map(e => {
                if (e._id === action.threadId)
                  return {
                    ...e,
                    likes: [...e.likes, action.userId],
                    dislikes: e.dislikes.filter(
                      (e) => e
                        !== action.userId
                    )
                  };
                else
                  return e;
              })
          },
          replies: action.replies
        }
      }

    case actions.UNLIKE_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            data: state.chatbox.threads.data
              .map(e => {
                if (e._id === action.threadId)
                  return {
                    ...e,
                    likes: e.likes.filter(
                      (e) => e
                        !== action.userId
                    )
                  };
                else
                  return e;
              }),
            pinnedThreads: state.chatbox.threads.pinnedThreads
              .map(e => {
                if (e._id === action.threadId)
                  return {
                    ...e,
                    likes: e.likes.filter(
                      (e) => e
                        !== action.userId
                    )
                  };
                else
                  return e;
              })

          },
          replies: action.replies

        }
      }

    case actions.DISLIKE_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            data: state.chatbox.threads.data
              .map(e => {
                if (e._id === action.threadId)
                  return {
                    ...e,
                    dislikes: [...e.dislikes, action.userId],
                    likes: e.likes.filter(
                      (e) => e
                        !== action.userId
                    )
                  };
                else
                  return e;
              }),
            pinnedThreads: state.chatbox.threads.pinnedThreads
              .map(e => {
                if (e._id === action.threadId)
                  return {
                    ...e,
                    dislikes: [...e.dislikes, action.userId],
                    likes: e.likes.filter(
                      (e) => e
                        !== action.userId
                    )
                  };
                else
                  return e;
              })
          },
          replies: action.replies
          // replies: {
          //   ...state.chatbox.replies,
          //   data: state.chatbox.replies.data
          //     .map(e => {
          //       if (e !== null && e._id === action.threadId)
          //         return {
          //           ...e,
          //           dislikes: [...e.dislikes, action.userId],
          //           likes: e.likes.filter(
          //             (e) => e
          //               !== action.userId
          //           )
          //         };
          //       else
          //         return e;
          //     })
          // }

        }
      }

    case actions.PIN_REPLY_TO_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          replies: action.replies
        }
      }

    case actions.UNPIN_REPLY_FROM_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          replies: action.replies
        }
      }


    case actions.UNDISLIKE_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            data: state.chatbox.threads.data
              .map(e => {
                if (e._id === action.threadId)
                  return {
                    ...e,
                    dislikes: e.dislikes.filter(
                      (e) => e
                        !== action.userId
                    )
                  };
                else
                  return e;
              }),
            pinnedThreads: state.chatbox.threads.pinnedThreads
              .map(e => {
                if (e._id === action.threadId)
                  return {
                    ...e,
                    dislikes: e.dislikes.filter(
                      (e) => e
                        !== action.userId
                    )
                  };
                else
                  return e;
              })
          },
          replies: action.replies
        }
      }

    case actions.ARCHIVE_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            data: state.chatbox.threads.data.map((e, index) => {
              if (action.payload === e._id) {
                return { ...e, archived: true }
              }
              else {
                return e;
              }
            })
            ,
            pinnedThreads: state.chatbox.threads.pinnedThreads.map((e, index) => {
              if (action.payload === e._id) {
                return { ...e, archived: true }
              }
              else {
                return e;
              }
            })
          },
        }
      }

    case actions.PUSH_THREAD:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            data: [...state.chatbox.threads.data, action.payload].sort(function (a, b) {
              return new Date(b.date) - new Date(a.date);
            })
          }
        }
      }

    case actions.LOAD_TAG_SEARCH_THREADS_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          tagSearch: {
            ...state.chatbox.threads,
            loading: false,
            canLoadMore: action.canLoadMore,
            data: action.payload.map(e => { return { ...e, loaded: false } }),
          }
        }
      }


    case actions.LOAD_ROOM_THREADS_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            loading: false,
            canLoadMore: action.canLoadMore,
            data: action.payload.map(e => { return { ...e, loaded: false } }),
            pinnedThreads: action.pinnedThreads,
            // .sort(function (a, b) {
            //   var dateA = new Date(a.date);
            //   var dateB = new Date(b.date);
            //   // var dayA = dateA.getDate() * dateA.getMonth();
            //   // var dayB = dateB.getDate() * dateB.getMonth();

            //   // if (dayA === dayB) {
            //   //   var scoreA = (a.likes.length - a.dislikes.length) + a.replies;
            //   //   var scoreB = (b.likes.length - b.dislikes.length) + b.replies;
            //   //   return scoreB - scoreA;
            //   // }
            //   //return dayB - dayA;

            // })
          }
        }
      }

    case actions.LOAD_MORE_ROOM_THREADS_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          threads: {
            ...state.chatbox.threads,
            loadingMore: false,
            canLoadMore: action.canLoadMore,
            data: [...state.chatbox.threads.data, ...action.payload]
          }
        }
      };

    case actions.SET_ROOM_SOCKET:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          socket: action.payload
        }
      }

    case actions.SHOW_PLAY_MEDIA_PRIVATE:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            mediaActive: true,
            media: {
              type: action.payload.type,
              extra: action.payload.extra,
              private: true
            },
          },
        },
      };

    case actions.HIDE_PLAY_MEDIA_PRIVATE:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            mediaActive: false
          },
        },
      };

    case actions.SET_MORE_PRIVATE_LIST:
      return {
        ...state,
        Private: {
          ...state.Private,
          list: {
            ...state.Private.list,
            data: [
              ...state.Private.list.data,
              ...action.payload.list.map((e) => ({ ...e })),
            ],
            load: 1,
            canLoadMore: action.payload.canLoadMore,
          },
        },
      };

    case actions.SHOW_PLAY_MEDIA:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          mediaActive: true,
          media: {
            type: action.payload.type,
            extra: action.payload.extra,
          },
        },
      };

    case actions.HIDE_PLAY_MEDIA:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          mediaActive: false,
        },
      };

    case actions.MORE_ROOM_MESSAGES_LOADED:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMessages: [...action.payload, ...state.chatbox.roomMessages],
          loadingMore: false,
          canLoadMore: action.canLoadMore,
        },
      };

    case actions.MORE_PRIVATE_MESSAGES_LOADED:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            data: [...action.payload, ...state.Private.box.data],
            loadingMore: false,
            canLoadMore: action.canLoadMore,
          },
        },
      };

    case actions.SHOW_UPLOAD_BOX:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          uploadBox: {
            ...state.chatbox.uploadBox,
            show: true,
          },
        },
      };

    case actions.HIDE_UPLOAD_BOX:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          uploadBox: {
            ...state.chatbox.uploadBox,
            show: false,
          },
        },
      };

    case actions.SET_USER_SUGGESTION_LOADING:
      return {
        ...state,
      };

    case actions.SET_PRIVATE_THREAD:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: action.payload,
          },
        },
      };

    case actions.UNSET_USER_SUGGESTION_LOADING:
      return {
        ...state,
      };

    case actions.SHOW_LOADING_MORE_ROOM_MESSAGES:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          loadingMore: true,
        },
      };

    case actions.HIDE_LOADING_MORE_ROOM_MESSAGES:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          loadingMore: false,
        },
      };

    case actions.SHOW_LOADING_MORE_PRIVATE_MESSAGES:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            loadingMore: true,
          },
        },
      };

    case actions.HIDE_LOADING_MORE_PRIVATE_MESSAGES:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            loadingMore: false,
          },
        },
      };

    case actions.LOAD_USER_SETTINGS:
      return {
        ...state,
        spGlobal: {
          ...state.spGlobal,
          settings: action.payload,
        },
        chatbox: {
          ...state.chatbox,
          sounds: action.payload.sounds,
        },
        sidebar: {
          ...state.sidebar,
          sounds: action.payload.sounds,
          rank: action.rank,
        },
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            sounds: action.payload.sounds,
          },
        },
      };
    case actions.LOAD_EMOJI_BOX:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          emojis: {
            ...state.chatbox.emojis,
            list: action.payload,
          },
        },
      };

    case actions.SHOW_EMOJI_BOX:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          emojis: {
            ...state.chatbox.emojis,
            show: true,
          },
        },
      };

    case actions.HIDE_EMOJI_BOX:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          emojis: {
            ...state.chatbox.emojis,
            show: false,
          },
        },
      };

    case actions.SET_ROOM_USER_SUGGESTIONS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          userSuggestions: {
            ...state.chatbox.userSuggestions,
            data: action.payload,
          },
        },
      };

    case actions.SET_ROOM_COMMANDS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          commands: action.payload,
        },
      };

    case actions.RESET_ROOM_COMMANDS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          commandSuggestions: {
            ...state.chatbox.commandSuggestions,
            data: action.payload,
          },
        },
      };

    case actions.SHOW_ROOM_COMMANDS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          commandSuggestions: {
            ...state.chatbox.commandSuggestions,
            show: true,
          },
        },
      };

    case actions.HIDE_ROOM_COMMANDS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          commandSuggestions: {
            ...state.chatbox.commandSuggestions,
            show: false,
          },
        },
      };

    case actions.LOAD_USERNAME_FROM_SUGGESTIONS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          messageInput: {
            ...state.chatbox.messageInput,
            messageText: action.payload,
          },
        },
      };

    case actions.ROLE_UPDATED:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          permissions: action.payload,
        },
      };

    case actions.LOAD_COMMAND_FROM_SUGGESTIONS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          messageInput: {
            ...state.chatbox.messageInput,
            messageText: action.payload,
          },
        },
      };

    case actions.RESET_ROOM_USER_SUGGESTIONS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          userSuggestions: {
            ...state.chatbox.userSuggestions,
            data: [],
          },
        },
      };
    case actions.UPDATE_PRIVATE_POINTER:
      return {
        ...state,
        Private: {
          ...state.Private,
          list: {
            ...state.Private.list,
            data: state.Private.list.data.map((e) =>
              e._id === action.payload.threadId
                ? { ...e, targetPointer: action.payload.messageId }
                : e
            ),
          },
          box: {
            ...state.Private.box,
            data: state.Private.box.data.map((e, i) => {
              if (i === 0) {
                console.log(action.payload.threadId);
                console.log(action.payload.messageId);
                console.log(state.Private.box.thread._id);
                console.log(
                  state.Private.box.thread._id === action.payload.threadId
                );
                console.log(
                  state.spGlobal.layout.loadedView === "privatebox" &&
                  state.Private.box.thread._id === action.payload.threadId
                );
                console.log(state.Private.box.thread.targetPointer);
                console.log(state.spGlobal.layout.loadedView);
              }
              return e;
            }),
            thread: {
              ...state.Private.box.thread,
              targetPointer:
                state.spGlobal.layout.loadedView === "privatebox" &&
                  state.Private.box.thread._id === action.payload.threadId
                  ? action.payload.messageId
                  : state.Private.box.thread.targetPointer,
              unreadMessages: 9,
            },
          },
        },
      };

    case actions.SET_SIDEBAR_ACTIVE_TAB:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          sidebarActiveTab: action.payload,
          privateNotif:
            action.payload === 2 ? false : state.sidebar.privateNotif,
        },
        chatbox: {
          ...state.chatbox,
          privateNotif: false,
        },
      };

    case actions.UNREAD_NOTIF:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          privateNotif: true,
        },
        chatbox: {
          ...state.chatbox,
          privateNotif: true,
        },
      };

    case actions.SET_ROOMS_ACTIVE_TAB:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsActiveTab: action.payload,
        },
      };

    case actions.PRIVATEBOX_DATA_LOADED:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            data: action.payload,
            init: true,
            canLoadMore: action.canLoadMore,
            thread: {
              ...state.Private.box.thread,
              targetPointer: action.targetPointer,
            },
          },
        },
      };

    case actions.NEW_PRIVATE_MESSAGE:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            data: [
              ...state.Private.box.data,
              { ...action.payload, singleLoaded: true },
            ],
          },
          list: {
            ...state.Private.list,
            data: state.Private.list.data
              .map((e) => {
                var retVal = null;
                if (e._id === action.threadId)
                  retVal = {
                    ...e,
                    lastMessage: action.payload,
                  };
                else retVal = e;
                return retVal;
              })
              .sort(function (a, b) {
                var dateA =
                  a.lastMessage === null
                    ? new Date(a.data)
                    : new Date(a.lastMessage.date);

                var dateB =
                  b.lastMessage === null
                    ? new Date(b.data)
                    : new Date(b.lastMessage.date);
                return dateB - dateA;
              }),
          },
        },
      };

    case actions.NEW_PRIVATE_MESSAGE_NOTIF:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          privateNotif:
            state.sidebar.sidebarActiveTab !== 2
              ? true
              : state.sidebar.privateNotif,
        },
        chatbox: {
          ...state.chatbox,
          privateNotif: true,
        },
        Private: {
          ...state.Private,
          list: {
            ...state.Private.list,
            data: state.Private.list.data
              .map((e) => {
                var retVal = null;
                if (e._id === action.threadId)
                  retVal = {
                    ...e,
                    lastMessage: action.payload,
                  };
                else retVal = e;
                console.log("[danger] retval is  ");
                console.log(retVal);
                if (e._id === action.threadId) {
                  retVal =
                    state.spGlobal.layout.loadedView !== "privatebox" ||
                      (state.spGlobal.layout.loadedView === "privatebox" &&
                        state.Private.box.thread._id !== action.threadId)
                      ? {
                        ...retVal,
                        unreadMessages: retVal.unreadMessages + 1,
                        new: true,
                      }
                      : retVal;
                }
                return retVal;
              })
              .sort(function (a, b) {
                var dateA =
                  a.lastMessage === null
                    ? new Date(a.data)
                    : new Date(a.lastMessage.date);

                var dateB =
                  b.lastMessage === null
                    ? new Date(b.data)
                    : new Date(b.lastMessage.date);
                return dateB - dateA;
              }),
          },
        },
      };

    case actions.CLEAR_PRIVATE_INPUT:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            messageInput: {
              messageText: "",
              sending: 2,
            },
          },
        },
      };

    case actions.RESET_PRIVATEBOX:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            data: [],
            messageInput: {
              sending: 2,
              messageText: "",
            },
            init: false,
          },
        },
      };

    case actions.LOAD_WAIT_COMPONENT:
      return {
        ...state,
        spGlobal: {
          ...state.spGlobal,
          layout: {
            ...state.spGlobal.layout,
            loadedView: "wait",
          },
        },
      };

    case actions.SET_PRIVATE_INPUT_TEXT:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            messageInput: {
              ...state.Private.box.messageInput,
              messageText: action.payload,
            },
          },
        },
      };

    case actions.PRIVATE_MESSAGE:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            messageInput: {
              ...state.Private.box.messageInput,
              sending: 0,
            },
          },
        },
      };

    case actions.PRIVATE_MESSAGE_SUCCESS:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            messageInput: {
              sending: 2,
              messageText: "",
            },
          },
        },
      };

    case actions.PRIVATE_MESSAGE_FAILURE:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            messageInput: {
              sending: 2,
              messageText: state.chatbox.messageInput.messageText.trim(),
            },
          },
        },
      };

    case actions.INIT_HOME:
      return {
        ...state,
        spGlobal: {
          ...state.spGlobal,
          layout: {
            device: action.payload.device,
            loadedView: action.payload.loadedView,
          },
        },
      };

    case actions.HIDE_PRIVATEBOX_LOAD:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            load: 2,
          },
        },
      };

    case actions.SHOW_PRIVATEBOX_LOAD:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            load: 0,
          },
        },
      };

    case actions.PRIVATE_REQUEST_ACCEPTED:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          privateNotif:
            state.sidebar.sidebarActiveTab !== 2
              ? true
              : state.sidebar.privateNotif,
        },
        chatbox: {
          ...state.chatbox,
          privateNotif: true,
        },

        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: {
              ...state.Private.box.thread,
              accepted: 1,
            },
          },
          list: {
            ...state.Private.list,
            data: state.Private.list.data.map((e) =>
              e._id === action.payload ? { ...e, accepted: 1 } : e
            ),
          },
        },
      };

    case actions.PRIVATE_REQUEST_BLOCKED:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: {
              ...state.Private.box.thread,
              accepted: -1,
            },
          },
          list: {
            ...state.Private.list,
            data: state.Private.list.data.map((e) =>
              e._id === action.payload ? { ...e, accepted: false } : e
            ),
          },
        },
      };

    case actions.PRIVATE_THREAD_BANNED:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: {
              ...state.Private.box.thread,
              blockers:
                state.Private.box.thread._id === action.payload.threadId
                  ? [
                    ...state.Private.box.thread.blockers,
                    action.payload.blocker,
                  ]
                  : state.Private.box.thread.blockers,
            },
          },
          list: {
            ...state.Private.list,
            data: state.Private.list.data.map((e) =>
              e._id === action.payload.threadId
                ? {
                  ...e,
                  blockers: [
                    ...state.Private.box.thread.blockers,
                    action.payload.blocker,
                  ],
                }
                : e
            ),
          },
        },
      };

    case actions.PRIVATE_THREAD_UNBANNED:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: {
              ...state.Private.box.thread,
              blockers:
                state.Private.box.thread._id === action.payload.threadId
                  ? state.Private.box.thread.blockers.filter(
                    (e) => e !== action.payload.blocker
                  )
                  : state.Private.box.thread.blockers,
            },
          },
          list: {
            ...state.Private.list,
            data: state.Private.list.data.map((e) =>
              e._id === action.payload.threadId
                ? {
                  ...e,
                  blockers: state.Private.box.thread.blockers.filter(
                    (e) => e !== action.payload.blocker
                  ),
                }
                : e
            ),
          },
        },
      };

    case actions.PRIVATE_REQUEST_UNBLOCKED:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: {
              ...state.Private.box.thread,
              accepted: 0,
            },
          },
          list: {
            ...state.Private.list,
            data: state.Private.list.data.map((e) =>
              e._id === action.payload ? { ...e, accepted: true } : e
            ),
          },
        },
      };

    case actions.PRIVATE_REQUEST_RECEIVED:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          privateNotif:
            state.sidebar.sidebarActiveTab !== 2
              ? true
              : state.sidebar.privateNotif,
        },
        chatbox: {
          ...state.chatbox,
          privateNotif: true,
        },

        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: {
              ...state.Private.box.thread,
            },
          },
          list: {
            ...state.Private.list,
            data: [...state.Private.list.data, action.payload],
          },
        },
      };

    case actions.INIT_PRIVATE_BOX:
      return {
        ...state,
        spGlobal: {
          ...state.spGlobal,
          layout: {
            ...state.spGlobal.layout,
            loadedView: "privatebox",
          },
        },
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: { ...action.payload, isTyping: false },
          },
          list: {
            ...state.Private.list,
            data: state.Private.list.data.map((e) =>
              e._id === action.payload._id
                ? { ...e, unreadMessages: 0, new: false }
                : e
            ),
          },
        },
      };

    case actions.SHOW_PRIVATE_TYPING:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: {
              ...state.Private.box.thread,
              isTyping: true,
            },
          },
        },
      };

    case actions.HIDE_PRIVATE_TYPING:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            thread: {
              ...state.Private.box.thread,
              isTyping: false,
            },
          },
        },
      };

    case actions.INIT_CHATBOX_ONLY:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMessages: state.chatbox.roomMessages.map((e) => {
            return { ...e, singleLoaded: false };
          }),
        },
        spGlobal: {
          ...state.spGlobal,
          layout: {
            ...state.spGlobal.layout,
            loadedView: "roomchat",
          },
        },
      };

    case actions.INIT_HOME_VIEW:
      return {
        ...state,
        spGlobal: {
          ...state.spGlobal,
          layout: {
            ...state.spGlobal.layout,
            loadedView: action.payload.loadedView,
          },
        },
      };

    case actions.GET_ROOMS_JOINED:
      return {
        ...state,
        sidebar: { ...state.sidebar, roomsJoined: { loading: true } },
      };
    case actions.GET_ROOMS_JOINED_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsJoined: { loading: false, data: action.payload },
        },
      };
    case actions.GET_ROOMS_JOINED_FAILURE:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsJoined: {
            loading: false,
            error: { status: false, message: action.payload },
          },
        },
      };

    case actions.GET_ROOMS_CREATED:
      return {
        ...state,
        sidebar: { ...state.sidebar, roomsCreated: { loading: true } },
      };

    case actions.GET_ROOMS_CREATED_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsCreated: { loading: false, data: action.payload },
        },
      };

    case actions.GET_ROOMS_CREATED_FAILURE:
      return {
        ...state,
        sidebar: {
          roomsCreated: {
            loading: false,
            error: { status: true, message: action.payload },
          },
        },
      };

    case actions.GET_FEATURED_ROOMS:
      return {
        ...state,
        sidebar: { ...state.sidebar, featuredRooms: { loading: true } },
      };

    case actions.GET_FEATURED_ROOMS_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          featuredRooms: { loading: false, data: action.payload },
          loaded: true,
        },
      };

    case actions.GET_FEATURED_ROOMS_FAILURE:
      return {
        ...state,
        sidebar: {
          featuredRooms: {
            loading: false,
            error: { status: true, message: action.payload },
          },
        },
      };

    case actions.GET_UNFEATURED_ROOMS:
      return {
        ...state,
        sidebar: { ...state.sidebar, unfeaturedRooms: { loading: true } },
      };

    case actions.GET_UNFEATURED_ROOMS_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          unfeaturedRooms: { loading: false, data: action.payload },
          loaded: true,
        },
      };

    case actions.GET_UNFEATURED_ROOMS_FAILURE:
      return {
        ...state,
        sidebar: {
          unfeaturedRooms: {
            loading: false,
            error: { status: true, message: action.payload },
          },
        },
      };

    case actions.CREATE_ROOM:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          createRoom: { ...state.sidebar.createRoom, creating: true },
        },
      };
    case actions.CREATE_ROOM_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          createRoom: { creating: false, status: 1 },
          roomsCreated: {
            ...state.sidebar.roomsCreated,
            data: [...state.sidebar.roomsCreated.data, action.payload],
          },
        },
      };
    case actions.CREATE_ROOM_FAILURE:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          createRoom: { creating: false, status: 0, errMsg: action.payload },
        },
      };
    case actions.LOAD_ROOM:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMessagesLoading: true,
          mountedView: 0,
          showFooter: true,
        },
        spGlobal: {
          ...state.spGlobal,
          layout: {
            ...state.spGlobal.layout,
            loadedView: "roomchat",
          },
        },
      };

    case actions.LOAD_ROOM_FAILURE:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMessagesLoading: false,
          showFooter: false,
          roomLoadError: {
            ...state.chatbox.roomLoadError,
            status: true,
            head: action.payload.head,
            body: action.payload.body,
          },
        },
      };

    case actions.LOAD_ROOM_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMessagesLoading: false,
          showFooter: true,
          roomMessages: action.payload.data,
          roomId: action.payload.roomDetails._id,
          roomTitle: action.payload.roomDetails.title,
          roomType: action.payload.roomDetails.type,
          roomDesc: action.payload.roomDetails.desc,
          roomTopic: action.payload.roomDetails.topic,
          mountedView: action.payload.roomDetails.mountedView,
          background: action.payload.roomDetails.background,
          iOwn: action.payload.roomDetails.iOwn,
          initRoom: true,
          permissions: action.payload.role,
          ignoreList: action.payload.ignoreList,
          canLoadMore: action.payload.canLoadMore,
        },
        memberlist: {
          ...state.memberlist,
          roomId: action.payload.roomDetails._id,
          members: action.payload.roomDetails.members,
          online: action.payload.onlineUsers,
          creator: [action.payload.creator],
        },
      };
    case actions.UNLOAD_ROOM:
      return {
        ...state,
        chatbox: {
          roomMessages: [],
          roomId: 0,
          roomTitle: "No Room",
        },
      };

    case actions.SEARCH_ROOM:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsSearched: { loading: true },
        },
      };

    case actions.SEARCH_ROOM_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsSearched: {
            loading: false,
            data: action.payload.map((e) =>
              e.joined === 0 ? { ...e, loading: false } : e
            ),
          },
        },
      };

    case actions.SEARCH_ROOM_FAILURE:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsSearched: {
            loading: false,
            error: {
              status: true,
              message: action.payload,
            },
          },
        },
      };

    case actions.JOIN_ROOM:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsSearched: {
            ...state.sidebar.roomsSearched,
            data: state.sidebar.roomsSearched.data.map((e) =>
              e._id === action.itemId ? { ...e, loading: true } : e
            ),
          },
        },
      };

    case actions.JOIN_ROOM_FAILURE:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsSearched: {
            ...state.sidebar.roomsSearched,
            data: state.sidebar.roomsSearched.data.map((e) =>
              e._id === action.itemId ? { ...e, loading: false } : e
            ),
          },
        },
      };

    case actions.JOIN_ROOM_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsSearched: {
            ...state.sidebar.roomsSearched,
            data: state.sidebar.roomsSearched.data.map((e) =>
              e._id === action.itemId
                ? { ...e, loading: false, joined: true }
                : e
            ),
          },
          roomsJoined: {
            ...state.sidebar.roomsJoined,
            data: [...state.sidebar.roomsJoined.data, action.payload],
          },
        },
      };

    case actions.LEAVE_ROOM:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsJoined: {
            ...state.sidebar.roomsJoined,
            loading: true,
          },
        },
      };

    case actions.LEAVE_ROOM_FAILURE:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsJoined: {
            ...state.sidebar.roomsJoined,
            loading: false,
          },
        },
      };

    case actions.LEAVE_ROOM_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          roomsJoined: {
            ...state.sidebar.roomsJoined,
            loading: false,
            data: state.sidebar.roomsJoined.data.filter(
              (e) => e._id !== action.itemId
            ),
          },
        },
      };

    case actions.DISPATCH_NOTIFICATION:
      return {
        ...state,
        spGlobal: {
          ...state.spGlobal,
          notification: {
            status: action.payload.status,
            heading: action.payload.heading,
            msg: action.payload.msg,
            show: true,
          },
        },
      };

    case actions.DISMISS_NOTIFICATION:
      return {
        ...state,
        spGlobal: {
          ...state.spGlobal,
          notification: {
            show: false,
          },
        },
      };

    case actions.ROOM_MESSAGE:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          messageInput: {
            ...state.chatbox.messageInput,
            sendingMessage: true,
          },
        },
      };

    case actions.ROOM_MESSAGE_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          messageInput: {
            sendingMessage: false,
            messageText: "",
          },
        },
      };

    case actions.CLEAR_ROOM_MESSAGES:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMessages: [],
        },
      };

    case actions.ROOM_MESSAGE_FAILURE:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          messageInput: {
            sendingMessage: false,
            messageText: state.chatbox.messageInput.messageText.trim(),
          },
        },
      };

    case actions.INCOMING_MESSAGE:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          incomingMessage: true,
        },
      };

    case actions.INCOMING_MESSAGE_SUCCESS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          incomingMessage: false,
          roomMessages:
            state.chatbox.roomMessages.length % 50 === 0
              ? [
                ...state.chatbox.roomMessages,
                { ...action.payload, isAd: true },
              ]
              : [
                ...state.chatbox.roomMessages,
                { ...action.payload, singleLoaded: true },
              ],
        },
      };

    case actions.ADD_AD_TO_LIST:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          incomingMessage: false,
          roomMessages: [
            ...state.chatbox.roomMessages,
            {
              isAd: true,
              singleLoaded: true,
              _id:
                "ac90" + Math.random(Math.floor(Math.random() * 2551100) + 1),
            },
          ],
        },
      };

    case actions.SORT_ROOM_MESSAGES:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMessages: state.chatbox.roomMessages.sort(state.chatbox.sorter),
          messageInput: {
            sendingMessage: false,
          },
        },
      };

    case actions.SET_INPUT_TEXT:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          messageInput: {
            ...state.chatbox.messageInput,
            messageText: action.payload,
          },
        },
      };

    case actions.SHOW_JOIN_ROOM_UI:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          joinRoom: {
            joining: true,
            roomId: action.payload._id,
            roomDetails: action.payload,
          },
        },
      };

    case actions.JOIN_ROOM_BYUI_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          joinRoom: {
            ...state.sidebar.joinRoom,
            joining: false,
          },
          roomsJoined: {
            ...state.sidebar.roomsJoined,
            data: [...state.sidebar.roomsJoined.data, action.payload],
          },
          featuredRooms: {
            ...state.sidebar.featuredRooms,
            data: state.sidebar.featuredRooms.data.map((e) =>
              e._id === action.itemId ? { ...e, access: 1 } : e
            ),
          },
        },
      };

    case actions.CLOSE_JOINROOM_UI:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          joinRoom: {
            joining: false,
            roomId: 0,
            roomDetails: {},
          },
        },
      };

    case actions.LOGOUT:
      return {
        state: undefined,
      };

    case actions.RESET_REDIRECT:
      return {
        ...state,
        spGlobal: {
          ...state.spGlobal,
          redirect: {
            status: false,
          },
        },
      };

    case actions.SHOW_PROFILE_UI:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          profile: {
            ...state.sidebar.profile,
            showUI: true,
          },
        },
      };

    case actions.HIDE_PROFILE_UI:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          profile: {
            ...state.sidebar.profile,
            showUI: false,
          },
        },
      };

    case actions.SHOW_ACCOUNT_UI:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          account: {
            ...state.sidebar.account,
            showUI: true,
          },
        },
      };

    case actions.HIDE_ACCOUNT_UI:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          account: {
            ...state.sidebar.account,
            showUI: false,
          },
        },
      };

    case actions.SHOW_USER_PROFILE_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          userProfile: {
            ...state.chatbox.userProfile,
            showUi: true,
            roomId: action.payload,
          },
        },
      };

    case actions.HIDE_USER_PROFILE_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          userProfile: {
            ...state.chatbox.userProfile,
            showUi: false,
          },
        },
      };

    case actions.LOAD_PROFILE:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          profile: {
            ...state.sidebar.profile,
            load: 0,
          },
        },
      };

    case actions.LOAD_PROFILE_ERROR:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          profile: {
            ...state.sidebar.profile,
            load: -1,
          },
        },
      };

    case actions.LOAD_PROFILE_SUCCESS:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          profile: {
            ...state.sidebar.profile,
            load: 1,
            data: action.payload,
          },
        },
      };

    case actions.USER_JOINED_ROOM:
      return {
        ...state,
        memberlist: {
          ...state.memberlist,
          online: state.memberlist.online.some(
            ({ username }) => username === action.payload.username
          )
            ? [...state.memberlist.online]
            : [...state.memberlist.online, action.payload].sort(function (
              a,
              b
            ) {
              return b.user.role.rank - a.user.role.rank;
            }),
        },
      };

    case actions.USER_LEFT_ROOM:
      return {
        ...state,
        memberlist: {
          ...state.memberlist,
          online: state.memberlist.online.filter(
            (e) => e.username !== action.payload.user.username
          ),
        },
      };

    case actions.LOAD_USERNAME:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          messageInput: {
            ...state.chatbox.messageInput,
            messageText:
              typeof state.chatbox.messageInput === "undefined"
                ? "@" + action.payload.username + " "
                : state.chatbox.messageInput.messageText.substr(
                  0,
                  action.payload.inputStart
                ) +
                "@" +
                action.payload.username +
                " " +
                state.chatbox.messageInput.messageText.substr(
                  action.payload.inputStart
                ),
          },
        },
      };

    case actions.SHOW_UPLOAD_FILE_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          uploadFile: {
            ...state.chatbox.uploadFile,
            showUi: true,
          },
        },
      };

    case actions.HIDE_UPLOAD_FILE_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          uploadFile: {
            ...state.chatbox.uploadFile,
            showUi: false,
          },
        },
      };

    case actions.SHOW_UPLOAD_VIDEO_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          uploadVideo: {
            ...state.chatbox.uploadVideo,
            showUi: true,
          },
        },
      };

    case actions.HIDE_UPLOAD_VIDEO_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          uploadVideo: {
            ...state.chatbox.uploadVideo,
            showUi: false,
          },
        },
      };

    case actions.SHOW_PRIVATE_UPLOAD_VIDEO_UI:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            uploadVideo: {
              ...state.Private.box.uploadVideo,
              showUi: true,
            },
          },
        },
      };

    case actions.HIDE_PRIVATE_UPLOAD_VIDEO_UI:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            uploadVideo: {
              ...state.Private.box.uploadVideo,
              showUi: false,
            },
          },
        },
      };

    case actions.SHOW_PRIVATE_UPLOAD_FILE_UI:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            uploadFile: {
              ...state.Private.box.uploadFile,
              showUi: true,
            },
          },
        },
      };

    case actions.HIDE_PRIVATE_UPLOAD_FILE_UI:
      return {
        ...state,
        Private: {
          ...state.Private,
          box: {
            ...state.Private.box,
            uploadFile: {
              ...state.Private.box.uploadFile,
              showUi: false,
            },
          },
        },
      };

    case actions.SHOW_ROOM_DETAILS_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomDetails: {
            showUi: true,
            roomId: action.payload,
          },
        },
      };

    case actions.HIDE_ROOM_DETAILS_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomDetails: {
            showUi: false,
          },
        },
      };

    case actions.SHOW_ROOM_SETTINGS_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomSettings: {
            showUi: true,
            roomId: action.payload,
          },
        },
      };

    case actions.HIDE_ROOM_SETTINGS_UI:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomSettings: {
            showUi: false,
          },
        },
      };

    case actions.SHOW_ROOM_MEMBERS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMembers: {
            showUi: true,
            roomId: action.payload,
          },
        },
      };

    case actions.HIDE_ROOM_MEMBERS:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMembers: {
            showUi: false,
          },
        },
      };

    case actions.DELETE_MESSAGE:
      return {
        ...state,
        chatbox: {
          ...state.chatbox,
          roomMessages: state.chatbox.roomMessages.filter(
            (e) => e._id !== action.messageId
          ),
        },
      };

    default:
      return state;
  }
}

export default appReducer;
