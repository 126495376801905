import React, { useState } from 'react'
import { RoomCard } from './RoomCard';
import { UserCard } from './UserCard';

export const RoomList = ({ columns, data, loading, updateRoom, deleteRoom }) => {

    const [showRoom, setShowRoom] = useState(false);
    const [room, setRoom] = useState([]);

    const geTDate = (d) => {
        var r = new Date(d);
        return r;
    }

    return (
        <div style={{ padding: '15px' }}>
            {/* {JSON.stringify(columns)} */}
            {/* <hr />
            {JSON.stringify(data)}
             */}
            {loading && <p>Loading</p>}
            {!loading && data.length !== 0 &&
                <table style={{ fontSize: '14px' }}>
                    <thead>
                        <tr>
                            {columns.map((e, index) =>

                                <th key={index}>
                                    {e.name}
                                </th>

                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(e =>

                            <tr onClick={() => {
                                //alert(e.username)
                                //setUser(e);
                                setRoom(e);
                                setShowRoom(true);
                                //setShowUser(true);
                            }}
                                key={e._id}
                                style={{
                                    cursor: 'pointer'
                                }}
                            >
                                <td style={{
                                    fontWeight: 'bold'
                                }}>{e.title}</td>
                                <td>{e.type}</td>
                                <td>{e.isFeatured ? 'Yes' : 'No'}</td>
                                <td>{e.creator ? '@' + e.creator.username : '<ORPHAN>'}</td>
                                <td>{e.members}</td>
                                <td>{e.mountedView === 0 ? 'Chat' : 'Threads'}</td>
                                <td>{geTDate(e.date).toLocaleDateString()}</td>
                            </tr>

                        )}
                    </tbody>
                </table>
            }
            {!loading && data.length === 0 && <p>No records here!</p>}

            {showRoom && <RoomCard deleteRoomParent={deleteRoom} updateRoom={updateRoom} room={room} closeHandler={setShowRoom} />}

        </div>
    )
}
