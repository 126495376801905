import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Error } from '../util/Error';
import JoinRoom from '../util/JoinRoom';
import GoogleAds from '../util/GoogleAds';

import {
  fetchRoomsJoined,
  sendSearchRoom,
  fetchRoomsCreated,
  initSendLoadRoom,
  sendJoinRoom,
  fetchFeaturedRooms,
  joinRoomByUI,
  sendCreateRoom,
  closeJoinRoomUI,
  initJoinRoomFromUI,
  doLogOut,
  showProfileUI,
  sendLeaveRoom,
  _setSidebarActiveTab,
  _setRoomsActiveTab,
  showAccountUI,
  checkPrivateNotif,
  initGuestLoadRoom,
  initHome,
} from '../../redux/actions/appActions';
import CreateRoom from '../util/CreateRoom';
import Profile from '../util/Profile';
import UserAccount from '../util/UserAccount';
import Axios from 'axios';
import C from '../../util/Constants';

const ConnectedList = ({ dispatch, sidebar }) => {
  useEffect(() => {
    console.log('Loading details of the sidebar ...');
    if (!sidebar.loaded) {
      //   dispatch(fetchRoomsJoined());
      //   dispatch(fetchRoomsCreated());
      //   dispatch(fetchFeaturedRooms());
      //   dispatch(checkPrivateNotif());
      loadRooms();
    }

    adjustSidebar();
  }, [dispatch]);

  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      doLogOut();
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
      return spSession;
    } catch (e) {
      doLogOut();
      return false;
    }
  };

  const loadRooms = () => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    Axios.get(
      C.API_EP +
        '/g/a/getRoomsList?username=' +
        spSession.username +
        '&token=' +
        spSession.token
    )
      .then((response) => {
        console.log(response);
        var data = response.data.rooms;
        setFeaturedRooms(data);
      })
      .catch((error) => {
        if (typeof error.status == 'undefined') {
          console.log('network error.');
          return;
        }
        if (error.status === 403) {
          doLogOut();
          return;
        }
      });
  };

  const [showCreateRoom, setShowCreateRoom] = useState(false);

  const [roomToSearch, setRoomToSearch] = useState('');

  const [featuredRooms, setFeaturedRooms] = useState([]);

  // const [sidebarActiveTab, setSidebarActiveTab] = useState(1);
  // const [roomsActiveTab, setRoomsActiveTab] = useState(1);
  const [profileActiveTab, setProfileActiveTab] = useState(1);

  const [colorLoad, setColorLoad] = useState(2);

  const [color, setColor] = useState('#001f3f');

  const setSidebarActiveTab = (tabNumber) => {
    dispatch(_setSidebarActiveTab(tabNumber));
  };

  const setRoomsActiveTab = (tabNumber) => {
    dispatch(_setRoomsActiveTab(tabNumber));
  };

  const showConfirmLogout = () => {
    dispatch(doLogOut());
  };

  const loadRoom = (id) => {
    console.log(id);
    dispatch(initHome({ device:  window.matchMedia('(max-width: 768px)').matches ? 2 : 1, loadedView: 'roomchat' }))
    dispatch(initGuestLoadRoom(id));
  };

  const showCreateRoomUI = () => {
    setShowCreateRoom(true);
  };

  const leaveRoom = (id) => {
    dispatch(sendLeaveRoom(id));
  };

  const joinRoom = (id) => {
    dispatch(sendJoinRoom(id));
  };

  const joinRoomFromUI = (id) => {
    dispatch(joinRoomByUI(id));
  };

  const showProfileModal = () => {
    dispatch(showProfileUI());
  };

  const showAccountModal = () => {
    dispatch(showAccountUI());
  };

  const adjustSidebar = () => {
    // if (window.matchMedia('(max-width: 768px)').matches) {
    //   var spChatbox = document.getElementsByClassName('sp-chatbox')[0];
    //   var spChatboxCard = spChatbox.getElementsByClassName('card')[0];
    //   var spChatBoxCardHeader = spChatboxCard.getElementsByClassName(
    //     'card-header'
    //   )[0];
    //   var spSidebar = document.getElementsByClassName('sp-sidebar')[0];
    //   spSidebar.style.top = spChatBoxCardHeader.offsetHeight + 'px';
    //   spSidebar.style.height =
    //     spSidebar.offsetHeight - spChatBoxCardHeader.offsetHeight + 'px';
    // }
  };

  const logOut = () => {
    showConfirmLogout()
  }

  const SideBarComp1 = () => {
    return (
      <div>
        {typeof sidebar.roomsActiveTab !== 'undefined' &&
          sidebar.roomsActiveTab === 1 && (
            <div
              className='card'
              style={{
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
              }}
            >
              <div className='card-header'>
                <h3 className='sidebar-heading'>Featured Rooms</h3>
              </div>
              <div className='card-body' style={{ padding: 0 }}>
                {typeof sidebar.rank !== 'undefined' && sidebar.rank == 0 && (
                  <GoogleAds />
                )}
                {typeof featuredRooms !== 'undefined' && featuredRooms.loading && (
                  <div class='progress' style={{ height: '5px' }}>
                    <div
                      class='progress-bar progress-bar-striped progress-bar-animated'
                      style={{ width: '100%', height: '5px' }}
                    ></div>
                  </div>
                )}

                <ul className='list-group'>
                  {typeof featuredRooms != 'undefined' &&
                    typeof featuredRooms != 'undefined' &&
                    featuredRooms.length > 0 &&
                    featuredRooms.map((el) => (
                      // <li
                      //   className={
                      //     localStorage.getItem(C.SP_LOADED_ROOM) === el._id
                      //       ? 'list-group-item d-flex justify-content-between align-items-center clickable active'
                      //       : 'list-group-item d-flex justify-content-between align-items-center clickable'
                      //   }
                      //   key={el._id}
                      //   onClick={
                      //     el.access === 1
                      //       ? () => {
                      //           loadRoom(el._id);
                      //         }
                      //       : () => {
                      //           dispatch(initJoinRoomFromUI(el._id));
                      //         }
                      //   }
                      // >
                      //   {el.title}
                      //   <i className='badge badge-primary badge-pill'>
                      //     {el.membersCount}
                      //   </i>
                      // </li>

                      <a
                        href='#'
                        class='list-group-item list-group-item-action flex-column align-items-start'
                        style={{
                          display: 'flex',
                          border: 'none',
                          borderBottom: '1px solid #eee',
                        }}
                        key={el._id}
                        onClick={() => {
                          loadRoom(el._id);
                        }}
                      >
                        <div class='d-flex w-100 justify-content-between'>
                          <h5
                            class='mb-1'
                            style={{ color: '#565050', fontWeight: 'bold' }}
                          >
                            {el.title}
                          </h5>
                          <small style={{ color: '#84a984' }}>
                            {el.membersCount + ' members'}
                          </small>
                        </div>
                        <p class='mb-1'>{el.desc}</p>
                      </a>
                    ))}
                  {typeof featuredRooms != 'undefined' &&
                    typeof featuredRooms.data != 'undefined' &&
                    featuredRooms.data.length === 0 && (
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        No featured rooms found!
                      </li>
                    )}

                  {typeof featuredRooms != 'undefined' &&
                    typeof featuredRooms.error != 'undefined' &&
                    featuredRooms.error.status === true && (
                      <div className='alert alert-danger'>
                        There was some error with the request.{' '}
                        <button className='btn btn-sm btn-danger'>
                          Click{' '}
                        </button>{' '}
                        to refresh
                      </div>
                    )}
                </ul>
              </div>
            </div>
          )}

        {typeof sidebar.roomsActiveTab !== 'undefined' &&
          sidebar.roomsActiveTab === 2 && (
            <div
              className='card'
              style={{
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
              }}
            >
              <div className='card-body' style={{ padding: 0 }}>
              <ul className='list-group'>
                <li className="list-group-item clickable" onClick={logOut}>Logout</li>
                </ul>
              </div>
            </div>
          )}

      </div>
    );
  };

  const SideBarComp3 = () => {
    return (
      <div>
        {profileActiveTab === 2 && <div className='card'></div>}
        <h1>Logout?</h1>
        {profileActiveTab === 1 && <div className='card'></div>}
      </div>
    );
  };

  return (
    <Fragment>
      <div id='sp-sidebar-header'></div>
      {typeof sidebar.sidebarActiveTab !== 'undefined' &&
        sidebar.sidebarActiveTab === 3 && (
          <div className='sp-sidebar-3' style={{ paddingLeft: '15px' }}>
            <ul class='list-group'>
              <li
                class='list-group-item d-flex justify-content-between align-items-center clickable'
                onClick={showProfileModal}
              >
                Profile
                <span className='badge badge-primary badge-pill'>
                  <i className='fas fa-user-circle'></i>
                </span>
              </li>
              <li
                onClick={showAccountModal}
                class='list-group-item d-flex justify-content-between align-items-center clickable'
              >
                Account
                <span className='badge badge-primary badge-pill'>
                  <i className='fas fa-user-shield '></i>
                </span>
              </li>
              <div id='colors' class='collapse' style={{ padding: '8px' }}>
                <h4 style={{ fontSize: '15px' }}>Select background color</h4>
                <select
                  className='form-control'
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                >
                  <option value='#001f3f'>Navy</option>
                  <option value='#0074D9'>Blue</option>
                  <option value='#7FDBFF'>Aqua</option>
                  <option value='#39CCCC'>Teal</option>
                  <option value='#3D9970'>Olive</option>
                  <option value='#2ECC40'>Green</option>
                  <option value='#01FF70'>Lime</option>
                  <option value='#FFDC00'>Yellow</option>
                  <option value='#FF851B'>Orange</option>
                  <option value='#FF4136'>Red</option>
                  <option value='#85144b'>Maroon</option>
                  <option value='#F012BE'>Fuchsia</option>
                  <option value='#B10DC9'>Purple</option>
                  <option value='#111111'>Black</option>
                  <option value='#AAAAAA'>Gray</option>
                </select>
                <button
                  className='btn btn-primary btn-sm'
                  style={{ margin: '5px' }}
                  disabled={colorLoad === 0}
                >
                  {colorLoad === 2
                    ? 'Save'
                    : colorLoad === 1
                    ? 'Saved!'
                    : 'Error'}
                </button>
              </div>
              <li class='list-group-item d-flex justify-content-between align-items-center clickable'>
                Help
              </li>
              <li
                class='list-group-item d-flex justify-content-between align-items-center clickable'
                onClick={() => {
                  showConfirmLogout();
                }}
              >
                Logout
              </li>
            </ul>
          </div>
        )}

      {typeof sidebar.sidebarActiveTab !== 'undefined' &&
        sidebar.sidebarActiveTab === 1 && (
          <div className='sp-sidebar-1' style={{ padding: '0' }}>
            <span
              className={
                typeof sidebar.roomsActiveTab !== 'undefined' &&
                sidebar.roomsActiveTab === 1
                  ? 'fas fa-star sp-sidebar-subheader-active'
                  : 'fas fa-star'
              }
              style={{ width: '50%' }}
              onClick={() => {
                setRoomsActiveTab(1);
              }}
            ></span>
            <span
              className={
                typeof sidebar.roomsActiveTab !== 'undefined' &&
                sidebar.roomsActiveTab === 2
                  ? 'fas fa-user sp-sidebar-subheader-active'
                  : 'fas fa-user'
              }
              style={{ width: '50%' }}
              onClick={() => {
                setRoomsActiveTab(2);
              }}
            ></span>

            {SideBarComp1()}
          </div>
        )}

      {typeof sidebar.profile !== 'undefined' &&
        sidebar.profile.showUI === true && <Profile />}

      {typeof sidebar.account !== 'undefined' &&
        sidebar.account.showUI === true && <UserAccount />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebar: state.appState.sidebar,
  };
};

const Sidebar = connect(mapStateToProps)(ConnectedList);

export default Sidebar;
