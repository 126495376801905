import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  loadProfileData,
  hideProfileUI,
} from './../../redux/actions/appActions';
import C from './../../util/Constants';
import axios from 'axios';

const ProfileContainer = ({ dispatch, parentData }) => {
  const InputFile = useRef(null);
  const [profile, setProfile] = useState({
    load: 2 /* -1 for error, 0 for loading, 1 for sccess, 2 for not loading */,
    update: 2 /* 2 for not updating, 0 for updating, 1 for success, -1 for error */,
    data: {
      fullname: '',
      picture: '',
      blob: '',
    },
  });

  const [oldData, setOldData] = useState({});

  let arr = [15];
  useEffect(() => {
    for (var i = 16; i < 100; i++) {
      console.log(ageSelect);
      console.log(i);
      arr = [...arr, i];
    }
    setAgeSelect(arr);

    setProfile({ ...profile, load: 0 });

    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    axios
      .get(
        C.API_EP +
          '/a/profile?email=' +
          spSession.email +
          '&token=' +
          spSession.token
      )
      .then((response) => {
        var res = response.data.profile;
        setOldData(res);
        setProfile({ ...profile, load: 1, data: res });
      })
      .catch((error) => {
        if (error.status === 403) {
          return;
        }
        setProfile({ load: -1 });
      });
  }, [dispatch]);

  const checkForSession = () => {
    if (localStorage.getItem(C.SP_SESSION) == null) {
      return false;
    }

    try {
      var spSession = JSON.parse(localStorage.getItem(C.SP_SESSION));
      return spSession;
    } catch (e) {
      return false;
    }
  };

  const withoutExtension = (filename) => {
    return filename.split('.')[0];
  };

  const setFullName = (e) => {
    setProfile({
      ...profile,
      data: { ...profile.data, fullname: e.target.value },
    });
  };

  const setGender = (e) => {
    setProfile({
      ...profile,
      data: { ...profile.data, gender: parseInt(e.target.value) },
    });
  };

  const setAge = (e) => {
    setProfile({
      ...profile,
      data: { ...profile.data, age: parseInt(e.target.value) },
    });
  };

  const setAbout = (e) => {
    setProfile({
      ...profile,
      data: { ...profile.data, about: e.target.value },
    });
  };

  const setBlob = (blob) => {
    setProfile({
      ...profile,
      data: { ...profile.data, blob: blob },
    });
  };

  const onlyExtension = (filename) => {
    return filename.split('.')[1];
  };

  const closeProfileUI = () => {
    dispatch(hideProfileUI());
  };

  const selectFile = () => {
    InputFile.current.click();
  };

  const updateProfile = () => {
    var spSession = checkForSession();
    if (spSession === false) {
      return;
    }

    var dataToUpdate = new FormData();
    setProfile({ ...profile, update: 0 });
    if (oldData.fullname.trim() !== profile.data.fullname.trim())
      dataToUpdate.append('fullname', profile.data.fullname);
    if (oldData.gender !== profile.data.gender)
      dataToUpdate.append('gender', profile.data.gender);
    if (oldData.age !== profile.data.age)
      dataToUpdate.append('age', profile.data.age);
    if (oldData.about !== profile.data.about)
      dataToUpdate.append('about', profile.data.about);
    console.log(dataToUpdate);
    dataToUpdate.append('email', spSession.email);
    dataToUpdate.append('token', spSession.token);

    if (typeof profile.data.blob !== 'undefined')
     dataToUpdate.append('avatar', InputFile.current.files[0]);

    axios
      .post(C.API_EP + '/profile/update', dataToUpdate)
      .then((response) => {
        setProfile({ ...profile, update: 1 });
      })
      .catch((e) => {
        setProfile({ ...profile, update: -1 });
      });
  };

  // function filePreview(input) {
  //   if (input.files && input.files[0]) {
  //     var reader = new FileReader();
  //     reader.onload = function (e) {
  //       e.target.result;
  //     };
  //     reader.readAsDataURL(input.files[0]);
  //   }
  // }

  const showImage = (e) => {
    console.log(e.target);
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setBlob(e.target.result);
        console.log(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]); // convert to base64 string
    }
  };

  const uploadImage = (e) => {};

  const [ageSelect, setAgeSelect] = useState([0]);
  return (
    <div className='sp-profile-wrapper'>
      <div className='sp-profile-inner'>
        <div className='card'>
          <div className='card-header'>
            Profile
            <i
              className='fas fa-times sp-small-icon'
              onClick={closeProfileUI}
            ></i>
          </div>
          <div className='card-body'>
            {typeof profile !== 'undefined' &&
              (profile.load === 0 || profile.update === 0) && (
                <div class='progress'>
                  <div
                    class='progress-bar progress-bar-striped progress-bar-animated'
                    style={{ width: '100%' }}
                  ></div>
                </div>
              )}

            {typeof profile !== 'undefined' && profile.load === -1 && (
              <div class='alert alert-danger'>Profile Loading Error.</div>
            )}

            {typeof profile !== 'undefined' && profile.update === -1 && (
              <div class='alert alert-danger'>Profile Updating Error.</div>
            )}
            {typeof profile !== 'undefined' &&
              profile.load === 1 &&
              profile.update !== 0 && (
                <div className='row'>
                  <div className='col-md-6'>
                    <div
                      style={{
                        height: '300px',
                        width: '300px',
                        margin: '5px',
                      }}
                    >
                      <img
                        src={
                          typeof profile.data !== 'undefined' &&
                          typeof profile.data.blob !== 'undefined'
                            ? profile.data.blob
                            : C.ASSET_EP +
                              '/avatars/' +
                              withoutExtension(profile.data.picture) +
                              '_md.' +
                              onlyExtension(profile.data.picture)
                        }
                        alt='user profile avatar'
                        style={{ maxHeight: '215px', maxWidth: '215px' }}
                      />
                      <input
                        type='file'
                        style={{ display: 'none' }}
                        ref={InputFile}
                        accept='image/gif, image/jpeg, image/png'
                        onChange={showImage}
                      />
                      <button
                        className='btn btn-primary btn-sm'
                        style={{ display: 'block' }}
                        onClick={selectFile}
                      >
                        New
                      </button>
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <b>Full Name:</b>
                    <input
                      type='text'
                      placeholder='Full Name'
                      className='form-control'
                      value={profile.data.fullname}
                      onChange={setFullName}
                    />
                    <b>Gender:</b>
                    <select
                      className='form-control'
                      onChange={setGender}
                      value={profile.data.gender}
                    >
                      <option value='0' key='0'>
                        Not specified
                      </option>
                      <option value='1' key='1'>
                        Male
                      </option>
                      <option value='2' key='2'>
                        Female
                      </option>
                    </select>

                    <b>Age:</b>
                    <select
                      className='form-control'
                      onChange={setAge}
                      value={profile.data.age}
                    >
                      {ageSelect.map((e) => (
                        <option value={e} key={e}>
                          {e}
                        </option>
                      ))}
                    </select>
                    <b>About:</b>
                    <textarea
                      onChange={setAbout}
                      value={profile.data.about}
                      className='form-control'
                    ></textarea>
                  </div>
                </div>
              )}
          </div>
          <div className='card-footer'>
            <button
              className='btn btn-success'
              disabled={
                typeof profile.data == 'undefined' ||
                (oldData.fullname === profile.data.fullname &&
                  oldData.age === profile.data.age &&
                  oldData.gender === profile.data.gender &&
                  typeof profile.data.blob === 'undefined' &&
                  oldData.about === profile.data.about)
              }
              onClick={updateProfile}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    parentData: state.appState.sidebar.profile,
  };
};

const Profile = connect(mapStateToProps)(ProfileContainer);

export default Profile;
