import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Loader } from './Loader';
import C from './../../util/Constants';
import { EditSetting } from './EditSetting';


function SASetting() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [editToggle, setEditToggle] = useState(false);
    const [editKVP, setEditKVP] = useState({});
    const [editor, setEditor] = useState(null);

    useEffect(() => {


        try {
            var spSession = JSON.parse(localStorage.getItem('sp_session'));
        } catch (e) {
            return;
        }

        Axios
            .get(
                C.API_EP +
                '/api/admin/settings?email=' +
                spSession.email +
                '&token=' +
                spSession.token
            )
            .then((response) => {
                setLoading(false);
                setData(response.data.settings);
            })
            .catch((error) => {
                setLoading(false)
                console.log("Error loading status: ", error);
                if (typeof error.response == 'undefined') {
                    console.log('network error.');
                    return;
                }
            });


        return () => {
        }
    }, [])

    const updateSettings = (uid, theKey, theValue) => {
        // alert("Updating user with " + uid);
        // console.log({ data })
        setData(
            data.map((e) => {
                if (e._id === uid) {
                    //alert("And theKey=" + theKey + " and theValue=" + theValue)
                    var updatedE = e;
                    e[theKey] = theValue;
                    console.log(updatedE)
                    return updatedE;
                }
                else {
                    return e;
                }
            })
        );
    }

    return (
        <div className="sp-content-area">
            {loading &&
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Loader />
                    <span style={{ marginTop: '35px', color: '#1a73e8', fontWeight: 'bold' }}>Loading Settings ...</span>
                </div>
            }

            {!loading &&
                <div>
                    <div
                        style={{
                            textAlign: 'left',
                            padding: '10px',
                            paddingLeft: '30px',
                            color: 'white',
                            background: '#1a73e8'
                        }}
                    >
                        <h3>Settings</h3>
                    </div>

                    <button style={{ float: 'right', margin: '10px' }} className='btn btn-success' disabled={true}><i className='fa fa-plus'></i> Add New</button>

                    <div style={{ margin: '10px' }}>
                        <table style={{ fontSize: '14px' }}>
                            <thead>
                                <tr>
                                    <th >
                                        ID
                                    </th>

                                    <th >
                                        Key
                                    </th>

                                    <th >
                                        Value
                                    </th>

                                    <th >
                                        Actions
                                    </th>

                                </tr>
                            </thead>
                            <tbody>

                                {data.map(e => {
                                    return (
                                        <tr >
                                            <td>{e._id}</td>
                                            <td>{e.key}</td>
                                            <td>{e.value}</td>
                                            <td>
                                                <button className='btn btn-primary' onClick={(ev) => {
                                                    var coordinates = ev.target.getBoundingClientRect();
                                                    setEditKVP(e);
                                                    setEditor({ x: coordinates.x, y: coordinates.y });
                                                    setEditToggle(!editToggle);
                                                }}>Edit</button>
                                                <button className='btn btn-danger' disabled={true}>Delete</button>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {data.length === 0 && <tr><td colSpan={4}><b>Nothing here.</b></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            {editToggle && <EditSetting editKVP={editKVP} editor={editor} closeHandler={() => { setEditToggle(false) }} updateSettings={updateSettings} />}

        </div>
    );
}

export default SASetting;
